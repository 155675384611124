import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';

// import Main from 'layouts/Main';
import { Minimal } from 'layouts';
import Container from 'components/Container';
import { Form } from './components';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Alert,
  Avatar,
  CircularProgress,
  Typography,
  useMediaQuery,
} from '@mui/material';
import validateInvitationToken from 'redux/actions/Auth/validateInvitationToken';

const SignupCover = () => {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const isSm = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  });
  const isXs = useMediaQuery(theme.breakpoints.up('xs'), {
    defaultMatches: true,
  });
  const { isLoggedIn } = useSelector((state) => state.firebaseSession);
  const params = new URLSearchParams(window.location.search);
  const invitationId = params.get('invitationId');
  const epst = params.get('epst');
  const prcit = params.get('prcit');
  const [validatingLink, setValidatingLink] = useState(false);
  const [linkValid, setLinkValid] = useState(false);
  const [linkValidationError, setLinkValidationError] = useState('');
  const invitationValidationData = useSelector(
    (state) => state.invitationValidationData,
  );

  useEffect(() => {
    if (invitationId) {
      setValidatingLink(true);
      dispatch(
        validateInvitationToken(invitationId, null, () => {
          setValidatingLink(false);
          setLinkValid(true);
        }),
      );
    } else {
      setLinkValid(true);
    }
  }, [invitationId]);

  useEffect(() => {
    if (epst) {
      setValidatingLink(true);
      dispatch(
        validateInvitationToken(epst, null, () => {
          setValidatingLink(false);
          setLinkValid(true);
        }),
      );
    } else {
      setLinkValid(true);
    }
  }, [epst]);

  useEffect(() => {
    if (invitationValidationData?.validating) {
      setValidatingLink(true);
    } else {
      setValidatingLink(false);
    }
  }, [invitationValidationData]);

  useEffect(() => {
    if (
      !invitationValidationData.validating &&
      invitationValidationData.error
    ) {
      setLinkValidationError(invitationValidationData.errorText);
      setValidatingLink(false);
    } else {
      setLinkValidationError('');
      setValidatingLink(false);
    }
  }, [
    invitationValidationData.error,
    invitationValidationData.validating,
    invitationValidationData.errorText,
  ]);

  useEffect(() => {
    if (isLoggedIn) {
      history.push('/');
    }
    if (invitationId) {
      setValidatingLink(true);
      dispatch(
        validateInvitationToken(invitationId, null, () => {
          setValidatingLink(false);
          setLinkValid(true);
        }),
      );
    } else {
      setLinkValid(true);
    }
  }, []);

  return (
    // <Minimal>
    <Box
      sx={{
        width: 1,
        height: isMd
          ? 'calc(100vh - 134px)'
          : isSm
          ? 'calc(100vh - 134px)'
          : isXs
          ? 'calc(100vh - 155px)'
          : 'calc(100vh - 120px)',
        overflow: 'hidden',
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
          width: '5px',
        },
        '&::-webkit-scrollbar-track': {
          boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
          webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: theme.palette.primary.main,
          borderRadius: 2,
          // outline: '1px solid slategrey',
        },
      }}
    >
      <Container paddingX={0} paddingY={0} maxWidth={1} height={1}>
        <Box
          display={'flex'}
          flexDirection={{ xs: 'column', md: 'row' }}
          position={'relative'}
          height={1}
        >
          <Box
            width={1}
            order={{ xs: 2, md: 1 }}
            display={'flex'}
            alignItems={'center'}
          >
            <Container paddingY={2} maxWidth={{ sm: 1, md: 700 }}>
              {validatingLink ? (
                <Box
                  display={'flex'}
                  alignItems="center"
                  justifyContent={'center'}
                  flexDirection="column"
                >
                  <CircularProgress />
                  <Typography
                    variant="p"
                    sx={{
                      fontWeight: 700,
                      textAlign: 'center',
                    }}
                    marginTop="20px"
                  >
                    Please wait while we validate the link ...{' '}
                  </Typography>
                </Box>
              ) : linkValid ? (
                <Form />
              ) : linkValidationError ? (
                <Box>
                  <Alert severity="error">{linkValidationError}</Alert>
                </Box>
              ) : (
                <></>
              )}
            </Container>
          </Box>
          {/* <Box
            sx={{
              flex: { xs: '0 0 100%', md: '0 0 50%' },
              position: 'relative',
              maxWidth: { xs: '100%', md: '50%' },
              order: { xs: 2, md: 2 },
              minHeight: { xs: 'auto', md: 'calc(100vh - 146px)' },
            }}
          >
            <Box
              sx={{
                width: { xs: 1, md: '50vw' },
                height: '100%',
                position: 'relative',
              }}
            >
              <Box
                sx={{
                  width: '100%',
                  height: '100%',
                  overflow: 'hidden',
                }}
              >
                <Box
                  sx={{
                    overflow: 'hidden',
                    left: '0%',
                    width: 1,
                    height: 1,
                    position: { xs: 'relative', md: 'absolute' },
                    // clipPath: {
                    //   xs: 'none',
                    //   md: 'polygon(10% 0%, 100% 0, 100% 100%, 0% 100%)',
                    // },
                    // shapeOutside: {
                    //   xs: 'none',
                    //   md: 'polygon(10% 0%, 100% 0, 100% 100%, 0% 100%)',
                    // },
                  }}
                >
                  <Box
                    sx={{
                      height: { xs: 'auto', md: 1 },
                    }}
                  >
                    <Box
                      height={{ xs: 1, md: 1 }}
                      maxHeight={{ xs: 1, md: 1 }}
                      width={1}
                      maxWidth={1}
                      sx={{
                        background: '#f1f1f1',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: 4,
                        '& .slick-list': {
                          borderRadius: 3,
                        },
                      }}
                    >
                      <Box
                        sx={{
                          width: { sm: 1, md: 0.8 },
                          margin: '0 auto',
                        }}
                      >
                        <Slider
                          autoplay
                          autoplaySpeed={10000}
                          dots={true}
                          infinite={true}
                          speed={500}
                          slidesToShow={1}
                          slidesToScroll={1}
                          arrows={false}
                          style={{
                            width: '80%',
                            margin: '0 auto',
                          }}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <Box
                              sx={{
                                width: '100%',
                                height: '380px',
                                background: theme.palette.background.paper,
                              }}
                            >
                              <Box
                                sx={{
                                  height: '70%',
                                  width: '100%',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  background:
                                    'url(/quotation-open.svg) 32px 32px fixed no-repeat, url(/quotation-closed.svg) calc(100% - 32px) calc(100% - 32px) fixed no-repeat',
                                  padding: '32px 48px',
                                }}
                              >
                                <Typography variant="boy1" textAlign={'center'}>
                                  I wish we had this in my previous job. I
                                  conducted so many interviews and wasted so
                                  much time. This would have been a life saver!
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  height: '30%',
                                  width: '100%',
                                  display: 'flex',
                                  flexDirection: 'column',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  backgroundColor: theme.palette.primary.main,
                                  padding: '32px 48px',
                                  position: 'relative',
                                }}
                              >
                                <Avatar
                                  variant="rounded"
                                  src={'/jimmy.svg'}
                                  sx={{
                                    width: 80,
                                    height: 80,
                                    position: 'absolute',
                                    top: -50,
                                    border: `5px solid ${theme.palette.primary.main}`,
                                    borderRadius: 40,
                                  }}
                                ></Avatar>
                                <Typography
                                  variant="body1"
                                  fontWeight={'bold'}
                                  color={'white'}
                                >
                                  Jimmy E.
                                </Typography>
                                <Typography variant="body1" color={'white'}>
                                  Account Executive
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <Box
                              sx={{
                                width: '100%',
                                height: '380px',
                                background: theme.palette.background.paper,
                              }}
                            >
                              <Box
                                sx={{
                                  height: '70%',
                                  width: '100%',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  background:
                                    'url(/quotation-open.svg) 32px 32px fixed no-repeat, url(/quotation-closed.svg) calc(100% - 32px) calc(100% - 32px) fixed no-repeat',
                                  padding: '32px 48px',
                                }}
                              >
                                <Typography variant="boy1" textAlign={'center'}>
                                  I love the idea of &apos;pitchathon&apos;. I
                                  would love to get some feedback on the quality
                                  of my pitch. Right now only managers give that
                                  feedback. This way I can get it whenever I
                                  need and keep getting better.
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  height: '30%',
                                  width: '100%',
                                  display: 'flex',
                                  flexDirection: 'column',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  backgroundColor: theme.palette.primary.main,
                                  padding: '32px 48px',
                                  position: 'relative',
                                }}
                              >
                                <Avatar
                                  variant="rounded"
                                  src={'/ripley.svg'}
                                  sx={{
                                    width: 80,
                                    height: 80,
                                    position: 'absolute',
                                    top: -50,
                                    border: `5px solid ${theme.palette.primary.main}`,
                                    borderRadius: 40,
                                  }}
                                ></Avatar>
                                <Typography
                                  variant="body1"
                                  fontWeight={'bold'}
                                  color={'white'}
                                >
                                  Ripley F.
                                </Typography>
                                <Typography variant="body1" color={'white'}>
                                  SDR
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <Box
                              sx={{
                                width: '100%',
                                height: '380px',
                                boxShadow:
                                  '0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.1)',
                                background: theme.palette.background.paper,
                              }}
                            >
                              <Box
                                sx={{
                                  height: '70%',
                                  width: '100%',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  background:
                                    'url(/quotation-open.svg) 32px 32px fixed no-repeat, url(/quotation-closed.svg) calc(100% - 32px) calc(100% - 32px) fixed no-repeat',
                                  padding: '32px 48px',
                                }}
                              >
                                <Typography variant="boy1" textAlign={'center'}>
                                  I really, really like it! It is a really good
                                  tool for salespeople as it gives them a good
                                  opportunity to practice along with a chance to
                                  win which is kind of a bonus.
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  height: '30%',
                                  width: '100%',
                                  display: 'flex',
                                  flexDirection: 'column',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  backgroundColor: theme.palette.primary.main,
                                  padding: '32px 48px',
                                  position: 'relative',
                                }}
                              >
                                <Avatar
                                  variant="rounded"
                                  src={'/enmanuel.svg'}
                                  sx={{
                                    width: 80,
                                    height: 80,
                                    position: 'absolute',
                                    top: -50,
                                  }}
                                ></Avatar>
                                <Typography
                                  variant="body1"
                                  fontWeight={'bold'}
                                  color={'white'}
                                >
                                  Enmanuel C.
                                </Typography>
                                <Typography variant="body1" color={'white'}>
                                  Senior Sales Engineer
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <Box
                              sx={{
                                width: '100%',
                                height: '380px',
                                boxShadow:
                                  '0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.1)',
                                background: theme.palette.background.paper,
                              }}
                            >
                              <Box
                                sx={{
                                  height: '70%',
                                  width: '100%',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  background:
                                    'url(/quotation-open.svg) 32px 32px fixed no-repeat, url(/quotation-closed.svg) calc(100% - 32px) calc(100% - 32px) fixed no-repeat',
                                  padding: '32px 48px',
                                }}
                              >
                                <Typography variant="boy1" textAlign={'center'}>
                                  The online contest was actually a really great
                                  experience. The UX is super straightforward.
                                  It is a really cool tool and is great for
                                  sales training as well.
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  height: '30%',
                                  width: '100%',
                                  display: 'flex',
                                  flexDirection: 'column',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  backgroundColor: theme.palette.primary.main,
                                  padding: '32px 48px',
                                  position: 'relative',
                                }}
                              >
                                <Avatar
                                  variant="rounded"
                                  src={'/michael.svg'}
                                  sx={{
                                    width: 80,
                                    height: 80,
                                    position: 'absolute',
                                    top: -50,
                                  }}
                                ></Avatar>
                                <Typography
                                  variant="body1"
                                  fontWeight={'bold'}
                                  color={'white'}
                                >
                                  Michael O.
                                </Typography>
                                <Typography variant="body1" color={'white'}>
                                  Senior Manager, Sales Operations
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        </Slider>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box> */}
        </Box>
      </Container>
    </Box>
    // </Minimal>
  );
};

export default SignupCover;
