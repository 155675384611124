import { auth } from '../../../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import axios from 'utils/axios';
import { v4 as uuidv4 } from 'uuid';
import showAlert from './showAlert';

const mapCrmUserToTeamMember = (payload, callback) => {
  return async (dispatch) => {
    dispatch({ type: 'MAP_TEAM_MEMBER_TO_CRM_USER' });
    try {
      let unsubscribe = onAuthStateChanged(auth, async (user) => {
        const token = await user?.getIdToken();
        const userInfo = localStorage.getItem('userInfo');
        const userId = JSON.parse(userInfo)?.id;
        const reqId = uuidv4();
        try {
          const response = await axios.post(`/us/qai/map-users`, payload, {
            params: {
              'request-id': reqId,
            },
            headers: token
              ? {
                  Authorization: `Bearer ${token}`,
                  // 'current-role': currentRole,
                  // 'x-api-version': 'v2',
                }
              : {},
          });
          if (response.status === 200) {
            await dispatch({
              type: 'MAP_TEAM_MEMBER_TO_CRM_USER_DONE',
              payload: response?.data,
            });
            if (typeof callback === 'function') {
              callback(response?.data);
            }
          } else {
            dispatch({
              type: 'MAP_TEAM_MEMBER_TO_CRM_USER_FAILED',
              payload: `Failed to map crm user to team member.`,
            });
            dispatch(
              showAlert({
                message:
                  'Failed to map crm user to team member. Please try again!',
                showCross: true,
                title: null,
                type: 'error',
                autoHideDuration: 2000,
                vertical: 'top',
                horizontal: 'center',
              }),
            );
          }
        } catch (e) {
          dispatch({
            type: 'MAP_TEAM_MEMBER_TO_CRM_USER_FAILED',
            payload: `Failed to map crm user to team member.`,
          });
          dispatch(
            showAlert({
              message:
                'Failed to map crm user to team member. Please try again!',
              showCross: true,
              title: null,
              type: 'error',
              autoHideDuration: 2000,
              vertical: 'top',
              horizontal: 'center',
            }),
          );
        }
        unsubscribe();
      });
    } catch (e) {
      dispatch({
        type: 'MAP_TEAM_MEMBER_TO_CRM_USER_FAILED',
        payload: `Failed to map crm user to team member.`,
      });
      dispatch(
        showAlert({
          message: 'Failed to map crm user to team member. Please try again!',
          showCross: true,
          title: null,
          type: 'error',
          autoHideDuration: 2000,
          vertical: 'top',
          horizontal: 'center',
        }),
      );
    }
  };
};

export default mapCrmUserToTeamMember;
