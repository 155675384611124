import React from 'react';
import PropTypes from 'prop-types';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import { BottomNavbarItems } from './components';
import { useTheme } from '@emotion/react';

const BottomNavbar = ({ open, variant, onClose, expanded }) => {
  const theme = useTheme();

  return (
    <Drawer
      anchor="top"
      onClose={() => onClose()}
      open={open}
      variant={variant}
      sx={{
        '& .MuiPaper-root': {
          height: 'auto',
          // maxHeight: 60,
          width: '100vw',
          borderTop: `1px solid ${theme.palette.background.level1}`,
          boxShadow: '0 -1px 10px #a3a3a3',
          padding: 1,
        },
      }}
      elevation={1}
    >
      <Box
        sx={{
          height: '100%',
          // padding: 1,
          // paddingTop: { xs: 2, sm: 3 },
        }}
      >
        <BottomNavbarItems onClose={onClose} expanded={false} />
      </Box>
    </Drawer>
  );
};

BottomNavbar.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  expanded: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
};

export default BottomNavbar;
