import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import useScrollTrigger from '@mui/material/useScrollTrigger';

import Container from 'components/Container';
import {
  Topbar,
  Sidebar,
  Footer,
  // ThemeModeToggler
} from './components';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';

const Auth = ({ children, colorInvert = false, bgcolor = 'transparent' }) => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const [openSidebar, setOpenSidebar] = useState(false);

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  const open = isMd ? false : openSidebar;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 38,
  });

  return (
    <ErrorBoundary>
      <Box>
        {/* <Box>
            <ThemeModeToggler />
          </Box> */}
        <AppBar
          position={'sticky'}
          sx={{
            top: 0,
            backgroundColor: trigger ? theme.palette.background.paper : bgcolor,
          }}
          elevation={trigger ? 1 : 0}
        >
          <Container paddingY={1}>
            <Topbar
              onSidebarOpen={handleSidebarOpen}
              colorInvert={trigger ? false : colorInvert}
            />
          </Container>
        </AppBar>
        <Sidebar onClose={handleSidebarClose} open={open} variant="temporary" />
        <main>
          {children}
          <Divider />
        </main>
        <Container paddingY={2}>
          <Footer />
        </Container>
      </Box>
    </ErrorBoundary>
  );
};

Auth.propTypes = {
  children: PropTypes.node,
  colorInvert: PropTypes.bool,
  bgcolor: PropTypes.string,
};

export default Auth;
