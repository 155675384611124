import {
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
  useTheme,
  Modal,
  Divider,
  Link,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tabs,
  Tab,
  Chip,
  useMediaQuery,
  DialogActions,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Tooltip,
  InputAdornment,
  FormLabel,
  FormControlLabel,
  Checkbox,
  FormGroup,
  RadioGroup,
  Radio,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  Drawer,
  Alert,
  Autocomplete,
  Badge,
  Popper,
  Fab,
  Menu,
  ListSubheader,
  Stepper,
  Step,
  StepButton,
  Switch,
} from '@mui/material';
import React, { memo, useEffect, useRef, useState } from 'react';
import Container from 'components/Container';
import { useDispatch, useSelector } from 'react-redux';
import {
  Add,
  AddLink,
  ArrowBack,
  ArrowDropDown,
  ArrowForward,
  ArrowRight,
  Article,
  Assistant,
  AudioFile,
  Audiotrack,
  AutoAwesome,
  Cached,
  Chat,
  ChevronRight,
  Close,
  CloudUpload,
  ContentPaste,
  Delete,
  Edit,
  ErrorOutline,
  FindReplace,
  FitnessCenter,
  Info,
  KeyboardArrowDown,
  KeyboardArrowRight,
  KeyboardArrowUp,
  Lock,
  Loop,
  Mic,
  MoreVert,
  PlayArrow,
  Redo,
  Refresh,
  Search,
  Send,
  StopCircle,
  Subject,
  TaskAlt,
  Timeline,
  Troubleshoot,
  Warning,
  WarningAmber,
} from '@mui/icons-material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useHistory } from 'react-router-dom';
//actions
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { detect } from 'detect-browser';
import moment from 'moment';
import RichTextEditor from 'components/RichTextEditor/RichTextEditor';
import createSalesAnalysisThread from 'redux/actions/Candidate/createSalesAnalysisThread';
import showAlert from 'redux/actions/Common/showAlert';
import checkSalesCallTranscriptStatus from 'redux/actions/Common/checkSalesCallTranscriptStatus';
import getSalesCallReviews from 'redux/actions/Common/getDeals';
import analyzeSalesCall from 'redux/actions/Common/analyzeSalesCall';

import { PropTypes } from 'prop-types';
import { Bar, Pie } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip as TooltipChart,
  Legend,
  ArcElement,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import LineGraph from 'components/LineGraph';
import parse from 'html-react-parser';
import getUserDetails from 'redux/actions/Common/getUserDetails';
import trackEvent from 'redux/actions/Common/trackEvent';
import logError from 'redux/actions/Common/logError';
import { capitalizeText, formatSeconds, getFileType } from 'utils';
import getAccounts from 'redux/actions/Compass/getAccounts';
import { useFormik, withFormik } from 'formik';
import * as yup from 'yup';

import getQuestionFramework from 'redux/actions/Compass/getQuestionFramework';
import addQuestionFrameworkSection from 'redux/actions/Compass/addQuestionFrameworkSection';
import addSectionQuestions from 'redux/actions/Compass/addSectionQuestions';
import deleteSection from 'redux/actions/Compass/deleteSection';
import updateQuestionFrameworkSection from 'redux/actions/Compass/updateQuestionFrameworkSection';
import deleteQuestion from 'redux/actions/Compass/deleteQuestion';
import updateQuestion from 'redux/actions/Compass/updateQuestion';
import deleteOption from 'redux/actions/Compass/deleteOption';
import updateOption from 'redux/actions/Compass/updateOption';
import addOption from 'redux/actions/Compass/addOption';
import addSectionReference from 'redux/actions/Compass/addSectionReference';
import getSectionsByLevel from 'redux/actions/Compass/getSectionsByLevel';
import getUploadUrlForHelpVideo from '../../../redux/actions/Common/getUploadUrlForHelpVideo';
import uploadHelpVideo from 'redux/actions/Common/uploadHelpVideo';
import verifyHelpVideoUpload from 'redux/actions/Common/verifyHelpVideoUpload';
import getHelpSections from 'redux/actions/Compass/getHelpSections';
import createHelpSection from 'redux/actions/Compass/createHelpSection';
import deleteHelpSection from 'redux/actions/Compass/deleteHelpSection';
import updateHelpSection from 'redux/actions/Compass/updateHelpSection';

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  TooltipChart,
  Legend,
  ChartDataLabels,
);

const VideoPreview = memo(
  function VideoPreview({ video, onDurationChange, style }) {
    return (
      <Box
        display={'flex'}
        flexDirection={'column'}
        sx={{
          alignItems: 'center',
          justifyContent: 'flex-start',
          ...style,
          background: '#d3d3d3',
          // borderRadius: 4,
          maxHeight: 250,
        }}
      >
        {video ? (
          <Box width={'90%'} display={'flex'} flexDirection={'column'} gap={1}>
            <video
              style={{ width: '100%', maxHeight: 250 }}
              preload={false}
              src={
                typeof video === 'string'
                  ? video
                  : window.URL.createObjectURL(video)
              }
              controls
              controlsList="nodownload"
              disablePictureInPicture
              onDurationChange={onDurationChange}
            />
          </Box>
        ) : null}
      </Box>
    );
  },
  (pre, post) => {
    return pre?.video === post?.video;
  },
);

const CompassHelpCenter = ({ uId, parentComponent }) => {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const params = new URLSearchParams(window.location.search);
  const { isLoggedIn } = useSelector((state) => state.firebaseSession);
  const userInfo = JSON.parse(localStorage.getItem('userInfo') || '{}');
  const organization = JSON.parse(localStorage.getItem('organization') || '{}');
  const { dashboardSidebarExpanded } = useSelector((state) => state.uiStates);
  const { uploadingFor, uploadPercentage } = useSelector(
    (state) => state.createSalesAnalysisThreadState,
  );
  const chatsScrollview = useRef(null);
  const { orgConfigs } = useSelector((state) => state.orgConfigs);
  const {
    accounts,
    fetching,
    fetchingPagination,
    lastSeen,
    emptyList,
  } = useSelector((state) => state.companyAccounts);
  const showControls =
    localStorage.getItem('showControls') === 'true' ? true : false;

  const { userDetails } = useSelector((state) => state.userDetails);
  const {
    showCompetitionDetailsOnTopbar,
    currentContextData,
    showBackButtonOnTopbar,
    currentNavigationLevel,
  } = useSelector((state) => state.uiStates);
  const [loading, setLoading] = useState(false);
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const isXs = useMediaQuery(theme.breakpoints.only('xs'), {
    defaultMatches: true,
  });
  const isSm = useMediaQuery(theme.breakpoints.only('sm'), {
    defaultMatches: true,
  });

  const browser = detect();
  const [viewType, setViewType] = useState('list');
  const [helpSections, setHelpSections] = useState([]);

  const [showConfirmDelete, setShowConfirmDelete] = useState(null);

  const [showAddHelpReference, setShowAddHelpReference] = useState(false);
  const [helpSectionTitle, setHelpSectionTitle] = useState('');
  const [helpText, setHelpText] = useState('');
  const [helpVideo, setHelpVideo] = useState(null);
  const helpVideoRef = useRef(null);
  const [invalidVideoFile, setInvalidVideoFile] = useState(false);
  const [showVideoPreview, setShowVideoPreview] = useState(false);
  const [helpVideoDuration, setHelpVideoDuration] = useState(0);
  const [addingHelpSection, setAddingHelpSection] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const [currentHelpSectionIndex, setCurrentHelpSectionIndex] = useState(null);

  const [activeHelpSection, setActiveHelpSection] = useState(0);

  const handleMenuClose = () => {
    setAnchorEl(null);
    setCurrentHelpSectionIndex(null);
  };

  const handleTrackEvent = (e, type) => {
    try {
      dispatch(
        trackEvent({
          context: 'click_event',
          eventType: type,
          userId: isLoggedIn ? userInfo?.id : null,
          time: new Date().getTime(),
          os: browser?.os,
          sessionId: isLoggedIn
            ? userInfo?.id
            : sessionStorage.getItem('trackingSessionId'),
          customData: {
            page_path: pathname,
            browser: browser?.name,
            browser_version: browser?.version,
          },
        }),
      );
    } catch (e) {
      console.log(e);
      dispatch(
        logError({
          message: e?.message,
          data: {
            formatted: {
              errorText: e?.message,
              severity: 'error',
            },
            raw: e,
          },
        }),
      );
    }
  };

  const handleAddHelpSection = (type) => {
    if (type === 'new') {
      setAddingHelpSection(true);
      dispatch(
        createHelpSection(
          {
            contentType: helpVideo?.type?.split(';')?.[0],
            duration: Math.round(helpVideoDuration * 1000),
            sizeInBytes: helpVideo?.size,
            text: helpText,
            title: helpSectionTitle,
          },
          (data) => {
            if (helpVideo && typeof helpVideo !== 'string') {
              dispatch(
                uploadHelpVideo(data?.uploadUrlRes?.url, helpVideo, () => {
                  dispatch(
                    verifyHelpVideoUpload(
                      data?.uploadUrlRes?.url && data?.uploadUrlRes?.videoId,
                      null,
                      () => {
                        setHelpText('');
                        setHelpSectionTitle('');
                        setHelpVideo(null);
                        setShowAddHelpReference(null);
                        setAddingHelpSection(false);
                        dispatch(
                          getHelpSections(null, (data) => {
                            console.log(data);
                            setHelpSections(data);
                          }),
                        );
                      },
                    ),
                  );
                }),
              );
            } else {
              setHelpText('');
              setHelpSectionTitle('');
              setShowAddHelpReference(null);
              setAddingHelpSection(false);
              dispatch(
                getHelpSections(null, (data) => {
                  console.log(data);
                  setHelpSections(data);
                }),
              );
            }
          },
          () => {
            setAddingHelpSection(false);
            dispatch(
              showAlert({
                message: 'Failed to update help section.',
                showCross: true,
                title: null,
                type: 'error',
                autoHideDuration: 2000,
                vertical: 'top',
                horizontal: 'center',
              }),
            );
          },
        ),
      );
    } else {
      setAddingHelpSection(true);
      dispatch(
        updateHelpSection(
          showAddHelpReference?.id,
          {
            contentType: helpVideo?.type?.split(';')?.[0],
            duration: Math.round(helpVideoDuration * 1000),
            sizeInBytes: helpVideo?.size,
            text: helpText,
            title: helpSectionTitle,
            uploadVideo:
              helpVideo && typeof helpVideo !== 'string' ? true : false,
          },
          (data) => {
            if (helpVideo && typeof helpVideo !== 'string') {
              dispatch(
                uploadHelpVideo(data?.uploadUrlRes?.url, helpVideo, () => {
                  dispatch(
                    verifyHelpVideoUpload(
                      data?.uploadUrlRes?.url && data?.uploadUrlRes?.videoId,
                      null,
                      () => {
                        setHelpVideo(null);
                        setHelpText('');
                        setHelpSectionTitle('');
                        setShowAddHelpReference(null);
                        setAddingHelpSection(false);
                        dispatch(
                          getHelpSections(null, (data) => {
                            console.log(data);
                            setHelpSections(data);
                          }),
                        );
                      },
                    ),
                  );
                }),
              );
            } else {
              setHelpText('');
              setHelpSectionTitle('');
              setShowAddHelpReference(null);
              setAddingHelpSection(false);
              dispatch(
                getHelpSections(null, (data) => {
                  console.log(data);
                  setHelpSections(data);
                }),
              );
            }
          },
          () => {
            setAddingHelpSection(false);
            dispatch(
              showAlert({
                message: 'Failed to update help section.',
                showCross: true,
                title: null,
                type: 'error',
                autoHideDuration: 2000,
                vertical: 'top',
                horizontal: 'center',
              }),
            );
          },
        ),
      );
    }
  };

  const handleVideoFilePicker = (event) => {
    handleTrackEvent(event, 'help_video_selected');
    if (
      event.target.files[0] &&
      event.target.files[0].type.includes('video') &&
      event.target.files[0]?.size <= 300 * 1000 * 1000
    ) {
      setHelpVideo(event.target.files[0]);
      setInvalidVideoFile(false);
    } else {
      helpVideoRef.current.value = null;
      setInvalidVideoFile(
        'Please select a valid video file of size not exceeding 300 MB.',
      );
    }
  };

  useEffect(() => {
    if (showAddHelpReference) {
      setHelpSectionTitle(showAddHelpReference?.title);
      setHelpText(showAddHelpReference?.text);
      setHelpVideo(showAddHelpReference?.customFields?.url);
    }
  }, [showAddHelpReference]);

  useEffect(() => {
    dispatch(
      getHelpSections(null, (data) => {
        // console.log(data);
        setHelpSections(data);
      }),
    );
  }, []);

  return (
    <Box
      sx={{
        width: {
          xs: '100%',
          sm: isLoggedIn ? 'calc(100% - 80px)' : 1,
          md:
            isLoggedIn && !uId
              ? dashboardSidebarExpanded
                ? 'calc(100% - 240px)'
                : 'calc(100% - 80px)'
              : 1,
        },
        marginLeft: {
          xs: '0px',
          sm: isLoggedIn ? '80px' : 0,
          md:
            isLoggedIn && !uId
              ? dashboardSidebarExpanded
                ? '240px'
                : '80px'
              : 0,
        },
        height: 1,
        minHeight:
          isLoggedIn && !uId ? 'calc(100vh - 60px)' : 'calc(100vh - 146px)',
      }}
    >
      <Box
        display={'flex'}
        flexDirection={{ xs: 'column', sm: 'column', md: 'row' }}
        height={1}
      >
        <Box
          flex={{ xs: 'auto', sm: 'auto', md: 1 }}
          display={'flex'}
          flexDirection={{ xs: 'row', sm: 'row', md: 'column' }}
          alignItems={{
            xs: 'start',
            sm: 'start',
            md: 'start',
          }}
          justifyContent={{
            xs: 'start',
            sm: 'start',
            md: 'flex-start',
          }}
          height={1}
          gap={2}
          sx={{
            borderRight: '1px solid #f1f1f1',
            minHeight: isXs ? 'auto' : 'calc(100vh - 60px)',
            padding: uId ? 0 : 2,
            paddingTop: 2,
          }}
        >
          {viewType === 'list' ? (
            <Box
              display={'flex'}
              flexDirection={'column'}
              alignItems={'start'}
              gap={1}
              width={1}
            >
              {helpSections && helpSections.length > 0 ? (
                <Box width={1}>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<Add />}
                    size="small"
                    onClick={() => setShowAddHelpReference('new')}
                  >
                    Create New Help Section
                  </Button>
                  <Box
                    display={'flex'}
                    mt={2}
                    sx={{ border: '1px solid #d3d3d3' }}
                  >
                    <Box
                      flex={1}
                      sx={{
                        maxHeight: 'calc(100vh - 150px)',
                        overflowY: 'scroll',
                        '&::-webkit-scrollbar': {
                          width: '5px',
                        },
                        '&::-webkit-scrollbar-track': {
                          boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                          webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                        },
                        '&::-webkit-scrollbar-thumb': {
                          backgroundColor: theme.palette.primary.main,
                          borderRadius: 2,
                          // outline: '1px solid slategrey',
                        },
                        direction: 'rtl',
                      }}
                    >
                      <Box
                        display={'flex'}
                        flexDirection={'column'}
                        sx={{
                          direction: 'ltr',
                        }}
                      >
                        {helpSections?.map((section, index) => {
                          return (
                            <Box
                              key={section?.id}
                              sx={{
                                display: 'flex',
                                gap: 2,
                                p: 2,
                                position: 'relative',
                                background:
                                  index === activeHelpSection
                                    ? '#f1f1f1'
                                    : '#fff',
                                borderBottom: '1px solid #d3d3d3',
                                borderRight:
                                  index === activeHelpSection
                                    ? 'none'
                                    : '1px solid #d3d3d3',
                                cursor: 'pointer',
                              }}
                              onClick={() => {
                                setActiveHelpSection(index);
                              }}
                            >
                              <Typography>{section?.title}</Typography>
                              <IconButton
                                size="small"
                                onClick={(e) => {
                                  setAnchorEl(e.target);
                                  setCurrentHelpSectionIndex(index);
                                }}
                                sx={{
                                  position: 'absolute',
                                  top: 5,
                                  right: 5,
                                }}
                              >
                                <MoreVert />
                              </IconButton>
                              <Menu
                                id="simple-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={
                                  Boolean(anchorEl) &&
                                  currentHelpSectionIndex === index
                                }
                                onClose={handleMenuClose}
                                sx={{
                                  '& .MuiMenu-paper': {
                                    boxShadow:
                                      '0 2px 2px 2px rgba(140, 152, 164, 0.2)',
                                  },
                                }}
                              >
                                <MenuItem
                                  // disabled
                                  onClick={() => {
                                    setShowAddHelpReference(section);
                                    handleMenuClose();
                                  }}
                                >
                                  Update Help Section
                                </MenuItem>
                                <MenuItem
                                  onClick={() => {
                                    setShowConfirmDelete(section?.id);
                                    setAnchorEl(null);
                                  }}
                                >
                                  Delete Help Section
                                </MenuItem>
                              </Menu>
                            </Box>
                          );
                        })}
                      </Box>
                    </Box>
                    <Box
                      display={'flex'}
                      flexDirection={'column'}
                      gap={2}
                      flex={1}
                      padding={2}
                      sx={{
                        background: '#f1f1f1',
                      }}
                    >
                      <VideoPreview
                        video={
                          helpSections?.[activeHelpSection]?.customFields?.url
                        }
                        onDurationChange={(e) => {
                          // setHelpVideoDuration(e.target.duration);
                        }}
                        style={{ flex: 1, maxHeight: 200 }}
                      />
                      <Divider orientation="vertical" flexItem />
                      <Box
                        display={'flex'}
                        flexDirection={'column'}
                        gap={1}
                        flex={1}
                        sx={{
                          maxHeight: 300,
                          overflowY: 'scroll',
                          '&::-webkit-scrollbar': {
                            width: '5px',
                          },
                          '&::-webkit-scrollbar-track': {
                            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                          },
                          '&::-webkit-scrollbar-thumb': {
                            backgroundColor: theme.palette.primary.main,
                            borderRadius: 2,
                          },
                          border: '1px solid #d3d3d3',
                        }}
                      >
                        <RichTextEditor
                          readOnly
                          value={helpSections?.[activeHelpSection]?.text}
                          onChange={() => {}}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              ) : (
                <Box
                  width={1}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                  flexDirection={'column'}
                  gap={1}
                  // minHeight={150}
                  p={2}
                >
                  <Typography>No Help Sections Present</Typography>
                  <Button
                    startIcon={<Add />}
                    onClick={() => setShowAddHelpReference('new')}
                  >
                    Add new help section
                  </Button>
                </Box>
              )}
            </Box>
          ) : (
            <Box
              display={'flex'}
              flexDirection={'column'}
              gap={1}
              width={1}
            ></Box>
          )}
        </Box>
      </Box>
      <Modal
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        open={Boolean(showAddHelpReference)}
        onClose={(e, reason) => {
          if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
            return;
          }
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box
          sx={{
            backgroundColor: theme.palette.background.paper,
            outline: 'none',
            maxHeight: '90vh',
            maxWidth: '50vw',
            minWidth: '50vw',
            overflowY: 'auto',
            borderRadius: 1,
            // padding: 2,
            position: 'relative',
          }}
        >
          <IconButton
            sx={{
              position: 'absolute',
              top: 10,
              right: 10,
              padding: '4px',
              background: theme.palette.background.default,
              ':hover': {
                backgroundColor: '#d3d3d3',
              },
            }}
            onClick={(e) => {
              setShowAddHelpReference(null);
            }}
          >
            <Close sx={{ fontSize: 20 }} />
          </IconButton>
          <Box
            p={2}
            sx={{
              borderBottom: '1px solid #d3d3d3',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Typography color={'text.secondary'}>
              Add Help Reference Section
            </Typography>
          </Box>
          <Box
            sx={{
              width: '100%',
              // minHeight: 'calc(80vh - 58px)',
              maxHeight: 'calc(90vh - 58px)',
              overflowY: 'scroll',
              '&::-webkit-scrollbar': {
                width: '5px',
              },
              '&::-webkit-scrollbar-track': {
                boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: theme.palette.primary.main,
                borderRadius: 2,
              },
              margin: '0 auto',
              overflowX: 'hidden',
              p: 2,
            }}
          >
            <Box display={'flex'} flexDirection={'column'} gap={1}>
              <TextField
                autoFocus
                label="Help Section Title"
                placeholder="Enter Help Section Title"
                variant="outlined"
                color="primary"
                size="small"
                name="helpSectionTitle"
                value={helpSectionTitle}
                onChange={(e) => setHelpSectionTitle(e.target.value)}
                sx={{
                  flex: 1,
                }}
              />
              <Box
                display={'flex'}
                flexDirection={'row'}
                gap={1}
                alignItems={'stretch'}
              >
                <Box flex={2}>
                  <RichTextEditor
                    value={helpText}
                    onChange={setHelpText}
                    placeholder="Enter Help Text"
                  />
                </Box>
                <Box flex={1}>
                  <InputLabel htmlFor="videoFile" style={{ cursor: 'pointer' }}>
                    <input
                      style={{ display: 'none' }}
                      id="videoFile"
                      ref={helpVideoRef}
                      type="file"
                      accept="video/*"
                      onChange={(e) => {
                        handleVideoFilePicker(e);
                      }}
                    />
                    <Box
                      sx={{
                        border: '1px dashed #d3d3d3',
                        borderRadius: 4,
                        p: 2,
                        textAlign: 'center',
                      }}
                      display={'flex'}
                      flexDirection={'column'}
                      justifyContent={'center'}
                      alignItems={'center'}
                      gap={1}
                    >
                      {helpVideo ? (
                        <Box>
                          <Typography
                            color={'#677788'}
                            variant="caption"
                            sx={{ whiteSpace: 'wrap' }}
                          >
                            {helpVideo?.name}
                          </Typography>
                          <VideoPreview
                            video={helpVideo}
                            onDurationChange={(e) => {
                              setHelpVideoDuration(e.target.duration);
                            }}
                          />
                        </Box>
                      ) : (
                        <CloudUpload
                          sx={{
                            fontSize: 20,
                            color: theme.palette.primary.main,
                          }}
                        />
                      )}
                      <Box
                        sx={{
                          background: theme.palette.primary.main,
                          borderRadius: 1,
                          padding: '4px 8px',
                        }}
                      >
                        <Typography variant="subtitle2" color={'#fff'}>
                          {helpVideo
                            ? 'Change Help Video'
                            : 'Choose Help Video'}
                        </Typography>
                      </Box>
                      <Box display={'flex'} flexDirection={'column'} gap={1}>
                        {invalidVideoFile ? (
                          <Alert severity="error">
                            <Typography
                              variant="caption"
                              sx={{
                                whiteSpace: 'pre-wrap',
                                color: theme.palette.error.main,
                              }}
                            >
                              {invalidVideoFile}
                            </Typography>
                          </Alert>
                        ) : null}
                        <Typography
                          variant="caption"
                          sx={{
                            whiteSpace: 'pre-wrap',
                          }}
                        >
                          (Common video formats supported)(Optional)
                        </Typography>
                        <Typography
                          variant="caption"
                          sx={{
                            whiteSpace: 'pre-wrap',
                          }}
                        >
                          Upload upto 300 MB of video
                        </Typography>
                      </Box>
                    </Box>
                  </InputLabel>
                  {/* )} */}
                </Box>
              </Box>
              <Button
                variant="contained"
                color="primary"
                startIcon={
                  addingHelpSection ? <CircularProgress size={20} /> : null
                }
                disabled={
                  !helpSectionTitle ||
                  !helpText ||
                  !helpVideo ||
                  addingHelpSection
                }
                onClick={() => {
                  handleAddHelpSection(showAddHelpReference);
                }}
                sx={{
                  mt: 4,
                }}
              >
                {showAddHelpReference !== 'new' ? 'Update' : 'Add'}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
      <Dialog
        open={Boolean(showConfirmDelete)}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          zIndex: 10002,
          '& .MuiPaperRootMuiDialogPaper': {
            maxWidth: '70vw',
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">Confirm</DialogTitle>
        <DialogContent>
          <Box display={'flex'} alignItems={'center'} gap={2}>
            <DialogContentText id="alert-dialog-description" flex={1}>
              Are you sure you want to delete this help section?
            </DialogContentText>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            // data-trackid={'cta_permission_denied_dialog_close'}
            onClick={(e) => {
              setShowConfirmDelete(null);
              // if (testMode) setShowTestDeviceDialog(null);
            }}
            color="primary"
            // autoFocus
          >
            Cancel
          </Button>
          <Button
            size="small"
            variant="contained"
            // data-trackid={'cta_permission_denied_dialog_close'}
            onClick={(e) => {
              dispatch(
                deleteHelpSection(showConfirmDelete, (data) => {
                  setShowConfirmDelete(false);
                  dispatch(
                    showAlert({
                      message: 'Section deleted successfully.',
                      showCross: true,
                      title: null,
                      type: 'success',
                      autoHideDuration: 2000,
                      vertical: 'top',
                      horizontal: 'center',
                    }),
                  );
                  dispatch(
                    getHelpSections(null, (data) => {
                      console.log(data);
                      setHelpSections(data);
                    }),
                  );
                }),
              );
            }}
            color="primary"
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

CompassHelpCenter.propTypes = {
  uId: PropTypes.string,
  member: PropTypes.object,
  parentComponent: PropTypes.string,
};

VideoPreview.propTypes = {
  video: PropTypes.any,
  onDurationChange: PropTypes.func,
  style: PropTypes.object,
};

export default CompassHelpCenter;
