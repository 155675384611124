import React from 'react';
import PropTypes from 'prop-types';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import { SidebarNav } from './components';
import { useTheme } from '@emotion/react';

const Sidebar = ({ open, variant, onClose, expanded }) => {
  const theme = useTheme();

  return (
    <Drawer
      anchor="left"
      onClose={() => onClose()}
      open={open}
      variant={variant}
      sx={{
        '& .MuiPaper-root': {
          width: '100%',
          maxWidth: expanded ? 240 : 80,
          height: '100vh',
          borderRight: `1px solid ${theme.palette.background.level1}`,
        },
      }}
    >
      <Box
        sx={{
          height: '100%',
          // padding: 1,
          // paddingTop: { xs: 2, sm: 3 },
        }}
      >
        <SidebarNav onClose={onClose} expanded={expanded} />
      </Box>
    </Drawer>
  );
};

Sidebar.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  expanded: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
};

export default Sidebar;
