import React, { useState, useEffect, useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import {
  Box,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Drawer,
  Grid,
  IconButton,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemSecondaryAction,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Divider,
  Switch,
  Menu,
  MenuItem,
  Card,
  Popper,
  Select,
  InputLabel,
  Modal,
  TableContainer,
  Paper,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Checkbox,
  Alert,
} from '@mui/material';
import MediaPreview from 'components/@2024/MediaPreview';
import { useDispatch, useSelector } from 'react-redux';
import trackEvent from 'redux/actions/Common/trackEvent';
import { detect } from 'detect-browser';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import logError from 'redux/actions/Common/logError';
import ParticipationPieChart from 'components/@2024/ParticipationPieChart';
import parse from 'html-react-parser';
import LineGraph from 'components/LineGraph';
import { PropTypes } from 'prop-types';

import { Bar, Pie } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip as TooltipChart,
  Legend,
  ArcElement,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {
  ArrowBack,
  Article,
  AutoAwesome,
  Cancel,
  CheckCircle,
  ChevronRight,
  Close,
  CloseRounded,
  Fullscreen,
  KeyboardArrowDown,
  KeyboardArrowUp,
  MoreVert,
} from '@mui/icons-material';
import RichTextEditor from 'components/RichTextEditor';
import getFeedbacksOnEntity from 'redux/actions/Candidate/getFeedbacksOnEntity';
import { capitalizeText, getMatches, validateEmail } from 'utils';
import moment from 'moment';
import postFeedbackOnEntity from 'redux/actions/Candidate/postFeedbackOnEntity';
import showAlert from 'redux/actions/Common/showAlert';
import checkSalesCallScoreStatus from 'redux/actions/Common/checkSalesCallScoreStatus';
import syncDataWithHubspot from 'redux/actions/Common/syncDataWithHubspot';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import updateSalesReviewData from 'redux/actions/Common/updateSalesReviewData';
import getSalesCallReviews from 'redux/actions/Common/getDeals';
import getConversationById from 'redux/actions/Candidate/getConversationById';
import getMessageResponse from 'redux/actions/Candidate/getMessageResponse';
import checkPracticeCallScoreStatus from 'redux/actions/Common/checkPracticeCallScoreStatus';
import checkCallWithTokenScoreStatus from 'redux/actions/Common/checkCallWithTokenScoreStatus';
import ProfileCompletion from 'components/ProfileCompletion';
import getAllHubspotDeals from 'redux/actions/Common/getAllHubspotDeals';
import getAllHubspotUsers from 'redux/actions/Common/getAllHubspotUsers';
import updateHubspotDeal from 'redux/actions/Common/updateHubspotDeal';
import mapHubspotUserToTeamMember from 'redux/actions/Common/mapHubspotUserToTeamMember';
import getUserDetails from 'redux/actions/Common/getUserDetails';
import getAllHubspotContacts from 'redux/actions/Common/getAllHubspotContacts';
import getOrganizationConfigs from 'redux/actions/Common/getOrganizationConfigs';

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  TooltipChart,
  Legend,
  ChartDataLabels,
);

const ReportPage = function ReportPage({
  type,
  report,
  setSalesReview,
  handleReanalyze,
  searchKeywords,
  searchKeywordsMap,
  setShowReAnalyzeDrawer,
  uId,
  tk,
  pc,
  setShowCrmUserMappingDialog,
  setShowUserNotMappedDialog,
  setShowSyncCrmPropertiesDialog,
}) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { isLoggedIn } = useSelector((state) => state.firebaseSession);
  const userInfo = JSON.parse(localStorage.getItem('userInfo') || '{}');
  const { userDetails } = useSelector((state) => state.userDetails);
  const feedbacksOnEntity = useSelector((state) => state.feedbacksOnEntity);
  const scrollDivMinimizedRef = useRef();
  const scrollDiv1Ref = useRef();
  const scrollDivMaximizedRef = useRef();

  const [scriptReadingFillerWords, setScriptReadingFillerWords] = useState([]);
  const [scriptRepeatWords, setScriptRepeatWords] = useState([]);
  const [fillerWordsRegexp, setFillerWordsRegexp] = useState(null);
  const [repeatWordsRegexp, setRepeatWordRegexp] = useState(null);

  const [currentParam, setCurrentParam] = useState('conversationScore');
  const browser = detect();

  const checkSalesCallScoreStatusTimer = useRef(null);
  const checkPresetQuestionsStatusTimer = useRef(null);
  const [scoreCalculationInProcess, setScoreCalculationInProcess] = useState(
    {},
  );

  const [currentMethod, setCurrentMethod] = useState(
    report?.analysis?.methodAnalysis?.callSummary?.analysis ||
      report?.analysis?.methodAnalysis?.callSummary?.analysisJson ||
      report?.analysis?.methodAnalysis?.callSummary?.analysisHtml
      ? 'callSummary'
      : report?.playbook
      ? 'playbook'
      : '',
  );
  const [showPlaybook, setShowPlaybook] = useState(false);

  const conversationDetailsById = useSelector(
    (state) => state.conversationDetailsById,
  );

  const { conversationDetails } = conversationDetailsById;
  const { orgConfigs } = useSelector((state) => state.orgConfigs);

  const [showSyncHubspotDialog, setShowSyncHubspotDialog] = useState(false);
  const [meetingTitle, setMeetingTitle] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [contactFirstName, setContactFirstName] = useState('');
  const [contactLastName, setContactLastName] = useState('');
  const [organizer, setOrganizer] = useState('');
  const [dateTime, setDateTime] = useState(new Date().getTime());
  const [syncingWithHubspot, setSyncingWithHubspot] = useState(false);

  const [feedbackOnEntity, setFeedbackOnEntity] = useState('');
  const [postingFeedback, setPostingFeedback] = useState(false);
  const [showAllFillers, setShowAllFillers] = useState(false);
  const [showAllRepetitions, setShowAllRepetitions] = useState(false);
  const playbookRef = useRef(null);
  const playbookRef1 = useRef(null);

  const [maximizedPlaybookFeedback, setMaximizedPlaybookFeedback] = useState(
    false,
  );

  const [maximizedTranscriptView, setMaximizedTranscriptView] = useState(false);

  const videoRef = useRef(null);

  const [showUpdateSpeakersDialog, setShowUpdateSpeakersDialog] = useState(
    false,
  );
  const [speaker, setSpeaker] = useState('Speaker 1');
  const [speakers, setSpeakers] = useState({});
  const [showSpeakerMismatch, setShowSpeakerMismath] = useState(null);
  const [reanalyzing, setReanalyzing] = useState(false);
  const [updatingSpeakers, setUpdatingSpeakers] = useState(false);

  const [scoreType, setScoreType] = useState('Speaker 1');
  const [matchIds, setMatchIds] = useState({});
  const [matchOffsets, setMatchOffsets] = useState({});

  const [currentDirection, setCurrentDirection] = useState('next');
  const [currentKeyword, setCurrentKeyword] = useState('');
  const [currentKeyIndex, setCurrentKeyIndex] = useState({});
  const [currentTranscriptRef, setCurrentTranscriptRef] = useState('minimized');
  const [showMatchingKeywords, setShowMatchingKeywords] = useState(true);

  const [anchorEl, setAnchorEl] = useState(null);

  const [askAIQuestion, setAskAIQuestion] = useState('');
  const [wordsLimit, setWordsLimit] = useState(50);
  const chatsScrollview = useRef(null);
  const [generatingResponse, setGeneratingResponse] = useState(false);

  const [showPresetQuestions, setShowPresetQuestions] = useState(true);

  const [anchorElDetails, setAnchorElDetails] = useState(null);
  const [popperDetails, setPopperDetails] = useState(null);
  const [fetchingConversationById, setFetchingConversationById] = useState(
    false,
  );

  const handleTrackEvent = (e, type) => {
    try {
      dispatch(
        trackEvent({
          context: 'click_event',
          eventType: type,
          userId: isLoggedIn ? userInfo?.id : null,
          time: new Date().getTime(),
          os: browser?.os,
          sessionId: isLoggedIn
            ? userInfo?.id
            : sessionStorage.getItem('trackingSessionId'),
          customData: {
            page_path: pathname,
            browser: browser?.name,
            browser_version: browser?.version,
          },
        }),
      );
    } catch (e) {
      console.log(e);
      dispatch(
        logError({
          message: e?.message,
          data: {
            formatted: {
              errorText: e?.message,
              severity: 'error',
            },
            raw: e,
          },
        }),
      );
    }
  };

  const handleHightlightMove = (type, keyword) => {
    if (type === 'next') {
      if (
        currentKeyIndex?.[keyword] ===
        matchIds[keyword?.split(' ')?.join('_')]?.length - 1
      ) {
        setCurrentKeyIndex((prev) => {
          return {
            ...prev,
            [keyword]: 0,
          };
        });
      } else {
        setCurrentKeyIndex((prev) => {
          return {
            ...prev,
            [keyword]: prev[keyword] + 1,
          };
        });
      }
    } else {
      if (currentKeyIndex?.[keyword] === 0) {
        setCurrentKeyIndex((prev) => {
          return {
            ...prev,
            [keyword]: matchIds[keyword?.split(' ')?.join('_')]?.length - 1,
          };
        });
      } else {
        setCurrentKeyIndex((prev) => {
          return {
            ...prev,
            [keyword]: prev[keyword] - 1,
          };
        });
      }
    }
    setCurrentKeyword(keyword);
    setCurrentDirection(type);
  };

  const handleFeedbackListScroll = (e, paginate) => {
    if (
      (e.target.scrollTop + e.target.clientHeight + 1 >=
        e.target.scrollHeight &&
        // viewType === 'list' &&
        !feedbacksOnEntity?.fetching &&
        !feedbacksOnEntity?.fetchingPagination &&
        feedbacksOnEntity?.feedbacks &&
        feedbacksOnEntity?.feedbacks.length % 10 === 0 &&
        feedbacksOnEntity?.emptyList === false) ||
      paginate
    ) {
      dispatch(
        getFeedbacksOnEntity(
          report?.id,
          10,
          feedbacksOnEntity?.lastSeen,
          () => {},
        ),
      );
    }
  };

  const handleUpdateSalesReview = (reanalyze) => {
    if (
      speakers &&
      Object.keys(speakers || {}).length > 0 &&
      Object.keys(speakers || {})?.filter((speaker) => !speakers[speaker])
        ?.length > 0
    ) {
      return;
    } else {
      if (reanalyze) {
        setReanalyzing(true);
        handleReanalyze(report?.id, 'list', null, speakers, speaker, () => {
          setShowUpdateSpeakersDialog(false);
          setReanalyzing(false);
        });
      } else {
        setUpdatingSpeakers(true);
        dispatch(
          updateSalesReviewData(
            report?.id,
            {
              speaker: speaker,
              speakers: speakers,
            },
            (data) => {
              setUpdatingSpeakers(false);
              setShowUpdateSpeakersDialog(false);
              setSalesReview((prev) => {
                return data;
              });
              dispatch({
                type: 'UPDATE_SALES_CALL_ANALYSIS',
                payload: {
                  id: report?.id,
                  data: data,
                },
              });
            },
            () => {
              setUpdatingSpeakers(false);
            },
          ),
        );
      }
    }
  };

  const handleSpeakerChange = (value) => {
    if (value !== report?.speaker) {
      setShowSpeakerMismath(value);
    } else {
      setSpeaker(value);
    }
  };

  useEffect(() => {
    if (maximizedTranscriptView) {
      setCurrentTranscriptRef('maximized');
    } else {
      setCurrentTranscriptRef('minimized');
    }
  }, [maximizedTranscriptView]);

  useEffect(() => {
    if (showMatchingKeywords) {
      if (
        currentKeyIndex &&
        Object.keys(currentKeyIndex || {})?.length > 0 &&
        currentKeyword
      ) {
        [].slice
          .call(document.getElementsByTagName('mark'))
          .forEach((el) => (el.style.backgroundColor = '#4672c8'));
        [].slice
          .call(document.getElementsByTagName('mark'))
          .forEach((el) => (el.style.color = '#fff'));
        const highlightElement = document.getElementById(
          `${currentKeyword?.split(' ')?.join('_')}${
            matchIds[currentKeyword?.split(' ')?.join('_')]?.[
              currentKeyIndex?.[currentKeyword]
            ]
          }`,
        );
        if (highlightElement) {
          if (currentTranscriptRef === 'maximized') {
            scrollDivMaximizedRef?.current?.scrollTo({
              top:
                currentDirection === 'prev'
                  ? highlightElement?.offsetTop - 120
                  : highlightElement?.offsetTop - 80,
              behavior: 'smooth',
            });
          } else {
            scrollDivMinimizedRef?.current?.scrollTo({
              top:
                currentDirection === 'prev'
                  ? highlightElement?.offsetTop - 120
                  : highlightElement?.offsetTop - 80,
              behavior: 'smooth',
            });
          }
          highlightElement.style.backgroundColor = '#fbf030';
          highlightElement.style.color = '#000';
        }
      }
    }
  }, [
    currentKeyIndex,
    currentKeyword,
    currentTranscriptRef,
    showMatchingKeywords,
  ]);

  useEffect(() => {
    if (scrollDivMinimizedRef && scrollDivMinimizedRef.current) {
      scrollDivMinimizedRef.current.scrollTo({
        top: 0,
      });
    }
    if (scrollDivMaximizedRef && scrollDivMaximizedRef.current) {
      scrollDivMaximizedRef.current.scrollTo({
        top: 0,
      });
    }
  }, [currentParam]);

  useEffect(() => {
    if (scrollDiv1Ref && scrollDiv1Ref.current) {
      scrollDiv1Ref.current.scrollTo({
        top: 0,
      });
    }
  }, [currentMethod]);

  useEffect(() => {
    if (report) {
      setSpeaker(report?.speaker);
      setScoreType(
        Object.keys(report?.speakers || {})?.find(
          (spk) => spk === report?.speaker,
        ) || 'Speaker 1',
      );
      setSpeakers(report?.speakers);
      setMeetingTitle(report?.title);
    }
  }, [report]);

  useEffect(() => {
    if (
      scriptReadingFillerWords &&
      Array.isArray(scriptReadingFillerWords) &&
      scriptReadingFillerWords.length > 0
    ) {
      setFillerWordsRegexp(
        new RegExp(
          scriptReadingFillerWords.map((w) => `{${w}}`).join('|'),
          'ig',
        ),
      );
    }
  }, [scriptReadingFillerWords]);

  useEffect(() => {
    if (
      scriptRepeatWords &&
      Array.isArray(scriptRepeatWords) &&
      scriptRepeatWords.length > 0
    ) {
      setRepeatWordRegexp(
        new RegExp(scriptRepeatWords.map((w) => `{${w}}`).join('|'), 'ig'),
      );
    }
  }, [scriptRepeatWords]);

  useEffect(() => {
    if (
      report?.analysis?.fillerInfo?.fillers &&
      Object.keys(report?.analysis?.fillerInfo?.fillers) &&
      Object.keys(report?.analysis?.fillerInfo?.fillers).length > 0
    ) {
      setScriptReadingFillerWords(
        Object.keys(report?.analysis?.fillerInfo?.fillers),
      );
    }
    if (
      report?.analysis?.repetitionInfo?.repetitions &&
      Object.keys(report?.analysis?.repetitionInfo?.repetitions) &&
      Object.keys(report?.analysis?.repetitionInfo?.repetitions).length > 0
    ) {
      setScriptRepeatWords(
        Object.keys(report?.analysis?.repetitionInfo?.repetitions),
      );
    }
  }, [report]);

  useEffect(() => {
    if (currentMethod === 'feedback') {
      dispatch(getFeedbacksOnEntity(report?.id, 10, null, () => {}));
    } else if (currentMethod === 'askAI') {
      setFetchingConversationById(true);
      dispatch(
        getConversationById(report?.conversationId, report?.speaker, (data) => {
          setFetchingConversationById(false);
          if (
            data?.presetQuestionStatus === 'completed' ||
            data?.presetQuestionStatus === 'failed'
          ) {
            clearInterval(checkPresetQuestionsStatusTimer.current);
            if (chatsScrollview?.current) {
              chatsScrollview?.current?.scroll({
                top: chatsScrollview?.current?.scrollHeight,
                behaviour: 'smooth',
              });
            }
          } else {
            checkPresetQuestionsStatusTimer.current = setInterval(() => {
              dispatch(
                getConversationById(
                  report?.conversationId,
                  report?.speaker,
                  (data) => {
                    if (
                      data?.presetQuestionStatus === 'completed' ||
                      data?.presetQuestionStatus === 'failed'
                    ) {
                      clearInterval(checkPresetQuestionsStatusTimer.current);
                    }
                  },
                ),
              );
            }, 5000);
          }
        }),
      );
    }
    return () => clearInterval(checkPresetQuestionsStatusTimer.current);
  }, [currentMethod]);

  useEffect(() => {
    if (showPlaybook && typeof showPlaybook === 'string') {
      if (playbookRef.current && report?.playbook) {
        const sectionId = document.getElementById(showPlaybook);
        playbookRef.current.scrollTo({
          top: sectionId?.offsetTop || 0,
          behavior: 'smooth',
        });
      }
    }
  }, [showPlaybook]);

  useEffect(() => {
    if (report?.transcript && searchKeywords && searchKeywords.length > 0) {
      const matchIdsMap = searchKeywords.reduce((acc, curr) => {
        acc[curr?.split(' ')?.join('_')] =
          getMatches(report?.transcript, curr) || [];
        return acc;
      }, {});
      setMatchIds(matchIdsMap);
      setCurrentKeyword(searchKeywords[0]);
      setCurrentKeyIndex(
        searchKeywords.reduce((acc, curr) => {
          acc[curr] = 0;
          return acc;
        }, {}),
      );
    }
  }, [searchKeywords, report?.transcript]);

  useEffect(() => {
    if (
      report?.speakerScoreMap &&
      Object.keys(report?.speakerScoreMap || {}) &&
      Object.keys(report?.speakerScoreMap || {})?.length > 0
    ) {
      setScoreCalculationInProcess(
        Object.keys(report?.speakerScoreMap || {})?.reduce((acc, curr) => {
          acc[curr] = report?.speakerScoreMap?.[curr]?.status;
          return acc;
        }, {}),
      );
    }
  }, [report]);

  useEffect(() => {
    if (
      scoreCalculationInProcess[scoreType] !== 'completed' &&
      scoreCalculationInProcess[scoreType] !== 'failed'
    ) {
      if (type === 'salesCall') {
        checkSalesCallScoreStatusTimer.current = setInterval(() => {
          dispatch(
            checkSalesCallScoreStatus(
              report?.id,
              (data) => {
                if (
                  data?.allSpeakers &&
                  Object.keys(data?.allSpeakers || {})?.length > 0
                ) {
                  setScoreCalculationInProcess(
                    Object.keys(data?.allSpeakers || {})?.reduce(
                      (acc, curr) => {
                        acc[curr] = data?.allSpeakers?.[curr]?.status;
                        return acc;
                      },
                      {},
                    ),
                  );
                }
                if (
                  Object.values(data?.allSpeakers || {}) &&
                  Object.values(data?.allSpeakers || {})?.length > 0 &&
                  Object.values(data?.allSpeakers || {})?.find(
                    (scoreMap) =>
                      scoreMap?.status !== 'completed' &&
                      scoreMap?.status !== 'failed',
                  ) === undefined
                ) {
                  clearInterval(checkSalesCallScoreStatusTimer.current);
                  if (
                    Object.values(data?.allSpeakers || {}) &&
                    Object.values(data?.allSpeakers || {})?.length > 0 &&
                    Object.values(data?.allSpeakers || {})?.find(
                      (scoreMap) =>
                        scoreMap &&
                        Object.keys(scoreMap || {}) &&
                        Object.keys(scoreMap || {})?.length > 0 &&
                        Object.keys(scoreMap || {}).filter(
                          (key) => key !== 'status',
                        ) &&
                        Object.keys(scoreMap || {}).filter(
                          (key) => key !== 'status',
                        )?.length > 0,
                    )
                  ) {
                    setSalesReview((prev) => {
                      return {
                        ...prev,
                        speakerScoreMap: data?.allSpeakers,
                      };
                    });
                    dispatch({
                      type: 'UPDATE_SALES_CALL_ANALYSIS_SCORES',
                      payload: {
                        id: report?.id,
                        value: data?.allSpeakers,
                      },
                    });
                  }
                }
              },
              () => {
                setScoreCalculationInProcess(false);
                clearInterval(checkSalesCallScoreStatusTimer.current);
              },
            ),
          );
        }, 10000);
      } else if (type === 'practiceCall') {
        checkSalesCallScoreStatusTimer.current = setInterval(() => {
          dispatch(
            checkPracticeCallScoreStatus(
              report?.id,
              (data) => {
                if (
                  data?.allSpeakers &&
                  Object.keys(data?.allSpeakers || {})?.length > 0
                ) {
                  setScoreCalculationInProcess(
                    Object.keys(data?.allSpeakers || {})?.reduce(
                      (acc, curr) => {
                        acc[curr] = data?.allSpeakers?.[curr]?.status;
                        return acc;
                      },
                      {},
                    ),
                  );
                }
                if (
                  Object.values(data?.allSpeakers || {}) &&
                  Object.values(data?.allSpeakers || {})?.length > 0 &&
                  Object.values(data?.allSpeakers || {})?.find(
                    (scoreMap) =>
                      scoreMap?.status !== 'completed' &&
                      scoreMap?.status !== 'failed',
                  ) === undefined
                ) {
                  clearInterval(checkSalesCallScoreStatusTimer.current);
                  if (
                    Object.values(data?.allSpeakers || {}) &&
                    Object.values(data?.allSpeakers || {})?.length > 0 &&
                    Object.values(data?.allSpeakers || {})?.find(
                      (scoreMap) =>
                        scoreMap &&
                        Object.keys(scoreMap || {}) &&
                        Object.keys(scoreMap || {})?.length > 0 &&
                        Object.keys(scoreMap || {}).filter(
                          (key) => key !== 'status',
                        ) &&
                        Object.keys(scoreMap || {}).filter(
                          (key) => key !== 'status',
                        )?.length > 0,
                    )
                  ) {
                    setSalesReview((prev) => {
                      return {
                        ...prev,
                        speakerScoreMap: data?.allSpeakers,
                      };
                    });
                    dispatch({
                      type: 'UPDATE_SALES_CALL_ANALYSIS_SCORES',
                      payload: {
                        id: report?.id,
                        value: data?.allSpeakers,
                      },
                    });
                  }
                }
              },
              () => {
                setScoreCalculationInProcess(false);
                clearInterval(checkSalesCallScoreStatusTimer.current);
              },
            ),
          );
        }, 10000);
      } else {
        checkSalesCallScoreStatusTimer.current = setInterval(() => {
          dispatch(
            checkCallWithTokenScoreStatus(
              tk,
              pc,
              (data) => {
                if (
                  data?.allSpeakers &&
                  Object.keys(data?.allSpeakers || {})?.length > 0
                ) {
                  setScoreCalculationInProcess(
                    Object.keys(data?.allSpeakers || {})?.reduce(
                      (acc, curr) => {
                        acc[curr] = data?.allSpeakers?.[curr]?.status;
                        return acc;
                      },
                      {},
                    ),
                  );
                }
                if (
                  Object.values(data?.allSpeakers || {}) &&
                  Object.values(data?.allSpeakers || {})?.length > 0 &&
                  Object.values(data?.allSpeakers || {})?.find(
                    (scoreMap) =>
                      scoreMap?.status !== 'completed' &&
                      scoreMap?.status !== 'failed',
                  ) === undefined
                ) {
                  clearInterval(checkSalesCallScoreStatusTimer.current);
                  if (
                    Object.values(data?.allSpeakers || {}) &&
                    Object.values(data?.allSpeakers || {})?.length > 0 &&
                    Object.values(data?.allSpeakers || {})?.find(
                      (scoreMap) =>
                        scoreMap &&
                        Object.keys(scoreMap || {}) &&
                        Object.keys(scoreMap || {})?.length > 0 &&
                        Object.keys(scoreMap || {}).filter(
                          (key) => key !== 'status',
                        ) &&
                        Object.keys(scoreMap || {}).filter(
                          (key) => key !== 'status',
                        )?.length > 0,
                    )
                  ) {
                    setSalesReview((prev) => {
                      return {
                        ...prev,
                        speakerScoreMap: data?.allSpeakers,
                      };
                    });
                    dispatch({
                      type: 'UPDATE_SALES_CALL_ANALYSIS_SCORES',
                      payload: {
                        id: report?.id,
                        value: data?.allSpeakers,
                      },
                    });
                  }
                }
              },
              () => {
                setScoreCalculationInProcess(false);
                clearInterval(checkSalesCallScoreStatusTimer.current);
              },
            ),
          );
        }, 10000);
      }
    }
    return () => {
      clearInterval(checkSalesCallScoreStatusTimer.current);
    };
  }, [scoreCalculationInProcess, scoreType]);

  useEffect(() => {
    const seekVideo = (time) => {
      console.log(time);
      const seconds =
        Number(time?.split(':')?.[0] || 0) * 60 +
        Number(time?.split(':')?.[1] || 0);
      console.log(seconds);
      if (videoRef?.current) {
        videoRef.current.currentTime = seconds;
        videoRef.current.play();
      }
    };
    if (
      [].slice.call(document.getElementsByClassName('highlight')) &&
      [].slice.call(document.getElementsByClassName('highlight'))?.length > 0
    ) {
      [].slice.call(document.getElementsByClassName('highlight'))?.map((el) => {
        el.addEventListener('click', () => {
          seekVideo(el?.innerText);
        });
      });
    }

    return () => {};
  }, [[].slice.call(document.getElementsByClassName('highlight'))]);

  // useEffect(() => {
  //   const onTimeUpdate = () => {
  //     console.log(videoRef.current.currentTime);
  //   };
  //   videoRef.current.addEventListener('timeupdate', onTimeUpdate);
  //   return () =>
  //     videoRef.current.removeEventListener('timeupdate', onTimeUpdate);
  // }, []);

  //   console.log(showSyncHubspotDialog);
  // console.log(getMatches(report?.transcript, 'attendance management'));
  // console.log(matchIds);
  // console.log(matchOffsets);
  // console.log(currentKeyIndex);
  // console.log(currentTranscriptRef);
  // console.log(scrollDivMaximizedRef);
  // console.log(scrollDivMinimizedRef);
  // console.log(scoreCalculationInProcess);
  // console.log(scoreType);
  // console.log(fetching);
  // console.log(conversationDetails);
  // console.log(report);

  return (
    <Box display={'flex'} flexDirection={'column'} gap={2} flex={1}>
      <Box
        display={'flex'}
        flex={1}
        sx={{
          border: '1px solid #d3d3d3',
          // height: 'auto',
          // padding: 2,
        }}
      >
        <Box flex={1} display={'flex'} flexDirection={'column'}>
          <Box
            display={'flex'}
            justifyContent={'space-between'}
            sx={{
              borderBottom: '1px solid #d3d3d3',
            }}
          >
            <Tabs
              value={currentParam}
              onChange={(e, newValue) => {
                handleTrackEvent(e, `change_factors_tab_to_${newValue}`);
                setCurrentParam(newValue);
              }}
              aria-label="Parameter Tabs"
              orientation={'horizontal'}
              // indicatorColor={theme.palette.background.default}
              variant="scrollable"
              // scrollButtons={!isMd}
              allowScrollButtonsMobile
            >
              <Tab
                value={'conversationScore'}
                label={
                  <Typography variant="caption" fontWeight={'bold'}>
                    Call Score
                  </Typography>
                }
                id={`simple-tab-${5}`}
                aria-controls={`simple-tabpanel-${5}`}
                sx={{}}
              />
              <Tab
                value={'transcript'}
                label={
                  <Typography variant="caption" fontWeight={'bold'}>
                    Transcript
                  </Typography>
                }
                id={`simple-tab-${0}`}
                aria-controls={`simple-tabpanel-${0}`}
                sx={{}}
              />
              <Tab
                value={'fillers'}
                label={
                  <Typography variant="caption" fontWeight={'bold'}>
                    Filler Words
                  </Typography>
                }
                id={`simple-tab-${0}`}
                aria-controls={`simple-tabpanel-${0}`}
                sx={{}}
              />
              <Tab
                value={'repeatitions'}
                label={
                  <Typography variant="caption" fontWeight={'bold'}>
                    Repeated Words
                  </Typography>
                }
                id={`simple-tab-${5}`}
                aria-controls={`simple-tabpanel-${5}`}
                sx={{}}
              />
            </Tabs>
            <Box display={'flex'} alignItems={'center'}>
              <Tooltip title="maximize view" placement="left" arrow>
                <Fullscreen
                  sx={{
                    cursor: 'pointer',
                    color: theme.palette.primary.main,
                    marginRight: 2,
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleTrackEvent(e, 'maximize_transcript_view');
                    setMaximizedTranscriptView((prev) => !prev);
                  }}
                />
              </Tooltip>
              {!uId && type === 'salesCall' ? (
                <Tooltip title="More options" placement="top" arrow>
                  <IconButton
                    sx={{ mr: 1 }}
                    onClick={(e) => {
                      setAnchorEl(e.target);
                    }}
                  >
                    <MoreVert
                      sx={{
                        cursor: 'pointer',
                        color: theme.palette.primary.main,
                      }}
                    />
                  </IconButton>
                </Tooltip>
              ) : null}
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                sx={{
                  '& .MuiMenu-paper': {
                    boxShadow: '0 2px 2px 2px rgba(140, 152, 164, 0.02)',
                  },
                }}
              >
                <MenuItem
                  onClick={() => {
                    setShowReAnalyzeDrawer(report?.id);
                    setAnchorEl(null);
                  }}
                >
                  Reanalyze Call
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setShowUpdateSpeakersDialog(true);
                    setAnchorEl(null);
                  }}
                >
                  Update Speaker Names
                </MenuItem>
              </Menu>
            </Box>
          </Box>
          {currentParam === 'transcript' && !maximizedTranscriptView ? (
            <Box display={'flex'}>
              <Box
                ref={scrollDivMinimizedRef}
                sx={{
                  flex: 2,
                  // borderRadius: 2,
                  mark: {
                    backgroundColor: theme.palette.primary.main,
                    color: 'white',
                    padding: '2px 6px',
                  },
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2,
                  minHeight: 'calc(50vh - 100px)',
                  maxHeight: 'calc(50vh - 100px)',
                  overflowY: 'scroll',
                  '&::-webkit-scrollbar': {
                    width: '5px',
                  },
                  '&::-webkit-scrollbar-track': {
                    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: theme.palette.primary.main,
                    borderRadius: 2,
                    // outline: '1px solid slategrey',
                  },
                }}
                position={'relative'}
              >
                {searchKeywords && searchKeywords.length > 0 ? (
                  <Box
                    sx={{
                      position: 'sticky',
                      top: 0,
                      left: 0,
                      background: '#fff',
                      padding: '4px 8px',
                      borderBottom: '1px solid #d3d3d3',
                    }}
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={
                      searchKeywords?.length > 0 ? 'space-between' : 'flex-end'
                    }
                  >
                    <Box
                      display={'flex'}
                      gap={1}
                      alignItems={'center'}
                      flexWrap={'wrap'}
                    >
                      <Box
                        display={'flex'}
                        flexDirection={'column'}
                        alignItems={'start'}
                      >
                        <Typography variant="caption" color={'text.secondary'}>
                          Matching Keywords
                        </Typography>
                      </Box>
                      {showMatchingKeywords
                        ? searchKeywords?.map((keyword, index) =>
                            searchKeywordsMap[keyword] > 0 ? (
                              <Box
                                key={index + 1}
                                display={'flex'}
                                alignItems={'center'}
                                gap={1}
                                sx={{
                                  border: '1px solid #d3d3d3',
                                  borderRadius: 1,
                                  padding: '2px 8px',
                                }}
                              >
                                <Typography variant="caption">
                                  {keyword}
                                </Typography>
                                <Chip
                                  size="small"
                                  color="primary"
                                  label={searchKeywordsMap[keyword]}
                                />
                                <Divider
                                  orientation="vertical"
                                  variant="fullWidth"
                                  flexItem
                                />
                                <Box
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    // gap: 1,
                                  }}
                                >
                                  <Tooltip
                                    title="Previous"
                                    arrow
                                    placement="top"
                                  >
                                    <IconButton
                                      sx={{
                                        padding: 0.5,
                                      }}
                                      onClick={() => {
                                        handleHightlightMove('prev', keyword);
                                      }}
                                    >
                                      <KeyboardArrowUp fontSize="small" />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip title="Next" arrow placement="top">
                                    <IconButton
                                      sx={{
                                        padding: 0.5,
                                      }}
                                      onClick={() => {
                                        handleHightlightMove('next', keyword);
                                      }}
                                    >
                                      <KeyboardArrowDown fontSize="small" />
                                    </IconButton>
                                  </Tooltip>
                                </Box>
                              </Box>
                            ) : null,
                          )
                        : null}
                    </Box>
                  </Box>
                ) : null}
                <Typography
                  variant="body1"
                  sx={{
                    whiteSpace: 'pre-wrap',
                    padding: 2,
                    mark: {
                      background: '#6b8bea',
                    },
                  }}
                >
                  {showMatchingKeywords &&
                  searchKeywords &&
                  searchKeywords.length > 0
                    ? parse(
                        report?.transcript
                          ?.replace(
                            new RegExp(
                              searchKeywords
                                ?.map((word) =>
                                  word
                                    .split(' ')
                                    .join('')
                                    ?.split('')
                                    ?.join('\\s*'),
                                )
                                ?.join('|'),
                              'ig',
                            ),
                            (highlight, offset) => {
                              return `<mark id="${highlight
                                ?.toLowerCase()
                                ?.split(' ')
                                ?.join('_')}${offset}">${highlight
                                .split('{')
                                .join('')
                                .split('}')
                                .join('')}</mark>`;
                            },
                          )
                          ?.replace(
                            new RegExp('(([0-5])?[0-9]):([0-5][0-9])', 'gmi'),
                            (highlight) => {
                              return `<button class="highlight">${highlight}</button>`;
                            },
                          ) || '',
                      )
                    : parse(
                        report?.transcript?.replace(
                          new RegExp('(([0-5])?[0-9]):([0-5][0-9])', 'gmi'),
                          (highlight) => {
                            return `<button class="highlight">${highlight}</button>`;
                          },
                        ) || '',
                      )}
                </Typography>
              </Box>
              <MediaPreview
                videoRef={videoRef}
                styles={{
                  flex: 1,
                  borderLeft: '1px solid #d3d3d3',
                  padding: 2,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}
                url={
                  type === 'practiceCall' || type === 'otpc'
                    ? report?.customFields?.audioLink || ''
                    : report?.customData?.url || ''
                }
                mediaType={report?.saleReviewMediaContentType || ''}
                handleVideoSeekTo
              />
            </Box>
          ) : (
            <Box
              ref={scrollDivMinimizedRef}
              sx={{
                padding: currentParam !== 'conversationScore' ? 2 : 0,
                // border: '1px solid #d3d3d3',
                borderRadius: 2,
                mark: {
                  backgroundColor: theme.palette.primary.main,
                  color: 'white',
                  padding: '2px 6px',
                },
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                minHeight: 'calc(50vh - 100px)',
                maxHeight: 'calc(50vh - 100px)',
                overflowY: 'scroll',
                '&::-webkit-scrollbar': {
                  width: '5px',
                },
                '&::-webkit-scrollbar-track': {
                  boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                  webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: theme.palette.primary.main,
                  borderRadius: 2,
                  // outline: '1px solid slategrey',
                },
              }}
            >
              {currentParam === 'fillers' ? (
                <>
                  <Box
                    sx={{
                      // border: '1px solid #d3d3d3',
                      // borderRadius: 10,
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Box
                      sx={{
                        background: showAllFillers
                          ? '#d3d3d3'
                          : theme.palette.primary.main,
                        color: showAllFillers ? '#000' : '#fff',
                        padding: '4px 8px',
                        borderTopLeftRadius: 10,
                        borderBottomLeftRadius: 10,
                        cursor: 'pointer',
                      }}
                      onClick={() => setShowAllFillers(false)}
                    >
                      <Typography variant="caption">Top 10 Fillers</Typography>
                    </Box>
                    <Box
                      sx={{
                        background: !showAllFillers
                          ? '#d3d3d3'
                          : theme.palette.primary.main,
                        color: !showAllFillers ? '#000' : '#fff',
                        padding: '4px 8px',
                        borderTopRightRadius: 10,
                        borderBottomRightRadius: 10,
                        cursor: 'pointer',
                      }}
                      onClick={() => setShowAllFillers(true)}
                    >
                      <Typography variant="caption">
                        Show all Fillers
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    display={'flex'}
                    gap={2}
                    sx={{
                      flexWrap: 'wrap',
                    }}
                  >
                    {report?.analysis?.fillerInfo?.fillers &&
                    Object.keys(report?.analysis?.fillerInfo?.fillers) &&
                    Array.isArray(
                      Object.keys(report?.analysis?.fillerInfo?.fillers),
                    ) &&
                    Object.keys(report?.analysis?.fillerInfo?.fillers)?.length >
                      0 ? (
                      Object.keys(report?.analysis?.fillerInfo?.fillers)
                        ?.sort((a, b) => {
                          if (
                            report?.analysis?.fillerInfo?.fillers?.[a] <
                            report?.analysis?.fillerInfo?.fillers?.[b]
                          ) {
                            return 1;
                          } else if (
                            report?.analysis?.fillerInfo?.fillers?.[a] >
                            report?.analysis?.fillerInfo?.fillers?.[b]
                          ) {
                            return -1;
                          }
                          return 0;
                        })
                        ?.slice(
                          0,
                          showAllFillers
                            ? Object.keys(report?.analysis?.fillerInfo?.fillers)
                                ?.length
                            : 10,
                        )
                        ?.map((filler, index) => (
                          <Typography key={index} sx={{}}>
                            {filler}{' '}
                            <Chip
                              label={
                                report?.analysis?.fillerInfo?.fillers?.[filler]
                              }
                              size="small"
                              color="primary"
                            ></Chip>
                          </Typography>
                        ))
                    ) : (
                      <Typography sx={{}} color={'text.secondary'}>
                        No filler words
                      </Typography>
                    )}
                  </Box>
                  <Typography
                    variant="body1"
                    sx={{
                      whiteSpace: 'pre-wrap',
                    }}
                  >
                    {report?.analysis?.fillerInfo?.transcript
                      ? parse(
                          report?.analysis?.fillerInfo?.transcript?.replace(
                            fillerWordsRegexp,
                            (highlight) => {
                              return `<mark>${highlight
                                .split('{')
                                .join('')
                                .split('}')
                                .join('')}</mark>`;
                            },
                          ) || '',
                        )
                      : ''}
                  </Typography>
                </>
              ) : currentParam === 'repeatitions' ? (
                <>
                  <Box
                    sx={{
                      // border: '1px solid #d3d3d3',
                      // borderRadius: 10,
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Box
                      sx={{
                        background: showAllRepetitions
                          ? '#d3d3d3'
                          : theme.palette.primary.main,
                        color: showAllRepetitions ? '#000' : '#fff',
                        padding: '4px 8px',
                        borderTopLeftRadius: 10,
                        borderBottomLeftRadius: 10,
                        cursor: 'pointer',
                      }}
                      onClick={() => setShowAllRepetitions(false)}
                    >
                      <Typography variant="caption">
                        Top 10 Repetitions
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        background: !showAllRepetitions
                          ? '#d3d3d3'
                          : theme.palette.primary.main,
                        color: !showAllRepetitions ? '#000' : '#fff',
                        padding: '4px 8px',
                        borderTopRightRadius: 10,
                        borderBottomRightRadius: 10,
                        cursor: 'pointer',
                      }}
                      onClick={() => setShowAllRepetitions(true)}
                    >
                      <Typography variant="caption">
                        Show all Repetitions
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    display={'flex'}
                    gap={2}
                    sx={{
                      flexWrap: 'wrap',
                    }}
                  >
                    {report?.analysis?.repetitionInfo?.repetitions &&
                    Object.keys(
                      report?.analysis?.repetitionInfo?.repetitions,
                    ) &&
                    Array.isArray(
                      Object.keys(
                        report?.analysis?.repetitionInfo?.repetitions,
                      ),
                    ) &&
                    Object.keys(report?.analysis?.repetitionInfo?.repetitions)
                      ?.length > 0 ? (
                      Object.keys(report?.analysis?.repetitionInfo?.repetitions)
                        ?.sort((a, b) => {
                          if (
                            report?.analysis?.repetitionInfo?.repetitions?.[a] <
                            report?.analysis?.repetitionInfo?.repetitions?.[b]
                          ) {
                            return 1;
                          } else if (
                            report?.analysis?.repetitionInfo?.repetitions?.[a] >
                            report?.analysis?.repetitionInfo?.repetitions?.[b]
                          ) {
                            return -1;
                          }
                          return 0;
                        })
                        ?.slice(
                          0,
                          showAllRepetitions
                            ? Object.keys(
                                report?.analysis?.repetitionInfo?.repetitions,
                              )?.length
                            : 10,
                        )
                        ?.map((repeatition, index) => (
                          <Typography key={index} sx={{}}>
                            {repeatition}{' '}
                            <Chip
                              label={
                                report?.analysis?.repetitionInfo?.repetitions?.[
                                  repeatition
                                ]
                              }
                              size="small"
                              color="primary"
                            ></Chip>
                          </Typography>
                        ))
                    ) : (
                      <Typography sx={{}} color={'text.secondary'}>
                        No repeated words
                      </Typography>
                    )}
                  </Box>
                  <Typography
                    variant="body1"
                    sx={{
                      whiteSpace: 'pre-wrap',
                    }}
                  >
                    {report?.analysis?.repetitionInfo?.transcript
                      ? parse(
                          report?.analysis?.repetitionInfo?.transcript?.replace(
                            repeatWordsRegexp,
                            (highlight) => {
                              return `<mark>${highlight
                                .split('{')
                                .join('')
                                .split('}')
                                .join('')}</mark>`;
                            },
                          ) || '',
                        )
                      : ''}
                  </Typography>
                </>
              ) : currentParam === 'pace' ? (
                <Box>
                  <LineGraph
                    height={'calc(50vh - 160px)'}
                    labels={
                      Object.keys(report?.analysis?.paceGraph || {})?.length >
                      15
                        ? [
                            ...new Set(
                              Object.keys(report?.analysis?.paceGraph)
                                ?.filter(
                                  (key) =>
                                    report?.analysis?.paceGraph?.[key] > 60,
                                )
                                .filter(
                                  (n) =>
                                    n %
                                      (2 +
                                        Math.floor(
                                          Object.keys(
                                            report?.analysis?.paceGraph,
                                          )?.length / 30,
                                        )) ===
                                    0,
                                ),
                            ),
                          ]
                        : Object.keys(report?.analysis?.paceGraph || {})
                    }
                    data={
                      Object.values(report?.analysis?.paceGraph || {})?.length >
                      15
                        ? Object.values(report?.analysis?.paceGraph)
                            ?.filter((value) => value > 60)
                            .reduce((acc, curr, index) => {
                              const averageValue =
                                Object.values(report?.analysis?.paceGraph)
                                  ?.filter((value) => value > 60)
                                  ?.slice(
                                    index *
                                      (2 +
                                        Math.floor(
                                          Object.keys(
                                            report?.analysis?.paceGraph,
                                          )?.filter(
                                            (key) =>
                                              report?.analysis?.paceGraph?.[
                                                key
                                              ] > 60,
                                          )?.length / 30,
                                        )),
                                    (2 +
                                      Math.floor(
                                        Object.keys(
                                          report?.analysis?.paceGraph,
                                        )?.filter(
                                          (key) =>
                                            report?.analysis?.paceGraph?.[key] >
                                            60,
                                        )?.length / 30,
                                      )) *
                                      (index + 1),
                                  )
                                  ?.reduce((acc, curr) => acc + curr, 0) /
                                (2 +
                                  Math.floor(
                                    Object.keys(
                                      report?.analysis?.paceGraph,
                                    )?.filter(
                                      (key) =>
                                        report?.analysis?.paceGraph?.[key] > 60,
                                    )?.length / 30,
                                  ));
                              acc.push(averageValue);
                              return acc;
                            }, [])
                            .slice(
                              0,
                              Object.keys(report?.analysis?.paceGraph).filter(
                                (n) =>
                                  n %
                                    (2 +
                                      Math.floor(
                                        Object.keys(report?.analysis?.paceGraph)
                                          ?.length / 30,
                                      )) ===
                                  0,
                              ).length,
                            )
                        : Object.values(report?.analysis?.paceGraph || {})
                    }
                  />
                </Box>
              ) : currentParam === 'pace-exp' ? (
                <Box>
                  <LineGraph
                    height={'calc(50vh - 160px)'}
                    labels={Object.keys(
                      report?.analysis?.paceGraph || {},
                    )?.filter((key) => report?.analysis?.paceGraph?.[key] > 60)}
                    data={Object.values(
                      report?.analysis?.paceGraph || {},
                    )?.filter((value) => value > 60)}
                  />
                </Box>
              ) : currentParam === 'transcript' ? (
                <Typography
                  variant="body1"
                  sx={{
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  {report?.transcript}
                </Typography>
              ) : currentParam === 'conversationScore' ? (
                <Box display={'flex'}>
                  <Box display={'flex'} flex={2} p={2}>
                    <Grid container spacing={2}>
                      {Object.keys(
                        JSON.parse(
                          report?.analysis?.methodAnalysis?.contentScoring
                            ?.analysisJson || '{}',
                        ) || {},
                      )
                        ?.sort(
                          (a, b) =>
                            report?.analysis?.methodAnalysis?.contentScoring?.contentScoringOrder
                              ?.map((obj) => Object.keys(obj)?.[0])
                              ?.indexOf(a) -
                            report?.analysis?.methodAnalysis?.contentScoring?.contentScoringOrder
                              ?.map((obj) => Object.keys(obj)?.[0])
                              ?.indexOf(b),
                        )
                        ?.map((key) => (
                          <Grid item xs={6} key={key}>
                            <Card sx={{ p: 2, height: '100%' }}>
                              <Box
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'space-between',
                                  gap: 2,
                                }}
                              >
                                <Typography variant="body1" fontWeight={'bold'}>
                                  {key}
                                </Typography>
                                {uId ? (
                                  <ProfileCompletion
                                    percentCompleted={Math.round(
                                      report?.analysis?.methodAnalysis
                                        ?.contentScoring?.scores?.[key],
                                    )}
                                    circleSize={45}
                                    circleTextSize={12}
                                    fontSize={12}
                                    thickness={5}
                                    dontShowExtraText={true}
                                  />
                                ) : null}
                              </Box>
                              <List component={'nav'}>
                                {Object.keys(
                                  JSON.parse(
                                    report?.analysis?.methodAnalysis
                                      ?.contentScoring?.analysisJson || '{}',
                                  )[key] || {},
                                )
                                  ?.sort(
                                    (a, b) =>
                                      Object.values(
                                        report?.analysis?.methodAnalysis?.contentScoring?.contentScoringOrder?.find(
                                          (obj) =>
                                            Object.keys(obj)?.[0] === key,
                                        ) || {},
                                      )?.[0]?.indexOf(a) -
                                      Object.values(
                                        report?.analysis?.methodAnalysis?.contentScoring?.contentScoringOrder?.find(
                                          (obj) =>
                                            Object.keys(obj)?.[0] === key,
                                        ) || {},
                                      )?.[0]?.indexOf(b),
                                  )
                                  ?.map((content) => (
                                    <ListItem
                                      disableGutters
                                      disablePadding
                                      key={content}
                                    >
                                      <ListItemIcon>
                                        {orgConfigs?.followNewContentScoring ? (
                                          JSON.parse(
                                            report?.analysis?.methodAnalysis
                                              ?.contentScoring?.analysisJson ||
                                              '{}',
                                          )[key][content]['met'] === 'true' ? (
                                            <CheckCircle
                                              sx={{
                                                color:
                                                  theme.palette.primary.main,
                                              }}
                                            />
                                          ) : (
                                            <Cancel
                                              sx={{
                                                color: theme.palette.error.main,
                                              }}
                                            />
                                          )
                                        ) : JSON.parse(
                                            report?.analysis?.methodAnalysis
                                              ?.contentScoring?.analysisJson ||
                                              '{}',
                                          )[key][content]['met'] ? (
                                          <CheckCircle
                                            sx={{
                                              color: theme.palette.primary.main,
                                            }}
                                          />
                                        ) : (
                                          <Cancel
                                            sx={{
                                              color: theme.palette.error.main,
                                            }}
                                          />
                                        )}
                                      </ListItemIcon>
                                      <ListItemText
                                        primary={content}
                                        sx={{
                                          color: theme.palette.primary.main,
                                        }}
                                        onMouseEnter={(e) => {
                                          setAnchorElDetails(e.currentTarget);
                                          setPopperDetails(
                                            JSON.parse(
                                              report?.analysis?.methodAnalysis
                                                ?.contentScoring
                                                ?.analysisJson || '{}',
                                            )[key][content],
                                          );
                                        }}
                                        onMouseLeave={() => {
                                          setAnchorElDetails(null);
                                          setPopperDetails(null);
                                        }}
                                      />
                                      <Popper
                                        id="mouse-over-popover"
                                        sx={{
                                          pointerEvents: 'none',
                                          zIndex: 1111111,
                                        }}
                                        open={Boolean(anchorElDetails)}
                                        anchorEl={anchorElDetails}
                                        placement="bottom"
                                        onClose={() => {
                                          setAnchorElDetails(null);
                                        }}
                                        // disableRestoreFocus
                                      >
                                        <Box
                                          sx={{
                                            border: '1px solid #d3d3d3',
                                            borderRadius: 1,
                                            padding: 1,
                                            background: '#fff',
                                            maxWidth: 400,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: 1,
                                            boxShadow: theme.shadows[1],
                                          }}
                                        >
                                          <Typography
                                            variant={'body1'}
                                            color={'text.secondary'}
                                            sx={{
                                              padding: 1,
                                            }}
                                          >
                                            <span
                                              style={{ fontWeight: 'bold' }}
                                            >
                                              Criteria:{' '}
                                            </span>
                                            {popperDetails?.description ||
                                              popperDetails?.question}
                                          </Typography>
                                          <Divider />
                                          <Typography
                                            variant={'body2'}
                                            sx={{
                                              padding: 1,
                                              background: orgConfigs?.followNewContentScoring
                                                ? popperDetails?.met === 'true'
                                                  ? 'rgba(65,168,84, 0.4)'
                                                  : 'rgba(239,65,74, 0.4)'
                                                : popperDetails?.met
                                                ? 'rgba(65,168,84, 0.4)'
                                                : 'rgba(239,65,74, 0.4)',
                                            }}
                                          >
                                            <span
                                              style={{ fontWeight: 'bold' }}
                                            >
                                              Reason:{' '}
                                            </span>
                                            {popperDetails?.reason ||
                                              'Not specified'}
                                          </Typography>
                                        </Box>
                                      </Popper>
                                    </ListItem>
                                  ))}
                              </List>
                            </Card>
                          </Grid>
                        ))}
                    </Grid>
                  </Box>
                  <Box
                    style={{
                      flex: 1,
                      borderLeft: '1px solid #d3d3d3',
                      padding: 2,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'column',
                      gap: 2,
                    }}
                  >
                    <Box
                      display={'flex'}
                      alignItems={'center'}
                      justifyContent={'center'}
                      flex={1}
                      p={2}
                    >
                      <ParticipationPieChart
                        styles={{
                          maxWidth: 180,
                        }}
                        title={'Call Participation'}
                        labels={
                          Object.keys(report?.analysis?.speakProportion || {})
                            ?.filter((sp) => sp !== 'silence')
                            ?.sort(
                              (a, b) => a?.split('_')?.[1] - b?.split('_')?.[1],
                            ) || []
                        }
                        speakers={report?.speakers || ''}
                        data={Object.keys(
                          report?.analysis?.speakProportion || {},
                        )
                          ?.filter((key) => key !== 'silence')
                          ?.sort(
                            (a, b) => a?.split('_')?.[1] - b?.split('_')?.[1],
                          )
                          ?.map((key, index) =>
                            Math.round(
                              (report?.analysis?.speakProportion[key] /
                                Object.keys(
                                  report?.analysis?.speakProportion || {},
                                )
                                  ?.filter((key) => key !== 'silence')
                                  ?.sort(
                                    (a, b) =>
                                      a?.split('_')?.[1] - b?.split('_')?.[1],
                                  )
                                  ?.reduce(
                                    (acc, curr) =>
                                      acc +
                                      report?.analysis?.speakProportion[curr],
                                    0,
                                  )) *
                                100,
                            ),
                          )}
                      />
                    </Box>
                    <Box
                      flex={1}
                      display={'flex'}
                      alignItems={'center'}
                      flexDirection={'columm'}
                      p={2}
                    >
                      {scoreCalculationInProcess?.[scoreType] !== 'completed' &&
                      scoreCalculationInProcess?.[scoreType] !== 'failed' ? (
                        <Box
                          display={'flex'}
                          flexDirection={'column'}
                          justifyContent={'center'}
                          alignItems={'center'}
                          gap={2}
                          flex={1}
                        >
                          <CircularProgress size={20} />
                          <Typography
                            variant="caption"
                            color={'text.secondary'}
                          >
                            Score generation is in process...
                          </Typography>
                        </Box>
                      ) : (
                        <Box
                          display={'flex'}
                          flexDirection={'column'}
                          alignItems={'center'}
                          gap={1}
                        >
                          <Box display={'flex'} alignItems={'center'}>
                            {Object.keys(report?.speakers || {})
                              ?.filter((spk) =>
                                type === 'practiceCall'
                                  ? spk === 'Speaker 1'
                                  : true,
                              )
                              ?.map((key, index, ar) => (
                                <Box
                                  key={key}
                                  sx={{
                                    background:
                                      scoreType !== key
                                        ? '#d3d3d3'
                                        : theme.palette.primary.main,
                                    color: scoreType !== key ? '#000' : '#fff',
                                    padding: '2px 8px',
                                    borderTopLeftRadius: index === 0 ? 20 : 0,
                                    borderBottomLeftRadius:
                                      index === 0 ? 20 : 0,
                                    borderBottomRightRadius:
                                      index === ar?.length - 1 ? 20 : 0,
                                    borderTopRightRadius:
                                      index === ar?.length - 1 ? 20 : 0,
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => setScoreType(key)}
                                >
                                  <Typography variant="caption">
                                    {capitalizeText(report?.speakers?.[key])}
                                  </Typography>
                                </Box>
                              ))}
                          </Box>
                          <Typography
                            variant="caption"
                            color={'text.secondary'}
                            mt={1}
                          >
                            Scores
                          </Typography>
                          <Bar
                            style={{
                              width: '100%',
                            }}
                            options={{
                              responsive: true,
                              layout: {
                                padding: 10,
                              },
                              plugins: {
                                legend: {
                                  display: false,
                                  // position: 'top',
                                },
                                tooltip: {
                                  enabled: true,
                                },

                                title: {
                                  display: false,
                                  text: 'Skills Score',
                                },
                                datalabels: {
                                  color: 'white',
                                  font: {
                                    weight: 'bold',
                                    size: 14,
                                  },
                                  // rotation: -90,
                                  formatter: function(value, context) {
                                    return `${value}`;
                                  },
                                },
                              },
                              elements: {
                                bar: {
                                  borderRadius: 6,
                                  borderSkipped: false,
                                },
                              },
                              scales: {
                                y: {
                                  beginAtZero: true,
                                  max: 100,
                                },
                              },
                            }}
                            data={{
                              labels:
                                scoreType === report?.speaker
                                  ? [
                                      'Pace',
                                      'Fluency',
                                      'Confidence',
                                      'Enthusiasm',
                                      'Clarity',
                                    ]
                                  : ['Fluency', 'Confidence', 'Enthusiasm'],
                              datasets: [
                                {
                                  label: 'Score',
                                  data:
                                    scoreType === report?.speaker
                                      ? [
                                          report?.analysis?.paceScore ===
                                          undefined
                                            ? '-'
                                            : Math.round(
                                                report?.analysis?.paceScore,
                                              ),
                                          report?.speakerScoreMap?.[scoreType]
                                            ?.fluency === undefined
                                            ? '-'
                                            : Math.round(
                                                report?.speakerScoreMap?.[
                                                  scoreType
                                                ]?.fluency,
                                              ),
                                          report?.speakerScoreMap?.[scoreType]
                                            ?.confidence === undefined
                                            ? '-'
                                            : Math.round(
                                                report?.speakerScoreMap?.[
                                                  scoreType
                                                ]?.confidence,
                                              ),
                                          report?.speakerScoreMap?.[scoreType]
                                            ?.enthusiasm === undefined
                                            ? '-'
                                            : Math.round(
                                                report?.speakerScoreMap?.[
                                                  scoreType
                                                ]?.enthusiasm,
                                              ),
                                          report?.analysis?.clarity ===
                                          undefined
                                            ? '-'
                                            : Math.round(
                                                report?.analysis?.clarity,
                                              ),
                                        ]
                                      : [
                                          report?.speakerScoreMap?.[scoreType]
                                            ?.fluency === undefined
                                            ? '-'
                                            : Math.round(
                                                report?.speakerScoreMap?.[
                                                  scoreType
                                                ]?.fluency,
                                              ),
                                          report?.speakerScoreMap?.[scoreType]
                                            ?.confidence === undefined
                                            ? '-'
                                            : Math.round(
                                                report?.speakerScoreMap?.[
                                                  scoreType
                                                ]?.confidence,
                                              ),
                                          report?.speakerScoreMap?.[scoreType]
                                            ?.enthusiasm === undefined
                                            ? '-'
                                            : Math.round(
                                                report?.speakerScoreMap?.[
                                                  scoreType
                                                ]?.enthusiasm,
                                              ),
                                        ],
                                  backgroundColor: theme.palette.primary.main,
                                  barThickness: 20,
                                  pointStyle: 'circle',
                                  fill: true,
                                },
                              ],
                            }}
                          />
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Box>
              ) : null}
            </Box>
          )}
        </Box>
      </Box>
      {report?.analysis ? (
        <>
          <Box
            display={'flex'}
            sx={{
              flex: 1,
              border: '1px solid #d3d3d3',
              // minHeight: 300,
            }}
          >
            {/* <Box
              flex={1}
              sx={{
                padding: 2,
                display: 'flex',
                gap: 2,
                alignItems: 'center',
                flexDirection: 'column',
                minHeight: 'calc(50vh - 100px)',
                maxHeight: 'calc(50vh - 100px)',
              }}
            >
              <Box display={'flex'} alignItems={'center'}>
                {Object.keys(report?.speakers || {})
                  ?.filter((spk) =>
                    type === 'practiceCall' ? spk === 'spk_0' : true,
                  )
                  ?.map((key, index, ar) => (
                    <Box
                      key={key}
                      sx={{
                        background:
                          scoreType !== key
                            ? '#d3d3d3'
                            : theme.palette.primary.main,
                        color: scoreType !== key ? '#000' : '#fff',
                        padding: '2px 8px',
                        borderTopLeftRadius: index === 0 ? 20 : 0,
                        borderBottomLeftRadius: index === 0 ? 20 : 0,
                        borderBottomRightRadius:
                          index === ar?.length - 1 ? 20 : 0,
                        borderTopRightRadius: index === ar?.length - 1 ? 20 : 0,
                        cursor: 'pointer',
                      }}
                      onClick={() => setScoreType(key)}
                    >
                      <Typography variant="caption">
                        {capitalizeText(report?.speakers?.[key])}
                      </Typography>
                    </Box>
                  ))}
              </Box>
              {scoreCalculationInProcess?.[scoreType] !== 'completed' &&
              scoreCalculationInProcess?.[scoreType] !== 'failed' ? (
                <Box
                  display={'flex'}
                  flexDirection={'column'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  gap={2}
                >
                  <CircularProgress size={20} />
                  <Typography variant="caption" color={'text.secondary'}>
                    Score generation is in process...
                  </Typography>
                </Box>
              ) : (
                <>
                  <Typography variant="caption" color={'text.secondary'} mt={1}>
                    Scores
                  </Typography>
                  <Bar
                    style={{
                      width: '100%',
                    }}
                    options={{
                      responsive: true,
                      layout: {
                        padding: 10,
                      },
                      plugins: {
                        legend: {
                          display: false,
                          // position: 'top',
                        },
                        tooltip: {
                          enabled: true,
                        },

                        title: {
                          display: false,
                          text: 'Skills Score',
                        },
                        datalabels: {
                          color: 'white',
                          font: {
                            weight: 'bold',
                            size: 14,
                          },
                          // rotation: -90,
                          formatter: function(value, context) {
                            return `${value}`;
                          },
                        },
                      },
                      elements: {
                        bar: {
                          borderRadius: 6,
                          borderSkipped: false,
                        },
                      },
                      scales: {
                        y: {
                          beginAtZero: true,
                          max: 100,
                        },
                      },
                    }}
                    data={{
                      labels:
                        scoreType === report?.speaker
                          ? [
                              'Pace',
                              'Fluency',
                              'Confidence',
                              'Enthusiasm',
                              'Clarity',
                            ]
                          : ['Fluency', 'Confidence', 'Enthusiasm'],
                      datasets: [
                        {
                          label: 'Score',
                          data:
                            scoreType === report?.speaker
                              ? [
                                  report?.analysis?.paceScore === undefined
                                    ? '-'
                                    : Math.round(report?.analysis?.paceScore),
                                  report?.speakerScoreMap?.[scoreType]
                                    ?.fluency === undefined
                                    ? '-'
                                    : Math.round(
                                        report?.speakerScoreMap?.[scoreType]
                                          ?.fluency,
                                      ),
                                  report?.speakerScoreMap?.[scoreType]
                                    ?.confidence === undefined
                                    ? '-'
                                    : Math.round(
                                        report?.speakerScoreMap?.[scoreType]
                                          ?.confidence,
                                      ),
                                  report?.speakerScoreMap?.[scoreType]
                                    ?.enthusiasm === undefined
                                    ? '-'
                                    : Math.round(
                                        report?.speakerScoreMap?.[scoreType]
                                          ?.enthusiasm,
                                      ),
                                  report?.analysis?.clarity === undefined
                                    ? '-'
                                    : Math.round(report?.analysis?.clarity),
                                ]
                              : [
                                  report?.speakerScoreMap?.[scoreType]
                                    ?.fluency === undefined
                                    ? '-'
                                    : Math.round(
                                        report?.speakerScoreMap?.[scoreType]
                                          ?.fluency,
                                      ),
                                  report?.speakerScoreMap?.[scoreType]
                                    ?.confidence === undefined
                                    ? '-'
                                    : Math.round(
                                        report?.speakerScoreMap?.[scoreType]
                                          ?.confidence,
                                      ),
                                  report?.speakerScoreMap?.[scoreType]
                                    ?.enthusiasm === undefined
                                    ? '-'
                                    : Math.round(
                                        report?.speakerScoreMap?.[scoreType]
                                          ?.enthusiasm,
                                      ),
                                ],
                          backgroundColor: theme.palette.primary.main,
                          barThickness: 20,
                          pointStyle: 'circle',
                          fill: true,
                        },
                      ],
                    }}
                  />
                </>
              )}
            </Box> */}
            <Box
              flex={1}
              // sx={{
              //   borderLeft: '1px solid #d3d3d3',
              // }}
            >
              <Box
                display={'flex'}
                alignItems={'center'}
                justifyContent={'space-between'}
              >
                <Tabs
                  value={currentMethod}
                  onChange={(e, newValue) => {
                    handleTrackEvent(e, `change_feedback_tab_to_${newValue}`);
                    setCurrentMethod(newValue);
                  }}
                  aria-label="Feedback Tabs"
                  orientation={'horizontal'}
                  variant="scrollable"
                  allowScrollButtonsMobile
                  sx={{ borderBottom: '1px solid #d3d3d3' }}
                >
                  {report?.analysis?.methodAnalysis &&
                  Object.keys(report?.analysis?.methodAnalysis || {}) &&
                  Object.keys(report?.analysis?.methodAnalysis || {})?.length >
                    0
                    ? process.env.REACT_APP_TARGET_ENV === 'local' ||
                      process.env.REACT_APP_TARGET_ENV === 'development'
                      ? [
                          'callSummary',
                          'playbook',
                          'askAI',
                          'numericDiscussion',
                          ...(report?.analysisMethodologies || []),
                        ]
                          .filter(
                            (item) =>
                              Object.keys(
                                report?.analysis?.methodAnalysis || {},
                              )?.includes(item) ||
                              (type === 'salesCall' &&
                                item === 'askAI' &&
                                Object.keys(
                                  userDetails?.plan?.features || {},
                                )?.find((feature) => feature === 'donna')),
                          )
                          ?.map((key) => (
                            <Tab
                              key={key}
                              value={key}
                              label={
                                <Typography
                                  variant="caption"
                                  fontWeight={'bold'}
                                >
                                  {key === 'callSummary'
                                    ? 'Call Summary'
                                    : key === 'playbook'
                                    ? 'Playbook'
                                    : key === 'numericDiscussion'
                                    ? 'Numerical Discussions'
                                    : key === 'askAI'
                                    ? orgConfigs?.navBarTitles?.[
                                        'Account Settings'
                                      ]?.subTitles?.['Ask Donna']?.title ||
                                      'Ask Donna'
                                    : key === 'meddpic'
                                    ? 'MEDDICC'
                                    : key.toUpperCase()}
                                </Typography>
                              }
                              id={`simple-tab-${5}`}
                              aria-controls={`simple-tabpanel-${5}`}
                              sx={{}}
                            />
                          ))
                      : [
                          'callSummary',
                          'playbook',
                          'askAI',
                          ...(report?.analysisMethodologies || []),
                        ]
                          .filter(
                            (item) =>
                              Object.keys(
                                report?.analysis?.methodAnalysis || {},
                              )?.includes(item) ||
                              (type === 'salesCall' &&
                                item === 'askAI' &&
                                Object.keys(
                                  userDetails?.plan?.features || {},
                                )?.find((feature) => feature === 'donna')),
                          )
                          ?.map((key) => (
                            <Tab
                              key={key}
                              value={key}
                              label={
                                <Typography
                                  variant="caption"
                                  fontWeight={'bold'}
                                >
                                  {key === 'callSummary'
                                    ? 'Call Summary'
                                    : key === 'playbook'
                                    ? 'Playbook'
                                    : key === 'numericDiscussion'
                                    ? 'Numerical Discussions'
                                    : key === 'askAI'
                                    ? orgConfigs?.navBarTitles?.[
                                        'Account Settings'
                                      ]?.subTitles?.['Ask Donna']?.title ||
                                      'Ask Donna'
                                    : key === 'meddpic'
                                    ? 'MEDDICC'
                                    : key.toUpperCase()}
                                </Typography>
                              }
                              id={`simple-tab-${5}`}
                              aria-controls={`simple-tabpanel-${5}`}
                              sx={{}}
                            />
                          ))
                    : null}
                  {type !== 'otpc' ? (
                    <Tab
                      value={'feedback'}
                      label={
                        <Typography variant="caption" fontWeight={'bold'}>
                          Notes
                        </Typography>
                      }
                      id={`simple-tab-${5}`}
                      aria-controls={`simple-tabpanel-${5}`}
                      sx={{}}
                    />
                  ) : null}
                </Tabs>
                <Box display={'flex'} alignItems={'center'} gap={2}>
                  <Tooltip title="maximize view" placement="left" arrow>
                    <Fullscreen
                      sx={{
                        cursor: 'pointer',
                        color: theme.palette.primary.main,
                        marginRight: 2,
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleTrackEvent(e, 'maximize_playbook_view');
                        setMaximizedPlaybookFeedback((prev) => !prev);
                      }}
                    />
                  </Tooltip>
                  {report?.playbook &&
                  currentMethod === 'playbook' &&
                  ((orgConfigs?.showPlaybookToManagersOnly &&
                    (userInfo?.orgRoles?.includes('ORG_ADMIN') ||
                      userInfo?.orgRoles?.includes('WL_ORG_ADMIN'))) ||
                    !orgConfigs?.showPlaybookToManagersOnly) ? (
                    <Tooltip title="show playbook" placement="top" arrow>
                      <Article
                        sx={{
                          cursor: 'pointer',
                          color: theme.palette.primary.main,
                          marginRight: 2,
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleTrackEvent(e, 'open_playbook_dialog');
                          setShowPlaybook((prev) => !prev);
                        }}
                      />
                    </Tooltip>
                  ) : null}
                </Box>
              </Box>
              {report?.analysis?.methodAnalysis &&
              Object.keys(report?.analysis?.methodAnalysis || {}) &&
              Object.keys(report?.analysis?.methodAnalysis || {})?.length >
                0 ? (
                <>
                  {currentMethod === 'callSummary' ||
                  (currentMethod === 'playbook' &&
                    !report?.analysis?.methodAnalysis?.playbook
                      ?.analysisJson) ? (
                    <Box position={'relative'}>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'flex-end',
                          gap: 2,
                          position: 'absolute',
                          top: 10,
                          right: 10,
                          zIndex: 1000,
                        }}
                      >
                        {type === 'salesCall' &&
                        (userDetails?.custom?.integration?.hubSpot ||
                          userDetails?.custom?.integration?.zohoCrm ||
                          userDetails?.custom?.integration?.pipedriveCrm) &&
                        currentMethod === 'callSummary' ? (
                          <Tooltip
                            title={
                              userDetails?.custom?.crmUser?.crmEmail
                                ? report?.analysis
                                  ? 'Sync with CRM'
                                  : report.status === 'failed'
                                  ? `Failed to generate transcript.${
                                      uId ? '' : ' Call Analysis Pending'
                                    }`
                                  : report?.status === 'pending' ||
                                    report?.status === 'in_process' ||
                                    report?.status === 'created'
                                  ? `CRM Sync will be available once analysis is ready`
                                  : report?.status === 'success'
                                  ? report?.analysisStatus === 'in_process'
                                    ? 'CRM Sync will be available once analysis is ready'
                                    : report?.analysisStatus === 'completed'
                                    ? 'Sync with CRM'
                                    : report?.analysisStatus === 'failed'
                                    ? `Failed to analyse call.${
                                        uId ? '' : ' Please analyse again!'
                                      }`
                                    : report?.analysisStatus === null
                                    ? `CRM Sync will be available once analysis is ready`
                                    : '-'
                                  : '-'
                                : userDetails?.orgRoles?.[0] === 'ORG_ADMIN' ||
                                  userDetails?.orgRoles?.[0] ===
                                    'WL_ORG_ADMIN' ||
                                  userDetails?.teamRoles?.[0] === 'TEAM_ADMIN'
                                ? 'Link CRM User'
                                : 'User is not mapped to CRM user email. Please contact your administrator.'
                            }
                            placement="top"
                            arrow
                          >
                            <Button
                              variant="contained"
                              size="small"
                              sx={{}}
                              onClick={() => {
                                if (
                                  userDetails?.plan?.trailEndsOn >
                                    new Date().getTime() ||
                                  userDetails?.plan?.currentPaymentStatus ===
                                    'paid'
                                ) {
                                  if (
                                    userDetails?.orgRoles?.[0] ===
                                      'ORG_ADMIN' ||
                                    userDetails?.orgRoles?.[0] ===
                                      'WL_ORG_ADMIN' ||
                                    userDetails?.teamRoles?.[0] ===
                                      'TEAM_ADMIN' ||
                                    userDetails?.teamRoles?.[0] === 'STANDARD'
                                  ) {
                                    if (
                                      userDetails?.custom?.crmUser?.crmEmail &&
                                      (report?.analysis ||
                                        report?.analysisStatus === 'completed')
                                    ) {
                                      setShowSyncCrmPropertiesDialog(report);
                                    } else {
                                      setShowCrmUserMappingDialog(true);
                                    }
                                  } else {
                                    setShowUserNotMappedDialog(report);
                                  }
                                } else {
                                  dispatch({
                                    type: 'UPDATE_UI_STATE',
                                    payload: {
                                      key: 'showPlanExpired',
                                      value: true,
                                    },
                                  });
                                }
                              }}
                            >
                              Sync with CRM
                            </Button>
                          </Tooltip>
                        ) : // )
                        null}
                      </Box>
                      <Box
                        ref={scrollDiv1Ref}
                        sx={{
                          display: 'flex',
                          gap: 2,
                          borderTop: '1px solid #d3d3d3',
                          minHeight: 'calc(50vh - 100px)',
                          maxHeight: 'calc(50vh - 100px)',
                          overflowY: 'scroll',
                          '&::-webkit-scrollbar': {
                            width: '5px',
                          },
                          '&::-webkit-scrollbar-track': {
                            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                          },
                          '&::-webkit-scrollbar-thumb': {
                            backgroundColor: theme.palette.primary.main,
                            borderRadius: 2,
                            // outline: '1px solid slategrey',
                          },
                          position: 'relative',
                          padding: 2,
                        }}
                      >
                        <Typography
                          variant="body1"
                          sx={{
                            whiteSpace: 'pre-wrap',
                            flex: 1,
                            // borderRight: '1px solid #d3d3d3',
                          }}
                        >
                          {report?.analysis?.methodAnalysis?.[currentMethod]
                            ?.analysisHtml
                            ? parse(
                                report?.analysis?.methodAnalysis?.[
                                  currentMethod
                                ]?.analysisHtml || '',
                              )
                            : report?.analysis?.methodAnalysis?.[currentMethod]
                                ?.analysis || ''}
                        </Typography>
                        {report?.playbook &&
                        currentMethod === 'playbook' &&
                        showPlaybook ? (
                          <Box
                            ref={playbookRef}
                            flex={1}
                            padding={2}
                            sx={{
                              borderLeft: '1px solid #d3d3d3',
                              minHeight: 'calc(50vh - 164px)',
                              maxHeight: 'calc(50vh - 164px)',
                              overflowY: 'scroll',
                              '&::-webkit-scrollbar': {
                                width: '5px',
                              },
                              '&::-webkit-scrollbar-track': {
                                boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                                webkitBoxShadow:
                                  'inset 0 0 6px rgba(0,0,0,0.00)',
                              },
                              '&::-webkit-scrollbar-thumb': {
                                backgroundColor: theme.palette.primary.main,
                                borderRadius: 2,
                                // outline: '1px solid slategrey',
                              },
                              position: 'relative',
                            }}
                          >
                            <Typography
                              variant="body2"
                              color={'text.secondary'}
                            >
                              Playbook
                            </Typography>
                            <Typography
                              variant="body1"
                              sx={{
                                whiteSpace: 'pre-wrap',
                                flex: 1,
                              }}
                            >
                              {parse(report?.playbook || '')}
                            </Typography>
                          </Box>
                        ) : null}
                      </Box>
                    </Box>
                  ) : currentMethod === 'playbook' ? (
                    <Box position={'relative'}>
                      <Box
                        ref={scrollDiv1Ref}
                        sx={{
                          display: 'flex',
                          // gap: 2,
                          borderTop: '1px solid #d3d3d3',
                        }}
                      >
                        <Box
                          flex={1}
                          padding={2}
                          sx={{
                            minHeight: 'calc(50vh - 100px)',
                            maxHeight: 'calc(50vh - 100px)',
                            overflowY: 'scroll',
                            '&::-webkit-scrollbar': {
                              width: '5px',
                            },
                            '&::-webkit-scrollbar-track': {
                              boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                              webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                            },
                            '&::-webkit-scrollbar-thumb': {
                              backgroundColor: theme.palette.primary.main,
                              borderRadius: 2,
                              // outline: '1px solid slategrey',
                            },
                            position: 'relative',
                          }}
                        >
                          <Box
                            display={'flex'}
                            flexDirection={'column'}
                            gap={1}
                          >
                            {Object.keys(
                              JSON.parse(
                                report?.analysis?.methodAnalysis?.playbook
                                  ?.analysisJson || '{}',
                              ) || {},
                            )?.map((key) => (
                              <Box
                                key={key}
                                display={'flex'}
                                flexDirection={'column'}
                                // gap={1}
                              >
                                <Typography variant="body1" fontWeight={'bold'}>
                                  {key
                                    ?.split('_')
                                    ?.map((word, index) =>
                                      index === 0
                                        ? word.charAt(0).toUpperCase() +
                                          word.slice(1)
                                        : word,
                                    )
                                    .join(' ')}
                                </Typography>
                                <List>
                                  {JSON.parse(
                                    report?.analysis?.methodAnalysis?.playbook
                                      ?.analysisJson || '{}',
                                  )[key] &&
                                  JSON.parse(
                                    report?.analysis?.methodAnalysis?.playbook
                                      ?.analysisJson || '{}',
                                  )[key]?.length > 0 ? (
                                    JSON.parse(
                                      report?.analysis?.methodAnalysis?.playbook
                                        ?.analysisJson || '{}',
                                    )[key]?.map((feedback, i) => (
                                      <ListItem
                                        key={
                                          feedback?.feedback ||
                                          feedback?.improvement ||
                                          ''
                                        }
                                      >
                                        <ListItemText
                                          primary={`[${i +
                                            1}]. ${feedback?.feedback ||
                                            feedback?.improvement ||
                                            ''}`}
                                          secondary={
                                            feedback?.playbook_section_id &&
                                            feedback?.playbook_section_id !==
                                              'N/A' &&
                                            report?.playbook ? (
                                              <Button
                                                variant="text"
                                                color="primary"
                                                size="small"
                                                onClick={() => {
                                                  if (
                                                    (orgConfigs?.showPlaybookToManagersOnly &&
                                                      (userInfo?.orgRoles?.includes(
                                                        'ORG_ADMIN',
                                                      ) ||
                                                        userInfo?.orgRoles?.includes(
                                                          'WL_ORG_ADMIN',
                                                        ))) ||
                                                    !orgConfigs?.showPlaybookToManagersOnly
                                                  ) {
                                                    if (
                                                      report?.playbook &&
                                                      currentMethod ===
                                                        'playbook' &&
                                                      showPlaybook
                                                    ) {
                                                      const sectionId = document.getElementById(
                                                        feedback?.playbook_section_id,
                                                      );
                                                      if (
                                                        playbookRef?.current
                                                      ) {
                                                        playbookRef.current.scrollTo(
                                                          {
                                                            top:
                                                              sectionId?.offsetTop ||
                                                              0,
                                                            behavior: 'smooth',
                                                          },
                                                        );
                                                      }
                                                    } else {
                                                      setShowPlaybook(
                                                        feedback?.playbook_section_id,
                                                      );
                                                    }
                                                  }
                                                }}
                                              >
                                                {feedback?.playbook_section_id &&
                                                feedback?.playbook_section_id !==
                                                  'N/A'
                                                  ? `Playbook Section: ${feedback?.playbook_section_id
                                                      ?.replace(
                                                        /([a-z])([A-Z])/g,
                                                        '$1 $2',
                                                      )
                                                      ?.split(' ')
                                                      ?.map((word) =>
                                                        capitalizeText(word),
                                                      )
                                                      ?.join(' ')}`
                                                  : ''}
                                              </Button>
                                            ) : null
                                          }
                                        />
                                      </ListItem>
                                    ))
                                  ) : (
                                    <ListItem>
                                      <ListItemText primary="No Feedback" />
                                    </ListItem>
                                  )}
                                </List>
                              </Box>
                            ))}
                          </Box>
                        </Box>
                        {report?.playbook &&
                        currentMethod === 'playbook' &&
                        showPlaybook ? (
                          <Box
                            ref={playbookRef}
                            flex={1}
                            padding={2}
                            sx={{
                              borderLeft: '1px solid #d3d3d3',
                              minHeight: 'calc(50vh - 100px)',
                              maxHeight: 'calc(50vh - 100px)',
                              overflowY: 'scroll',
                              '&::-webkit-scrollbar': {
                                width: '5px',
                              },
                              '&::-webkit-scrollbar-track': {
                                boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                                webkitBoxShadow:
                                  'inset 0 0 6px rgba(0,0,0,0.00)',
                              },
                              '&::-webkit-scrollbar-thumb': {
                                backgroundColor: theme.palette.primary.main,
                                borderRadius: 2,
                                // outline: '1px solid slategrey',
                              },
                              position: 'relative',
                            }}
                          >
                            <Typography
                              variant="body2"
                              color={'text.secondary'}
                            >
                              Playbook
                            </Typography>
                            <Typography
                              variant="body1"
                              sx={{
                                whiteSpace: 'pre-wrap',
                                flex: 1,
                              }}
                            >
                              {parse(report?.playbook)}
                            </Typography>
                          </Box>
                        ) : null}
                      </Box>
                    </Box>
                  ) : currentMethod === 'numericDiscussion' ? (
                    <Box position={'relative'}>
                      <Box
                        ref={scrollDiv1Ref}
                        sx={{
                          display: 'flex',
                          // gap: 2,
                          borderTop: '1px solid #d3d3d3',
                        }}
                      >
                        <Box
                          flex={1}
                          padding={2}
                          sx={{
                            minHeight: 'calc(50vh - 100px)',
                            maxHeight: 'calc(50vh - 100px)',
                            overflowY: 'scroll',
                            '&::-webkit-scrollbar': {
                              width: '5px',
                            },
                            '&::-webkit-scrollbar-track': {
                              boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                              webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                            },
                            '&::-webkit-scrollbar-thumb': {
                              backgroundColor: theme.palette.primary.main,
                              borderRadius: 2,
                              // outline: '1px solid slategrey',
                            },
                            position: 'relative',
                          }}
                        >
                          <Box
                            display={'flex'}
                            flexDirection={'column'}
                            gap={1}
                          >
                            {Object.keys(
                              JSON.parse(
                                report?.analysis?.methodAnalysis
                                  ?.numericDiscussion?.analysisJson || '{}',
                              ) || {},
                            )?.map((key) => (
                              <Box
                                key={key}
                                display={'flex'}
                                flexDirection={'column'}
                              >
                                <Typography variant="body1" fontWeight={'bold'}>
                                  {key
                                    ?.split('_')
                                    ?.map((word, index) =>
                                      index === 0
                                        ? word.charAt(0).toUpperCase() +
                                          word.slice(1)
                                        : word,
                                    )
                                    .join(' ')}
                                </Typography>
                                <List>
                                  {JSON.parse(
                                    report?.analysis?.methodAnalysis
                                      ?.numericDiscussion?.analysisJson || '{}',
                                  )[key] &&
                                  JSON.parse(
                                    report?.analysis?.methodAnalysis
                                      ?.numericDiscussion?.analysisJson || '{}',
                                  )[key]?.length > 0 ? (
                                    JSON.parse(
                                      report?.analysis?.methodAnalysis
                                        ?.numericDiscussion?.analysisJson ||
                                        '{}',
                                    )[key]?.map((information, i) => (
                                      <ListItem
                                        key={
                                          information?.key_point ||
                                          information?.context ||
                                          ''
                                        }
                                      >
                                        <ListItemText
                                          primary={`[${i +
                                            1}]. ${information?.key_point ||
                                            ''} : ${information?.context ||
                                            ''}`}
                                        />
                                      </ListItem>
                                    ))
                                  ) : (
                                    <ListItem>
                                      <ListItemText primary="No Feedback" />
                                    </ListItem>
                                  )}
                                </List>
                              </Box>
                            ))}
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  ) : report?.analysis?.methodAnalysis?.[currentMethod] ? (
                    <Box
                      ref={scrollDiv1Ref}
                      sx={{
                        display: 'flex',
                        gap: 2,
                        borderTop: '1px solid #d3d3d3',

                        minHeight: '300px',
                        maxHeight: '300px',
                        overflowY: 'scroll',
                        '&::-webkit-scrollbar': {
                          width: '5px',
                        },
                        '&::-webkit-scrollbar-track': {
                          boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                          webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                        },
                        '&::-webkit-scrollbar-thumb': {
                          backgroundColor: theme.palette.primary.main,
                          borderRadius: 2,
                          // outline: '1px solid slategrey',
                        },
                        position: 'relative',
                      }}
                    >
                      <Typography
                        variant="body1"
                        sx={{
                          whiteSpace: 'pre-wrap',
                          padding: 2,
                          flex: 1,
                          borderRight: '1px solid #d3d3d3',
                        }}
                      >
                        {
                          report?.analysis?.methodAnalysis?.[currentMethod]
                            ?.analysis
                        }
                      </Typography>
                    </Box>
                  ) : currentMethod === 'feedback' ? (
                    <Box
                      ref={scrollDiv1Ref}
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 2,
                        borderTop: '1px solid #d3d3d3',

                        minHeight: '300px',
                        maxHeight: '300px',
                        overflowY: 'scroll',
                        '&::-webkit-scrollbar': {
                          width: '5px',
                        },
                        '&::-webkit-scrollbar-track': {
                          boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                          webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                        },
                        '&::-webkit-scrollbar-thumb': {
                          backgroundColor: theme.palette.primary.main,
                          borderRadius: 2,
                          // outline: '1px solid slategrey',
                        },
                        padding: 1,
                      }}
                    >
                      {feedbacksOnEntity?.fetching ? (
                        <Box
                          width={1}
                          height={1}
                          display={'flex'}
                          justifyContent={'center'}
                          alignItems={'center'}
                          flexDirection={'column'}
                        >
                          <CircularProgress size={20} />
                          <Typography
                            variant="body1"
                            color={'text.primary'}
                            mt={4}
                          >
                            Loading all notes ...
                          </Typography>
                        </Box>
                      ) : feedbacksOnEntity?.feedbacks &&
                        feedbacksOnEntity?.feedbacks?.length > 0 ? (
                        <Box
                          flex={3}
                          display={'flex'}
                          flexDirection={'column'}
                          gap={1}
                        >
                          <Box
                            sx={{
                              // minHeight: 150,
                              // maxHeight: 150,
                              overflowY: 'scroll',
                              '&::-webkit-scrollbar': {
                                width: '5px',
                              },
                              '&::-webkit-scrollbar-track': {
                                boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                                webkitBoxShadow:
                                  'inset 0 0 6px rgba(0,0,0,0.00)',
                              },
                              '&::-webkit-scrollbar-thumb': {
                                backgroundColor: theme.palette.primary.main,
                                borderRadius: 2,
                              },
                              padding: 1,
                              display: 'flex',
                              flexDirection: 'column',
                              gap: 1,
                            }}
                            onScroll={handleFeedbackListScroll}
                          >
                            {feedbacksOnEntity?.feedbacks.map((feedback, i) => (
                              <Box
                                key={feedback?.id}
                                display={'flex'}
                                flexDirection={'column'}
                                gap={1}
                              >
                                <Box
                                  display={'flex'}
                                  justifyContent={'space-between'}
                                >
                                  <Box
                                    display={'flex'}
                                    flexDirection={'column'}
                                  >
                                    <Typography
                                      variant="subtitle1"
                                      fontWeight={'bold'}
                                      color={'text.secondary'}
                                    >
                                      {capitalizeText(
                                        feedback?.customFields?.user?.fullName,
                                      )}
                                    </Typography>
                                    <Typography variant="caption">
                                      {feedback?.customFields?.user?.email}
                                    </Typography>
                                  </Box>
                                  <Typography
                                    variant="caption"
                                    fontWeight={'bold'}
                                    color={'text.secondary'}
                                  >
                                    {moment(feedback?.createdOn).format(
                                      'DD MMMM YYYY',
                                    )}
                                  </Typography>
                                </Box>
                                <Typography
                                  variant="body1"
                                  sx={{
                                    whiteSpace: 'pre-wrap',
                                    // padding: 2,
                                    flex: 1,
                                    // borderRight: '1px solid #d3d3d3',
                                  }}
                                >
                                  {feedback?.feedback}
                                </Typography>
                              </Box>
                            ))}
                            {feedbacksOnEntity?.fetchingPagination ? (
                              <Box
                                sx={{
                                  width: '100%',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  padding: 1,
                                  textAlign: 'center',
                                }}
                              >
                                <Button
                                  size="small"
                                  variant="contained"
                                  // type="submit"
                                  color="primary"
                                  onClick={handleFeedbackListScroll}
                                  // className="searchSettings"
                                  disabled
                                  // fullWidth
                                >
                                  <Typography color="textPrimary">
                                    Loading more notes ...{' '}
                                  </Typography>
                                  <CircularProgress size={20} />
                                </Button>
                              </Box>
                            ) : feedbacksOnEntity?.emptyList === false &&
                              feedbacksOnEntity?.feedbacks.length >= 10 ? (
                              <Box
                                sx={{
                                  // width: '100%',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  padding: 1,
                                  textAlign: 'center',
                                }}
                              >
                                <Button
                                  size="small"
                                  variant="contained"
                                  // type="submit"
                                  // fullWidth
                                  color="primary"
                                  onClick={(e) =>
                                    handleFeedbackListScroll(e, true)
                                  }
                                  // className="searchSettings"
                                >
                                  Load More
                                </Button>
                              </Box>
                            ) : null}
                          </Box>
                        </Box>
                      ) : (
                        <Box
                          flex={3}
                          display={'flex'}
                          justifyContent={'center'}
                          alignItems={'center'}
                          flexDirection={'column'}
                          gap={2}
                        >
                          <Typography
                            variant="body1"
                            color={'text.secondary'}
                            mt={4}
                          >
                            No notes added yet.
                          </Typography>
                        </Box>
                      )}
                      <Box
                        display={'flex'}
                        flexDirection={'column'}
                        gap={1}
                        flex={2}
                        borderLeft={'1px solid #d3d3d3'}
                        p={1}
                      >
                        <TextField
                          autoFocus
                          placeholder="Enter a note"
                          variant="outlined"
                          color="primary"
                          size="small"
                          name="feedbackOnEntity"
                          multiline
                          rows={4}
                          value={feedbackOnEntity}
                          onChange={(e) => setFeedbackOnEntity(e.target.value)}
                          fullWidth
                        />
                        <Button
                          variant="contained"
                          size="small"
                          color="primary"
                          onClick={() => {
                            setPostingFeedback(true);
                            dispatch(
                              postFeedbackOnEntity(
                                report?.id,
                                {
                                  entityId: report?.id,
                                  feedback: feedbackOnEntity,
                                  type:
                                    type === 'salesCall'
                                      ? 'sales_call_analysis'
                                      : type === 'practiceCall'
                                      ? 'conversation_standard'
                                      : 'conversation_otpc',
                                },
                                () => {
                                  setFeedbackOnEntity('');
                                  dispatch(
                                    showAlert({
                                      message: 'Note is added successfully.',
                                      showCross: true,
                                      title: null,
                                      type: 'success',
                                      autoHideDuration: 5000,
                                      vertical: 'top',
                                      horizontal: 'center',
                                    }),
                                  );
                                  dispatch(
                                    getFeedbacksOnEntity(
                                      report?.id,
                                      10,
                                      null,
                                      () => {},
                                    ),
                                  );
                                  setPostingFeedback(false);
                                },
                                () => {
                                  dispatch(
                                    showAlert({
                                      message: 'Failed to post note.',
                                      showCross: true,
                                      title: null,
                                      type: 'error',
                                      autoHideDuration: 5000,
                                      vertical: 'top',
                                      horizontal: 'center',
                                    }),
                                  );
                                  setPostingFeedback(false);
                                },
                              ),
                            );
                          }}
                        >
                          Add Note
                        </Button>
                      </Box>
                    </Box>
                  ) : currentMethod === 'askAI' ? (
                    <Box
                      ref={scrollDiv1Ref}
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 2,
                        borderTop: '1px solid #d3d3d3',
                        minHeight: 'calc(50vh - 100px)',
                        maxHeight: 'calc(50vh - 100px)',
                        overflowY: 'scroll',
                        '&::-webkit-scrollbar': {
                          width: '5px',
                        },
                        '&::-webkit-scrollbar-track': {
                          boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                          webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                        },
                        '&::-webkit-scrollbar-thumb': {
                          backgroundColor: theme.palette.primary.main,
                          borderRadius: 2,
                          // outline: '1px solid slategrey',
                        },
                        // padding: 1,
                        position: 'relative',
                      }}
                    >
                      {fetchingConversationById ? (
                        <Box
                          width={1}
                          height={1}
                          display={'flex'}
                          justifyContent={'center'}
                          alignItems={'center'}
                          flexDirection={'column'}
                          p={2}
                        >
                          <CircularProgress size={20} />
                          <Typography
                            variant="body1"
                            color={'text.primary'}
                            mt={2}
                          >
                            Loading all chats ...
                          </Typography>
                        </Box>
                      ) : conversationDetails ? (
                        conversationDetails?.presetMessageMap ||
                        (conversationDetails?.messages &&
                          conversationDetails?.messages?.length > 0) ? (
                          <Box
                            flex={1}
                            display={'flex'}
                            flexDirection={'column'}
                            gap={1}
                          >
                            <Box
                              ref={chatsScrollview}
                              sx={{
                                minHeight: 'calc(50vh - 158px)',
                                maxHeight: 'calc(50vh - 158px)',
                                overflowY: 'scroll',
                                '&::-webkit-scrollbar': {
                                  width: '5px',
                                },
                                '&::-webkit-scrollbar-track': {
                                  boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                                  webkitBoxShadow:
                                    'inset 0 0 6px rgba(0,0,0,0.00)',
                                },
                                '&::-webkit-scrollbar-thumb': {
                                  backgroundColor: theme.palette.primary.main,
                                  borderRadius: 2,
                                },
                                padding: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 1,
                              }}
                            >
                              {Object.values(
                                conversationDetails?.presetMessageMap || {},
                              )?.map(({ question, answer }, index) => (
                                <>
                                  {question ? (
                                    <Box
                                      key={question?.id}
                                      sx={{
                                        borderTopLeftRadius:
                                          question?.gptMessage?.role === 'user'
                                            ? 16
                                            : 0,
                                        borderTopRightRadius:
                                          question?.gptMessage?.role === 'user'
                                            ? 0
                                            : 16,
                                        borderBottomLeftRadius:
                                          question?.gptMessage?.role === 'user'
                                            ? 8
                                            : 16,
                                        borderBottomRightRadius:
                                          question?.gptMessage?.role === 'user'
                                            ? 16
                                            : 8,
                                        maxWidth: '80%',
                                        alignSelf:
                                          question?.gptMessage?.role === 'user'
                                            ? 'end'
                                            : 'start',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        // gap: 1,
                                        boxShadow: theme.shadows[8],
                                        background:
                                          question?.gptMessage?.role === 'user'
                                            ? theme.palette.primary.main
                                            : '#fff',
                                      }}
                                    >
                                      <Box
                                        display={'flex'}
                                        alignItems={'center'}
                                        justifyContent={'space-between'}
                                        sx={{
                                          padding: '8px 16px',
                                          gap: 4,
                                        }}
                                      >
                                        {question?.gptMessage?.role ===
                                        'user' ? (
                                          <Typography
                                            variant="caption"
                                            sx={{
                                              color:
                                                question?.gptMessage?.role ===
                                                'user'
                                                  ? '#f1f1f1'
                                                  : theme.palette.text
                                                      .secondary,
                                            }}
                                          >
                                            You
                                          </Typography>
                                        ) : (
                                          <Typography
                                            variant="caption"
                                            sx={{
                                              color:
                                                question?.gptMessage?.role ===
                                                'user'
                                                  ? '#f1f1f1'
                                                  : theme.palette.text
                                                      .secondary,
                                            }}
                                          >
                                            {orgConfigs?.navBarTitles?.[
                                              'Account Settings'
                                            ]?.subTitles?.['Ask Donna']?.title
                                              ?.toLowerCase()
                                              ?.split('ask')
                                              ?.join('')
                                              ?.trim()
                                              ? capitalizeText(
                                                  orgConfigs?.navBarTitles?.[
                                                    'Account Settings'
                                                  ]?.subTitles?.[
                                                    'Ask Donna'
                                                  ]?.title
                                                    ?.toLowerCase()
                                                    ?.split('ask')
                                                    ?.join('')
                                                    ?.trim(),
                                                )
                                              : 'Ask Donna'}
                                          </Typography>
                                        )}
                                        <Typography
                                          variant="caption"
                                          sx={{
                                            color:
                                              question?.gptMessage?.role ===
                                              'user'
                                                ? '#f1f1f1'
                                                : theme.palette.text.secondary,
                                          }}
                                        >
                                          {moment(question?.createdOn).format(
                                            'DD MMMM YYYY hh:mm:ss A',
                                          )}
                                        </Typography>
                                      </Box>
                                      <Typography
                                        sx={{
                                          whiteSpace: 'pre-wrap',
                                          color:
                                            question?.gptMessage?.role ===
                                            'user'
                                              ? '#fff'
                                              : theme.palette.text.primary,
                                          padding: '0px 16px 16px 16px',
                                        }}
                                      >
                                        {question?.gptMessage?.content}
                                      </Typography>
                                    </Box>
                                  ) : null}
                                  {answer ? (
                                    <Box
                                      key={answer?.id}
                                      sx={{
                                        borderTopLeftRadius:
                                          answer?.gptMessage?.role === 'user'
                                            ? 16
                                            : 0,
                                        borderTopRightRadius:
                                          answer?.gptMessage?.role === 'user'
                                            ? 0
                                            : 16,
                                        borderBottomLeftRadius:
                                          answer?.gptMessage?.role === 'user'
                                            ? 8
                                            : 16,
                                        borderBottomRightRadius:
                                          answer?.gptMessage?.role === 'user'
                                            ? 16
                                            : 8,
                                        maxWidth: '80%',
                                        alignSelf:
                                          answer?.gptMessage?.role === 'user'
                                            ? 'end'
                                            : 'start',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        // gap: 1,
                                        boxShadow: theme.shadows[8],
                                        background:
                                          answer?.gptMessage?.role === 'user'
                                            ? theme.palette.primary.main
                                            : '#fff',
                                      }}
                                    >
                                      <Box
                                        display={'flex'}
                                        alignItems={'center'}
                                        justifyContent={'space-between'}
                                        sx={{
                                          padding: '8px 16px',
                                          gap: 4,
                                        }}
                                      >
                                        {answer?.gptMessage?.role === 'user' ? (
                                          <Typography
                                            variant="caption"
                                            sx={{
                                              color:
                                                answer?.gptMessage?.role ===
                                                'user'
                                                  ? '#f1f1f1'
                                                  : theme.palette.text
                                                      .secondary,
                                            }}
                                          >
                                            You
                                          </Typography>
                                        ) : (
                                          <Typography
                                            variant="caption"
                                            sx={{
                                              color:
                                                answer?.gptMessage?.role ===
                                                'user'
                                                  ? '#f1f1f1'
                                                  : theme.palette.text
                                                      .secondary,
                                            }}
                                          >
                                            {orgConfigs?.navBarTitles?.[
                                              'Account Settings'
                                            ]?.subTitles?.['Ask Donna']?.title
                                              ?.toLowerCase()
                                              ?.split('ask')
                                              ?.join('')
                                              ?.trim()
                                              ? capitalizeText(
                                                  orgConfigs?.navBarTitles?.[
                                                    'Account Settings'
                                                  ]?.subTitles?.[
                                                    'Ask Donna'
                                                  ]?.title
                                                    ?.toLowerCase()
                                                    ?.split('ask')
                                                    ?.join('')
                                                    ?.trim(),
                                                )
                                              : 'Ask Donna'}
                                          </Typography>
                                        )}
                                        <Typography
                                          variant="caption"
                                          sx={{
                                            color:
                                              answer?.gptMessage?.role ===
                                              'user'
                                                ? '#f1f1f1'
                                                : theme.palette.text.secondary,
                                          }}
                                        >
                                          {moment(answer?.createdOn).format(
                                            'DD MMMM YYYY hh:mm:ss A',
                                          )}
                                        </Typography>
                                      </Box>
                                      <Typography
                                        sx={{
                                          whiteSpace: 'pre-wrap',
                                          color:
                                            answer?.gptMessage?.role === 'user'
                                              ? '#fff'
                                              : theme.palette.text.primary,
                                          padding: '0px 16px 16px 16px',
                                        }}
                                      >
                                        {answer?.gptMessage?.content}
                                      </Typography>
                                    </Box>
                                  ) : (
                                    <Box
                                      width={1}
                                      height={1}
                                      display={'flex'}
                                      justifyContent={'center'}
                                      alignItems={'center'}
                                      flexDirection={'column'}
                                      p={2}
                                      sx={{
                                        borderTopLeftRadius:
                                          answer?.gptMessage?.role === 'user'
                                            ? 16
                                            : 0,
                                        borderTopRightRadius:
                                          answer?.gptMessage?.role === 'user'
                                            ? 0
                                            : 16,
                                        borderBottomLeftRadius:
                                          answer?.gptMessage?.role === 'user'
                                            ? 8
                                            : 16,
                                        borderBottomRightRadius:
                                          answer?.gptMessage?.role === 'user'
                                            ? 16
                                            : 8,
                                        maxWidth: '80%',
                                        alignSelf:
                                          answer?.gptMessage?.role === 'user'
                                            ? 'end'
                                            : 'start',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        // gap: 1,
                                        boxShadow: theme.shadows[8],
                                        background:
                                          answer?.gptMessage?.role === 'user'
                                            ? theme.palette.primary.main
                                            : '#fff',
                                      }}
                                    >
                                      <CircularProgress size={20} />
                                      <Typography
                                        variant="body1"
                                        color={'text.primary'}
                                        mt={4}
                                      >
                                        This will take a few seconds. Please
                                        check back after a short while.
                                      </Typography>
                                    </Box>
                                  )}
                                </>
                              ))}
                              {conversationDetails?.messages?.map(
                                (msg, index) => (
                                  <>
                                    {msg ? (
                                      <Box
                                        key={msg?.id}
                                        sx={{
                                          borderTopLeftRadius:
                                            msg?.gptMessage?.role === 'user'
                                              ? 16
                                              : 0,
                                          borderTopRightRadius:
                                            msg?.gptMessage?.role === 'user'
                                              ? 0
                                              : 16,
                                          borderBottomLeftRadius:
                                            msg?.gptMessage?.role === 'user'
                                              ? 8
                                              : 16,
                                          borderBottomRightRadius:
                                            msg?.gptMessage?.role === 'user'
                                              ? 16
                                              : 8,
                                          maxWidth: '80%',
                                          alignSelf:
                                            msg?.gptMessage?.role === 'user'
                                              ? 'end'
                                              : 'start',
                                          display: 'flex',
                                          flexDirection: 'column',
                                          // gap: 1,
                                          boxShadow: theme.shadows[8],
                                          background:
                                            msg?.gptMessage?.role === 'user'
                                              ? theme.palette.primary.main
                                              : '#fff',
                                        }}
                                      >
                                        <Box
                                          display={'flex'}
                                          alignItems={'center'}
                                          justifyContent={'space-between'}
                                          sx={{
                                            padding: '8px 16px',
                                            gap: 4,
                                          }}
                                        >
                                          {msg?.gptMessage?.role === 'user' ? (
                                            <Typography
                                              variant="caption"
                                              sx={{
                                                color:
                                                  msg?.gptMessage?.role ===
                                                  'user'
                                                    ? '#f1f1f1'
                                                    : theme.palette.text
                                                        .secondary,
                                              }}
                                            >
                                              {`${msg?.customFields?.userDetails?.firstName} ${msg?.customFields?.userDetails?.lastName}` !==
                                              userInfo?.fullName
                                                ? `${msg?.customFields?.userDetails?.firstName} ${msg?.customFields?.userDetails?.lastName}`
                                                : 'You'}
                                            </Typography>
                                          ) : (
                                            <Typography
                                              variant="caption"
                                              sx={{
                                                color:
                                                  msg?.gptMessage?.role ===
                                                  'user'
                                                    ? '#f1f1f1'
                                                    : theme.palette.text
                                                        .secondary,
                                              }}
                                            >
                                              {orgConfigs?.navBarTitles?.[
                                                'Account Settings'
                                              ]?.subTitles?.['Ask Donna']?.title
                                                ?.toLowerCase()
                                                ?.split('ask')
                                                ?.join('')
                                                ?.trim()
                                                ? capitalizeText(
                                                    orgConfigs?.navBarTitles?.[
                                                      'Account Settings'
                                                    ]?.subTitles?.[
                                                      'Ask Donna'
                                                    ]?.title
                                                      ?.toLowerCase()
                                                      ?.split('ask')
                                                      ?.join('')
                                                      ?.trim(),
                                                  )
                                                : 'Ask Donna'}
                                            </Typography>
                                          )}
                                          <Typography
                                            variant="caption"
                                            sx={{
                                              color:
                                                msg?.gptMessage?.role === 'user'
                                                  ? '#f1f1f1'
                                                  : theme.palette.text
                                                      .secondary,
                                            }}
                                          >
                                            {moment(msg?.createdOn).format(
                                              'DD MMMM YYYY hh:mm:ss A',
                                            )}
                                          </Typography>
                                        </Box>
                                        <Typography
                                          sx={{
                                            whiteSpace: 'pre-wrap',
                                            color:
                                              msg?.gptMessage?.role === 'user'
                                                ? '#fff'
                                                : theme.palette.text.primary,
                                            padding: '0px 16px 16px 16px',
                                          }}
                                        >
                                          {msg?.gptMessage?.content}
                                        </Typography>
                                      </Box>
                                    ) : null}
                                  </>
                                ),
                              )}
                            </Box>
                          </Box>
                        ) : !conversationDetails?.presetMessageMap &&
                          (!conversationDetails?.messages ||
                            (conversationDetails?.messages &&
                              conversationDetails?.messages?.length === 0)) ? (
                          <Box
                            width={1}
                            height={1}
                            display={'flex'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            p={2}
                            flexDirection={'column'}
                          >
                            <Typography
                              variant="body1"
                              color={'text.secondary'}
                              mt={4}
                            >
                              No conversation done yet.
                            </Typography>
                          </Box>
                        ) : (
                          <Box
                            width={1}
                            height={1}
                            display={'flex'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            p={2}
                            flexDirection={'column'}
                          >
                            <CircularProgress size={20} />
                            <Typography
                              variant="body1"
                              color={'text.primary'}
                              mt={4}
                            >
                              This will take a few seconds. Please check back
                              after a short while.
                            </Typography>
                          </Box>
                        )
                      ) : (
                        <Box
                          flex={3}
                          display={'flex'}
                          justifyContent={'center'}
                          alignItems={'center'}
                          flexDirection={'column'}
                          gap={2}
                        >
                          <Typography
                            variant="body1"
                            color={'text.secondary'}
                            mt={4}
                          >
                            No conversation done yet.
                          </Typography>
                        </Box>
                      )}
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          if (
                            userDetails?.plan?.trailEndsOn >
                              new Date().getTime() ||
                            userDetails?.plan?.currentPaymentStatus === 'paid'
                          ) {
                            setGeneratingResponse(true);
                            dispatch(
                              getMessageResponse(
                                report?.conversationId,
                                askAIQuestion,
                                report?.speaker,
                                null,
                                null,
                                'v2',
                                wordsLimit,
                                async (data) => {
                                  // console.log(data);
                                  setGeneratingResponse(false);
                                  setAskAIQuestion('');
                                  dispatch({
                                    type: 'UPDATE_CONVERSATION_MESSAGES',
                                    payload: data,
                                  });
                                  if (chatsScrollview?.current) {
                                    chatsScrollview?.current?.scroll({
                                      top:
                                        chatsScrollview?.current?.scrollHeight,
                                      behaviour: 'smooth',
                                    });
                                  }
                                },
                                (errorCode) => {
                                  setGeneratingResponse(false);
                                  if (errorCode === 'quota exceeded') {
                                    // setShowLimitExhausted(errorCode);
                                  }
                                  // console.log('coming here');
                                  // setGettingResponse(false);
                                  // setMessage('');
                                  // resetTranscript();
                                },
                              ),
                            );
                          } else {
                            dispatch({
                              type: 'UPDATE_UI_STATE',
                              payload: {
                                key: 'showPlanExpired',
                                value: true,
                              },
                            });
                          }
                        }}
                      >
                        <Box
                          display={'flex'}
                          flexDirection={'row'}
                          alignItems={'center'}
                          gap={1}
                          borderTop={'1px solid #d3d3d3'}
                          p={1}
                          sx={{
                            position: 'absolute',
                            bottom: 0,
                            left: 0,
                            zIndex: 11,
                            width: 1,
                            background: '#fff',
                          }}
                        >
                          <TextField
                            autoFocus
                            placeholder="Ask any question"
                            variant="outlined"
                            color="primary"
                            size="small"
                            name="askAIQuestion"
                            value={askAIQuestion}
                            onChange={(e) => setAskAIQuestion(e.target.value)}
                            sx={{ flex: 4 }}
                            autoComplete="off"
                          />
                          <FormControl size="small" sx={{ minWidth: 150 }}>
                            <InputLabel id="wordsLimit">Words Limit</InputLabel>
                            <Select
                              variant="outlined"
                              size="small"
                              labelId={'wordsLimit'}
                              label={'Words Limit'}
                              id={'wordsLimit'}
                              name={'wordsLimit'}
                              MenuProps={{ disableScrollLock: true }}
                              onChange={(e) => {
                                setWordsLimit(e.target.value);
                              }}
                              value={wordsLimit}
                            >
                              <MenuItem value={50}>
                                <Typography variant="subtitle2">50</Typography>
                              </MenuItem>
                              <MenuItem value={100}>
                                <Typography variant="subtitle2">100</Typography>
                              </MenuItem>
                              <MenuItem value={150}>
                                <Typography variant="subtitle2">150</Typography>
                              </MenuItem>
                              <MenuItem value={200}>
                                <Typography variant="subtitle2">200</Typography>
                              </MenuItem>
                              {process.env.REACT_APP_TARGET_ENV === 'local' ||
                              process.env.REACT_APP_TARGET_ENV ===
                                'development' ? (
                                <MenuItem value={500}>
                                  <Typography variant="subtitle2">
                                    500
                                  </Typography>
                                </MenuItem>
                              ) : null}
                            </Select>
                          </FormControl>
                          <Button
                            sx={{ flex: 1 }}
                            variant="contained"
                            size="small"
                            color="primary"
                            disabled={
                              generatingResponse ||
                              !askAIQuestion.trim() ||
                              conversationDetails?.presetQuestionStatus !==
                                'completed'
                            }
                            type="submit"
                            startIcon={
                              generatingResponse ? (
                                <CircularProgress size={20} />
                              ) : (
                                <AutoAwesome />
                              )
                            }
                          >
                            {orgConfigs?.navBarTitles?.['Account Settings']
                              ?.subTitles?.['Ask Donna']?.title || 'Ask Donna'}
                          </Button>
                        </Box>
                      </form>
                    </Box>
                  ) : (
                    <Box
                      display={'flex'}
                      alignItems={'center'}
                      justifyContent={'center'}
                      sx={{
                        minHeight: 'calc(100vh - 600px)',
                      }}
                    >
                      <Typography
                        variant="body1"
                        sx={{
                          whiteSpace: 'pre-wrap',
                          padding: 2,
                        }}
                        color={'text.secondary'}
                      >
                        Not available
                      </Typography>
                    </Box>
                  )}
                </>
              ) : null}
            </Box>
          </Box>
        </>
      ) : null}
      <Drawer
        open={Boolean(maximizedPlaybookFeedback)}
        anchor="right"
        onClose={() => setMaximizedPlaybookFeedback(false)}
        sx={{
          '& .MuiPaper-root': {
            width: 1,
          },
        }}
      >
        <Box
          p={1}
          sx={{
            width: 1,
            borderBottom: '1px solid #d3d3d3',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 2,
            }}
          >
            <IconButton onClick={() => setMaximizedPlaybookFeedback(false)}>
              <ChevronRight sx={{}} />
            </IconButton>
            <Typography>{report?.title}</Typography>
          </Box>
          <Button
            startIcon={<Close />}
            onClick={() => setMaximizedPlaybookFeedback(false)}
            variant="outlined"
            size="small"
          >
            Close
          </Button>
        </Box>
        <Box display={'flex'} flex={1}>
          {/* <Box display={'flex'} flexDirection={'column'} gap={2} flex={1}>
            <MediaPreview
              videoRef={videoRef}
              styles={{
                flex: 1,
                padding: 3,
              }}
              url={
                type === 'practiceCall' || type === 'otpc'
                  ? report?.customFields?.audioLink || ''
                  : report?.customData?.url || ''
              }
              mediaType={report?.saleReviewMediaContentType || ''}
              handleVideoSeekTo
            />
            <Box
              flex={1}
              sx={{
                padding: 2,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              
            </Box>
          </Box> */}
          <Box
            ref={scrollDiv1Ref}
            sx={{
              // display: 'flex',
              flex: 1,
              width: 1,
              // borderLeft: '1px solid #d3d3d3',
            }}
          >
            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              <Tabs
                value={currentMethod}
                onChange={(e, newValue) => {
                  handleTrackEvent(e, `change_feedback_tab_to_${newValue}`);
                  setCurrentMethod(newValue);
                }}
                aria-label="Feedback Tabs"
                orientation={'horizontal'}
                // indicatorColor={theme.palette.background.default}
                variant="scrollable"
                // scrollButtons={!isMd}
                allowScrollButtonsMobile
                sx={{ borderBottom: '1px solid #d3d3d3' }}
              >
                {report?.analysis?.methodAnalysis &&
                Object.keys(report?.analysis?.methodAnalysis || {}) &&
                Object.keys(report?.analysis?.methodAnalysis || {})?.length > 0
                  ? process.env.REACT_APP_TARGET_ENV === 'local' ||
                    process.env.REACT_APP_TARGET_ENV === 'development'
                    ? [
                        'callSummary',
                        'playbook',
                        'askAI',
                        'numericDiscussion',
                        ...(report?.analysisMethodologies || []),
                      ]
                        .filter(
                          (item) =>
                            Object.keys(
                              report?.analysis?.methodAnalysis || {},
                            )?.includes(item) ||
                            (type === 'salesCall' &&
                              item === 'askAI' &&
                              Object.keys(
                                userDetails?.plan?.features || {},
                              )?.find((feature) => feature === 'donna')),
                        )
                        ?.map((key) => (
                          <Tab
                            key={key}
                            value={key}
                            label={
                              <Typography variant="caption" fontWeight={'bold'}>
                                {key === 'callSummary'
                                  ? 'Call Summary'
                                  : key === 'playbook'
                                  ? 'Playbook'
                                  : key === 'numericDiscussion'
                                  ? 'Numerical Discussions'
                                  : key === 'askAI'
                                  ? orgConfigs?.navBarTitles?.[
                                      'Account Settings'
                                    ]?.subTitles?.['Ask Donna']?.title ||
                                    'Ask Donna'
                                  : key === 'meddpic'
                                  ? 'MEDDICC'
                                  : key.toUpperCase()}
                              </Typography>
                            }
                            id={`simple-tab-${5}`}
                            aria-controls={`simple-tabpanel-${5}`}
                            sx={{}}
                          />
                        ))
                    : [
                        'callSummary',
                        'playbook',
                        'askAI',
                        ...(report?.analysisMethodologies || []),
                      ]
                        .filter(
                          (item) =>
                            Object.keys(
                              report?.analysis?.methodAnalysis || {},
                            )?.includes(item) ||
                            (type === 'salesCall' &&
                              item === 'askAI' &&
                              Object.keys(
                                userDetails?.plan?.features || {},
                              )?.find((feature) => feature === 'donna')),
                        )
                        ?.map((key) => (
                          <Tab
                            key={key}
                            value={key}
                            label={
                              <Typography variant="caption" fontWeight={'bold'}>
                                {key === 'callSummary'
                                  ? 'Call Summary'
                                  : key === 'playbook'
                                  ? 'Playbook'
                                  : key === 'numericDiscussion'
                                  ? 'Numerical Discussions'
                                  : key === 'askAI'
                                  ? orgConfigs?.navBarTitles?.[
                                      'Account Settings'
                                    ]?.subTitles?.['Ask Donna']?.title ||
                                    'Ask Donna'
                                  : key === 'meddpic'
                                  ? 'MEDDICC'
                                  : key.toUpperCase()}
                              </Typography>
                            }
                            id={`simple-tab-${5}`}
                            aria-controls={`simple-tabpanel-${5}`}
                            sx={{}}
                          />
                        ))
                  : null}
                {type !== 'otpc' ? (
                  <Tab
                    value={'feedback'}
                    label={
                      <Typography variant="caption" fontWeight={'bold'}>
                        Notes
                      </Typography>
                    }
                    id={`simple-tab-${5}`}
                    aria-controls={`simple-tabpanel-${5}`}
                    sx={{}}
                  />
                ) : null}
              </Tabs>
              <Box display={'flex'} alignItems={'center'} gap={2}>
                {report?.playbook &&
                currentMethod === 'playbook' &&
                ((orgConfigs?.showPlaybookToManagersOnly &&
                  (userInfo?.orgRoles?.includes('ORG_ADMIN') ||
                    userInfo?.orgRoles?.includes('WL_ORG_ADMIN'))) ||
                  !orgConfigs?.showPlaybookToManagersOnly) ? (
                  <Tooltip title="show playbook" placement="top" arrow>
                    <Article
                      sx={{
                        cursor: 'pointer',
                        color: theme.palette.primary.main,
                        marginRight: 2,
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleTrackEvent(e, 'open_playbook_dialog');
                        setShowPlaybook((prev) => !prev);
                      }}
                    />
                  </Tooltip>
                ) : null}
              </Box>
            </Box>
            {report?.analysis?.methodAnalysis &&
            Object.keys(report?.analysis?.methodAnalysis || {}) &&
            Object.keys(report?.analysis?.methodAnalysis || {})?.length > 0 ? (
              <>
                {currentMethod === 'callSummary' ||
                (currentMethod === 'playbook' &&
                  !report?.analysis?.methodAnalysis?.playbook?.analysisJson) ? (
                  <Box position={'relative'}>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        gap: 2,
                        position: 'absolute',
                        top: 10,
                        right: 10,
                        zIndex: 1000,
                      }}
                    >
                      {type === 'salesCall' &&
                      (userDetails?.custom?.integration?.hubSpot ||
                        userDetails?.custom?.integration?.zohoCrm ||
                        userDetails?.custom?.integration?.pipedriveCrm) &&
                      currentMethod === 'callSummary' ? (
                        <Tooltip
                          title={
                            userDetails?.custom?.crmUser?.crmEmail
                              ? report?.analysis
                                ? 'Sync with Zoho'
                                : report.status === 'failed'
                                ? `Failed to generate transcript.${
                                    uId ? '' : ' Call Analysis Pending'
                                  }`
                                : report?.status === 'pending' ||
                                  report?.status === 'in_process' ||
                                  report?.status === 'created'
                                ? `Zoho Sync will be available once analysis is ready`
                                : report?.status === 'success'
                                ? report?.analysisStatus === 'in_process'
                                  ? 'Zoho Sync will be available once analysis is ready'
                                  : report?.analysisStatus === 'completed'
                                  ? 'Sync with Zoho'
                                  : report?.analysisStatus === 'failed'
                                  ? `Failed to analyse call.${
                                      uId ? '' : ' Please analyse again!'
                                    }`
                                  : report?.analysisStatus === null
                                  ? `Zoho Sync will be available once analysis is ready`
                                  : '-'
                                : '-'
                              : userDetails?.orgRoles?.[0] === 'ORG_ADMIN' ||
                                userDetails?.orgRoles?.[0] === 'WL_ORG_ADMIN' ||
                                userDetails?.teamRoles?.[0] === 'TEAM_ADMIN'
                              ? 'Link Zoho User'
                              : 'User is not mapped to Zoho user email. Please contact your administrator.'
                          }
                          placement="top"
                          arrow
                        >
                          <Button
                            variant="contained"
                            size="small"
                            sx={{}}
                            onClick={() => {
                              if (
                                userDetails?.plan?.trailEndsOn >
                                  new Date().getTime() ||
                                userDetails?.plan?.currentPaymentStatus ===
                                  'paid'
                              ) {
                                if (
                                  userDetails?.orgRoles?.[0] === 'ORG_ADMIN' ||
                                  userDetails?.orgRoles?.[0] ===
                                    'WL_ORG_ADMIN' ||
                                  userDetails?.teamRoles?.[0] ===
                                    'TEAM_ADMIN' ||
                                  userDetails?.teamRoles?.[0] === 'STANDARD'
                                ) {
                                  if (
                                    userDetails?.custom?.crmUser?.crmEmail &&
                                    (report?.analysis ||
                                      report?.analysisStatus === 'completed')
                                  ) {
                                    setShowSyncCrmPropertiesDialog(report);
                                  } else {
                                    setShowCrmUserMappingDialog(true);
                                  }
                                } else {
                                  setShowUserNotMappedDialog(report);
                                }
                              } else {
                                dispatch({
                                  type: 'UPDATE_UI_STATE',
                                  payload: {
                                    key: 'showPlanExpired',
                                    value: true,
                                  },
                                });
                              }
                            }}
                          >
                            Sync with CRM
                          </Button>
                        </Tooltip>
                      ) : // )
                      null}
                    </Box>
                    <Box
                      ref={scrollDiv1Ref}
                      sx={{
                        display: 'flex',
                        gap: 2,
                        borderTop: '1px solid #d3d3d3',
                        minHeight: 'calc(100vh - 110px)',
                        maxHeight: 'calc(100vh - 110px)',
                        overflowY: 'scroll',
                        '&::-webkit-scrollbar': {
                          width: '5px',
                        },
                        '&::-webkit-scrollbar-track': {
                          boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                          webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                        },
                        '&::-webkit-scrollbar-thumb': {
                          backgroundColor: theme.palette.primary.main,
                          borderRadius: 2,
                          // outline: '1px solid slategrey',
                        },
                        position: 'relative',
                        padding: 2,
                      }}
                    >
                      <Typography
                        variant="body1"
                        sx={{
                          whiteSpace: 'pre-wrap',
                          flex: 1,
                          // borderRight: '1px solid #d3d3d3',
                        }}
                      >
                        {report?.analysis?.methodAnalysis?.[currentMethod]
                          ?.analysisHtml
                          ? parse(
                              report?.analysis?.methodAnalysis?.[currentMethod]
                                ?.analysisHtml || '',
                            )
                          : report?.analysis?.methodAnalysis?.[currentMethod]
                              ?.analysis || ''}
                      </Typography>
                      {report?.playbook &&
                      currentMethod === 'playbook' &&
                      showPlaybook ? (
                        <Box
                          ref={playbookRef}
                          flex={1}
                          padding={2}
                          sx={{
                            borderLeft: '1px solid #d3d3d3',
                            minHeight: 'calc(100vh - 146px)',
                            maxHeight: 'calc(100vh - 146px)',
                            overflowY: 'scroll',
                            '&::-webkit-scrollbar': {
                              width: '5px',
                            },
                            '&::-webkit-scrollbar-track': {
                              boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                              webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                            },
                            '&::-webkit-scrollbar-thumb': {
                              backgroundColor: theme.palette.primary.main,
                              borderRadius: 2,
                              // outline: '1px solid slategrey',
                            },
                            position: 'relative',
                          }}
                        >
                          <Typography variant="body2" color={'text.secondary'}>
                            Playbook
                          </Typography>
                          <Typography
                            variant="body1"
                            sx={{
                              whiteSpace: 'pre-wrap',
                              flex: 1,
                            }}
                          >
                            {parse(report?.playbook || '')}
                          </Typography>
                        </Box>
                      ) : null}
                    </Box>
                  </Box>
                ) : currentMethod === 'playbook' ? (
                  <Box position={'relative'}>
                    <Box
                      ref={scrollDiv1Ref}
                      sx={{
                        display: 'flex',
                        // gap: 2,
                        borderTop: '1px solid #d3d3d3',
                      }}
                    >
                      <Box
                        flex={1}
                        padding={2}
                        sx={{
                          minHeight: 'calc(100vh - 110px)',
                          maxHeight: 'calc(100vh - 110px)',
                          overflowY: 'scroll',
                          '&::-webkit-scrollbar': {
                            width: '5px',
                          },
                          '&::-webkit-scrollbar-track': {
                            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                          },
                          '&::-webkit-scrollbar-thumb': {
                            backgroundColor: theme.palette.primary.main,
                            borderRadius: 2,
                            // outline: '1px solid slategrey',
                          },
                          position: 'relative',
                        }}
                      >
                        <Box display={'flex'} flexDirection={'column'} gap={1}>
                          {Object.keys(
                            JSON.parse(
                              report?.analysis?.methodAnalysis?.playbook
                                ?.analysisJson || '{}',
                            ) || {},
                          )?.map((key) => (
                            <Box
                              key={key}
                              display={'flex'}
                              flexDirection={'column'}
                              // gap={1}
                            >
                              <Typography variant="body1" fontWeight={'bold'}>
                                {key
                                  ?.split('_')
                                  ?.map((word, index) =>
                                    index === 0
                                      ? word.charAt(0).toUpperCase() +
                                        word.slice(1)
                                      : word,
                                  )
                                  .join(' ')}
                              </Typography>
                              <List>
                                {JSON.parse(
                                  report?.analysis?.methodAnalysis?.playbook
                                    ?.analysisJson || '{}',
                                )[key] &&
                                JSON.parse(
                                  report?.analysis?.methodAnalysis?.playbook
                                    ?.analysisJson || '{}',
                                )[key]?.length > 0 ? (
                                  JSON.parse(
                                    report?.analysis?.methodAnalysis?.playbook
                                      ?.analysisJson || '{}',
                                  )[key]?.map((feedback, i) => (
                                    <ListItem
                                      key={
                                        feedback?.feedback ||
                                        feedback?.improvement ||
                                        ''
                                      }
                                    >
                                      <ListItemText
                                        primary={`[${i +
                                          1}]. ${feedback?.feedback ||
                                          feedback?.improvement ||
                                          ''}`}
                                        secondary={
                                          feedback?.playbook_section_id &&
                                          feedback?.playbook_section_id !==
                                            'N/A' &&
                                          report?.playbook ? (
                                            <Button
                                              variant="text"
                                              color="primary"
                                              size="small"
                                              onClick={() => {
                                                if (
                                                  (orgConfigs?.showPlaybookToManagersOnly &&
                                                    (userInfo?.orgRoles?.includes(
                                                      'ORG_ADMIN',
                                                    ) ||
                                                      userInfo?.orgRoles?.includes(
                                                        'WL_ORG_ADMIN',
                                                      ))) ||
                                                  !orgConfigs?.showPlaybookToManagersOnly
                                                ) {
                                                  if (
                                                    report?.playbook &&
                                                    currentMethod ===
                                                      'playbook' &&
                                                    showPlaybook
                                                  ) {
                                                    const sectionId = document.getElementById(
                                                      feedback?.playbook_section_id,
                                                    );
                                                    if (playbookRef?.current) {
                                                      playbookRef.current.scrollTo(
                                                        {
                                                          top:
                                                            sectionId?.offsetTop ||
                                                            0,
                                                          behavior: 'smooth',
                                                        },
                                                      );
                                                    }
                                                  } else {
                                                    setShowPlaybook(
                                                      feedback?.playbook_section_id,
                                                    );
                                                  }
                                                }
                                              }}
                                            >
                                              {feedback?.playbook_section_id &&
                                              feedback?.playbook_section_id !==
                                                'N/A'
                                                ? `Playbook Section: ${feedback?.playbook_section_id
                                                    ?.replace(
                                                      /([a-z])([A-Z])/g,
                                                      '$1 $2',
                                                    )
                                                    ?.split(' ')
                                                    ?.map((word) =>
                                                      capitalizeText(word),
                                                    )
                                                    ?.join(' ')}`
                                                : ''}
                                            </Button>
                                          ) : null
                                        }
                                      />
                                    </ListItem>
                                  ))
                                ) : (
                                  <ListItem>
                                    <ListItemText primary="No Feedback" />
                                  </ListItem>
                                )}
                              </List>
                            </Box>
                          ))}
                        </Box>
                      </Box>
                      {report?.playbook &&
                      currentMethod === 'playbook' &&
                      showPlaybook ? (
                        <Box
                          ref={playbookRef}
                          flex={1}
                          padding={2}
                          sx={{
                            borderLeft: '1px solid #d3d3d3',
                            minHeight: 'calc(100vh - 110px)',
                            maxHeight: 'calc(100vh - 110px)',
                            overflowY: 'scroll',
                            '&::-webkit-scrollbar': {
                              width: '5px',
                            },
                            '&::-webkit-scrollbar-track': {
                              boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                              webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                            },
                            '&::-webkit-scrollbar-thumb': {
                              backgroundColor: theme.palette.primary.main,
                              borderRadius: 2,
                              // outline: '1px solid slategrey',
                            },
                            position: 'relative',
                          }}
                        >
                          <Typography variant="body2" color={'text.secondary'}>
                            Playbook
                          </Typography>
                          <Typography
                            variant="body1"
                            sx={{
                              whiteSpace: 'pre-wrap',
                              flex: 1,
                            }}
                          >
                            {parse(report?.playbook || '')}
                          </Typography>
                        </Box>
                      ) : null}
                    </Box>
                  </Box>
                ) : currentMethod === 'numericDiscussion' ? (
                  <Box position={'relative'}>
                    <Box
                      ref={scrollDiv1Ref}
                      sx={{
                        display: 'flex',
                        borderTop: '1px solid #d3d3d3',
                      }}
                    >
                      <Box
                        flex={1}
                        padding={2}
                        sx={{
                          minHeight: 'calc(100vh - 110px)',
                          maxHeight: 'calc(100vh - 110px)',
                          overflowY: 'scroll',
                          '&::-webkit-scrollbar': {
                            width: '5px',
                          },
                          '&::-webkit-scrollbar-track': {
                            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                          },
                          '&::-webkit-scrollbar-thumb': {
                            backgroundColor: theme.palette.primary.main,
                            borderRadius: 2,
                            // outline: '1px solid slategrey',
                          },
                          position: 'relative',
                        }}
                      >
                        <Box display={'flex'} flexDirection={'column'} gap={1}>
                          {Object.keys(
                            JSON.parse(
                              report?.analysis?.methodAnalysis
                                ?.numericDiscussion?.analysisJson || '{}',
                            ) || {},
                          )?.map((key) => (
                            <Box
                              key={key}
                              display={'flex'}
                              flexDirection={'column'}
                            >
                              <Typography variant="body1" fontWeight={'bold'}>
                                {key
                                  ?.split('_')
                                  ?.map((word, index) =>
                                    index === 0
                                      ? word.charAt(0).toUpperCase() +
                                        word.slice(1)
                                      : word,
                                  )
                                  .join(' ')}
                              </Typography>
                              <List>
                                {console.log(
                                  JSON.parse(
                                    report?.analysis?.methodAnalysis
                                      ?.numericDiscussion?.analysisJson || '{}',
                                  ),
                                )}
                                {JSON.parse(
                                  report?.analysis?.methodAnalysis
                                    ?.numericDiscussion?.analysisJson || '{}',
                                )[key] &&
                                JSON.parse(
                                  report?.analysis?.methodAnalysis
                                    ?.numericDiscussion?.analysisJson || '{}',
                                )[key]?.length > 0 ? (
                                  JSON.parse(
                                    report?.analysis?.methodAnalysis
                                      ?.numericDiscussion?.analysisJson || '{}',
                                  )[key]?.map((information, i) => (
                                    <ListItem
                                      key={
                                        information?.key_point ||
                                        information?.context ||
                                        ''
                                      }
                                    >
                                      <ListItemText
                                        primary={`[${i +
                                          1}]. ${information?.key_point ||
                                          ''} : ${information?.context || ''}`}
                                      />
                                    </ListItem>
                                  ))
                                ) : (
                                  <ListItem>
                                    <ListItemText primary="No Feedback" />
                                  </ListItem>
                                )}
                              </List>
                            </Box>
                          ))}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                ) : report?.analysis?.methodAnalysis?.[currentMethod] ? (
                  <Box
                    ref={scrollDiv1Ref}
                    sx={{
                      display: 'flex',
                      gap: 2,
                      borderTop: '1px solid #d3d3d3',
                      minHeight: 'calc(100vh - 110px)',
                      maxHeight: 'calc(100vh - 110px)',
                      overflowY: 'scroll',
                      '&::-webkit-scrollbar': {
                        width: '5px',
                      },
                      '&::-webkit-scrollbar-track': {
                        boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                        webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                      },
                      '&::-webkit-scrollbar-thumb': {
                        backgroundColor: theme.palette.primary.main,
                        borderRadius: 2,
                        // outline: '1px solid slategrey',
                      },
                      position: 'relative',
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        whiteSpace: 'pre-wrap',
                        padding: 2,
                        flex: 1,
                        borderRight: '1px solid #d3d3d3',
                      }}
                    >
                      {
                        report?.analysis?.methodAnalysis?.[currentMethod]
                          ?.analysis
                      }
                    </Typography>
                  </Box>
                ) : currentMethod === 'feedback' ? (
                  <Box
                    ref={scrollDiv1Ref}
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: 2,
                      borderTop: '1px solid #d3d3d3',
                      minHeight: 'calc(100vh - 110px)',
                      maxHeight: 'calc(100vh - 110px)',
                      overflowY: 'scroll',
                      '&::-webkit-scrollbar': {
                        width: '5px',
                      },
                      '&::-webkit-scrollbar-track': {
                        boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                        webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                      },
                      '&::-webkit-scrollbar-thumb': {
                        backgroundColor: theme.palette.primary.main,
                        borderRadius: 2,
                        // outline: '1px solid slategrey',
                      },
                      padding: 1,
                    }}
                  >
                    {feedbacksOnEntity?.fetching ? (
                      <Box
                        width={1}
                        height={1}
                        display={'flex'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        flexDirection={'column'}
                      >
                        <CircularProgress size={20} />
                        <Typography
                          variant="body1"
                          color={'text.primary'}
                          mt={4}
                        >
                          Loading all notes ...
                        </Typography>
                      </Box>
                    ) : feedbacksOnEntity?.feedbacks &&
                      feedbacksOnEntity?.feedbacks?.length > 0 ? (
                      <Box
                        flex={3}
                        display={'flex'}
                        flexDirection={'column'}
                        gap={1}
                      >
                        <Box
                          sx={{
                            // minHeight: 150,
                            // maxHeight: 150,
                            overflowY: 'scroll',
                            '&::-webkit-scrollbar': {
                              width: '5px',
                            },
                            '&::-webkit-scrollbar-track': {
                              boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                              webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                            },
                            '&::-webkit-scrollbar-thumb': {
                              backgroundColor: theme.palette.primary.main,
                              borderRadius: 2,
                            },
                            padding: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 1,
                          }}
                          onScroll={handleFeedbackListScroll}
                        >
                          {feedbacksOnEntity?.feedbacks.map((feedback, i) => (
                            <Box
                              key={feedback?.id}
                              display={'flex'}
                              flexDirection={'column'}
                              gap={1}
                            >
                              <Box
                                display={'flex'}
                                justifyContent={'space-between'}
                              >
                                <Box display={'flex'} flexDirection={'column'}>
                                  <Typography
                                    variant="subtitle1"
                                    fontWeight={'bold'}
                                    color={'text.secondary'}
                                  >
                                    {capitalizeText(
                                      feedback?.customFields?.user?.fullName,
                                    )}
                                  </Typography>
                                  <Typography variant="caption">
                                    {feedback?.customFields?.user?.email}
                                  </Typography>
                                </Box>
                                <Typography
                                  variant="caption"
                                  fontWeight={'bold'}
                                  color={'text.secondary'}
                                >
                                  {moment(feedback?.createdOn).format(
                                    'DD MMMM YYYY',
                                  )}
                                </Typography>
                              </Box>
                              <Typography
                                variant="body1"
                                sx={{
                                  whiteSpace: 'pre-wrap',
                                  // padding: 2,
                                  flex: 1,
                                  // borderRight: '1px solid #d3d3d3',
                                }}
                              >
                                {feedback?.feedback}
                              </Typography>
                            </Box>
                          ))}
                          {feedbacksOnEntity?.fetchingPagination ? (
                            <Box
                              sx={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                padding: 1,
                                textAlign: 'center',
                              }}
                            >
                              <Button
                                size="small"
                                variant="contained"
                                // type="submit"
                                color="primary"
                                onClick={handleFeedbackListScroll}
                                // className="searchSettings"
                                disabled
                                // fullWidth
                              >
                                <Typography color="textPrimary">
                                  Loading more notes ...{' '}
                                </Typography>
                                <CircularProgress size={20} />
                              </Button>
                            </Box>
                          ) : feedbacksOnEntity?.emptyList === false &&
                            feedbacksOnEntity?.feedbacks.length >= 10 ? (
                            <Box
                              sx={{
                                // width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                padding: 1,
                                textAlign: 'center',
                              }}
                            >
                              <Button
                                size="small"
                                variant="contained"
                                // type="submit"
                                // fullWidth
                                color="primary"
                                onClick={(e) =>
                                  handleFeedbackListScroll(e, true)
                                }
                                // className="searchSettings"
                              >
                                Load More
                              </Button>
                            </Box>
                          ) : null}
                        </Box>
                      </Box>
                    ) : (
                      <Box
                        flex={3}
                        display={'flex'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        flexDirection={'column'}
                        gap={2}
                      >
                        <Typography
                          variant="body1"
                          color={'text.secondary'}
                          mt={4}
                        >
                          No notes added yet.
                        </Typography>
                      </Box>
                    )}
                    {/* {uId ? ( */}
                    <Box
                      display={'flex'}
                      flexDirection={'column'}
                      gap={1}
                      flex={2}
                      borderLeft={'1px solid #d3d3d3'}
                      p={1}
                    >
                      <TextField
                        autoFocus
                        placeholder="Enter a note"
                        variant="outlined"
                        color="primary"
                        size="small"
                        name="feedbackOnEntity"
                        multiline
                        rows={4}
                        value={feedbackOnEntity}
                        onChange={(e) => setFeedbackOnEntity(e.target.value)}
                        fullWidth
                      />
                      <Button
                        variant="contained"
                        size="small"
                        color="primary"
                        onClick={() => {
                          setPostingFeedback(true);
                          dispatch(
                            postFeedbackOnEntity(
                              report?.id,
                              {
                                entityId: report?.id,
                                feedback: feedbackOnEntity,
                                type:
                                  type === 'salesCall'
                                    ? 'sales_call_analysis'
                                    : type === 'practiceCall'
                                    ? 'conversation_standard'
                                    : 'conversation_otpc',
                              },
                              () => {
                                setFeedbackOnEntity('');
                                dispatch(
                                  showAlert({
                                    message: 'Note is added successfully.',
                                    showCross: true,
                                    title: null,
                                    type: 'success',
                                    autoHideDuration: 5000,
                                    vertical: 'top',
                                    horizontal: 'center',
                                  }),
                                );
                                dispatch(
                                  getFeedbacksOnEntity(
                                    report?.id,
                                    10,
                                    null,
                                    () => {},
                                  ),
                                );
                                setPostingFeedback(false);
                              },
                              () => {
                                dispatch(
                                  showAlert({
                                    message: 'Failed to post note.',
                                    showCross: true,
                                    title: null,
                                    type: 'error',
                                    autoHideDuration: 5000,
                                    vertical: 'top',
                                    horizontal: 'center',
                                  }),
                                );
                                setPostingFeedback(false);
                              },
                            ),
                          );
                        }}
                      >
                        Add Note
                      </Button>
                    </Box>
                    {/* ) : null} */}
                  </Box>
                ) : currentMethod === 'askAI' ? (
                  <Box
                    ref={scrollDiv1Ref}
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: 2,
                      borderTop: '1px solid #d3d3d3',
                      minHeight: 'calc(100vh - 110px)',
                      maxHeight: 'calc(100vh - 110px)',
                      overflowY: 'scroll',
                      '&::-webkit-scrollbar': {
                        width: '5px',
                      },
                      '&::-webkit-scrollbar-track': {
                        boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                        webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                      },
                      '&::-webkit-scrollbar-thumb': {
                        backgroundColor: theme.palette.primary.main,
                        borderRadius: 2,
                        // outline: '1px solid slategrey',
                      },
                      // padding: 1,
                      position: 'relative',
                    }}
                  >
                    {conversationDetailsById?.fetching ? (
                      <Box
                        width={1}
                        height={1}
                        display={'flex'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        flexDirection={'column'}
                      >
                        <CircularProgress size={20} />
                        <Typography
                          variant="body1"
                          color={'text.primary'}
                          mt={4}
                        >
                          Loading all chats ...
                        </Typography>
                      </Box>
                    ) : conversationDetails ? (
                      conversationDetails?.presetMessageMap ||
                      (conversationDetails?.messages &&
                        conversationDetails?.messages?.length > 0) ? (
                        <Box
                          flex={1}
                          display={'flex'}
                          flexDirection={'column'}
                          gap={1}
                        >
                          <Box
                            ref={chatsScrollview}
                            sx={{
                              minHeight: 'calc(100vh - 170px)',
                              maxHeight: 'calc(100vh - 170px)',
                              overflowY: 'scroll',
                              '&::-webkit-scrollbar': {
                                width: '5px',
                              },
                              '&::-webkit-scrollbar-track': {
                                boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                                webkitBoxShadow:
                                  'inset 0 0 6px rgba(0,0,0,0.00)',
                              },
                              '&::-webkit-scrollbar-thumb': {
                                backgroundColor: theme.palette.primary.main,
                                borderRadius: 2,
                              },
                              padding: 2,
                              display: 'flex',
                              flexDirection: 'column',
                              gap: 1,
                            }}
                          >
                            {Object.values(
                              conversationDetails?.presetMessageMap || {},
                            )?.map(({ question, answer }, index) => (
                              <>
                                {question ? (
                                  <Box
                                    key={question?.id}
                                    sx={{
                                      borderTopLeftRadius:
                                        question?.gptMessage?.role === 'user'
                                          ? 16
                                          : 0,
                                      borderTopRightRadius:
                                        question?.gptMessage?.role === 'user'
                                          ? 0
                                          : 16,
                                      borderBottomLeftRadius:
                                        question?.gptMessage?.role === 'user'
                                          ? 8
                                          : 16,
                                      borderBottomRightRadius:
                                        question?.gptMessage?.role === 'user'
                                          ? 16
                                          : 8,
                                      maxWidth: '80%',
                                      alignSelf:
                                        question?.gptMessage?.role === 'user'
                                          ? 'end'
                                          : 'start',
                                      display: 'flex',
                                      flexDirection: 'column',
                                      // gap: 1,
                                      boxShadow: theme.shadows[8],
                                      background:
                                        question?.gptMessage?.role === 'user'
                                          ? theme.palette.primary.main
                                          : '#fff',
                                    }}
                                  >
                                    <Box
                                      display={'flex'}
                                      alignItems={'center'}
                                      justifyContent={'space-between'}
                                      sx={{
                                        padding: '8px 16px',
                                        gap: 4,
                                      }}
                                    >
                                      {question?.gptMessage?.role === 'user' ? (
                                        <Typography
                                          variant="caption"
                                          sx={{
                                            color:
                                              question?.gptMessage?.role ===
                                              'user'
                                                ? '#f1f1f1'
                                                : theme.palette.text.secondary,
                                          }}
                                        >
                                          You
                                        </Typography>
                                      ) : (
                                        <Typography
                                          variant="caption"
                                          sx={{
                                            color:
                                              question?.gptMessage?.role ===
                                              'user'
                                                ? '#f1f1f1'
                                                : theme.palette.text.secondary,
                                          }}
                                        >
                                          {orgConfigs?.navBarTitles?.[
                                            'Account Settings'
                                          ]?.subTitles?.['Ask Donna']?.title
                                            ?.toLowerCase()
                                            ?.split('ask')
                                            ?.join('')
                                            ?.trim()
                                            ? capitalizeText(
                                                orgConfigs?.navBarTitles?.[
                                                  'Account Settings'
                                                ]?.subTitles?.[
                                                  'Ask Donna'
                                                ]?.title
                                                  ?.toLowerCase()
                                                  ?.split('ask')
                                                  ?.join('')
                                                  ?.trim(),
                                              )
                                            : 'Ask Donna'}
                                        </Typography>
                                      )}
                                      <Typography
                                        variant="caption"
                                        sx={{
                                          color:
                                            question?.gptMessage?.role ===
                                            'user'
                                              ? '#f1f1f1'
                                              : theme.palette.text.secondary,
                                        }}
                                      >
                                        {moment(question?.createdOn).format(
                                          'DD MMMM YYYY hh:mm:ss A',
                                        )}
                                      </Typography>
                                    </Box>
                                    <Typography
                                      sx={{
                                        whiteSpace: 'pre-wrap',
                                        color:
                                          question?.gptMessage?.role === 'user'
                                            ? '#fff'
                                            : theme.palette.text.primary,
                                        padding: '0px 16px 16px 16px',
                                      }}
                                    >
                                      {question?.gptMessage?.content}
                                    </Typography>
                                  </Box>
                                ) : null}
                                {answer ? (
                                  <Box
                                    key={answer?.id}
                                    sx={{
                                      borderTopLeftRadius:
                                        answer?.gptMessage?.role === 'user'
                                          ? 16
                                          : 0,
                                      borderTopRightRadius:
                                        answer?.gptMessage?.role === 'user'
                                          ? 0
                                          : 16,
                                      borderBottomLeftRadius:
                                        answer?.gptMessage?.role === 'user'
                                          ? 8
                                          : 16,
                                      borderBottomRightRadius:
                                        answer?.gptMessage?.role === 'user'
                                          ? 16
                                          : 8,
                                      maxWidth: '80%',
                                      alignSelf:
                                        answer?.gptMessage?.role === 'user'
                                          ? 'end'
                                          : 'start',
                                      display: 'flex',
                                      flexDirection: 'column',
                                      // gap: 1,
                                      boxShadow: theme.shadows[8],
                                      background:
                                        answer?.gptMessage?.role === 'user'
                                          ? theme.palette.primary.main
                                          : '#fff',
                                    }}
                                  >
                                    <Box
                                      display={'flex'}
                                      alignItems={'center'}
                                      justifyContent={'space-between'}
                                      sx={{
                                        padding: '8px 16px',
                                        gap: 4,
                                      }}
                                    >
                                      {answer?.gptMessage?.role === 'user' ? (
                                        <Typography
                                          variant="caption"
                                          sx={{
                                            color:
                                              answer?.gptMessage?.role ===
                                              'user'
                                                ? '#f1f1f1'
                                                : theme.palette.text.secondary,
                                          }}
                                        >
                                          You
                                        </Typography>
                                      ) : (
                                        <Typography
                                          variant="caption"
                                          sx={{
                                            color:
                                              answer?.gptMessage?.role ===
                                              'user'
                                                ? '#f1f1f1'
                                                : theme.palette.text.secondary,
                                          }}
                                        >
                                          {orgConfigs?.navBarTitles?.[
                                            'Account Settings'
                                          ]?.subTitles?.['Ask Donna']?.title
                                            ?.toLowerCase()
                                            ?.split('ask')
                                            ?.join('')
                                            ?.trim()
                                            ? capitalizeText(
                                                orgConfigs?.navBarTitles?.[
                                                  'Account Settings'
                                                ]?.subTitles?.[
                                                  'Ask Donna'
                                                ]?.title
                                                  ?.toLowerCase()
                                                  ?.split('ask')
                                                  ?.join('')
                                                  ?.trim(),
                                              )
                                            : 'Ask Donna'}
                                        </Typography>
                                      )}
                                      <Typography
                                        variant="caption"
                                        sx={{
                                          color:
                                            answer?.gptMessage?.role === 'user'
                                              ? '#f1f1f1'
                                              : theme.palette.text.secondary,
                                        }}
                                      >
                                        {moment(answer?.createdOn).format(
                                          'DD MMMM YYYY hh:mm:ss A',
                                        )}
                                      </Typography>
                                    </Box>
                                    <Typography
                                      sx={{
                                        whiteSpace: 'pre-wrap',
                                        color:
                                          answer?.gptMessage?.role === 'user'
                                            ? '#fff'
                                            : theme.palette.text.primary,
                                        padding: '0px 16px 16px 16px',
                                      }}
                                    >
                                      {answer?.gptMessage?.content}
                                    </Typography>
                                  </Box>
                                ) : (
                                  <Box
                                    width={1}
                                    height={1}
                                    display={'flex'}
                                    justifyContent={'center'}
                                    alignItems={'center'}
                                    flexDirection={'column'}
                                    p={2}
                                    sx={{
                                      borderTopLeftRadius:
                                        answer?.gptMessage?.role === 'user'
                                          ? 16
                                          : 0,
                                      borderTopRightRadius:
                                        answer?.gptMessage?.role === 'user'
                                          ? 0
                                          : 16,
                                      borderBottomLeftRadius:
                                        answer?.gptMessage?.role === 'user'
                                          ? 8
                                          : 16,
                                      borderBottomRightRadius:
                                        answer?.gptMessage?.role === 'user'
                                          ? 16
                                          : 8,
                                      maxWidth: '80%',
                                      alignSelf:
                                        answer?.gptMessage?.role === 'user'
                                          ? 'end'
                                          : 'start',
                                      display: 'flex',
                                      flexDirection: 'column',
                                      // gap: 1,
                                      boxShadow: theme.shadows[8],
                                      background:
                                        answer?.gptMessage?.role === 'user'
                                          ? theme.palette.primary.main
                                          : '#fff',
                                    }}
                                  >
                                    <CircularProgress size={20} />
                                    <Typography
                                      variant="body1"
                                      color={'text.primary'}
                                      mt={4}
                                    >
                                      This will take a few seconds. Please check
                                      back after a short while.
                                    </Typography>
                                  </Box>
                                )}
                              </>
                            ))}
                            {conversationDetails?.messages?.map(
                              (msg, index) => (
                                <>
                                  {msg ? (
                                    <Box
                                      key={msg?.id}
                                      sx={{
                                        borderTopLeftRadius:
                                          msg?.gptMessage?.role === 'user'
                                            ? 16
                                            : 0,
                                        borderTopRightRadius:
                                          msg?.gptMessage?.role === 'user'
                                            ? 0
                                            : 16,
                                        borderBottomLeftRadius:
                                          msg?.gptMessage?.role === 'user'
                                            ? 8
                                            : 16,
                                        borderBottomRightRadius:
                                          msg?.gptMessage?.role === 'user'
                                            ? 16
                                            : 8,
                                        maxWidth: '80%',
                                        alignSelf:
                                          msg?.gptMessage?.role === 'user'
                                            ? 'end'
                                            : 'start',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        // gap: 1,
                                        boxShadow: theme.shadows[8],
                                        background:
                                          msg?.gptMessage?.role === 'user'
                                            ? theme.palette.primary.main
                                            : '#fff',
                                      }}
                                    >
                                      <Box
                                        display={'flex'}
                                        alignItems={'center'}
                                        justifyContent={'space-between'}
                                        sx={{
                                          padding: '8px 16px',
                                          gap: 4,
                                        }}
                                      >
                                        {msg?.gptMessage?.role === 'user' ? (
                                          <Typography
                                            variant="caption"
                                            sx={{
                                              color:
                                                msg?.gptMessage?.role === 'user'
                                                  ? '#f1f1f1'
                                                  : theme.palette.text
                                                      .secondary,
                                            }}
                                          >
                                            {`${msg?.customFields?.userDetails?.firstName} ${msg?.customFields?.userDetails?.lastName}` !==
                                            userInfo?.fullName
                                              ? `${msg?.customFields?.userDetails?.firstName} ${msg?.customFields?.userDetails?.lastName}`
                                              : 'You'}
                                          </Typography>
                                        ) : (
                                          <Typography
                                            variant="caption"
                                            sx={{
                                              color:
                                                msg?.gptMessage?.role === 'user'
                                                  ? '#f1f1f1'
                                                  : theme.palette.text
                                                      .secondary,
                                            }}
                                          >
                                            {orgConfigs?.navBarTitles?.[
                                              'Account Settings'
                                            ]?.subTitles?.['Ask Donna']?.title
                                              ?.toLowerCase()
                                              ?.split('ask')
                                              ?.join('')
                                              ?.trim()
                                              ? capitalizeText(
                                                  orgConfigs?.navBarTitles?.[
                                                    'Account Settings'
                                                  ]?.subTitles?.[
                                                    'Ask Donna'
                                                  ]?.title
                                                    ?.toLowerCase()
                                                    ?.split('ask')
                                                    ?.join('')
                                                    ?.trim(),
                                                )
                                              : 'Ask Donna'}
                                          </Typography>
                                        )}
                                        <Typography
                                          variant="caption"
                                          sx={{
                                            color:
                                              msg?.gptMessage?.role === 'user'
                                                ? '#f1f1f1'
                                                : theme.palette.text.secondary,
                                          }}
                                        >
                                          {moment(msg?.createdOn).format(
                                            'DD MMMM YYYY hh:mm:ss A',
                                          )}
                                        </Typography>
                                      </Box>
                                      <Typography
                                        sx={{
                                          whiteSpace: 'pre-wrap',
                                          color:
                                            msg?.gptMessage?.role === 'user'
                                              ? '#fff'
                                              : theme.palette.text.primary,
                                          padding: '0px 16px 16px 16px',
                                        }}
                                      >
                                        {msg?.gptMessage?.content}
                                      </Typography>
                                    </Box>
                                  ) : null}
                                </>
                              ),
                            )}
                          </Box>
                        </Box>
                      ) : (
                        <Box
                          width={1}
                          height={1}
                          display={'flex'}
                          justifyContent={'center'}
                          alignItems={'center'}
                          p={2}
                          flexDirection={'column'}
                        >
                          <CircularProgress size={20} />
                          <Typography
                            variant="body1"
                            color={'text.primary'}
                            mt={4}
                          >
                            This will take a few seconds. Please check back
                            after a short while.
                          </Typography>
                        </Box>
                      )
                    ) : (
                      <Box
                        flex={3}
                        display={'flex'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        flexDirection={'column'}
                        gap={2}
                      >
                        <Typography
                          variant="body1"
                          color={'text.secondary'}
                          mt={4}
                        >
                          No conversation done yet.
                        </Typography>
                      </Box>
                    )}
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        if (
                          userDetails?.plan?.trailEndsOn >
                            new Date().getTime() ||
                          userDetails?.plan?.currentPaymentStatus === 'paid'
                        ) {
                          setGeneratingResponse(true);
                          dispatch(
                            getMessageResponse(
                              report?.conversationId,
                              askAIQuestion,
                              report?.speaker,
                              null,
                              null,
                              'v2',
                              wordsLimit,
                              async (data) => {
                                // console.log(data);
                                setGeneratingResponse(false);
                                setAskAIQuestion('');
                                dispatch({
                                  type: 'UPDATE_CONVERSATION_MESSAGES',
                                  payload: data,
                                });
                                if (chatsScrollview?.current) {
                                  chatsScrollview?.current?.scroll({
                                    top: chatsScrollview?.current?.scrollHeight,
                                    behaviour: 'smooth',
                                  });
                                }
                              },
                              (errorCode) => {
                                setGeneratingResponse(false);
                                if (errorCode === 'quota exceeded') {
                                  // setShowLimitExhausted(errorCode);
                                }
                                // console.log('coming here');
                                // setGettingResponse(false);
                                // setMessage('');
                                // resetTranscript();
                              },
                            ),
                          );
                        } else {
                          dispatch({
                            type: 'UPDATE_UI_STATE',
                            payload: {
                              key: 'showPlanExpired',
                              value: true,
                            },
                          });
                        }
                      }}
                    >
                      <Box
                        display={'flex'}
                        flexDirection={'row'}
                        alignItems={'center'}
                        gap={1}
                        borderTop={'1px solid #d3d3d3'}
                        p={1}
                        sx={{
                          position: 'absolute',
                          bottom: 0,
                          left: 0,
                          zIndex: 11,
                          width: 1,
                          background: '#fff',
                        }}
                      >
                        <TextField
                          autoFocus
                          placeholder="Ask any question"
                          variant="outlined"
                          color="primary"
                          size="small"
                          name="askAIQuestion"
                          value={askAIQuestion}
                          onChange={(e) => setAskAIQuestion(e.target.value)}
                          sx={{ flex: 4 }}
                        />
                        <FormControl size="small" sx={{ minWidth: 150 }}>
                          <InputLabel id="wordsLimit">Words Limit</InputLabel>
                          <Select
                            variant="outlined"
                            size="small"
                            labelId={'wordsLimit'}
                            label={'Words Limit'}
                            id={'wordsLimit'}
                            name={'wordsLimit'}
                            MenuProps={{ disableScrollLock: true }}
                            onChange={(e) => {
                              setWordsLimit(e.target.value);
                            }}
                            value={wordsLimit}
                          >
                            <MenuItem value={50}>
                              <Typography variant="subtitle2">50</Typography>
                            </MenuItem>
                            <MenuItem value={100}>
                              <Typography variant="subtitle2">100</Typography>
                            </MenuItem>
                            <MenuItem value={150}>
                              <Typography variant="subtitle2">150</Typography>
                            </MenuItem>
                            <MenuItem value={200}>
                              <Typography variant="subtitle2">200</Typography>
                            </MenuItem>
                            {process.env.REACT_APP_TARGET_ENV === 'local' ||
                            process.env.REACT_APP_TARGET_ENV ===
                              'development' ? (
                              <MenuItem value={500}>
                                <Typography variant="subtitle2">500</Typography>
                              </MenuItem>
                            ) : null}
                          </Select>
                        </FormControl>
                        <Button
                          sx={{ flex: 1 }}
                          variant="contained"
                          size="small"
                          color="primary"
                          disabled={
                            generatingResponse ||
                            !askAIQuestion.trim() ||
                            conversationDetails?.presetQuestionStatus !==
                              'completed'
                          }
                          type="submit"
                          startIcon={
                            generatingResponse ? (
                              <CircularProgress size={20} />
                            ) : (
                              <AutoAwesome />
                            )
                          }
                        >
                          {orgConfigs?.navBarTitles?.['Account Settings']
                            ?.subTitles?.['Ask Donna']?.title || 'Ask Donna'}
                        </Button>
                      </Box>
                    </form>
                  </Box>
                ) : (
                  <Box
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    sx={{
                      minHeight: 'calc(100vh - 600px)',
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        whiteSpace: 'pre-wrap',
                        padding: 2,
                      }}
                      color={'text.secondary'}
                    >
                      Not available
                    </Typography>
                  </Box>
                )}
              </>
            ) : null}
          </Box>
        </Box>
      </Drawer>
      <Drawer
        open={Boolean(maximizedTranscriptView)}
        anchor="right"
        onClose={() => setMaximizedTranscriptView(false)}
        sx={{
          '& .MuiPaper-root': {
            width: 1,
          },
        }}
      >
        <Box
          p={1}
          sx={{
            width: 1,
            borderBottom: '1px solid #d3d3d3',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 2,
            }}
          >
            <IconButton onClick={() => setMaximizedTranscriptView(false)}>
              <ChevronRight sx={{}} />
            </IconButton>
            <Typography>{report?.title}</Typography>
          </Box>
          <Box display={'flex'} alignItems={'center'} gap={2}>
            <Button
              startIcon={<Close />}
              onClick={() => setMaximizedTranscriptView(false)}
              variant="outlined"
              size="small"
            >
              Close
            </Button>
          </Box>
        </Box>
        <Box
          display={'flex'}
          flex={1}
          sx={{
            border: '1px solid #d3d3d3',
            // height: 'auto',
          }}
        >
          {/* <Box display={'flex'} flexDirection={'column'} gap={2} flex={1}>
            <Box
              style={{
                flex: 1,
                borderLeft: '1px solid #d3d3d3',
                padding: 2,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              
            </Box>
          </Box> */}
          <Box
            ref={scrollDiv1Ref}
            sx={{
              // display: 'flex',
              flex: 1,
              width: 1,
              // borderLeft: '1px solid #d3d3d3',
            }}
          >
            <Box
              display={'flex'}
              justifyContent={'space-between'}
              sx={{
                borderBottom: '1px solid #d3d3d3',
              }}
            >
              <Tabs
                value={currentParam}
                onChange={(e, newValue) => {
                  handleTrackEvent(e, `change_factors_tab_to_${newValue}`);
                  setCurrentParam(newValue);
                }}
                aria-label="Parameter Tabs"
                orientation={'horizontal'}
                // indicatorColor={theme.palette.background.default}
                variant="scrollable"
                // scrollButtons={!isMd}
                allowScrollButtonsMobile
              >
                <Tab
                  value={'conversationScore'}
                  label={
                    <Typography variant="caption" fontWeight={'bold'}>
                      Call Score
                    </Typography>
                  }
                  id={`simple-tab-${5}`}
                  aria-controls={`simple-tabpanel-${5}`}
                  sx={{}}
                />
                <Tab
                  value={'transcript'}
                  label={
                    <Typography variant="caption" fontWeight={'bold'}>
                      Transcript
                    </Typography>
                  }
                  id={`simple-tab-${0}`}
                  aria-controls={`simple-tabpanel-${0}`}
                  sx={{}}
                />
                <Tab
                  value={'fillers'}
                  label={
                    <Typography variant="caption" fontWeight={'bold'}>
                      Filler Words
                    </Typography>
                  }
                  id={`simple-tab-${0}`}
                  aria-controls={`simple-tabpanel-${0}`}
                  sx={{}}
                />
                <Tab
                  value={'repeatitions'}
                  label={
                    <Typography variant="caption" fontWeight={'bold'}>
                      Repeated Words
                    </Typography>
                  }
                  id={`simple-tab-${5}`}
                  aria-controls={`simple-tabpanel-${5}`}
                  sx={{}}
                />
              </Tabs>
            </Box>
            {currentParam === 'transcript' ? (
              <Box display={'flex'}>
                <Box
                  ref={scrollDivMaximizedRef}
                  sx={{
                    flex: 2,
                    // padding: 2,
                    // border: '1px solid #d3d3d3',
                    // borderRadius: 2,
                    mark: {
                      backgroundColor: theme.palette.primary.main,
                      color: 'white',
                      padding: '2px 6px',
                    },
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    minHeight: 'calc(100vh - 110px)',
                    maxHeight: 'calc(100vh - 110px)',
                    overflowY: 'scroll',
                    '&::-webkit-scrollbar': {
                      width: '5px',
                    },
                    '&::-webkit-scrollbar-track': {
                      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      backgroundColor: theme.palette.primary.main,
                      borderRadius: 2,
                      // outline: '1px solid slategrey',
                    },
                    borderRight: '1px solid #d3d3d3',
                  }}
                  position={'relative'}
                >
                  {searchKeywords && searchKeywords.length > 0 ? (
                    <Box
                      sx={{
                        position: 'sticky',
                        top: 0,
                        left: 0,
                        background: '#fff',
                        padding: '4px 8px',
                        borderBottom: '1px solid #d3d3d3',
                      }}
                      display={'flex'}
                      alignItems={'center'}
                      justifyContent={
                        searchKeywords?.length > 0
                          ? 'space-between'
                          : 'flex-end'
                      }
                    >
                      <Box
                        display={'flex'}
                        gap={1}
                        alignItems={'center'}
                        flexWrap={'wrap'}
                      >
                        <Box
                          display={'flex'}
                          flexDirection={'column'}
                          alignItems={'start'}
                        >
                          <Typography
                            variant="caption"
                            color={'text.secondary'}
                          >
                            Matching Keywords
                          </Typography>
                        </Box>
                        {showMatchingKeywords &&
                          searchKeywords?.map((keyword, index) =>
                            searchKeywordsMap[keyword] > 0 ? (
                              <Box
                                key={index + 1}
                                display={'flex'}
                                alignItems={'center'}
                                gap={1}
                                sx={{
                                  border: '1px solid #d3d3d3',
                                  borderRadius: 1,
                                  padding: '2px 8px',
                                }}
                              >
                                <Typography variant="caption">
                                  {keyword}
                                </Typography>
                                <Chip
                                  size="small"
                                  color="primary"
                                  label={searchKeywordsMap[keyword]}
                                />
                                <Divider
                                  orientation="vertical"
                                  variant="fullWidth"
                                  flexItem
                                />
                                <Box
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    // gap: 1,
                                  }}
                                >
                                  <Tooltip
                                    title="Previous"
                                    arrow
                                    placement="top"
                                  >
                                    <IconButton
                                      sx={{
                                        padding: 0.5,
                                      }}
                                      onClick={() => {
                                        handleHightlightMove('prev', keyword);
                                      }}
                                    >
                                      <KeyboardArrowUp fontSize="small" />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip title="Next" arrow placement="top">
                                    <IconButton
                                      sx={{
                                        padding: 0.5,
                                      }}
                                      onClick={() => {
                                        handleHightlightMove('next', keyword);
                                      }}
                                    >
                                      <KeyboardArrowDown fontSize="small" />
                                    </IconButton>
                                  </Tooltip>
                                </Box>
                              </Box>
                            ) : null,
                          )}
                      </Box>
                    </Box>
                  ) : null}
                  <Typography
                    variant="body1"
                    sx={{
                      whiteSpace: 'pre-wrap',
                      padding: 2,
                      mark: {
                        backgroundColor: '#6b8bea',
                      },
                    }}
                  >
                    {showMatchingKeywords &&
                    searchKeywords &&
                    searchKeywords.length > 0
                      ? parse(
                          report?.transcript
                            ?.replace(
                              new RegExp(
                                searchKeywords
                                  ?.map((word) =>
                                    word
                                      .split(' ')
                                      .join('')
                                      ?.split('')
                                      ?.join('\\s*'),
                                  )
                                  ?.join('|'),
                                'ig',
                              ),
                              (highlight, offset) => {
                                return `<mark id="${highlight
                                  ?.toLowerCase()
                                  ?.split(' ')
                                  ?.join('_')}${offset}">${highlight
                                  .split('{')
                                  .join('')
                                  .split('}')
                                  .join('')}</mark>`;
                              },
                            )
                            ?.replace(
                              new RegExp('(([0-5])?[0-9]):([0-5][0-9])', 'gmi'),
                              (highlight) => {
                                return `<button class="highlight">${highlight}</button>`;
                              },
                            ) || '',
                        )
                      : parse(
                          report?.transcript?.replace(
                            new RegExp('(([0-5])?[0-9]):([0-5][0-9])', 'gmi'),
                            (highlight) => {
                              return `<button class="highlight">${highlight}</button>`;
                            },
                          ) || '',
                        )}
                  </Typography>
                </Box>
                <MediaPreview
                  videoRef={videoRef}
                  styles={{
                    flex: 1,
                    padding: 2,
                    alignSelf: 'start',
                  }}
                  url={
                    type === 'practiceCall' || type === 'otpc'
                      ? report?.customFields?.audioLink || ''
                      : report?.customData?.url || ''
                  }
                  mediaType={report?.saleReviewMediaContentType || ''}
                  handleVideoSeekTo
                />
              </Box>
            ) : (
              <Box
                ref={scrollDivMaximizedRef}
                sx={{
                  padding: currentParam !== 'conversationScore' ? 2 : 0,
                  // border: '1px solid #d3d3d3',
                  borderRadius: 2,
                  mark: {
                    backgroundColor: theme.palette.primary.main,
                    color: 'white',
                    padding: '2px 6px',
                  },
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2,
                  minHeight: 'calc(100vh - 110px)',
                  maxHeight: 'calc(100vh - 110px)',
                  overflowY: 'scroll',
                  '&::-webkit-scrollbar': {
                    width: '5px',
                  },
                  '&::-webkit-scrollbar-track': {
                    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: theme.palette.primary.main,
                    borderRadius: 2,
                    // outline: '1px solid slategrey',
                  },
                }}
              >
                {currentParam === 'fillers' ? (
                  <>
                    <Box
                      sx={{
                        // border: '1px solid #d3d3d3',
                        // borderRadius: 10,
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Box
                        sx={{
                          background: showAllFillers
                            ? '#d3d3d3'
                            : theme.palette.primary.main,
                          color: showAllFillers ? '#000' : '#fff',
                          padding: '4px 8px',
                          borderTopLeftRadius: 10,
                          borderBottomLeftRadius: 10,
                          cursor: 'pointer',
                        }}
                        onClick={() => setShowAllFillers(false)}
                      >
                        <Typography variant="caption">
                          Top 10 Fillers
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          background: !showAllFillers
                            ? '#d3d3d3'
                            : theme.palette.primary.main,
                          color: !showAllFillers ? '#000' : '#fff',
                          padding: '4px 8px',
                          borderTopRightRadius: 10,
                          borderBottomRightRadius: 10,
                          cursor: 'pointer',
                        }}
                        onClick={() => setShowAllFillers(true)}
                      >
                        <Typography variant="caption">
                          Show all Fillers
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      display={'flex'}
                      gap={2}
                      sx={{
                        flexWrap: 'wrap',
                      }}
                    >
                      {report?.analysis?.fillerInfo?.fillers &&
                      Object.keys(report?.analysis?.fillerInfo?.fillers) &&
                      Array.isArray(
                        Object.keys(report?.analysis?.fillerInfo?.fillers),
                      ) &&
                      Object.keys(report?.analysis?.fillerInfo?.fillers)
                        ?.length > 0 ? (
                        Object.keys(report?.analysis?.fillerInfo?.fillers)
                          ?.sort((a, b) => {
                            if (
                              report?.analysis?.fillerInfo?.fillers?.[a] <
                              report?.analysis?.fillerInfo?.fillers?.[b]
                            ) {
                              return 1;
                            } else if (
                              report?.analysis?.fillerInfo?.fillers?.[a] >
                              report?.analysis?.fillerInfo?.fillers?.[b]
                            ) {
                              return -1;
                            }
                            return 0;
                          })
                          ?.slice(
                            0,
                            showAllFillers
                              ? Object.keys(
                                  report?.analysis?.fillerInfo?.fillers,
                                )?.length
                              : 10,
                          )
                          ?.map((filler, index) => (
                            <Typography key={index} sx={{}}>
                              {filler}{' '}
                              <Chip
                                label={
                                  report?.analysis?.fillerInfo?.fillers?.[
                                    filler
                                  ]
                                }
                                size="small"
                                color="primary"
                              ></Chip>
                            </Typography>
                          ))
                      ) : (
                        <Typography sx={{}} color={'text.secondary'}>
                          No filler words
                        </Typography>
                      )}
                    </Box>
                    <Typography
                      variant="body1"
                      sx={{
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      {report?.analysis?.fillerInfo?.transcript
                        ? parse(
                            report?.analysis?.fillerInfo?.transcript?.replace(
                              fillerWordsRegexp,
                              (highlight) => {
                                return `<mark>${highlight
                                  .split('{')
                                  .join('')
                                  .split('}')
                                  .join('')}</mark>`;
                              },
                            ) || '',
                          )
                        : ''}
                    </Typography>
                  </>
                ) : currentParam === 'repeatitions' ? (
                  <>
                    <Box
                      sx={{
                        // border: '1px solid #d3d3d3',
                        // borderRadius: 10,
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Box
                        sx={{
                          background: showAllRepetitions
                            ? '#d3d3d3'
                            : theme.palette.primary.main,
                          color: showAllRepetitions ? '#000' : '#fff',
                          padding: '4px 8px',
                          borderTopLeftRadius: 10,
                          borderBottomLeftRadius: 10,
                          cursor: 'pointer',
                        }}
                        onClick={() => setShowAllRepetitions(false)}
                      >
                        <Typography variant="caption">
                          Top 10 Repetitions
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          background: !showAllRepetitions
                            ? '#d3d3d3'
                            : theme.palette.primary.main,
                          color: !showAllRepetitions ? '#000' : '#fff',
                          padding: '4px 8px',
                          borderTopRightRadius: 10,
                          borderBottomRightRadius: 10,
                          cursor: 'pointer',
                        }}
                        onClick={() => setShowAllRepetitions(true)}
                      >
                        <Typography variant="caption">
                          Show all Repetitions
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      display={'flex'}
                      gap={2}
                      sx={{
                        flexWrap: 'wrap',
                      }}
                    >
                      {report?.analysis?.repetitionInfo?.repetitions &&
                      Object.keys(
                        report?.analysis?.repetitionInfo?.repetitions,
                      ) &&
                      Array.isArray(
                        Object.keys(
                          report?.analysis?.repetitionInfo?.repetitions,
                        ),
                      ) &&
                      Object.keys(report?.analysis?.repetitionInfo?.repetitions)
                        ?.length > 0 ? (
                        Object.keys(
                          report?.analysis?.repetitionInfo?.repetitions,
                        )
                          ?.sort((a, b) => {
                            if (
                              report?.analysis?.repetitionInfo?.repetitions?.[
                                a
                              ] <
                              report?.analysis?.repetitionInfo?.repetitions?.[b]
                            ) {
                              return 1;
                            } else if (
                              report?.analysis?.repetitionInfo?.repetitions?.[
                                a
                              ] >
                              report?.analysis?.repetitionInfo?.repetitions?.[b]
                            ) {
                              return -1;
                            }
                            return 0;
                          })
                          ?.slice(
                            0,
                            showAllRepetitions
                              ? Object.keys(
                                  report?.analysis?.repetitionInfo?.repetitions,
                                )?.length
                              : 10,
                          )
                          ?.map((repeatition, index) => (
                            <Typography key={index} sx={{}}>
                              {repeatition}{' '}
                              <Chip
                                label={
                                  report?.analysis?.repetitionInfo
                                    ?.repetitions?.[repeatition]
                                }
                                size="small"
                                color="primary"
                              ></Chip>
                            </Typography>
                          ))
                      ) : (
                        <Typography sx={{}} color={'text.secondary'}>
                          No repeated words
                        </Typography>
                      )}
                    </Box>
                    <Typography
                      variant="body1"
                      sx={{
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      {report?.analysis?.repetitionInfo?.transcript
                        ? parse(
                            report?.analysis?.repetitionInfo?.transcript?.replace(
                              repeatWordsRegexp,
                              (highlight) => {
                                return `<mark>${highlight
                                  .split('{')
                                  .join('')
                                  .split('}')
                                  .join('')}</mark>`;
                              },
                            ) || '',
                          )
                        : ''}
                    </Typography>
                  </>
                ) : currentParam === 'pace' ? (
                  <Box>
                    <LineGraph
                      height={'calc(50vh - 160px)'}
                      labels={
                        Object.keys(report?.analysis?.paceGraph || {})?.length >
                        15
                          ? [
                              ...new Set(
                                Object.keys(report?.analysis?.paceGraph)
                                  ?.filter(
                                    (key) =>
                                      report?.analysis?.paceGraph?.[key] > 60,
                                  )
                                  .filter(
                                    (n) =>
                                      n %
                                        (2 +
                                          Math.floor(
                                            Object.keys(
                                              report?.analysis?.paceGraph,
                                            )?.length / 30,
                                          )) ===
                                      0,
                                  ),
                              ),
                            ]
                          : Object.keys(report?.analysis?.paceGraph || {})
                      }
                      data={
                        Object.values(report?.analysis?.paceGraph || {})
                          ?.length > 15
                          ? Object.values(report?.analysis?.paceGraph)
                              ?.filter((value) => value > 60)
                              .reduce((acc, curr, index) => {
                                const averageValue =
                                  Object.values(report?.analysis?.paceGraph)
                                    ?.filter((value) => value > 60)
                                    ?.slice(
                                      index *
                                        (2 +
                                          Math.floor(
                                            Object.keys(
                                              report?.analysis?.paceGraph,
                                            )?.filter(
                                              (key) =>
                                                report?.analysis?.paceGraph?.[
                                                  key
                                                ] > 60,
                                            )?.length / 30,
                                          )),
                                      (2 +
                                        Math.floor(
                                          Object.keys(
                                            report?.analysis?.paceGraph,
                                          )?.filter(
                                            (key) =>
                                              report?.analysis?.paceGraph?.[
                                                key
                                              ] > 60,
                                          )?.length / 30,
                                        )) *
                                        (index + 1),
                                    )
                                    ?.reduce((acc, curr) => acc + curr, 0) /
                                  (2 +
                                    Math.floor(
                                      Object.keys(
                                        report?.analysis?.paceGraph,
                                      )?.filter(
                                        (key) =>
                                          report?.analysis?.paceGraph?.[key] >
                                          60,
                                      )?.length / 30,
                                    ));
                                acc.push(averageValue);
                                return acc;
                              }, [])
                              .slice(
                                0,
                                Object.keys(report?.analysis?.paceGraph).filter(
                                  (n) =>
                                    n %
                                      (2 +
                                        Math.floor(
                                          Object.keys(
                                            report?.analysis?.paceGraph,
                                          )?.length / 30,
                                        )) ===
                                    0,
                                ).length,
                              )
                          : Object.values(report?.analysis?.paceGraph || {})
                      }
                    />
                  </Box>
                ) : currentParam === 'pace-exp' ? (
                  <Box>
                    <LineGraph
                      height={'calc(50vh - 160px)'}
                      labels={Object.keys(
                        report?.analysis?.paceGraph || {},
                      )?.filter(
                        (key) => report?.analysis?.paceGraph?.[key] > 60,
                      )}
                      data={Object.values(
                        report?.analysis?.paceGraph || {},
                      )?.filter((value) => value > 60)}
                    />
                  </Box>
                ) : currentParam === 'transcript' ? (
                  <Typography
                    variant="body1"
                    sx={{
                      whiteSpace: 'pre-wrap',
                    }}
                  >
                    {report?.transcript}
                  </Typography>
                ) : currentParam === 'conversationScore' ? (
                  <Box display={'flex'}>
                    <Box display={'flex'} flex={2} p={2}>
                      <Grid container spacing={2}>
                        {Object.keys(
                          JSON.parse(
                            report?.analysis?.methodAnalysis?.contentScoring
                              ?.analysisJson || '{}',
                          ) || {},
                        )
                          ?.sort(
                            (a, b) =>
                              report?.analysis?.methodAnalysis?.contentScoring?.contentScoringOrder
                                ?.map((obj) => Object.keys(obj)?.[0])
                                ?.indexOf(a) -
                              report?.analysis?.methodAnalysis?.contentScoring?.contentScoringOrder
                                ?.map((obj) => Object.keys(obj)?.[0])
                                ?.indexOf(b),
                          )
                          ?.map((key) => (
                            <Grid item xs={6} key={key}>
                              <Card sx={{ p: 2, height: '100%' }}>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    gap: 2,
                                  }}
                                >
                                  <Typography
                                    variant="body1"
                                    fontWeight={'bold'}
                                  >
                                    {key}
                                  </Typography>
                                  {uId ? (
                                    <ProfileCompletion
                                      percentCompleted={Math.round(
                                        report?.analysis?.methodAnalysis
                                          ?.contentScoring?.scores?.[key],
                                      )}
                                      circleSize={45}
                                      circleTextSize={12}
                                      fontSize={12}
                                      thickness={5}
                                      dontShowExtraText={true}
                                    />
                                  ) : null}
                                </Box>
                                <List component={'nav'}>
                                  {Object.keys(
                                    JSON.parse(
                                      report?.analysis?.methodAnalysis
                                        ?.contentScoring?.analysisJson || '{}',
                                    )[key] || {},
                                  )
                                    ?.sort(
                                      (a, b) =>
                                        Object.values(
                                          report?.analysis?.methodAnalysis?.contentScoring?.contentScoringOrder?.find(
                                            (obj) =>
                                              Object.keys(obj)?.[0] === key,
                                          ) || {},
                                        )?.[0]?.indexOf(a) -
                                        Object.values(
                                          report?.analysis?.methodAnalysis?.contentScoring?.contentScoringOrder?.find(
                                            (obj) =>
                                              Object.keys(obj)?.[0] === key,
                                          ) || {},
                                        )?.[0]?.indexOf(b),
                                    )
                                    ?.map((content) => (
                                      <ListItem
                                        disableGutters
                                        disablePadding
                                        key={content}
                                      >
                                        <ListItemIcon>
                                          {orgConfigs?.followNewContentScoring ? (
                                            JSON.parse(
                                              report?.analysis?.methodAnalysis
                                                ?.contentScoring
                                                ?.analysisJson || '{}',
                                            )[key][content]['met'] ===
                                            'true' ? (
                                              <CheckCircle
                                                sx={{
                                                  color:
                                                    theme.palette.primary.main,
                                                }}
                                              />
                                            ) : (
                                              <Cancel
                                                sx={{
                                                  color:
                                                    theme.palette.error.main,
                                                }}
                                              />
                                            )
                                          ) : JSON.parse(
                                              report?.analysis?.methodAnalysis
                                                ?.contentScoring
                                                ?.analysisJson || '{}',
                                            )[key][content]['met'] ? (
                                            <CheckCircle
                                              sx={{
                                                color:
                                                  theme.palette.primary.main,
                                              }}
                                            />
                                          ) : (
                                            <Cancel
                                              sx={{
                                                color: theme.palette.error.main,
                                              }}
                                            />
                                          )}
                                        </ListItemIcon>
                                        <ListItemText
                                          primary={content}
                                          sx={{
                                            color: theme.palette.primary.main,
                                          }}
                                          onMouseEnter={(e) => {
                                            setAnchorElDetails(e.currentTarget);
                                            setPopperDetails(
                                              JSON.parse(
                                                report?.analysis?.methodAnalysis
                                                  ?.contentScoring
                                                  ?.analysisJson || '{}',
                                              )[key][content],
                                            );
                                          }}
                                          onMouseLeave={() => {
                                            setAnchorElDetails(null);
                                            setPopperDetails(null);
                                          }}
                                        />
                                        <Popper
                                          id="mouse-over-popover"
                                          sx={{
                                            pointerEvents: 'none',
                                          }}
                                          open={Boolean(anchorElDetails)}
                                          anchorEl={anchorElDetails}
                                          placement="bottom"
                                          onClose={() => {
                                            setAnchorElDetails(null);
                                          }}
                                          // disableRestoreFocus
                                        >
                                          <Box
                                            sx={{
                                              border: '1px solid #d3d3d3',
                                              borderRadius: 1,
                                              padding: 1,
                                              background: '#fff',
                                              maxWidth: 400,
                                              display: 'flex',
                                              flexDirection: 'column',
                                              gap: 1,
                                              boxShadow: theme.shadows[1],
                                            }}
                                          >
                                            <Typography
                                              variant={'body1'}
                                              color={'text.secondary'}
                                              sx={{
                                                padding: 1,
                                              }}
                                            >
                                              <span
                                                style={{ fontWeight: 'bold' }}
                                              >
                                                Criteria:{' '}
                                              </span>
                                              {popperDetails?.description ||
                                                popperDetails?.question}
                                            </Typography>
                                            <Divider />
                                            <Typography
                                              variant={'body2'}
                                              sx={{
                                                padding: 1,
                                                background: orgConfigs?.followNewContentScoring
                                                  ? popperDetails?.met ===
                                                    'true'
                                                    ? 'rgba(65,168,84, 0.4)'
                                                    : 'rgba(239,65,74, 0.4)'
                                                  : popperDetails?.met
                                                  ? 'rgba(65,168,84, 0.4)'
                                                  : 'rgba(239,65,74, 0.4)',
                                              }}
                                            >
                                              <span
                                                style={{ fontWeight: 'bold' }}
                                              >
                                                Reason:{' '}
                                              </span>
                                              {popperDetails?.reason ||
                                                'Not specified'}
                                            </Typography>
                                          </Box>
                                        </Popper>
                                      </ListItem>
                                    ))}
                                </List>
                              </Card>
                            </Grid>
                          ))}
                      </Grid>
                    </Box>
                    <Box
                      style={{
                        flex: 1,
                        borderLeft: '1px solid #d3d3d3',
                        padding: 2,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        gap: 2,
                        height: 'calc(100vh - 120px)',
                      }}
                    >
                      <Box
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'center'}
                        flex={1}
                        p={2}
                      >
                        <ParticipationPieChart
                          styles={{
                            maxWidth: 180,
                          }}
                          title={'Call Participation'}
                          labels={
                            Object.keys(report?.analysis?.speakProportion || {})
                              ?.filter((sp) => sp !== 'silence')
                              ?.sort(
                                (a, b) =>
                                  a?.split('_')?.[1] - b?.split('_')?.[1],
                              ) || []
                          }
                          speakers={report?.speakers || ''}
                          data={Object.keys(
                            report?.analysis?.speakProportion || {},
                          )
                            ?.filter((key) => key !== 'silence')
                            ?.sort(
                              (a, b) => a?.split('_')?.[1] - b?.split('_')?.[1],
                            )
                            ?.map((key, index) =>
                              Math.round(
                                (report?.analysis?.speakProportion[key] /
                                  Object.keys(
                                    report?.analysis?.speakProportion || {},
                                  )
                                    ?.filter((key) => key !== 'silence')
                                    ?.sort(
                                      (a, b) =>
                                        a?.split('_')?.[1] - b?.split('_')?.[1],
                                    )
                                    ?.reduce(
                                      (acc, curr) =>
                                        acc +
                                        report?.analysis?.speakProportion[curr],
                                      0,
                                    )) *
                                  100,
                              ),
                            )}
                        />
                      </Box>
                      <Box
                        flex={1}
                        display={'flex'}
                        alignItems={'center'}
                        flexDirection={'columm'}
                        p={2}
                      >
                        {scoreCalculationInProcess?.[scoreType] !==
                          'completed' &&
                        scoreCalculationInProcess?.[scoreType] !== 'failed' ? (
                          <Box
                            display={'flex'}
                            flexDirection={'column'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            gap={2}
                          >
                            <CircularProgress size={20} />
                            <Typography
                              variant="caption"
                              color={'text.secondary'}
                            >
                              Score generation is in process...
                            </Typography>
                          </Box>
                        ) : (
                          <Box
                            display={'flex'}
                            flexDirection={'column'}
                            alignItems={'center'}
                            gap={1}
                          >
                            <Box display={'flex'} alignItems={'center'}>
                              {Object.keys(report?.speakers || {})
                                ?.filter((spk) =>
                                  type === 'practiceCall'
                                    ? spk === 'Speaker 1'
                                    : true,
                                )
                                ?.map((key, index, ar) => (
                                  <Box
                                    key={key}
                                    sx={{
                                      background:
                                        scoreType !== key
                                          ? '#d3d3d3'
                                          : theme.palette.primary.main,
                                      color:
                                        scoreType !== key ? '#000' : '#fff',
                                      padding: '2px 8px',
                                      borderTopLeftRadius: index === 0 ? 20 : 0,
                                      borderBottomLeftRadius:
                                        index === 0 ? 20 : 0,
                                      borderBottomRightRadius:
                                        index === ar?.length - 1 ? 20 : 0,
                                      borderTopRightRadius:
                                        index === ar?.length - 1 ? 20 : 0,
                                      cursor: 'pointer',
                                    }}
                                    onClick={() => setScoreType(key)}
                                  >
                                    <Typography variant="caption">
                                      {capitalizeText(report?.speakers?.[key])}
                                    </Typography>
                                  </Box>
                                ))}
                            </Box>
                            <Typography
                              variant="caption"
                              color={'text.secondary'}
                              mt={1}
                            >
                              Scores
                            </Typography>
                            <Bar
                              style={{
                                width: '100%',
                              }}
                              options={{
                                responsive: true,
                                layout: {
                                  padding: 10,
                                },
                                plugins: {
                                  legend: {
                                    display: false,
                                    // position: 'top',
                                  },
                                  tooltip: {
                                    enabled: true,
                                  },

                                  title: {
                                    display: false,
                                    text: 'Skills Score',
                                  },
                                  datalabels: {
                                    color: 'white',
                                    font: {
                                      weight: 'bold',
                                      size: 14,
                                    },
                                    // rotation: -90,
                                    formatter: function(value, context) {
                                      return `${value}`;
                                    },
                                  },
                                },
                                elements: {
                                  bar: {
                                    borderRadius: 6,
                                    borderSkipped: false,
                                  },
                                },
                                scales: {
                                  y: {
                                    beginAtZero: true,
                                    max: 100,
                                  },
                                },
                              }}
                              data={{
                                labels:
                                  scoreType === report?.speaker
                                    ? [
                                        'Pace',
                                        'Fluency',
                                        'Confidence',
                                        'Enthusiasm',
                                        'Clarity',
                                      ]
                                    : ['Fluency', 'Confidence', 'Enthusiasm'],
                                datasets: [
                                  {
                                    label: 'Score',
                                    data:
                                      scoreType === report?.speaker
                                        ? [
                                            report?.analysis?.paceScore ===
                                            undefined
                                              ? '-'
                                              : Math.round(
                                                  report?.analysis?.paceScore,
                                                ),
                                            report?.speakerScoreMap?.[scoreType]
                                              ?.fluency === undefined
                                              ? '-'
                                              : Math.round(
                                                  report?.speakerScoreMap?.[
                                                    scoreType
                                                  ]?.fluency,
                                                ),
                                            report?.speakerScoreMap?.[scoreType]
                                              ?.confidence === undefined
                                              ? '-'
                                              : Math.round(
                                                  report?.speakerScoreMap?.[
                                                    scoreType
                                                  ]?.confidence,
                                                ),
                                            report?.speakerScoreMap?.[scoreType]
                                              ?.enthusiasm === undefined
                                              ? '-'
                                              : Math.round(
                                                  report?.speakerScoreMap?.[
                                                    scoreType
                                                  ]?.enthusiasm,
                                                ),
                                            report?.analysis?.clarity ===
                                            undefined
                                              ? '-'
                                              : Math.round(
                                                  report?.analysis?.clarity,
                                                ),
                                          ]
                                        : [
                                            report?.speakerScoreMap?.[scoreType]
                                              ?.fluency === undefined
                                              ? '-'
                                              : Math.round(
                                                  report?.speakerScoreMap?.[
                                                    scoreType
                                                  ]?.fluency,
                                                ),
                                            report?.speakerScoreMap?.[scoreType]
                                              ?.confidence === undefined
                                              ? '-'
                                              : Math.round(
                                                  report?.speakerScoreMap?.[
                                                    scoreType
                                                  ]?.confidence,
                                                ),
                                            report?.speakerScoreMap?.[scoreType]
                                              ?.enthusiasm === undefined
                                              ? '-'
                                              : Math.round(
                                                  report?.speakerScoreMap?.[
                                                    scoreType
                                                  ]?.enthusiasm,
                                                ),
                                          ],
                                    backgroundColor: theme.palette.primary.main,
                                    barThickness: 20,
                                    pointStyle: 'circle',
                                    fill: true,
                                  },
                                ],
                              }}
                            />
                          </Box>
                        )}
                      </Box>
                    </Box>
                  </Box>
                ) : null}
              </Box>
            )}
          </Box>
        </Box>
      </Drawer>
      <Dialog
        open={Boolean(showSpeakerMismatch)}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          zIndex: 10002,
          '& .MuiPaper-root-MuiDialog-paper': {
            maxWidth: '70vw',
            minWidth: 500,
          },
        }}
      >
        <DialogContent sx={{ width: 1 }}>
          <Box
            width={1}
            display={'flex'}
            flexDirection={'column'}
            gap={1}
            sx={{ paddingTop: 2 }}
          >
            <Typography variant="body1">
              You are changing the salesperson. Are you sure you want to change
              the salesperson and trigger re-analysis?
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions sx={{ width: 1 }}>
          <Box
            width={1}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Button
              // data-trackid={'cta_permission_denied_dialog_close'}
              onClick={(e) => {
                setShowSpeakerMismath(false);
              }}
              color="primary"
              // autoFocus
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              size="small"
              onClick={(e) => {
                setSpeaker(showSpeakerMismatch);
                setShowSpeakerMismath(null);
              }}
              color="primary"
              // autoFocus
            >
              Proceed
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
      <Dialog
        open={Boolean(showUpdateSpeakersDialog)}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          zIndex: 1300,
          '& .MuiPaper-root-MuiDialog-paper': {
            maxWidth: '70vw',
            minWidth: 500,
          },
        }}
      >
        <DialogTitle sx={{ width: 1, background: '#d3d3d3' }}>
          <Typography variant="body1" color={'text.secondary'}>
            Update Speakers and Salesperson
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ width: 1 }}>
          <Box
            width={1}
            display={'flex'}
            flexDirection={'column'}
            gap={1}
            sx={{ paddingTop: 2 }}
          >
            <FormControl>
              <FormLabel id="demo-row-radio-buttons-group-label">
                Who is the salesperson?
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={speaker}
                onChange={(e) => handleSpeakerChange(e.target.value)}
              >
                {speakers &&
                  Object.keys(speakers) &&
                  Object.keys(speakers).length > 0 &&
                  Object.keys(speakers)
                    ?.sort()
                    ?.map((spk) => (
                      <FormControlLabel
                        key={spk}
                        value={spk}
                        control={<Radio />}
                        label={speakers?.[spk]}
                      />
                    ))}
              </RadioGroup>
            </FormControl>
            <FormLabel id="speakers-labels">Speakers</FormLabel>
            {speakers &&
            Object.keys(speakers) &&
            Object.keys(speakers).length > 0
              ? Object.keys(speakers)
                  .sort()
                  .map((spk) => (
                    <Grid container spacing={1} key={spk}>
                      <Grid item xs={4}>
                        <Typography flex={1}>{spk}</Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <TextField
                          flex={2}
                          autoFocus
                          placeholder={`Name`}
                          variant="outlined"
                          color="primary"
                          size="small"
                          name={`${spk}_name`}
                          value={speakers?.[spk]}
                          onChange={(e) => {
                            const updatedSpeakes = {
                              ...speakers,
                              [spk]: e.target.value,
                            };
                            setSpeakers(updatedSpeakes);
                          }}
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  ))
              : null}
          </Box>
        </DialogContent>
        <DialogActions sx={{ width: 1 }}>
          <Box
            width={1}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Button
              // data-trackid={'cta_permission_denied_dialog_close'}
              onClick={(e) => {
                setShowUpdateSpeakersDialog(false);
              }}
              color="primary"
              // autoFocus
            >
              Dismiss
            </Button>
            <Button
              variant="contained"
              size="small"
              startIcon={
                updatingSpeakers || reanalyzing ? (
                  <CircularProgress size={14} />
                ) : null
              }
              disabled={
                updatingSpeakers ||
                reanalyzing ||
                Object.keys(speakers)?.filter((spk) => !speakers[spk])?.length >
                  0
              }
              onClick={(e) => {
                if (
                  userDetails?.plan?.trailEndsOn > new Date().getTime() ||
                  userDetails?.plan?.currentPaymentStatus === 'paid'
                ) {
                  handleUpdateSalesReview(speaker !== report?.speaker);
                } else {
                  dispatch({
                    type: 'UPDATE_UI_STATE',
                    payload: {
                      key: 'showPlanExpired',
                      value: true,
                    },
                  });
                }
              }}
              color="primary"
              // autoFocus
            >
              {speaker !== report?.speaker ? 'Update and Re-analyze' : 'Update'}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
      <Dialog
        open={Boolean(showSyncHubspotDialog)}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          // zIndex: 10002,
          '& .MuiPaper-root-MuiDialog-paper': {
            maxWidth: '70vw',
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">
          Sync the recording and call summary to HubSpot
        </DialogTitle>
        <DialogContent>
          <Box display={'flex'} alignItems={'center'} gap={2}>
            <DialogContentText id="alert-dialog-description" flex={1}>
              Please provide the hubspot contact details you wanted this
              recording to be uploaded on.
            </DialogContentText>
          </Box>
          <Box mt={2}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="caption" color={'text.secondary'}>
                  Meeting Title*
                </Typography>
                <TextField
                  placeholder="Meeting Title*"
                  variant="outlined"
                  name={'meetingTitle'}
                  // fullWidth
                  // multiline
                  // rows={3}
                  size="small"
                  sx={{
                    width: '100%',
                  }}
                  value={meetingTitle}
                  onChange={(e) => setMeetingTitle(e.target.value)}
                  type="text"
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="caption" color={'text.secondary'}>
                  Contact Email(HubSpot)*
                </Typography>
                <TextField
                  placeholder="Contact Email*"
                  variant="outlined"
                  name={'contactEmail'}
                  size="small"
                  sx={{
                    width: '100%',
                  }}
                  value={contactEmail}
                  onChange={(e) => setContactEmail(e.target.value)}
                  type="email"
                />
              </Grid>
              <Grid item xs={6}>
                <Typography variant="caption" color={'text.secondary'}>
                  Contact First Name
                </Typography>
                <TextField
                  placeholder="Contact First Name"
                  variant="outlined"
                  name={'contactFirstName'}
                  // fullWidth
                  // multiline
                  // rows={3}
                  size="small"
                  sx={{
                    width: '100%',
                  }}
                  value={contactFirstName}
                  onChange={(e) => setContactFirstName(e.target.value)}
                  type="text"
                />
              </Grid>
              <Grid item xs={6}>
                <Typography variant="caption" color={'text.secondary'}>
                  Contact Last Name
                </Typography>
                <TextField
                  placeholder="Contact Last Name"
                  variant="outlined"
                  name={'contactLastName'}
                  // fullWidth
                  // multiline
                  // rows={3}
                  size="small"
                  sx={{
                    width: '100%',
                  }}
                  value={contactLastName}
                  onChange={(e) => setContactLastName(e.target.value)}
                  type="text"
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="caption" color={'text.secondary'}>
                  Meeting Time
                </Typography>
                <br />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    // label="Meeting Time"
                    value={dayjs(dateTime)}
                    onChange={(newValue) => {
                      console.log(new Date(newValue).getTime());
                      setDateTime(new Date(newValue).getTime());
                    }}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box
            width={1}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Button
              // data-trackid={'cta_permission_denied_dialog_close'}
              onClick={(e) => {
                setShowSyncHubspotDialog(false);
              }}
              color="primary"
              // autoFocus
            >
              Dismiss
            </Button>
            <Button
              variant="contained"
              size="small"
              disabled={syncingWithHubspot}
              // data-trackid={'cta_permission_denied_dialog_close'}
              onClick={(e) => {
                setSyncingWithHubspot(true);
                dispatch(
                  syncDataWithHubspot(
                    report?.id,
                    {
                      email: contactEmail,
                      fname: contactFirstName,
                      hubSpot: true,
                      lname: contactLastName,
                      meetingTitle: meetingTitle,
                      startTime: dateTime,
                    },
                    () => {
                      setShowSyncHubspotDialog(false);
                      setSyncingWithHubspot(false);
                      dispatch({
                        type: 'UPDATE_SALES_CALL_ANALYSIS',
                        payload: {
                          id: report?.id,
                          data: {
                            ...report,
                            exported: true,
                          },
                        },
                      });
                      setSalesReview((prev) => {
                        return {
                          ...prev,
                          exported: true,
                        };
                      });
                      dispatch(
                        showAlert({
                          message:
                            'Summary is successfully exported to HubSpot.',
                          showCross: true,
                          title: null,
                          type: 'success',
                          autoHideDuration: 5000,
                          vertical: 'top',
                          horizontal: 'center',
                        }),
                      );
                    },
                    () => {
                      setSyncingWithHubspot(false);
                      // setShowSyncHubspotDialog(false)
                      dispatch(
                        showAlert({
                          message:
                            'Failed to export summary. Please try again.',
                          showCross: true,
                          title: null,
                          type: 'error',
                          autoHideDuration: 5000,
                          vertical: 'top',
                          horizontal: 'center',
                        }),
                      );
                    },
                  ),
                );
              }}
              color="primary"
              // autoFocus
            >
              Sync
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

ReportPage.propTypes = {
  report: PropTypes.object,
  setSalesReview: PropTypes.func,
  type: PropTypes.string.isRequired,
  handleReanalyze: PropTypes.func,
  searchKeywords: PropTypes.array,
  searchKeywordsMap: PropTypes.object,
  setShowReAnalyzeDrawer: PropTypes.func,
  uId: PropTypes.string,
  tk: PropTypes.string,
  pc: PropTypes.string,
  setShowSyncCrmPropertiesDialog: PropTypes.func,
  setShowCrmUserMappingDialog: PropTypes.func,
  setShowUserNotMappedDialog: PropTypes.func,
};

export default ReportPage;
