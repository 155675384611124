import { onAuthStateChanged } from 'firebase/auth';
import axios from 'utils/axios';
import { auth } from '../../../firebase';
import { v4 as uuidv4 } from 'uuid';

const generateDeal = (dealId, callback) => {
  return async (dispatch) => {
    dispatch({ type: 'GENERATING_DEAL_SUMMARY' });
    try {
      let unsubscribe = onAuthStateChanged(auth, async (user) => {
        const token = await user?.getIdToken();
        const userInfo = localStorage.getItem('userInfo');
        const userId = JSON.parse(userInfo)?.id;
        const reqId = uuidv4();
        try {
          const response = await axios.post(
            `/us/qai/deals/${dealId}/summary`,
            null,
            {
              params: {
                'request-id': reqId,
              },
              headers: {
                Authorization: `Bearer ${token}`,
                // 'current-role': 'candidate',
                // 'x-api-version': 'v2',
              },
            },
          );
          if (response.status === 200) {
            await dispatch({
              type: 'GENERATING_DEAL_SUMMARY_DONE',
              payload: {
                dealSummary: response?.data,
              },
            });
            if (typeof callback === 'function') {
              callback(response?.data, false);
            }
          } else {
            dispatch({
              type: 'GENERATING_DEAL_SUMMARY_FAILED',
              payload: `Failed to generate deal summary`,
            });
            if (typeof callback === 'function') {
              callback(null, true);
            }
          }
        } catch (e) {
          dispatch({
            type: 'GENERATING_DEAL_SUMMARY_FAILED',
            payload: `Failed to generate deal summary`,
          });
          if (typeof callback === 'function') {
            callback(null, true);
          }
        }
        unsubscribe();
      });
    } catch (e) {
      dispatch({
        type: 'GENERATING_DEAL_SUMMARY_FAILED',
        payload: `Failed to generate deal summary`,
      });
      if (typeof callback === 'function') {
        callback(null, true);
      }
    }
  };
};

export default generateDeal;
