const initialstate = {
  fetching: null,
  fetchingPagination: null,
  fetchingSuccessful: null,
  fetchingFailed: null,
  error: null,
  errorText: null,
  deals: [],
  emptyList: null,
  lastSeen: null,
  lastTime: 0,
};

const getDealsReducer = (state = initialstate, action) => {
  switch (action.type) {
    case 'FETCHING_ALL_DEALS':
      return Object.assign({}, state, {
        fetching: true,
        fetchingPagination: false,
      });
    case 'FETCHING_ALL_DEALS_WITH_PAGINATION':
      return Object.assign({}, state, {
        fetching: false,
        fetchingPagination: true,
      });
    case 'FETCHING_ALL_DEALS_DONE':
      return Object.assign({}, state, {
        fetching: false,
        fetchingPagination: false,
        fetchingSuccessful: true,
        fetchingFailed: false,
        error: false,
        errorText: '',
        deals: action.payload.deals,
        lastSeen: action.payload.lastSeen
          ? action.payload.lastSeen
          : state.lastSeen,
        lastTime: action.payload.lastTime
          ? action.payload.lastTime
          : state.lastTime,
        emptyList: false,
      });
    case 'ADD_DEALS_TO_ALL_DEALS':
      return Object.assign({}, state, {
        fetching: false,
        fetchingSuccessful: true,
        fetchingPagination: false,
        fetchingFailed: false,
        deals: Array.isArray(action.payload.deals)
          ? state.deals
            ? state.deals.concat(action.payload.deals)
            : action.payload.deals
          : state.deals,
        emptyList:
          Array.isArray(action.payload.deals) &&
          (action.payload.deals.length === 0 ||
            action.payload.deals.length % 10 > 0)
            ? true
            : false,
        lastSeen: action.payload.lastSeen
          ? action.payload.lastSeen
          : state.lastSeen,
        lastTime: action.payload.lastTime
          ? action.payload.lastTime
          : state.lastTime,
        error: false,
        errorText: '',
      });
    case 'UPDATE_DEALS': {
      const updatedDeals = state.deals.map((sr) => {
        if (sr?.id === action.payload.id) {
          return action.payload.data;
        } else {
          return sr;
        }
      });
      return Object.assign({}, state, {
        fetching: false,
        fetchingPagination: false,
        fetchingSuccessful: true,
        fetchingFailed: false,
        deals: updatedDeals,
        lastTime: state.lastTime,
        lastSeen: state.lastSeen,
        emptyList: state.emptyList,
        error: state.error,
        errorText: state.errorText,
      });
    }
    case 'FETCHING_ALL_DEALS_FAILED':
      return Object.assign({}, state, {
        fetching: false,
        fetchingSuccessful: false,
        fetchingPagination: false,
        fetchingFailed: true,
        error: true,
        errorText: action.payload,
        deals: [],
        emptyList: false,
        lastSeen: '',
        lastTime: 0,
      });

    case 'FETCHING_ALL_DEALS_PAGINATION_FAILED':
      return Object.assign({}, state, {
        fetchingPagination: false,
      });
    default:
      return state;
  }
};

export default getDealsReducer;
