import { auth } from '../../../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import axios from 'utils/axios';
import { v4 as uuidv4 } from 'uuid';
import showAlert from './showAlert';

const getDeals = (payload, callback) => {
  return async (dispatch) => {
    if (payload?.lastSeen || payload?.lastTime) {
      dispatch({ type: 'FETCHING_ALL_DEALS_WITH_PAGINATION' });
    } else {
      dispatch({ type: 'FETCHING_ALL_DEALS' });
    }
    try {
      let unsubscribe = onAuthStateChanged(auth, async (user) => {
        const token = await user?.getIdToken();
        const userInfo = localStorage.getItem('userInfo');
        const userId = JSON.parse(userInfo)?.id;
        const reqId = uuidv4();
        try {
          const response = await axios.post(`/us/qai/deals/list`, payload, {
            params: {
              'request-id': reqId,
            },
            headers: token
              ? {
                  Authorization: `Bearer ${token}`,
                }
              : {},
          });
          if (response.status === 200) {
            if (payload?.lastSeen || payload?.lastTime) {
              await dispatch({
                type: 'ADD_DEALS_TO_ALL_DEALS',
                payload: {
                  deals: response?.data,
                  lastSeen: response?.data?.[response?.data?.length - 1]?.id,
                  lastTime: response?.data?.[response?.data?.length - 1]?.id,
                },
              });
            } else {
              await dispatch({
                type: 'FETCHING_ALL_DEALS_DONE',
                payload: {
                  deals: response?.data,
                  lastSeen: response?.data?.[response?.data?.length - 1]?.id,
                  lastTime: response?.data?.[response?.data?.length - 1]?.id,
                },
              });
            }
            if (typeof callback === 'function') {
              callback(response?.data);
            }
          } else {
            dispatch({
              type: 'FETCHING_ALL_DEALS_FAILED',
              payload: 'Failed to fetch deals',
            });
            dispatch(
              showAlert({
                message: 'Oops! Something went wrong. Please try again.',
                showCross: true,
                title: null,
                type: 'error',
                autoHideDuration: 2000,
                vertical: 'top',
                horizontal: 'center',
              }),
            );
          }
        } catch (e) {
          dispatch({
            type: 'FETCHING_ALL_DEALS_FAILED',
            payload: 'Failed to fetch deals',
          });
          dispatch(
            showAlert({
              message: 'Oops! Something went wrong. Please try again.',
              showCross: true,
              title: null,
              type: 'error',
              autoHideDuration: 2000,
              vertical: 'top',
              horizontal: 'center',
            }),
          );
        }
        unsubscribe();
      });
    } catch (e) {
      dispatch({
        type: 'FETCHING_ALL_DEALS_FAILED',
        payload: 'Failed to fetch deals',
      });
      dispatch(
        showAlert({
          message: 'Oops! Something went wrong. Please try again.',
          showCross: true,
          title: null,
          type: 'error',
          autoHideDuration: 2000,
          vertical: 'top',
          horizontal: 'center',
        }),
      );
    }
  };
};

export default getDeals;
