import { auth } from '../../../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import axios from 'utils/axios';
import { v4 as uuidv4 } from 'uuid';

const updateCrmDeal = (dealId, saleReviewId, payload, callback, errCb) => {
  return async (dispatch) => {
    dispatch({ type: 'UPDATE_CRM_DEAL_PROPERTIES' });
    try {
      let unsubscribe = onAuthStateChanged(auth, async (user) => {
        const token = await user?.getIdToken();
        const userInfo = localStorage.getItem('userInfo');
        const userId = JSON.parse(userInfo)?.id;
        const reqId = uuidv4();
        try {
          const response = await axios.put(
            `/us/qai/deals/crm/${dealId}`,
            payload,
            {
              params: {
                'request-id': reqId,
                saleReviewId,
              },
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
          );
          console.log(response);
          if (response.status === 200) {
            await dispatch({
              type: 'UPDATE_CRM_DEAL_PROPERTIES_DONE',
            });
            if (typeof callback === 'function') {
              callback(response?.data);
            }
          } else {
            dispatch({
              type: 'UPDATE_CRM_DEAL_PROPERTIES_FAILED',
              payload: `Failed to update deal properties`,
            });
            if (typeof errCb === 'function') {
              errCb(response?.response?.data);
            }
          }
        } catch (e) {
          dispatch({
            type: 'UPDATE_CRM_DEAL_PROPERTIES_FAILED',
            payload: `Failed to update deal properties`,
          });
          if (typeof errCb === 'function') {
            errCb(e?.response?.data);
          }
        }
        unsubscribe();
      });
    } catch (e) {
      console.log(e);
      dispatch({
        type: 'UPDATE_CRM_DEAL_PROPERTIES_FAILED',
        payload: `Failed to update deal properties`,
      });
      if (typeof errCb === 'function') {
        errCb(e?.response?.data);
      }
    }
  };
};

export default updateCrmDeal;
