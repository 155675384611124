import {
  Box,
  Button,
  Chip,
  CircularProgress,
  ClickAwayListener,
  Divider,
  FormControl,
  MenuItem,
  Select,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import LineGraph from 'components/LineGraph/LineGraph';
import parse from 'html-react-parser';
import { Add, Check, Sync } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import getAllDealNames from 'redux/actions/Common/getAllDealNames';
import crmHubspotSync from 'redux/actions/Common/crmHubspotSync';

const DealSelect = ({
  selectedCrmDeal,
  setSelectedCrmDeal,
  showCreateNew,
  refreshList,
  setRefreshList,
  crmSyncing,
  setShowDealCreationDialog,
  setDealEditable,
  fullWidth,
  ...rest
}) => {
  const theme = useTheme();
  const history = useHistory();
  const pathParams = useParams();
  const dispatch = useDispatch();
  const teamId = localStorage.getItem('teamId') || pathParams?.teamId;
  const [crmDeals, setCrmDeals] = useState([]);

  const { userDetails } = useSelector((state) => state.userDetails);
  const [syncingCrmDeals, setSyncingCrmDeals] = useState(false);

  useEffect(() => {
    if (refreshList) {
      dispatch(
        getAllDealNames((dealNames) => {
          setCrmDeals(dealNames);
          setRefreshList(false);
        }),
      );
    }
  }, [refreshList]);

  useEffect(() => {
    dispatch(
      getAllDealNames((dealNames) => {
        setCrmDeals(dealNames);
      }),
    );
  }, []);

  return (
    <ClickAwayListener
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
      onClickAway={() => {
        setDealEditable(false);
      }}
    >
      <FormControl fullWidth={fullWidth}>
        <Select
          size="small"
          labelId="deal-select-label"
          id="deal-select"
          name="selectedCrmDeal"
          MenuProps={{
            disableScrollLock: true,
          }}
          onChange={(e) => {
            setSelectedCrmDeal(e.target.value);
          }}
          value={selectedCrmDeal}
        >
          {/* <MenuItem value={'-'}> */}
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'flex-end'}
            // p={1}
            paddingX={1}
            gap={2}
            marginBottom={1}
          >
            <Button
              variant="outlined"
              color="primary"
              size="small"
              startIcon={
                syncingCrmDeals ? <CircularProgress size={20} /> : <Sync />
              }
              onClick={() => {
                setSyncingCrmDeals(true);
                if (
                  userDetails?.custom?.integration?.hubSpot ||
                  userDetails?.custom?.integration?.pipedriveCrm ||
                  userDetails?.custom?.integration?.zohoCrm
                ) {
                  dispatch(
                    crmHubspotSync(
                      () => {
                        setSyncingCrmDeals(false);
                        setRefreshList(true);
                      },
                      () => {
                        setSyncingCrmDeals(false);
                      },
                    ),
                  );
                } else {
                  setSyncingCrmDeals(false);
                  setRefreshList(true);
                }
              }}
              sx={{
                padding: '4px 8px',
              }}
            >
              Refresh
            </Button>
            <Button
              variant="outlined"
              color="primary"
              size="small"
              startIcon={<Add />}
              onClick={() => {
                if (
                  userDetails?.custom?.integration?.hubSpot ||
                  userDetails?.custom?.integration?.pipedriveCrm ||
                  userDetails?.custom?.integration?.zohoCrm
                ) {
                  if (userDetails?.custom?.integration?.hubSpot) {
                    window.open('https://app.hubspot.com/login', '__blank');
                  } else if (userDetails?.custom?.integration?.zohoCrm) {
                    window.open('https://crm.zoho.com', '__blank');
                  } else if (userDetails?.custom?.integration?.pipedriveCrm) {
                    window.open(
                      'https://app.pipedrive.com/auth/login',
                      '__blank',
                    );
                  }
                } else {
                  setShowDealCreationDialog(true);
                }
              }}
              sx={{
                padding: '4px 8px',
              }}
            >
              Create Deal
            </Button>
          </Box>
          {/* </MenuItem> */}
          <MenuItem value={'select'}>
            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              <Typography>Select a Deal</Typography>
            </Box>
          </MenuItem>
          {/* {showCreateNew && crmDeals && crmDeals?.length === 0 ? (
          <MenuItem value={'createnew'}>
            <Typography>+ Create New Deal</Typography>
          </MenuItem>
        ) : null} */}
          {syncingCrmDeals || crmSyncing ? (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: 2,
              }}
            >
              <CircularProgress size={20} />
              <Typography>Syncing Deals</Typography>
            </Box>
          ) : crmDeals && crmDeals?.length > 0 ? (
            crmDeals?.map((deal) => (
              <MenuItem key={deal?.id} value={deal?.id}>
                <Box
                  sx={{
                    display: 'flex',
                    gap: 2,
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography>{deal?.name}</Typography>
                  {/* <Typography>
                                      {moment(deal?.createdOn).format(
                                        'DD MMM YYYY',
                                      )}
                                    </Typography> */}
                </Box>
              </MenuItem>
            ))
          ) : null}
        </Select>
      </FormControl>
    </ClickAwayListener>
  );
};

DealSelect.propTypes = {
  selectedCrmDeal: PropTypes.string,
  setSelectedCrmDeal: PropTypes.func,
  showCreateNew: PropTypes.bool,
  refreshList: PropTypes.bool,
  setRefreshList: PropTypes.func,
  crmSyncing: PropTypes.bool,
  setShowDealCreationDialog: PropTypes.func,
  // crmDeals: PropTypes.object,
  setDealEditable: PropTypes.func,
  fullWidth: PropTypes.bool,
};

export default DealSelect;
