import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import useScrollTrigger from '@mui/material/useScrollTrigger';

import Container from 'components/Container';
import {
  Topbar,
  Sidebar,
  Footer,
  BottomNavbar,
  // ThemeModeToggler
} from './components';

import AlertPopup from 'components/AlertPopup';
import { useDispatch, useSelector } from 'react-redux';
import hideAlert from 'redux/actions/Common/hiderAlert';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';

const PublicFluid = ({
  children,
  colorInvert = false,
  bgcolor = 'transparent',
  noTopbar,
  noFooter,
  leftSidebar,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const isXs = useMediaQuery(theme.breakpoints.only('xs'), {
    defaultMatches: false,
  });

  const [openSidebar, setOpenSidebar] = useState(false);
  const [showBottomNav, setShowBottomNav] = useState(false);
  const alertState = useSelector((state) => state.alertState);
  const { dashboardSidebarExpanded } = useSelector((state) => state.uiStates);

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  const open = isMd ? false : openSidebar;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 38,
  });

  useEffect(() => {
    if (isXs) {
      setShowBottomNav(true);
    } else {
      setShowBottomNav(false);
    }
  }, [isXs]);

  useEffect(() => {
    if (isMd) {
      dispatch({
        type: 'UPDATE_UI_STATE',
        payload: {
          key: 'dashboardSidebarExpanded',
          value: true,
        },
      });
    } else {
      dispatch({
        type: 'UPDATE_UI_STATE',
        payload: {
          key: 'dashboardSidebarExpanded',
          value: false,
        },
      });
    }
  }, [isMd]);

  return (
    <ErrorBoundary>
      <Box>
        {/* <Box>
            <ThemeModeToggler />
          </Box> */}
        {!noTopbar && !showBottomNav ? (
          <AppBar
            position={'sticky'}
            sx={{
              top: 0,
              backgroundColor: trigger
                ? theme.palette.background.paper
                : bgcolor,
              // background: '#343a40',
            }}
            elevation={trigger ? 1 : 0}
          >
            <Container paddingY={1} maxWidth={{ sm: 1, md: 1236 }}>
              <Topbar
                onSidebarOpen={handleSidebarOpen}
                colorInvert={trigger ? false : colorInvert}
              />
            </Container>
          </AppBar>
        ) : null}
        <Sidebar onClose={handleSidebarClose} open={open} variant="temporary" />
        {leftSidebar && !showBottomNav ? (
          <Sidebar onClose={() => {}} open={true} variant="permanent" />
        ) : null}
        {/* {showBottomNav ? (
          <BottomNavbar
            onClose={() => {}}
            open={true}
            variant="permanent"
            pages={pages}
            expanded={dashboardSidebarExpanded}
          />
        ) : null} */}
        <main>
          <AlertPopup
            // classes={{ root: classes.browserInfoSnackbar }}
            open={alertState.open}
            autoHideDuration={6000}
            handleClose={() => dispatch(hideAlert())}
            message={alertState.message}
            type={alertState.type}
            verticalLocation={alertState.vertical}
            horizontalLocation={alertState.horizontal}
          ></AlertPopup>
          {children}
          {!noFooter ? <Divider /> : null}
        </main>
        {!noFooter ? (
          <Container paddingY={1} maxWidth={{ sm: 1, md: 1236 }}>
            <Footer />
          </Container>
        ) : null}
      </Box>
    </ErrorBoundary>
  );
};

PublicFluid.propTypes = {
  children: PropTypes.node,
  colorInvert: PropTypes.bool,
  bgcolor: PropTypes.string,
  noTopbar: PropTypes.bool,
  noFooter: PropTypes.bool,
  leftSidebar: PropTypes.bool,
};

export default PublicFluid;
