import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import trackEvent from 'redux/actions/Common/trackEvent';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { detect } from 'detect-browser';
import logError from 'redux/actions/Common/logError';

// import NavItem from './components/NavItem';

const SidebarNav = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { isLoggedIn } = useSelector((state) => state.firebaseSession);
  const { fetching, domainDetails } = useSelector(
    (state) => state.whitelabelDetails,
  );
  const userInfo = JSON.parse(localStorage.getItem('userInfo') || '{}');
  const { pathname } = useLocation();
  const browser = detect();
  // const { mode } = theme.palette;

  // const {
  //   landings: landingPages,
  //   secondary: secondaryPages,
  //   company: companyPages,
  //   account: accountPages,
  //   portfolio: portfolioPages,
  //   blog: blogPages,
  // } = pages;

  const handleTrackEvent = (e, type) => {
    try {
      dispatch(
        trackEvent({
          context: 'click_event',
          eventType: type,
          userId: isLoggedIn ? userInfo?.id : null,
          time: new Date().getTime(),
          os: browser?.os,
          sessionId: isLoggedIn
            ? userInfo?.id
            : sessionStorage.getItem('trackingSessionId'),
          customData: {
            page_path: pathname,
            browser: browser?.name,
            browser_version: browser?.version,
          },
        }),
      );
    } catch (e) {
      console.log(e);
      dispatch(
        logError({
          message: e?.message,
          data: {
            formatted: {
              errorText: e?.message,
              severity: 'error',
            },
            raw: e,
          },
        }),
      );
    }
  };

  return (
    <Box>
      <Box width={1} paddingX={2} paddingY={1}>
        <Box
          display={'flex'}
          component="a"
          href={
            domainDetails?.organizationWebsite
              ? `https://${domainDetails?.organizationWebsite}`
              : 'https://qualification.ai'
          }
          title={domainDetails?.appTitle || 'Qualification AI'}
          width={{ xs: 60, md: 70 }}
        >
          <Box
            component={'img'}
            src={
              theme.palette.mode === 'dark'
                ? '/dark_theme_logo.svg'
                : domainDetails?.logoWithoutText || '/light_theme_logo.svg'
            }
            height={1}
            width={1}
          />
        </Box>
      </Box>
      <Box paddingX={2} paddingY={2}>
        <Box marginTop={2}>
          <Button
            data-trackid={'cta_sign_in_sign_up'}
            size={'large'}
            variant="outlined"
            fullWidth
            component="a"
            href="/auth/signin"
            onClick={(e) => {
              handleTrackEvent(e, 'cta_sign_in_sign_up');
            }}
          >
            Sign In / Sign Up
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

SidebarNav.propTypes = {};

export default SidebarNav;
