import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { alpha, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import firebaseLogout from 'redux/actions/Auth/firebaseLogout';
import firebaseCheckSession from 'redux/actions/Auth/firebaseCheckSession';
import { NavItem } from './components';
import {
  Avatar,
  Divider,
  IconButton,
  Link,
  Tooltip,
  useMediaQuery,
} from '@mui/material';
import {
  Add,
  Article,
  AutoGraph,
  Badge,
  Business,
  CorporateFare,
  Group,
  Groups,
  ListAlt,
  Logout,
  ManageAccounts,
  MonetizationOn,
  PlaylistAddCheck,
  RecentActors,
  RecordVoiceOver,
  SettingsSuggest,
  SupervisorAccount,
  TaskAlt,
  Troubleshoot,
  VoiceChat,
  VoiceOverOffSharp,
  Work,
  WorkOutline,
} from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import { useLocation, useParams } from 'react-router-dom';
import { detect } from 'detect-browser';
import trackEvent from 'redux/actions/Common/trackEvent';
import logError from 'redux/actions/Common/logError';
import packageJSON from '../../../../../../../package.json';
import { regex1, uuidRegex } from 'utils';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import updateNotificationPreferences from 'redux/actions/Common/updateNotificationPreferences.js';
import { spectraEmails } from 'data';

const SidebarNav = ({ onClose, expanded }) => {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const { pathname, search } = useLocation();
  const [activeLink, setActiveLink] = useState('');
  const { isLoggedIn } = useSelector((state) => state.firebaseSession);
  const userInfo = JSON.parse(localStorage.getItem('userInfo') || '{}');
  const {
    dashboardSidebarExpanded,
    loggedInUserTeamDetails,
    pinnedTeams,
    currentTeamToManage,
  } = useSelector((state) => state.uiStates);
  const { fetching, domainDetails } = useSelector(
    (state) => state.whitelabelDetails,
  );
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const { userDetails } = useSelector((state) => state.userDetails);
  const browser = detect();

  const handleTrackEvent = (e, type) => {
    try {
      dispatch(
        trackEvent({
          context: 'click_event',
          eventType: type,
          userId: isLoggedIn ? userInfo?.id : null,
          time: new Date().getTime(),
          os: browser?.os,
          sessionId: isLoggedIn
            ? userInfo?.id
            : sessionStorage.getItem('trackingSessionId'),
          customData: {
            page_path: pathname,
            browser: browser?.name,
            browser_version: browser?.version,
          },
        }),
      );
    } catch (e) {
      console.log(e);
      dispatch(
        logError({
          message: e?.message,
          data: {
            formatted: {
              errorText: e?.message,
              severity: 'error',
            },
            raw: e,
          },
        }),
      );
    }
  };

  const handleLogoutClick = () => {
    if (localStorage.getItem('notificationToken')) {
      dispatch(
        updateNotificationPreferences(
          {
            pushnotification: false,
          },
          () => {
            localStorage.removeItem('notificationToken');
            dispatch(
              firebaseLogout(() => {
                dispatch(firebaseCheckSession());
                history.go();
              }),
            );
          },
        ),
      );
    } else {
      dispatch(
        firebaseLogout(() => {
          dispatch(firebaseCheckSession());
          history.go();
        }),
      );
    }
  };
  useEffect(() => {
    setActiveLink(window && window.location ? window.location.pathname : '');
  }, []);

  // console.log(competitionDetails);
  // console.log(userDetails);
  // console.log(loggedInUserTeamDetails);
  // console.log(pinnedTeams);
  // console.log(pathname);
  // console.log(search);
  // console.log(regex1.test(pathname));

  return (
    <Box
      height={1}
      // paddingTop={1}
      // paddingBottom={2}
      display={'flex'}
      flexDirection={'column'}
      // justifyContent={'space-between'}
    >
      <Box
        width={1}
        paddingX={3}
        paddingY={2}
        display={'flex'}
        flexDirection={expanded ? 'row' : 'column'}
        // justifyContent={'center'}
        alignItems={'center'}
        gap={2}
      >
        {/* {!isMd ? ( */}
        <MenuIcon
          data-trackid={'toggle_sidebar_expand'}
          onClick={(e) => {
            handleTrackEvent(e, 'toggle_sidebar_expand');
            dispatch({
              type: 'UPDATE_UI_STATE',
              payload: {
                key: 'dashboardSidebarExpanded',
                value: !dashboardSidebarExpanded,
              },
            });
          }}
          sx={{ color: theme.palette.primary.main, cursor: 'pointer' }}
        />
        {/* ) : null} */}
        <Box
          data-trackid={'logo_clicked'}
          display={'flex'}
          component="a"
          href={
            domainDetails?.organizationWebsite
              ? `https://${domainDetails?.organizationWebsite}`
              : 'https://qualification.ai'
          }
          title={domainDetails?.appTitle || 'Qualification AI'}
          width={30}
          onClick={(e) => {
            handleTrackEvent(e, 'logo_clicked');
          }}
        >
          <Box
            component={'img'}
            src={
              theme.palette.mode === 'dark'
                ? '/dark_theme_logo.svg'
                : domainDetails?.logoWithoutText || '/light_theme_logo.svg'
            }
            height={1}
            width={1}
          />
        </Box>
      </Box>
      <Box mt={1}>
        {/* SS ADMIN */}
        {userInfo?.orgRoles?.includes('SS_ADMIN') ? (
          <>
            <Box>
              <NavItem
                data-trackid={'companyAccounts'}
                title={' Accounts'}
                items={[
                  {
                    name: ` All Accounts`,
                    href: `/accounts`,
                    startIcon: '',
                  },
                  {
                    name: ` Compass Framework`,
                    href: `/compass-framework`,
                    startIcon: '',
                  },
                  {
                    name: ` Compass Helps`,
                    href: `/compass-helps`,
                    startIcon: '',
                  },
                ]}
                href={'/accounts'}
                startIcon={
                  <Business
                    style={{
                      color: new RegExp(`/accounts`, 'i').test(pathname)
                        ? theme.palette.text.primary
                        : theme.palette.text.secondary,
                    }}
                  />
                }
                onClick={(e) => {
                  handleTrackEvent(e, 'accounts');
                }}
              />
              {/* <NavItem
                data-trackid={'croInterviews'}
                title={' CRO Interviews'}
                items={[
                  {
                    name: `Interview History`,
                    href: `/cro-interviews`,
                    startIcon: '',
                  },
                ]}
                href={'/cro-interviews'}
                startIcon={
                  <RecentActors
                    style={{
                      color: new RegExp(`/cro-interviews`, 'i').test(pathname)
                        ? theme.palette.text.primary
                        : theme.palette.text.secondary,
                    }}
                  />
                }
                onClick={(e) => {
                  handleTrackEvent(e, 'cro_interviews');
                }}
              /> */}
              {/* <NavItem
                data-trackid={'digital-twin'}
                title={
                  userInfo?.orgRoles?.includes('SS_ADMIN')
                    ? 'John Stopper'
                    : userInfo?.email === 'jbsales@qualification.ai' ||
                      userInfo?.email === 'vijay+laa1@list2shop.com'
                    ? ' John Barrows'
                    : ' Brynne Tillman'
                }
                items={[
                  {
                    name: `Training Data`,
                    href: `/digital-twin?type=training`,
                    startIcon: '',
                  },
                  {
                    name: `All Conversations`,
                    href: `/digital-twin`,
                    startIcon: '',
                  },
                  {
                    name: userInfo?.orgRoles?.includes('SS_ADMIN')
                      ? 'Talk to John'
                      : userInfo?.email === 'jbsales@qualification.ai' ||
                        userInfo?.email === 'vijay+laa1@list2shop.com'
                      ? 'Talk to John'
                      : `Talk to Brynne`,
                    href: `/digital-twin?type=talk`,
                    startIcon: '',
                    disabled: userInfo?.orgRoles?.includes('SS_ADMIN'),
                  },
                  {
                    name: userInfo?.orgRoles?.includes('SS_ADMIN')
                      ? 'Chat with John'
                      : userInfo?.email === 'jbsales@qualification.ai' ||
                        userInfo?.email === 'vijay+laa1@list2shop.com'
                      ? 'Chat with John'
                      : `Chat with Brynne`,
                    href: `/digital-twin?type=chat`,
                    startIcon: '',
                  },
                ]}
                href={'/digital-twin'}
                startIcon={
                  <Avatar
                    variant="circle"
                    alt={
                      userInfo?.orgRoles?.includes('SS_ADMIN')
                        ? 'John Stopper'
                        : userInfo?.email === 'jbsales@qualification.ai' ||
                          userInfo?.email === 'vijay+laa1@list2shop.com'
                        ? 'John Barrows'
                        : 'Brynne Tillman'
                    }
                    src={
                      userInfo?.orgRoles?.includes('SS_ADMIN')
                        ? '/js.jpeg'
                        : userInfo?.email === 'jbsales@qualification.ai' ||
                          userInfo?.email === 'vijay+laa1@list2shop.com'
                        ? '/jb.jpeg'
                        : '/bt.jpeg'
                    }
                    sx={{
                      width: 26,
                      height: 26,
                    }}
                  >
                    {userInfo?.orgRoles?.includes('SS_ADMIN')
                      ? 'John Stopper'
                      : userInfo?.email === 'jbsales@qualification.ai' ||
                        userInfo?.email === 'vijay+laa1@list2shop.com'
                      ? 'John Barrows'
                      : 'Brynne Tillman'}
                  </Avatar>
                }
                onClick={(e) => {
                  handleTrackEvent(e, 'digital-twin');
                }}
              /> */}
            </Box>
          </>
        ) : null}
        {/* SS ADMIN */}

        {/* WL ORG ADMIN */}
        {userInfo?.orgRoles?.[0] === 'WL_ORG_ADMIN' ? (
          <Box>
            <NavItem
              data-trackid={'open_customer_setings'}
              title={'Clients Dashboard'}
              items={[
                {
                  name: `Manage Clients`,
                  href: `/customer/settings?t=customers`,
                  startIcon: '',
                },
                {
                  name: `Manage Plans`,
                  href: `/customer/settings?t=plans`,
                  startIcon: '',
                },
                {
                  name: `Clients Ask Donna`,
                  href: `/customer/settings?t=clients-ask-donna`,
                  startIcon: '',
                },
                {
                  name: `Clients Playbook`,
                  href: `/customer/settings?t=clients-playbooks`,
                  startIcon: '',
                },
                {
                  name: `Bulk Practice Invite`,
                  href: `/customer/settings?t=bulk-invite`,
                  startIcon: '',
                },
                {
                  name: `Whitelabel Details`,
                  href: `/customer/settings`,
                  startIcon: '',
                },
              ]}
              href={'/customer/settings'}
              startIcon={
                <SupervisorAccount
                  sx={{
                    color: new RegExp(`/customer/settings`, 'i').test(pathname)
                      ? theme.palette.text.primary
                      : theme.palette.text.secondary,
                  }}
                />
              }
              onClick={(e) => {
                handleTrackEvent(e, 'open_customer_setings');
              }}
            />
          </Box>
        ) : null}
        {/* WL ORG ADMIN */}

        {Object.keys(userDetails?.custom?.plan?.features || {})?.includes(
          'expert_bot',
        ) ? (
          <NavItem
            data-trackid={'digital-twin'}
            title={`Ask ${userDetails?.custom?.expertBot?.title || 'Ron'}`}
            items={[
              {
                name: `All Conversations`,
                href: `/digital-twin`,
                startIcon: '',
              },
              {
                name: `Talk to ${userDetails?.custom?.expertBot?.title ||
                  'Ron'}`,
                href: `/digital-twin?type=talk`,
                startIcon: '',
                disabled: true,
              },
              {
                name: `Chat with ${userDetails?.custom?.expertBot?.title ||
                  'Ron'}`,
                href: `/digital-twin?type=chat`,
                startIcon: '',
              },
            ]}
            href={'/digital-twin'}
            startIcon={
              <Avatar
                variant="circle"
                alt={'Ask Ron'}
                src={'/ron.jpeg'}
                sx={{
                  width: 26,
                  height: 26,
                }}
              >
                R
              </Avatar>
            }
            onClick={(e) => {
              handleTrackEvent(e, 'digital-twin');
            }}
          />
        ) : null}

        {/* ORG ADMIN */}
        {(userInfo?.orgRoles?.[0] === 'ORG_ADMIN' ||
          userInfo?.orgRoles?.[0] === 'WL_ORG_ADMIN') &&
        userInfo?.custom?.orgType !== 'demo_org' ? (
          <Box>
            <NavItem
              data-trackid={'teams'}
              title={' My Organization'}
              items={[
                {
                  name: `All Teams`,
                  href: `/all-teams`,
                  startIcon: '',
                },
                {
                  name: `All Members`,
                  href: `/org-settings?manageusers=true`,
                  startIcon: '',
                },
                {
                  name: `Ask Donna`,
                  href: `/settings?t=donna-presets`,
                  startIcon: '',
                  highlighted: new RegExp(
                    `/settings?t=donna-presets`,
                    'i',
                  ).test(pathname),
                },
                {
                  name: `Plans`,
                  href: `/pricing`,
                  startIcon: '',
                  highlighted: new RegExp(`/pricing`, 'i').test(pathname),
                },
                // {
                //   name: `Organization Settings`,
                //   href: `/settings?t=integrations`,
                //   startIcon: '',
                //   highlighted:
                //     (new RegExp(`/settings`, 'i').test(pathname) &&
                //       !new RegExp(`/customer/settings`, 'i').test(pathname)) ||
                //     new RegExp(`/pricing`, 'i').test(pathname),
                // },
              ]}
              href={'/all-teams'}
              highlighted={
                (new RegExp(`/settings`, 'i').test(pathname) &&
                  !new RegExp(`/customer/settings`, 'i').test(pathname)) ||
                new RegExp(`/pricing`, 'i').test(pathname) ||
                new RegExp(`/all-teams`, 'i').test(pathname) ||
                new RegExp(`/org-settings`, 'i').test(pathname)
              }
              startIcon={
                <CorporateFare
                  style={{
                    color:
                      (new RegExp(`/settings`, 'i').test(pathname) &&
                        !new RegExp(`/customer/settings`, 'i').test(
                          pathname,
                        )) ||
                      new RegExp(`/pricing`, 'i').test(pathname) ||
                      new RegExp(`/all-teams`, 'i').test(pathname) ||
                      new RegExp(`/org-settings`, 'i').test(pathname)
                        ? theme.palette.text.primary
                        : theme.palette.text.secondary,
                  }}
                />
              }
              onClick={(e) => {
                handleTrackEvent(e, 'all-teams');
              }}
            />
          </Box>
        ) : null}
        {/* ORG ADMIN */}

        {userInfo?.orgRoles?.[0] === 'WL_ORG_ADMIN' ||
        userInfo?.teamRoles?.[0] === 'TEAM_ADMIN' ||
        userInfo?.orgRoles?.[0] === 'ORG_ADMIN' ? (
          <Box>
            <NavItem
              data-trackid={'myTools'}
              title={' Tools'}
              items={[
                {
                  name: `My Tasks`,
                  href: `/tasks`,
                  startIcon: '',
                },
                {
                  name: `My Performance`,
                  href: `/performance`,
                  startIcon: '',
                },
                {
                  name: `Integrations`,
                  href: `/profile-settings?t=integrations`,
                  startIcon: '',
                  id: 'team-admin-user-integrations',
                },
              ]}
              href={'/tasks'}
              highlighted={
                new RegExp(`/tasks`, 'i').test(pathname) ||
                new RegExp(`/profile-settings`, 'i').test(pathname) ||
                new RegExp(`/performance`, 'i').test(pathname)
              }
              startIcon={
                <SettingsSuggest
                  style={{
                    color:
                      new RegExp(`/tasks`, 'i').test(pathname) ||
                      new RegExp(`/profile-settings`, 'i').test(pathname) ||
                      new RegExp(`/performance`, 'i').test(pathname)
                        ? theme.palette.text.primary
                        : theme.palette.text.secondary,
                  }}
                />
              }
              onClick={(e) => {
                handleTrackEvent(e, 'team-settings');
              }}
            />
          </Box>
        ) : null}

        {/* TEAM ADMIN */}
        {userInfo?.teamRoles?.[0] === 'TEAM_ADMIN' &&
        userInfo?.orgRoles?.[0] !== 'ORG_ADMIN' &&
        userInfo?.orgRoles?.[0] !== 'WL_ORG_ADMIN' ? (
          <Box>
            <NavItem
              data-trackid={'teamSettings'}
              title={loggedInUserTeamDetails?.name || ' My Team'}
              items={[
                {
                  name: `Score Dashboard`,
                  href: `/team-settings?scoresboard=true`,
                  startIcon: '',
                },
                {
                  name: `Manage Members`,
                  href: `/team-settings?manageusers=true`,
                  startIcon: '',
                  id: 'team-admin-manage-members',
                },
                {
                  name: `Team Settings`,
                  href: `/team-settings?teamsettings=true`,
                  startIcon: '',
                  id: 'team-admin-team-settings',
                },
                {
                  name: spectraEmails?.includes(userDetails?.email)
                    ? 'Call Analysis'
                    : `Sales Call Analysis`,
                  href: `/sales-call-analysis`,
                  startIcon: '',
                  highlighted: new RegExp(`/sales-call-analysis`, 'i').test(
                    pathname,
                  ),
                  id: 'team-admin-sales-call-analysis',
                },
                {
                  name: `Private Roleplays`,
                  href: `/conversation-ai`,
                  startIcon: '',
                  highlighted: new RegExp(`/conversation-ai`, 'i').test(
                    pathname,
                  ),
                  id: 'team-admin-private-roleplays',
                },
              ]}
              href={'/team-settings?scoresboard=true'}
              highlighted={
                new RegExp(`/sales-call-analysis`, 'i').test(pathname) ||
                new RegExp(`/conversation-ai`, 'i').test(pathname) ||
                new RegExp(`/team-settings`, 'i').test(pathname)
              }
              startIcon={
                <Groups
                  style={{
                    color:
                      new RegExp(`/sales-call-analysis`, 'i').test(pathname) ||
                      new RegExp(`/conversation-ai`, 'i').test(pathname) ||
                      new RegExp(`/team-settings`, 'i').test(pathname)
                        ? theme.palette.text.primary
                        : theme.palette.text.secondary,
                  }}
                />
              }
              onClick={(e) => {
                handleTrackEvent(e, 'team-settings');
              }}
            />
          </Box>
        ) : null}
        {/* TEAM ADMIN */}

        {/* Demo Org */}
        {userInfo?.custom?.orgType === 'demo_org' ||
        userInfo?.teamRoles?.[0] === 'STANDARD' ? (
          <>
            <NavItem
              data-trackid={'sales_call_analysis'}
              title={'Deals'}
              items={[]}
              href={'/deals'}
              startIcon={
                <MonetizationOn
                  style={{
                    color: new RegExp(`/deals`, 'i').test(pathname)
                      ? theme.palette.text.primary
                      : theme.palette.text.secondary,
                  }}
                />
              }
              onClick={(e) => {
                handleTrackEvent(e, 'deals');
              }}
            />
            {Object.keys(userDetails?.plan?.features || {})?.find(
              (feature) => feature === 'call_analysis',
            ) ? (
              <Box>
                <NavItem
                  data-trackid={'sales_call_analysis'}
                  title={
                    spectraEmails?.includes(userDetails?.email)
                      ? ' Call Analysis'
                      : ' Sales Call Analysis'
                  }
                  items={[]}
                  href={'/sales-call-analysis'}
                  startIcon={
                    <Troubleshoot
                      style={{
                        color: new RegExp(`/sales-call-analysis`, 'i').test(
                          pathname,
                        )
                          ? theme.palette.text.primary
                          : theme.palette.text.secondary,
                      }}
                    />
                  }
                  onClick={(e) => {
                    handleTrackEvent(e, 'sales_call_analysis');
                  }}
                />
              </Box>
            ) : null}
            {Object.keys(userDetails?.plan?.features || {})?.find(
              (feature) => feature === 'practice_call',
            ) ? (
              <Box>
                <NavItem
                  data-trackid={'conversation_ai'}
                  title={' Private Roleplays'}
                  items={
                    [
                      // {
                      //   name: `All Roleplays`,
                      //   href: `/conversation-ai`,
                      //   startIcon: '',
                      // },
                      // {
                      //   name: `Start Roleplay`,
                      //   href: `/conversation-ai?new=true`,
                      //   id: 'private-roleplays-start-analysis',
                      //   startIcon: '',
                      // },
                    ]
                  }
                  href={'/conversation-ai'}
                  startIcon={
                    <RecordVoiceOver
                      style={{
                        color: new RegExp(`/conversation-ai`, 'i').test(
                          pathname,
                        )
                          ? theme.palette.text.primary
                          : theme.palette.text.secondary,
                      }}
                    />
                  }
                  onClick={(e) => {
                    handleTrackEvent(e, 'conversation_ai');
                  }}
                />
              </Box>
            ) : null}
            {Object.keys(userDetails?.plan?.features || {})?.find(
              (feature) => feature === 'call_analysis',
            ) &&
            Object.keys(userDetails?.plan?.features || {})?.find(
              (feature) => feature === 'task',
            ) ? (
              <Box>
                <NavItem
                  data-trackid={'tasks'}
                  title={' My Tasks'}
                  items={
                    [
                      // {
                      //   name: `Action Items`,
                      //   href: `/action-items`,
                      //   startIcon: '',
                      // },
                      // {
                      //   name: `Create New Action`,
                      //   href: `/action-items?new=true`,
                      //   startIcon: '',
                      // },
                    ]
                  }
                  href={'/tasks'}
                  startIcon={
                    <TaskAlt
                      style={{
                        color: new RegExp(`/tasks`, 'i').test(pathname)
                          ? theme.palette.text.primary
                          : theme.palette.text.secondary,
                      }}
                    />
                  }
                  onClick={(e) => {
                    handleTrackEvent(e, 'tasks');
                  }}
                />
              </Box>
            ) : null}
            {userInfo?.custom?.orgType !== 'demo_org' ? (
              <Box>
                <NavItem
                  data-trackid={'performance'}
                  title={' My Performance'}
                  items={
                    [
                      // {
                      //   name: `All Conversations`,
                      //   href: `/conversation-ai`,
                      //   startIcon: '',
                      // },
                      // {
                      //   name: `New Conversation`,
                      //   href: `/conversation-ai?new=true`,
                      //   startIcon: '',
                      // },
                    ]
                  }
                  href={'/performance'}
                  startIcon={
                    <AutoGraph
                      style={{
                        color: new RegExp(`/performance`, 'i').test(pathname)
                          ? theme.palette.text.primary
                          : theme.palette.text.secondary,
                      }}
                    />
                  }
                  onClick={(e) => {
                    handleTrackEvent(e, 'performance');
                  }}
                />
              </Box>
            ) : null}
            <Box>
              <NavItem
                data-trackid={'integrations'}
                title={' Integrations'}
                id="standard-user-integrations"
                items={
                  [
                    // {
                    //   name: `All Conversations`,
                    //   href: `/conversation-ai`,
                    //   startIcon: '',
                    // },
                    // {
                    //   name: `New Conversation`,
                    //   href: `/conversation-ai?new=true`,
                    //   startIcon: '',
                    // },
                  ]
                }
                href={'/integrations'}
                startIcon={
                  <SettingsSuggest
                    style={{
                      color: new RegExp(`/integrations`, 'i').test(pathname)
                        ? theme.palette.text.primary
                        : theme.palette.text.secondary,
                    }}
                  />
                }
                onClick={(e) => {
                  handleTrackEvent(e, 'intergrations');
                }}
              />
            </Box>
            {userInfo?.teamRoles?.[0] === 'STANDARD' ? (
              <Box>
                <NavItem
                  data-trackid={'playbook'}
                  title={' Playbook'}
                  id="standard-user-playbook-settings"
                  items={
                    [
                      // {
                      //   name: `All Conversations`,
                      //   href: `/conversation-ai`,
                      //   startIcon: '',
                      // },
                      // {
                      //   name: `New Conversation`,
                      //   href: `/conversation-ai?new=true`,
                      //   startIcon: '',
                      // },
                    ]
                  }
                  href={'/playbook'}
                  startIcon={
                    <Article
                      style={{
                        color: new RegExp(`/playbook`, 'i').test(pathname)
                          ? theme.palette.text.primary
                          : theme.palette.text.secondary,
                      }}
                    />
                  }
                  onClick={(e) => {
                    handleTrackEvent(e, 'playbook');
                  }}
                />
              </Box>
            ) : null}
          </>
        ) : null}
        {/* Demo Org */}

        {userInfo?.orgRoles?.[0] === 'WL_ORG_ADMIN' ||
        userInfo?.orgRoles?.[0] === 'ORG_ADMIN' ? (
          <Box>
            {/* {currentTeamToManage ? (
              <NavItem
                data-trackid={'currentTeamToManage'}
                title={` ${currentTeamToManage?.name}`}
                items={[
                  {
                    name: `Score Dashboard`,
                    href: `/teams/${currentTeamToManage?.id}/team-settings?scoresboard=true`,
                    startIcon: '',
                    highlighted: new RegExp(
                      `/teams/${currentTeamToManage?.id}/team-settings?scoresboard=true`,
                      'i',
                    ).test(pathname),
                  },
                  {
                    name: `Manage Members`,
                    href: `/teams/${currentTeamToManage?.id}/team-settings?manageusers=true`,
                    startIcon: '',
                    highlighted:
                      new RegExp(
                        `/teams/${currentTeamToManage?.id}/team-settings?manageusers=true`,
                        'i',
                      ).test(pathname) ||
                      new RegExp(
                        `/teams/${currentTeamToManage?.id}/team-settings?manageusers=true&teaminvite=true`,
                        'i',
                      ).test(pathname),
                  },
                  {
                    name: `Team Settings`,
                    href: `/teams/${currentTeamToManage?.id}/team-settings?teamsettings=true`,
                    startIcon: '',
                    highlighted: new RegExp(
                      `/teams/${currentTeamToManage?.id}/team-settings?teamsettings=true`,
                      'i',
                    ).test(pathname),
                  },
                  {
                    name: `Sales Call Analysis`,
                    href: `/teams/${currentTeamToManage?.id}/sales-call-analysis`,
                    startIcon: '',
                    highlighted: new RegExp(
                      `/teams/${currentTeamToManage?.id}/sales-call-analysis`,
                      'i',
                    ).test(pathname),
                  },
                  {
                    name: `Private Roleplays`,
                    href: `/teams/${currentTeamToManage?.id}/conversation-ai`,
                    startIcon: '',
                    highlighted: new RegExp(
                      `/teams/${currentTeamToManage?.id}/conversation-ai`,
                      'i',
                    ).test(pathname),
                  },
                ]}
                href={`/teams/${currentTeamToManage?.id}/sales-call-analysis`}
                highlighted={
                  new RegExp(
                    `/teams/${currentTeamToManage?.id}/sales-call-analysis`,
                    'i',
                  ).test(pathname) ||
                  new RegExp(
                    `/teams/${currentTeamToManage?.id}/conversation-ai`,
                    'i',
                  ).test(pathname) ||
                  new RegExp(
                    `/teams/${currentTeamToManage?.id}/team-settings`,
                    'i',
                  ).test(pathname) ||
                  new RegExp(
                    `/teams/${currentTeamToManage?.id}/team-settings`,
                    'i',
                  ).test(pathname) ||
                  new RegExp(
                    `/teams/${currentTeamToManage?.id}/team-settings`,
                    'i',
                  ).test(pathname)
                }
                startIcon={
                  <Groups
                    style={{
                      color:
                        new RegExp(
                          `/teams/${currentTeamToManage?.id}/sales-call-analysis`,
                          'i',
                        ).test(pathname) ||
                        new RegExp(
                          `/teams/${currentTeamToManage?.id}/conversation-ai`,
                          'i',
                        ).test(pathname) ||
                        new RegExp(
                          `/teams/${currentTeamToManage?.id}/team-settings`,
                          'i',
                        ).test(pathname) ||
                        new RegExp(
                          `/teams/${currentTeamToManage?.id}/team-settings`,
                          'i',
                        ).test(pathname) ||
                        new RegExp(
                          `/teams/${currentTeamToManage?.id}/team-settings`,
                          'i',
                        ).test(pathname)
                          ? theme.palette.text.primary
                          : theme.palette.text.secondary,
                    }}
                  />
                }
                onClick={(e) => {
                  handleTrackEvent(e, 'team-settings');
                }}
              />
            ) : null} */}
            {pinnedTeams && pinnedTeams?.length > 0
              ? pinnedTeams
                  ?.sort(
                    (teamA, teamB) => teamB?.defaultTeam - teamA?.defaultTeam,
                  )
                  ?.map((pinnedTeam) => (
                    <>
                      {pinnedTeam ? (
                        <NavItem
                          id={
                            pinnedTeam?.defaultTeam
                              ? 'left-nav-default-team'
                              : 'left-nav-pinned-team'
                          }
                          data-trackid={'pinnedTeam'}
                          title={` ${pinnedTeam?.name}`}
                          forPinnedTeam={true}
                          team={pinnedTeam}
                          items={[
                            {
                              name: `Deals`,
                              href: `/teams/${pinnedTeam?.id}/deals`,
                              startIcon: '',
                              highlighted: new RegExp(
                                `/teams/${pinnedTeam?.id}/deals`,
                                'i',
                              ).test(pathname),
                            },
                            {
                              name: `Score Dashboard`,
                              href: `/teams/${pinnedTeam?.id}/team-settings?scoresboard=true`,
                              startIcon: '',
                              highlighted: new RegExp(
                                `/teams/${pinnedTeam?.id}/team-settings?scoresboard=true`,
                                'i',
                              ).test(pathname),
                            },
                            {
                              name: `Manage Members`,
                              href: `/teams/${pinnedTeam?.id}/team-settings?manageusers=true`,
                              startIcon: '',
                              highlighted:
                                new RegExp(
                                  `/teams/${pinnedTeam?.id}/team-settings?manageusers=true`,
                                  'i',
                                ).test(`${pathname}${search}`) ||
                                new RegExp(
                                  `/teams/${pinnedTeam?.id}/team-settings?manageusers=true&teaminvite=true`,
                                  'i',
                                ).test(`${pathname}${search}`),
                            },
                            {
                              name: `Team Settings`,
                              href: `/teams/${pinnedTeam?.id}/team-settings?teamsettings=true`,
                              startIcon: '',
                              highlighted: new RegExp(
                                `/teams/${pinnedTeam?.id}/team-settings?teamsettings=true`,
                                'i',
                              ).test(pathname),
                            },
                            {
                              name: spectraEmails?.includes(userDetails?.email)
                                ? 'Call Analysis'
                                : `Sales Call Analysis`,
                              href: `/teams/${pinnedTeam?.id}/sales-call-analysis`,
                              startIcon: '',
                              highlighted: new RegExp(
                                `/teams/${pinnedTeam?.id}/sales-call-analysis`,
                                'i',
                              ).test(pathname),
                            },
                            {
                              name: `Private Roleplays`,
                              href: `/teams/${pinnedTeam?.id}/conversation-ai`,
                              startIcon: '',
                              highlighted: new RegExp(
                                `/teams/${pinnedTeam?.id}/conversation-ai`,
                                'i',
                              ).test(pathname),
                            },
                          ]}
                          href={`/teams/${pinnedTeam?.id}/team-settings?scoresboard=true`}
                          highlighted={
                            new RegExp(
                              `/teams/${pinnedTeam?.id}/sales-call-analysis`,
                              'i',
                            ).test(pathname) ||
                            new RegExp(
                              `/teams/${pinnedTeam?.id}/conversation-ai`,
                              'i',
                            ).test(pathname) ||
                            new RegExp(
                              `/teams/${pinnedTeam?.id}/team-settings`,
                              'i',
                            ).test(pathname)
                          }
                          startIcon={
                            <Groups
                              style={{
                                color:
                                  new RegExp(
                                    `/teams/${pinnedTeam?.id}/sales-call-analysis`,
                                    'i',
                                  ).test(pathname) ||
                                  new RegExp(
                                    `/teams/${pinnedTeam?.id}/conversation-ai`,
                                    'i',
                                  ).test(pathname) ||
                                  new RegExp(
                                    `/teams/${pinnedTeam?.id}/team-settings`,
                                    'i',
                                  ).test(pathname)
                                    ? theme.palette.text.primary
                                    : theme.palette.text.secondary,
                              }}
                            />
                          }
                          onClick={(e) => {
                            handleTrackEvent(e, 'team-settings');
                          }}
                        />
                      ) : null}
                    </>
                  ))
              : null}
          </Box>
        ) : null}
      </Box>
      <Box marginTop={'auto'} display={'flex'} flexDirection={'column'}>
        <Divider
          orientation="horizontal"
          sx={{
            width: '100%',
            height: '1px',
          }}
        />
        <Box p={1} display={'flex'} flexDirection={'column'}>
          {expanded ? (
            (window.location.host !== 'app.qualification.ai' &&
              window.location.host !== 'app.sellingbees.com' &&
              window.location.host !== 'localhost:3000') ||
            userInfo?.custom?.orgType === 'w_org' ? (
              <Box
                sx={
                  {
                    // padding: '4px 8px',
                  }
                }
              >
                <Typography variant="caption" color={'text.primary'}>
                  Powered by{' '}
                  <span style={{ fontWeight: 'bold' }}>Qualification AI</span>
                </Typography>
              </Box>
            ) : null
          ) : null}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              // gap: 1,
            }}
          >
            <Link
              underline="none"
              component="a"
              href="/privacy"
              // color="text.primary"
              variant={'caption'}
            >
              Privacy Policy
            </Link>
            <Link
              underline="none"
              component="a"
              href="/tos"
              // color="text.primary"
              variant={'caption'}
            >
              Terms of Service
            </Link>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

SidebarNav.propTypes = {
  onClose: PropTypes.func,
  expanded: PropTypes.bool,
};

export default SidebarNav;
