import axios from 'utils/axios';
import { v4 as uuidv4 } from 'uuid';

const sendResetPasswordLink = (email, callback, errCb) => {
  return async (dispatch) => {
    dispatch({ type: 'SENDING_RESET_PASSWORD_LINK' });
    try {
      const reqId = uuidv4();
      const response = await axios.post(`/us/users/reset-password`, email, {
        params: {
          'request-id': reqId,
        },
        headers: {
          'Content-Type': 'plain/text',
        },
      });
      if (response.status === 200) {
        await dispatch({
          type: 'SENDING_RESET_PASSWORD_LINK_DONE',
        });
        if (typeof callback === 'function') {
          callback(response?.data);
        }
      } else {
        dispatch({
          type: 'SENDING_RESET_PASSWORD_LINK_FAILED',
          payload: `Failed to send reset password email.`,
        });
        if (typeof errCb === 'function') {
          errCb();
        }
      }
    } catch (e) {
      dispatch({
        type: 'SENDING_RESET_PASSWORD_LINK_FAILED',
        payload: `Failed to send reset password email.`,
      });
      if (typeof errCb === 'function') {
        errCb();
      }
    }
  };
};

export default sendResetPasswordLink;
