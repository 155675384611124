import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { alpha, useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import { Divider, Typography } from '@mui/material';
import { useSelector } from 'react-redux';

// import { NavItem } from './components';

const Topbar = ({
  onSidebarOpen,
  //  pages, colorInvert = false
}) => {
  const theme = useTheme();
  // const { mode } = theme.palette;
  const { fetching, domainDetails } = useSelector(
    (state) => state.whitelabelDetails,
  );
  // const {
  //   landings: landingPages,
  //   secondary: secondaryPages,
  //   company: companyPages,
  //   account: accountPages,
  //   portfolio: portfolioPages,
  //   blog: blogPages,
  // } = pages;

  return (
    <Box
      display={'flex'}
      justifyContent={'space-between'}
      alignItems={'center'}
      width={1}
    >
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <Box
          display={'flex'}
          component="a"
          href={
            domainDetails?.organizationWebsite
              ? `https://${domainDetails?.organizationWebsite}`
              : 'https://qualification.ai'
          }
          title={domainDetails?.appTitle || 'Qualification AI'}
          height={{ xs: 50, md: 50 }}
        >
          <Box
            component={'img'}
            src={
              theme.palette.mode === 'dark'
                ? '/dark_theme_logo.svg'
                : domainDetails?.textInLogo
                ? domainDetails?.logo || '/light_theme_logo.svg'
                : domainDetails?.logoWithoutText || '/light_theme_logo.svg'
            }
            height={1}
            width={1}
          />
        </Box>
        {domainDetails?.textInLogo ? null : (
          <Box ml={2}>
            <Typography color="text.primary" variant="h6" fontWeight={'600'}>
              {domainDetails?.appTitle || 'Qualification AI'}
            </Typography>
            <Divider
              sx={{ border: `0.5px solid ${theme.palette.text.primary}` }}
            />
            <Typography variant="caption" color="text.primary">
              {domainDetails?.appSubTitle || 'Makes Practice Perfect'}
            </Typography>
          </Box>
        )}
      </Box>
      <Box sx={{ display: { xs: 'none', md: 'flex' } }} alignItems={'center'}>
        <Box marginLeft={4}>
          {/* <Button
            size={'large'}
            variant="outlined"
            fullWidth
            component="a"
            href="/auth/signin"
          >
            Sign In / Sign Up
          </Button> */}
        </Box>
      </Box>
      <Box sx={{ display: { xs: 'block', md: 'none' } }} alignItems={'center'}>
        <Button
          onClick={() => onSidebarOpen()}
          aria-label="Menu"
          variant={'outlined'}
          sx={{
            borderRadius: 2,
            minWidth: 'auto',
            padding: 1,
            borderColor: alpha(theme.palette.divider, 0.2),
          }}
        >
          <MenuIcon />
        </Button>
      </Box>
    </Box>
  );
};

Topbar.propTypes = {
  onSidebarOpen: PropTypes.func,
  colorInvert: PropTypes.bool,
};

export default Topbar;
