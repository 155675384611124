import {
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
  useTheme,
  Modal,
  Divider,
  Link,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tabs,
  Tab,
  Chip,
  useMediaQuery,
  DialogActions,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Tooltip,
  InputAdornment,
  FormLabel,
  FormControlLabel,
  Checkbox,
  FormGroup,
  RadioGroup,
  Radio,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  Drawer,
  Alert,
  Autocomplete,
  Badge,
  Popper,
} from '@mui/material';
import React, { memo, useEffect, useRef, useState } from 'react';
import Container from 'components/Container';
import { useDispatch, useSelector } from 'react-redux';
import {
  Add,
  Archive,
  ArrowBack,
  Article,
  Assessment,
  Assistant,
  AudioFile,
  Audiotrack,
  AutoAwesome,
  BookmarkAdd,
  Cached,
  Chat,
  Check,
  ChevronRight,
  Close,
  CloudUpload,
  Delete,
  Edit,
  ErrorOutline,
  FilterList,
  FindReplace,
  Info,
  KeyboardArrowDown,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  KeyboardArrowUp,
  Lock,
  Loop,
  Mic,
  PlayArrow,
  Redo,
  Refresh,
  Replay,
  Search,
  Send,
  StopCircle,
  Subject,
  Sync,
  TaskAlt,
  Timeline,
  Troubleshoot,
  Unarchive,
  Warning,
  WarningAmber,
} from '@mui/icons-material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useHistory } from 'react-router-dom';
//actions
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { detect } from 'detect-browser';
import moment from 'moment';
import showAlert from 'redux/actions/Common/showAlert';

import { PropTypes } from 'prop-types';
import { Bar, Pie } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip as TooltipChart,
  Legend,
  ArcElement,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import parse from 'html-react-parser';
import getUserDetails from 'redux/actions/Common/getUserDetails';
import trackEvent from 'redux/actions/Common/trackEvent';
import logError from 'redux/actions/Common/logError';
import { capitalizeText, formatSeconds, validateEmail } from 'utils';
import getOrganizationConfigs from 'redux/actions/Common/getOrganizationConfigs';
import updateGlobalOrganizationConfig from 'redux/actions/Common/updateGlobalOrganizationConfig';
import syncDataWithHubspot from 'redux/actions/Common/syncDataWithHubspot';
import MediaPreview from 'components/@2024/MediaPreview';
import ReportPage from 'pages/ReportPage';
import getPlaybookHistory from 'redux/actions/Common/getPlaybookHistory';
import getMessageResponse from 'redux/actions/Candidate/getMessageResponse';
import ProfileCompletion from 'components/ProfileCompletion';
import updateSalesReviewData from 'redux/actions/Common/updateSalesReviewData';
import reAnalyzeSalesCallv2 from 'redux/actions/Common/reAnalyzeSalesCallv2';
import getRemainingCredits from 'redux/actions/Common/getRemainingCredits';
import AutoCompleteMUI from 'components/@2024/AutoCompleteMUI';
import { v4 as uuidv4 } from 'uuid';
import updateTeam from 'redux/actions/Common/updateTeam';
import {
  DatePicker,
  DateTimePicker,
  LocalizationProvider,
} from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import getDeals from 'redux/actions/Common/getDeals';
import createNewDeal from 'redux/actions/Candidate/createNewDeal';
import getAllTeamMembers from 'redux/actions/Common/getAllTeamMembers';
import deleteDeal from 'redux/actions/Candidate/deleteDeal';
import updateDeal from 'redux/actions/Candidate/updateDeal';
import crmHubspotSync from 'redux/actions/Common/crmHubspotSync';
import generateDeal from 'redux/actions/Candidate/generateDeal';

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  TooltipChart,
  Legend,
  ChartDataLabels,
);

const VideoPreview = memo(
  function VideoPreview({ selectedAudio, onDurationChange }) {
    return (
      <Box
        // flex={1}
        display={'flex'}
        flexDirection={'column'}
        sx={{ alignItems: 'center', justifyContent: 'flex-start' }}
      >
        {selectedAudio ? (
          selectedAudio?.type?.split('/')?.[0] === 'audio' ? (
            <Box display={'flex'} flexDirection={'column'} gap={1}>
              <Typography
                variant="body2"
                color={'text.secondary'}
                textAlign={'center'}
              >
                Selected File
              </Typography>
              <audio
                preload
                src={window.URL.createObjectURL(selectedAudio)}
                controls
                onDurationChange={onDurationChange}
              />
            </Box>
          ) : (
            <Box
              width={'90%'}
              display={'flex'}
              flexDirection={'column'}
              gap={1}
            >
              <Typography
                variant="body2"
                color={'text.secondary'}
                textAlign={'center'}
              >
                Selected File
              </Typography>
              <video
                style={{ width: '100%' }}
                preload={false}
                src={window.URL.createObjectURL(selectedAudio)}
                controls
                controlsList="nodownload"
                disablePictureInPicture
                onDurationChange={onDurationChange}
              />
            </Box>
          )
        ) : null}
      </Box>
    );
  },
  (pre, post) => {
    return pre?.selectedAudio === post?.selectedAudio;
  },
);

const activeUUID = uuidv4();

const Deals = ({ uId, member, parentComponent }) => {
  const theme = useTheme();
  const history = useHistory();
  const browser = detect();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const pathParams = useParams();
  const params = new URLSearchParams(window.location.search);
  const { isLoggedIn } = useSelector((state) => state.firebaseSession);
  const userInfo = JSON.parse(localStorage.getItem('userInfo') || '{}');
  const organization = JSON.parse(localStorage.getItem('organization') || '{}');
  const { dashboardSidebarExpanded } = useSelector((state) => state.uiStates);
  const teamId = localStorage.getItem('teamId') || pathParams?.teamId;
  const { uploadingFor, uploadPercentage } = useSelector(
    (state) => state.createSalesAnalysisThreadState,
  );
  const chatsScrollview = useRef(null);
  const { orgConfigs } = useSelector((state) => state.orgConfigs);
  const {
    deals,
    fetching,
    fetchingPagination,
    lastSeen,
    lastTime,
    emptyList,
  } = useSelector((state) => state.deals);
  const { userDetails } = useSelector((state) => state.userDetails);
  const { members } = useSelector((state) => state.allTeamMembers);
  const {
    showCompetitionDetailsOnTopbar,
    currentContextData,
    showBackButtonOnTopbar,
    currentNavigationLevel,
    creditsRemaining,
    loggedInUserTeamDetails,
    pinnedTeams,
  } = useSelector((state) => state.uiStates);
  const [loading, setLoading] = useState(false);
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const isXs = useMediaQuery(theme.breakpoints.only('xs'), {
    defaultMatches: true,
  });
  const isSm = useMediaQuery(theme.breakpoints.only('sm'), {
    defaultMatches: true,
  });

  const [currentTab, setCurrentTab] = useState('active');

  const [anchorElDetails, setAnchorElDetails] = useState(null);
  const [popperDetails, setPopperDetails] = useState(null);
  const [activeAccount, setActiveAccount] = useState(activeUUID);

  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);

  const [anchorElAddAccount, setAnchorElAddAccount] = useState(null);
  const openAddAccountMenu = Boolean(anchorElAddAccount);

  const [viewType, setViewType] = useState('list');

  const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState(null);
  const [createNewDealDialog, setCreateNewDealDialog] = useState(false);

  const [accountName, setAccountName] = useState('');
  const [dealName, setDealName] = useState('');
  const [dealSize, setDealSize] = useState(0);
  const [dealSizeUnit, setDealSizeUnit] = useState('usd');
  const [dealContact, setDealContact] = useState('');
  const [dealDescription, setDealDescription] = useState('');
  const [creatingDeal, setCreatingDeal] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [goLiveOn, setGoLiveOn] = useState(new Date().getTime());
  const [dealClosed, setDealClosed] = useState(false);
  const [dealCloseDate, setDealCloseDate] = useState(new Date().getTime());
  const [dealStage, setDealStage] = useState('');

  const [activeDeal, setActiveDeal] = useState(null);
  const [activeDealIndex, setActiveDealIndex] = useState(0);
  const [syncingCrmDeals, setSyncingCrmDeals] = useState(false);

  const [generatingDealSummary, setGeneratingDealSummary] = useState(false);

  const handleTrackEvent = (e, type) => {
    try {
      dispatch(
        trackEvent({
          context: 'click_event',
          eventType: type,
          userId: isLoggedIn ? userInfo?.id : null,
          time: new Date().getTime(),
          os: browser?.os,
          sessionId: isLoggedIn
            ? userInfo?.id
            : sessionStorage.getItem('trackingSessionId'),
          customData: {
            page_path: pathname,
            browser: browser?.name,
            browser_version: browser?.version,
          },
        }),
      );
    } catch (e) {
      console.log(e);
      dispatch(
        logError({
          message: e?.message,
          data: {
            formatted: {
              errorText: e?.message,
              severity: 'error',
            },
            raw: e,
          },
        }),
      );
    }
  };

  const handleListScroll = (e, paginate) => {
    // console.log(e.target.scrollTop + e.target.clientHeight);
    // console.log(e.target.clientHeight);
    // console.log(e.target.scrollHeight);
    // console.log(
    //   e.target.scrollTop + e.target.clientHeight + 1 >= e.target.scrollHeight,
    // );
    if (
      (e.target.scrollTop + e.target.clientHeight + 1 >=
        e.target.scrollHeight &&
        // viewType === 'list' &&
        !fetching &&
        !fetchingPagination &&
        deals &&
        deals.length % 10 === 0 &&
        emptyList === false) ||
      paginate
    ) {
      if (uId) {
        dispatch(
          getDeals(
            {
              archived: currentTab === 'archived' ? true : false,
              size: 10,
              lastSeen: lastSeen,
              lastTime: lastTime,
            },
            // {
            //   accountTagId: activeAccount !== activeUUID ? activeAccount : null,
            //   userId: uId,
            //   // keywords: searchKeywords?.join(','),
            //   lastSeen: lastSeen,
            //   lastTime: lastTime,
            //   size: 10,
            //   teamId: teamId || null,
            // },
            () => {},
          ),
        );
      } else {
        dispatch(
          getDeals(
            {
              archived: currentTab === 'archived' ? true : false,
              size: 10,
              lastSeen: lastSeen,
              lastTime: lastTime,
            },
            () => {},
          ),
        );
      }
    }
  };

  useEffect(() => {
    // if (currentTab === 'create') {
    if (teamId) {
      // console.log('coming here');
      // console.log(teamId);
      // console.log(pinnedTeams);
      if (pinnedTeams && pinnedTeams?.length > 0) {
        setAccounts(
          Object.keys(
            pinnedTeams?.find((team) => team?.id === teamId)?.accountTagMap ||
              {},
          )?.map((key) => {
            return {
              id: key,
              name: pinnedTeams?.find((team) => team?.id === teamId)
                ?.accountTagMap?.[key],
            };
          }) || [],
        );
      } else {
        setAccounts(
          Object.keys(loggedInUserTeamDetails?.accountTagMap || {})?.map(
            (key) => {
              return {
                id: key,
                name: loggedInUserTeamDetails?.accountTagMap?.[key],
              };
            },
          ) || [],
        );
      }
    } else {
      if (orgConfigs) {
        setAccounts(
          Object.keys(orgConfigs?.accountTagMap || {})?.map((key) => {
            return {
              id: key,
              name: orgConfigs?.accountTagMap?.[key],
            };
          }) || [],
        );
      }
    }
    // }
  }, [orgConfigs, teamId, loggedInUserTeamDetails, pinnedTeams]);

  useEffect(() => {
    if (createNewDealDialog && typeof createNewDealDialog === 'object') {
      setDealName(createNewDealDialog?.name);
      setDealDescription(createNewDealDialog?.description);
      setGoLiveOn(createNewDealDialog?.goLiveOn || new Date().getTime());
      setDealCloseDate(createNewDealDialog?.closeOn || new Date().getTime());
      setDealStage(createNewDealDialog?.stage);
      setDealContact(createNewDealDialog?.contact?.email);
      setDealSize(createNewDealDialog?.amount?.amount);
      setDealSizeUnit(createNewDealDialog?.amount?.currency);
      setAccountName(
        accounts?.find((ac) => ac?.id === createNewDealDialog?.accountId)?.name,
      );
      setDealClosed(createNewDealDialog?.closeOn || false);
      setDealCloseDate(createNewDealDialog?.closeOn || new Date().getTime());
    }
  }, [createNewDealDialog]);

  useEffect(() => {
    // if (currentTab === 'list' && viewType === 'analysis') {
    if (uId) {
      dispatch(
        getDeals(
          { archived: currentTab === 'archived' ? true : false, size: 10 },
          // {
          //   accountTagId: activeAccount !== activeUUID ? activeAccount : null,
          //   userId: uId,
          //   // keywords: searchKeywords?.join(','),
          //   lastTime: lastTime,
          //   size: 10,
          //   teamId: teamId || null,
          // },
          () => {},
        ),
      );
    } else {
      dispatch(
        getDeals(
          { archived: currentTab === 'archived' ? true : false, size: 10 },
          () => {},
        ),
      );
    }
    // }
  }, [currentTab, viewType, teamId]);

  useEffect(() => {
    if (!showBackButtonOnTopbar) {
      setViewType('list');
      setCurrentTab('active');
      setActiveDealIndex(0);
    }
  }, [showBackButtonOnTopbar]);

  useEffect(() => {
    if (viewType === 'report') {
      dispatch({
        type: 'UPDATE_UI_STATE',
        payload: {
          key: 'showBackButtonOnTopbar',
          value: true,
        },
      });
      // dispatch({
      //   type: 'UPDATE_UI_STATE',
      //   payload: {
      //     key: 'currentContextData',
      //     value: {
      //       ...currentContextData,
      //       title: salesReview?.title || '',
      //     },
      //   },
      // });
    } else {
      if (!(params && params.get('new') === 'true') && !uId) {
        // history.push('/sales-call-analysis');
      }
      if (!uId) {
        dispatch({
          type: 'UPDATE_UI_STATE',
          payload: {
            key: 'showBackButtonOnTopbar',
            value: false,
          },
        });
        dispatch({
          type: 'UPDATE_UI_STATE',
          payload: {
            key: 'currentContextData',
            value: {
              ...currentContextData,
              title: '',
            },
          },
        });
      }
    }
  }, [viewType]);

  useEffect(() => {
    if (member && uId) {
      dispatch({
        type: 'UPDATE_UI_STATE',
        payload: {
          key: 'currentContextData',
          value: {
            ...currentContextData,
            name: member?.fullName,
            email: member?.email,
          },
        },
      });
    }
  }, [member, uId]);

  useEffect(() => {
    if (
      (parentComponent === 'teamSettings' && currentNavigationLevel > 2) ||
      (parentComponent === 'customerSettings' && currentNavigationLevel > 3)
    ) {
      setViewType('report');
    } else {
      setViewType('list');
    }
  }, [currentNavigationLevel]);

  useEffect(() => {
    setActiveDeal(deals?.[activeDealIndex]);
  }, [activeDealIndex, deals]);

  useEffect(() => {
    if (params.get('new') === 'true') {
      setCreateNewDealDialog(true);
    }
    if (uId) {
      console.log('uId', uId);
    } else {
      dispatch({
        type: 'UPDATE_UI_STATE',
        payload: {
          key: 'currentContextData',
          value: null,
        },
      });
      dispatch(
        getAllTeamMembers(
          teamId || null,
          null,
          true,
          0,
          25,
          null,
          (data) => {},
        ),
      );
      dispatch(getOrganizationConfigs((data) => {}));
      dispatch(
        getUserDetails((data) => {
          if (data?.custom?.plan?.price?.pricingScheme === 'usage_based') {
            dispatch(
              getRemainingCredits((data) => {
                // console.log(data);
                dispatch({
                  type: 'UPDATE_UI_STATE',
                  payload: {
                    key: 'creditsRemaining',
                    value: data,
                  },
                });
              }),
            );
          }
        }),
      );
    }
    return () => {
      dispatch({
        type: 'UPDATE_UI_STATE',
        payload: {
          key: 'showBackButtonOnTopbar',
          value: false,
        },
      });
      dispatch({
        type: 'UPDATE_UI_STATE',
        payload: {
          key: 'currentContextData',
          value: null,
        },
      });
    };
  }, []);

  // console.log(teamId);
  // console.log(selectedDeal);
  // console.log(hubspotDeals);
  // console.log(orgConfigs?.hubspotDealProperties);
  // console.log(propertiesChanged);
  // console.log(propertiesUnchaged);

  // console.log(dealContacts);
  // console.log(propertiesChanged);
  // console.log(teamId);
  // console.log(lastSeen);
  // console.log(lastTime);

  return (
    <Box
      sx={{
        width: {
          xs: '100%',
          sm: isLoggedIn ? 'calc(100% - 80px)' : 1,
          md:
            isLoggedIn && !uId
              ? dashboardSidebarExpanded
                ? 'calc(100% - 240px)'
                : 'calc(100% - 80px)'
              : 1,
        },
        marginLeft: {
          xs: '0px',
          sm: isLoggedIn ? '80px' : 0,
          md:
            isLoggedIn && !uId
              ? dashboardSidebarExpanded
                ? '240px'
                : '80px'
              : 0,
        },
        height: 1,
        minHeight:
          isLoggedIn && !uId ? 'calc(100vh - 60px)' : 'calc(100vh - 146px)',
      }}
    >
      <Box
        display={'flex'}
        flexDirection={{ xs: 'column', sm: 'column', md: 'row' }}
        height={1}
      >
        <Box
          flex={{ xs: 'auto', sm: 'auto', md: 1 }}
          display={'flex'}
          flexDirection={{ xs: 'row', sm: 'row', md: 'column' }}
          alignItems={{
            xs: 'start',
            sm: 'start',
            md: currentTab === 'create' ? 'center' : 'start',
          }}
          justifyContent={{
            xs: 'start',
            sm: 'start',
            md: currentTab === 'create' ? 'center' : 'flex-start',
          }}
          height={1}
          gap={2}
          sx={{
            minHeight: isXs
              ? 'auto'
              : viewType === 'report'
              ? 'calc(100vh - 130px)'
              : 'calc(100vh - 60px)',
            padding: 2,
            paddingTop: 1,
          }}
        >
          <Box
            display={'flex'}
            flexDirection={'column'}
            width={1}
            border={'1px solid #d3d3d3'}
            borderRadius={2}
          >
            {viewType === 'list' ? (
              <>
                <Box
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'space-between'}
                  gap={1}
                >
                  <Tabs
                    value={currentTab}
                    onChange={(e, newValue) => setCurrentTab(newValue)}
                    aria-label="Deals Tabs"
                    orientation={'horizontal'}
                    variant="scrollable"
                    allowScrollButtonsMobile
                    sx={{
                      flex: 1,
                    }}
                  >
                    <Tab
                      value={'active'}
                      label={
                        <Typography variant="body1">Active Deals</Typography>
                      }
                      id={`simple-tab-${0}`}
                      aria-controls={`simple-tabpanel-${0}`}
                      sx={{}}
                    />
                    <Tab
                      value={'archived'}
                      label={
                        <Typography variant="body1">Archived Deals</Typography>
                      }
                      id={`simple-tab-${5}`}
                      aria-controls={`simple-tabpanel-${5}`}
                      sx={{}}
                    />
                  </Tabs>
                  {isXs ||
                  userDetails?.orgRoles?.[0] === 'ORG_ADMIN' ||
                  userDetails?.teamRoles?.[0] === 'TEAM_ADMIN' ||
                  userDetails?.teamRoles?.[0] === 'STANDARD' ||
                  userDetails?.orgRoles?.[0] === 'WL_ORG_ADMIN' ? (
                    <Box
                      display={'flex'}
                      alignItems={'center'}
                      justifyContent={'flex-end'}
                      gap={1}
                      marginRight={1}
                    >
                      {userDetails?.custom?.integration?.hubSpot ||
                      userDetails?.custom?.integration?.pipedriveCrm ||
                      userDetails?.custom?.integration?.zohoCrm ? (
                        <Button
                          startIcon={
                            syncingCrmDeals ? (
                              <CircularProgress size={20} />
                            ) : (
                              <Sync />
                            )
                          }
                          variant="contained"
                          color="primary"
                          size="small"
                          disabled={syncingCrmDeals}
                          // sx={{ marginLeft: 'auto' }}
                          onClick={() => {
                            setSyncingCrmDeals(true);
                            dispatch(
                              crmHubspotSync(
                                () => {
                                  setSyncingCrmDeals(false);
                                  dispatch(
                                    showAlert({
                                      message:
                                        'Deals are synced with CRM successfully.',
                                      showCross: true,
                                      title: null,
                                      type: 'success',
                                      autoHideDuration: 2000,
                                      vertical: 'top',
                                      horizontal: 'center',
                                    }),
                                  );
                                  dispatch(
                                    getDeals(
                                      {
                                        archived:
                                          currentTab === 'archived'
                                            ? true
                                            : false,
                                        size: 10,
                                      },
                                      (data) => {},
                                    ),
                                  );
                                },
                                () => {
                                  setSyncingCrmDeals(false);
                                  dispatch(
                                    showAlert({
                                      message:
                                        'Failed to sync deals to CRM. Please try again.',
                                      showCross: true,
                                      title: null,
                                      type: 'error',
                                      autoHideDuration: 2000,
                                      vertical: 'top',
                                      horizontal: 'center',
                                    }),
                                  );
                                },
                              ),
                            );
                          }}
                        >
                          CRM Sync
                        </Button>
                      ) : (
                        <Button
                          startIcon={<Add />}
                          variant="contained"
                          color="primary"
                          size="small"
                          // sx={{ marginLeft: 'auto' }}
                          onClick={() => {
                            setCreateNewDealDialog(true);
                          }}
                        >
                          New Deal
                        </Button>
                      )}
                    </Box>
                  ) : null}
                </Box>
                <Box
                  sx={{
                    borderTop: '1px solid #d3d3d3',
                    minHeight: 'calc(100vh - 135px)',
                  }}
                >
                  {fetching ? (
                    <Box
                      width={1}
                      height={1}
                      display={'flex'}
                      justifyContent={'center'}
                      alignItems={'center'}
                      flexDirection={'column'}
                    >
                      <CircularProgress size={20} />
                      <Typography variant="body1" color={'text.primary'} mt={4}>
                        Loading ...
                      </Typography>
                    </Box>
                  ) : deals && deals?.length > 0 ? (
                    <Box display={'flex'} flexDirection={'column'} gap={1}>
                      <TableContainer
                        component={Paper}
                        sx={{
                          minHeight: uId
                            ? 'calc(100vh - 90px)'
                            : (isXs ||
                                userDetails?.orgRoles?.[0] === 'ORG_ADMIN' ||
                                userDetails?.teamRoles?.[0] === 'TEAM_ADMIN' ||
                                userDetails?.orgRoles?.[0] ===
                                  'WL_ORG_ADMIN') &&
                              deals &&
                              deals?.length > 0
                            ? 'calc(100vh - 130px)'
                            : 'calc(100vh - 90px)',
                          maxHeight: uId
                            ? 'calc(100vh - 90px)'
                            : (isXs ||
                                userDetails?.orgRoles?.[0] === 'ORG_ADMIN' ||
                                userDetails?.teamRoles?.[0] === 'TEAM_ADMIN' ||
                                userDetails?.orgRoles?.[0] ===
                                  'WL_ORG_ADMIN') &&
                              deals &&
                              deals?.length > 0
                            ? 'calc(100vh - 130px)'
                            : 'calc(100vh - 90px)',
                          overflowY: 'scroll',
                          '&::-webkit-scrollbar': {
                            width: '5px',
                          },
                          '&::-webkit-scrollbar-track': {
                            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                          },
                          '&::-webkit-scrollbar-thumb': {
                            backgroundColor: theme.palette.primary.main,
                            borderRadius: 2,
                          },
                        }}
                        onScroll={handleListScroll}
                      >
                        <Table
                          sx={{ minWidth: 750 }}
                          stickyHeader
                          aria-label="simple table"
                        >
                          <TableHead sx={{ bgcolor: 'alternate.dark' }}>
                            <TableRow>
                              <TableCell>
                                <Typography
                                  variant={'caption'}
                                  fontWeight={700}
                                  sx={{ textTransform: 'uppercase' }}
                                >
                                  Deal
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  variant={'caption'}
                                  fontWeight={700}
                                  sx={{ textTransform: 'uppercase' }}
                                >
                                  CRM
                                </Typography>
                              </TableCell>
                              {/* <TableCell align="center">
                                <Box
                                  display={'flex'}
                                  justifyContent={'center'}
                                  alignItems={'center'}
                                  gap={1}
                                >
                                  <Typography
                                    variant={'caption'}
                                    fontWeight={700}
                                    sx={{
                                      textTransform: 'uppercase',
                                      // textAlign: 'center',
                                    }}
                                  >
                                    Contact
                                  </Typography>
                                </Box>
                              </TableCell> */}
                              <TableCell align="center">
                                <Typography
                                  variant={'caption'}
                                  fontWeight={700}
                                  sx={{ textTransform: 'uppercase' }}
                                >
                                  Deal Stage
                                </Typography>
                              </TableCell>
                              <TableCell align="center">
                                <Typography
                                  variant={'caption'}
                                  fontWeight={700}
                                  sx={{ textTransform: 'uppercase' }}
                                >
                                  Deal Size
                                </Typography>
                              </TableCell>
                              <TableCell align="center">
                                <Typography
                                  variant={'caption'}
                                  fontWeight={700}
                                  sx={{ textTransform: 'uppercase' }}
                                  textAlign={'center'}
                                  // align="center"
                                >
                                  Confidence (%)
                                </Typography>
                              </TableCell>
                              <TableCell align="center">
                                <Typography
                                  variant={'caption'}
                                  fontWeight={700}
                                  sx={{ textTransform: 'uppercase' }}
                                >
                                  Close Date
                                </Typography>
                              </TableCell>
                              {/* <TableCell align="center">
                                <Typography
                                  variant={'caption'}
                                  fontWeight={700}
                                  sx={{ textTransform: 'uppercase' }}
                                >
                                  Go Live Date
                                </Typography>
                              </TableCell> */}
                              <TableCell align="center">
                                <Typography
                                  variant={'caption'}
                                  fontWeight={700}
                                  sx={{ textTransform: 'uppercase' }}
                                >
                                  Calls
                                </Typography>
                              </TableCell>
                              <TableCell align="center">
                                <Typography
                                  variant={'caption'}
                                  fontWeight={700}
                                  sx={{ textTransform: 'uppercase' }}
                                >
                                  Actions
                                </Typography>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {deals
                              ?.filter((deal) =>
                                currentTab === 'archived'
                                  ? deal?.archived
                                  : !deal?.archived,
                              )
                              .map((deal, i) => {
                                return (
                                  <TableRow key={i}>
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      sx={{ minWidth: 150 }}
                                    >
                                      <Typography
                                        variant={'subtitle2'}
                                        // fontWeight={700}
                                        // sx={{
                                        //   cursor: 'pointer',
                                        // }}
                                        onClick={() => {}}
                                      >
                                        {deal?.name}
                                      </Typography>
                                    </TableCell>
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      sx={{ minWidth: 150 }}
                                    >
                                      <Typography
                                        variant={'subtitle2'}
                                        // fontWeight={700}
                                        sx={{
                                          cursor: 'pointer',
                                        }}
                                        onClick={() => {}}
                                      >
                                        {deal?.crm === 'zohocrm'
                                          ? 'Zoho'
                                          : deal?.crm === 'pipedrive'
                                          ? 'Pipedrive'
                                          : deal?.crm === 'hubspot'
                                          ? 'HubSpot'
                                          : '--'}
                                      </Typography>
                                    </TableCell>
                                    {/* <TableCell
                                      component="th"
                                      scope="row"
                                      align="center"
                                      // sx={{ maxWidth: 100 }}
                                    >
                                      <Typography
                                        variant={'subtitle2'}
                                        // fontWeight={700}
                                        textAlign={'center'}
                                        sx={{
                                          cursor: 'pointer',
                                        }}
                                        onClick={() => {
                                          // setAccountEditable(ar);
                                          setAnchorEl(null);
                                        }}
                                      >
                                        {deal?.contact?.email || '--'}
                                      </Typography>
                                    </TableCell> */}
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      align="center"
                                      // sx={{ maxWidth: 100 }}
                                    >
                                      <Typography
                                        variant={'subtitle2'}
                                        // fontWeight={700}
                                        textAlign={'center'}
                                        sx={{
                                          cursor: 'pointer',
                                        }}
                                        onClick={() => {
                                          // setAccountEditable(ar);
                                          setAnchorEl(null);
                                        }}
                                      >
                                        {deal?.stage || '--'}
                                      </Typography>
                                    </TableCell>
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      align="center"
                                      // sx={{ maxWidth: 100 }}
                                    >
                                      <Typography
                                        variant={'subtitle2'}
                                        // fontWeight={700}
                                        textAlign={'center'}
                                        sx={{
                                          cursor: 'pointer',
                                        }}
                                        onClick={() => {
                                          // setAccountEditable(ar);
                                          setAnchorEl(null);
                                        }}
                                      >
                                        {deal?.amount?.amount ? (
                                          <>
                                            {deal?.amount?.currency === 'usd'
                                              ? '$'
                                              : deal?.amount?.currency === 'inr'
                                              ? '₹'
                                              : '$'}
                                            {deal?.amount?.amount || '--'}
                                          </>
                                        ) : (
                                          '--'
                                        )}
                                      </Typography>
                                    </TableCell>
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      align="center"
                                      // sx={{ maxWidth: 100 }}
                                    >
                                      <Typography
                                        variant={'subtitle2'}
                                        // fontWeight={700}
                                        textAlign={'center'}
                                        sx={{
                                          cursor: 'pointer',
                                        }}
                                        onClick={() => {
                                          // setAccountEditable(ar);
                                          setAnchorEl(null);
                                        }}
                                      >
                                        {deal?.confidence || '--'}
                                      </Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                      <Typography variant={'caption'}>
                                        {deal?.closeOn > 0
                                          ? moment(deal?.closeOn).format(
                                              'DD MMMM YYYY',
                                            )
                                          : '--'}
                                      </Typography>
                                    </TableCell>
                                    {/* <TableCell align="center">
                                      <Typography variant={'caption'}>
                                        {deal?.goLiveOn
                                          ? moment(deal?.goLiveOn).format(
                                              'DD MMMM YYYY',
                                            )
                                          : '--'}
                                      </Typography>
                                    </TableCell> */}
                                    <TableCell align="center">
                                      {deal?.calls && deal?.calls > 0 ? (
                                        <Button
                                          size="small"
                                          variant="text"
                                          color="primary"
                                          onClick={() => {
                                            history.push(
                                              `/teams/${teamId}/sales-call-analysis?dealId=${deal?.id}`,
                                            );
                                          }}
                                        >
                                          <Typography
                                            variant="body2"
                                            fontWeight={'bold'}
                                            sx={{
                                              textDecoration: 'underline',
                                            }}
                                          >
                                            {deal?.calls || 0}
                                          </Typography>
                                        </Button>
                                      ) : (
                                        <Typography variant={'caption'}>
                                          {deal?.calls ? deal?.calls : 0}
                                        </Typography>
                                      )}
                                    </TableCell>
                                    <TableCell align="center">
                                      <Box
                                        display={'flex'}
                                        alignItems={'center'}
                                        gap={1}
                                        justifyContent={'center'}
                                      >
                                        <Tooltip
                                          arrow
                                          placement="top"
                                          title={'Deal Report'}
                                        >
                                          <IconButton
                                            onClick={(e) => {
                                              setViewType('report');
                                              setActiveDealIndex(i);
                                            }}
                                          >
                                            <Assessment
                                              sx={{
                                                color:
                                                  theme.palette.primary.main,
                                                fontSize: 20,
                                              }}
                                            />
                                          </IconButton>
                                        </Tooltip>
                                        <Tooltip
                                          arrow
                                          placement="top"
                                          title={
                                            deal?.archived
                                              ? 'Deal is in archived state. You can not upload call for archived deal'
                                              : 'Upload Call'
                                          }
                                        >
                                          <IconButton
                                            onClick={(e) => {
                                              if (!deal?.archived) {
                                                history.push(
                                                  `/teams/${teamId}/sales-call-analysis?dealId=${deal?.id}&new=true`,
                                                );
                                              }
                                            }}
                                          >
                                            <CloudUpload
                                              sx={{
                                                color: deal?.archived
                                                  ? '#a3a3a3'
                                                  : theme.palette.primary.main,
                                                fontSize: 20,
                                              }}
                                            />
                                          </IconButton>
                                        </Tooltip>
                                        <Tooltip
                                          arrow
                                          placement="top"
                                          title={
                                            deal?.archived
                                              ? 'Deal is in archived state. You can not update archived deal'
                                              : 'Update Deal'
                                          }
                                        >
                                          <IconButton
                                            disabled={deal?.crm}
                                            onClick={(e) => {
                                              if (!deal?.archived) {
                                                setCreateNewDealDialog(deal);
                                              }
                                            }}
                                          >
                                            <Edit
                                              sx={{
                                                color:
                                                  deal?.archived || deal?.crm
                                                    ? '#a3a3a3'
                                                    : theme.palette.primary
                                                        .main,
                                                fontSize: 20,
                                              }}
                                            />
                                          </IconButton>
                                        </Tooltip>
                                        <Tooltip
                                          arrow
                                          placement="top"
                                          title={
                                            deal?.archived
                                              ? 'Unarchive Deal'
                                              : 'Archive Deal'
                                          }
                                        >
                                          <IconButton
                                            onClick={(e) => {
                                              dispatch(
                                                updateDeal(
                                                  deal?.id,
                                                  {
                                                    archived: !deal?.archived,
                                                  },
                                                  (data, error) => {
                                                    if (error) {
                                                      dispatch(
                                                        showAlert({
                                                          message:
                                                            'Oops, Something went wront! Please try again.',
                                                          showCross: true,
                                                          title: null,
                                                          type: 'error',
                                                          autoHideDuration: 2000,
                                                          vertical: 'top',
                                                          horizontal: 'center',
                                                        }),
                                                      );
                                                    } else {
                                                      dispatch({
                                                        type: 'UPDATE_DEALS',
                                                        payload: {
                                                          id: deal?.id,
                                                          data: {
                                                            ...deal,
                                                            archived: !deal?.archived,
                                                          },
                                                        },
                                                      });
                                                    }
                                                  },
                                                ),
                                              );
                                            }}
                                          >
                                            {deal?.archived ? (
                                              <Unarchive
                                                sx={{
                                                  color:
                                                    theme.palette.primary.main,
                                                  fontSize: 20,
                                                }}
                                              />
                                            ) : (
                                              <Archive
                                                sx={{
                                                  color:
                                                    theme.palette.primary.main,
                                                  fontSize: 20,
                                                }}
                                              />
                                            )}
                                          </IconButton>
                                        </Tooltip>
                                        <Tooltip
                                          arrow
                                          placement="top"
                                          title={'Delete Deal'}
                                        >
                                          <IconButton
                                            onClick={(e) => {
                                              setShowConfirmDeleteDialog(
                                                deal?.id,
                                              );
                                            }}
                                          >
                                            <Delete
                                              sx={{
                                                color: '#a3a3a3',
                                                fontSize: 20,
                                              }}
                                            />
                                          </IconButton>
                                        </Tooltip>
                                      </Box>
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                          </TableBody>
                          <TableFooter>
                            <TableRow>
                              <TableCell
                                variant="footer"
                                colSpan={'100%'}
                                sx={{
                                  padding: 0,
                                }}
                              >
                                {fetchingPagination ? (
                                  <Box
                                    sx={{
                                      width: '100%',
                                      display: 'flex',
                                      justifyContent: 'center',
                                      padding: 1,
                                      textAlign: 'center',
                                    }}
                                  >
                                    <Button
                                      size="small"
                                      variant="contained"
                                      // type="submit"
                                      color="primary"
                                      onClick={handleListScroll}
                                      // className="searchSettings"
                                      disabled
                                      // fullWidth
                                    >
                                      <Typography color="textPrimary">
                                        Loading ...{' '}
                                      </Typography>
                                      <CircularProgress size={20} />
                                    </Button>
                                  </Box>
                                ) : emptyList === false &&
                                  deals.length >= 10 ? (
                                  <Box
                                    sx={{
                                      width: '100%',
                                      display: 'flex',
                                      justifyContent: 'center',
                                      padding: 1,
                                      textAlign: 'center',
                                    }}
                                  >
                                    <Button
                                      size="small"
                                      variant="contained"
                                      color="primary"
                                      onClick={(e) => handleListScroll(e, true)}
                                    >
                                      Load More
                                    </Button>
                                  </Box>
                                ) : null}
                              </TableCell>
                            </TableRow>
                          </TableFooter>
                        </Table>
                      </TableContainer>
                    </Box>
                  ) : (
                    <Box
                      width={1}
                      height={1}
                      display={'flex'}
                      justifyContent={'center'}
                      alignItems={'center'}
                      flexDirection={'column'}
                      gap={2}
                    >
                      <Typography
                        variant="body1"
                        color={'text.secondary'}
                        mt={4}
                      >
                        No deals found
                      </Typography>
                    </Box>
                  )}
                </Box>
              </>
            ) : (
              <Box
                display={'flex'}
                flexDirection={'column'}
                gap={2}
                border={'1px solid #d3d3d3'}
                sx={{
                  minHeight: 'calc(100vh - 84px)',
                  maxHeight: 'calc(100vh - 84px)',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,
                      padding: '8px 0',
                    }}
                  >
                    <IconButton
                      disabled={activeDealIndex === 0}
                      onClick={() => {
                        setActiveDealIndex(activeDealIndex - 1);
                      }}
                    >
                      <KeyboardArrowLeft />
                    </IconButton>
                    <Typography variant="body1" color={'text.secondary'}>
                      {activeDeal?.name}
                    </Typography>
                    <IconButton
                      disabled={activeDealIndex === deals?.length - 1}
                      onClick={() => {
                        setActiveDealIndex(activeDealIndex + 1);
                      }}
                    >
                      <KeyboardArrowRight />
                    </IconButton>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: '0 24px',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 1,
                      minWidth: 250,
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Typography sx={{ flex: 2 }}>Deal Size</Typography>
                      <Typography sx={{ flex: 1 }}>Confidence</Typography>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'stretch',
                        border: '1px solid #d3d3d3',
                      }}
                    >
                      <Typography
                        color={'text.secondary'}
                        fontWeight={'bold'}
                        sx={{ flex: 2, p: 1 }}
                      >
                        {activeDeal?.amount?.amount ? (
                          <>
                            {activeDeal?.amount?.currency === 'usd'
                              ? '$'
                              : activeDeal?.amount?.currency === 'inr'
                              ? '₹'
                              : '$'}
                            {activeDeal?.amount?.amount || '--'}
                          </>
                        ) : (
                          '--'
                        )}
                      </Typography>
                      <Divider
                        orientation="vertical"
                        variant="fullWidth"
                        flexItem
                      />
                      <Typography
                        sx={{ flex: 1, p: 1 }}
                        color={'text.secondary'}
                        fontWeight={'bold'}
                      >
                        {activeDeal?.confidence
                          ? `${activeDeal?.confidence}%`
                          : '--'}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 1,
                      minWidth: 350,
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Typography sx={{ flex: 1 }}>Close Date</Typography>
                      <Typography sx={{ flex: 1 }}>Go Live Date</Typography>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'stretch',
                        border: '1px solid #d3d3d3',
                      }}
                    >
                      <Typography
                        sx={{ flex: 1, p: 1 }}
                        color={'text.secondary'}
                        fontWeight={'bold'}
                      >
                        {activeDeal?.closeOn > 0
                          ? moment(activeDeal?.closeOn).format('DD MMMM YYYY')
                          : '--'}
                      </Typography>
                      <Divider
                        orientation="vertical"
                        variant="fullWidth"
                        flexItem
                      />
                      <Typography
                        sx={{ flex: 1, p: 1 }}
                        color={'text.secondary'}
                        fontWeight={'bold'}
                      >
                        {activeDeal?.goLiveOn
                          ? moment(activeDeal?.goLiveOn).format('DD MMMM YYYY')
                          : '--'}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 1,
                      minWidth: 250,
                    }}
                  >
                    {/* <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Typography sx={{ flex: 1 }}>Hubspot Deal</Typography>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'stretch',
                        border: '1px solid #d3d3d3',
                      }}
                    >
                      <Typography
                        sx={{ flex: 1, p: 1 }}
                        color={'text.secondary'}
                        fontWeight={'bold'}
                      >
                        {activeDeal?.crmDeam || '--'}
                      </Typography>
                    </Box> */}
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1,
                    padding: '8px 24px',
                    borderTop: '1px solid #d3d3d3',
                    maxHeight: 'calc(100vh - 240px)',
                    overflowY: 'scroll',
                    '&::-webkit-scrollbar': {
                      width: '5px',
                    },
                    '&::-webkit-scrollbar-track': {
                      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      backgroundColor: theme.palette.primary.main,
                      borderRadius: 2,
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                      gap: 2,
                    }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      startIcon={<Troubleshoot />}
                      onClick={() => {
                        history.push(
                          `/teams/${teamId}/sales-call-analysis?dealId=${activeDeal?.id}`,
                        );
                      }}
                    >
                      {activeDeal?.calls || 0} Calls
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      startIcon={
                        generatingDealSummary ? (
                          <CircularProgress size={20} sx={{ color: '#fff' }} />
                        ) : (
                          <Replay />
                        )
                      }
                      onClick={() => {
                        setGeneratingDealSummary(true);
                        dispatch(
                          generateDeal(activeDeal?.id, (data, error) => {
                            console.log(data);
                            setGeneratingDealSummary(false);
                            if (error) {
                              dispatch(
                                showAlert({
                                  message:
                                    'Oops, Something went wront! Please try again.',
                                  showCross: true,
                                  title: null,
                                  type: 'error',
                                  autoHideDuration: 2000,
                                  vertical: 'top',
                                  horizontal: 'center',
                                }),
                              );
                            } else {
                              dispatch({
                                type: 'UPDATE_DEALS',
                                payload: {
                                  id: activeDeal?.id,
                                  data: {
                                    ...activeDeal,
                                    dealSummarySections: data,
                                  },
                                },
                              });
                              dispatch(
                                showAlert({
                                  message:
                                    'Deal Summary is generated successfully.',
                                  showCross: true,
                                  title: null,
                                  type: 'success',
                                  autoHideDuration: 2000,
                                  vertical: 'top',
                                  horizontal: 'center',
                                }),
                              );
                            }
                          }),
                        );
                      }}
                    >
                      Generate Summary
                    </Button>
                  </Box>
                  {activeDeal?.dealSummarySections &&
                  activeDeal?.dealSummarySections?.length > 0 ? (
                    activeDeal?.dealSummarySections?.map((point) => (
                      <Box
                        key={point}
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: 1,
                        }}
                      >
                        <Typography color={`text.secondary`}>
                          {capitalizeText(point?.title?.split('_')?.join(' '))}
                        </Typography>
                        <Box
                          sx={{
                            border: '1px solid #d3d3d3',
                            minHeight: 200,
                            p: 2,
                          }}
                        >
                          {point?.valueStr ? (
                            <Typography>{point?.valueStr}</Typography>
                          ) : (
                            <ul
                              style={{
                                listStyle: 'number',
                                margin: 0,
                                marginBlock: 0,
                                padding: 0,
                                paddingLeft: 16,
                              }}
                            >
                              {point?.valueArrStr?.map((item) => (
                                <li key={item}>
                                  <Typography>{item}</Typography>
                                </li>
                              ))}
                            </ul>
                          )}
                        </Box>
                      </Box>
                    ))
                  ) : (
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 1,
                      }}
                    >
                      <Box
                        sx={{
                          border: '1px solid #d3d3d3',
                          minHeight: 200,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Typography color={'text.secondary'}>
                          No Summary Available
                        </Typography>
                      </Box>
                    </Box>
                  )}
                </Box>
                {/* <Button
                  size="small"
                  variant="outlined"
                  onClick={(e) => {
                    setViewType('list');
                  }}
                  color="primary"
                >
                  Go Back
                </Button> */}
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <Dialog
        open={Boolean(showConfirmDeleteDialog)}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          zIndex: 10002,
          '& .MuiPaper-root-MuiDialog-paper': {
            maxWidth: '70vw',
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">Confirm</DialogTitle>
        <DialogContent>
          <Box display={'flex'} alignItems={'center'} gap={2}>
            <DialogContentText id="alert-dialog-description" flex={1}>
              Are you sure you want to delete this sales call?
            </DialogContentText>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            // data-trackid={'cta_permission_denied_dialog_close'}
            onClick={(e) => {
              setShowConfirmDeleteDialog(null);
              // if (testMode) setShowTestDeviceDialog(null);
            }}
            color="primary"
            // autoFocus
          >
            Cancel
          </Button>
          <Button
            size="small"
            variant="contained"
            // data-trackid={'cta_permission_denied_dialog_close'}
            onClick={(e) => {
              dispatch(
                deleteDeal(
                  showConfirmDeleteDialog,

                  (data) => {
                    setShowConfirmDeleteDialog(false);
                    dispatch(
                      getDeals(
                        {
                          archived: currentTab === 'archived' ? true : false,
                          size: 10,
                        },
                        (data) => {},
                      ),
                    );
                  },
                  () => {
                    setShowConfirmDeleteDialog(false);
                  },
                ),
              );
            }}
            color="primary"
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Modal
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        open={createNewDealDialog}
        onClose={(e, reason) => {
          if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
            return;
          }
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box
          sx={{
            backgroundColor: theme.palette.background.paper,
            // boxShadow: theme.shadows[5],
            outline: 'none',
            maxHeight: '80vh',
            maxWidth: '50vw',
            minWidth: '50vw',
            overflowY: 'auto',
            borderRadius: 1,
            padding: 2,
            position: 'relative',
          }}
        >
          <IconButton
            sx={{
              position: 'absolute',
              top: 14,
              right: 15,
              padding: '4px',
              background: theme.palette.background.default,
              ':hover': {
                backgroundColor: '#d3d3d3',
              },
            }}
            onClick={(e) => {
              setCreateNewDealDialog(false);
            }}
          >
            <Close sx={{ fontSize: 20 }} />
          </IconButton>
          <Box display={'flex'} flexDirection={'column'} gap={1}>
            <Typography variant={'h6'}>
              {createNewDealDialog && typeof createNewDealDialog === 'object'
                ? 'Update Deal'
                : 'Create new deal'}
            </Typography>
            <Box display={'flex'} alignItems={'center'} gap={2}>
              <TextField
                autoFocus
                label="Deal Name"
                variant="outlined"
                color="primary"
                size="small"
                name="dealName"
                value={dealName}
                onChange={(e) => setDealName(e.target.value)}
                fullWidth
                sx={{ flex: 2 }}
              />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Deal Go Live Date"
                  format="YYYY-MM-DD"
                  value={dayjs(goLiveOn)}
                  onChange={(newValue) =>
                    setGoLiveOn(new Date(newValue).getTime())
                  }
                  sx={{ flex: 1 }}
                  slotProps={{ textField: { size: 'small' } }}
                />
              </LocalizationProvider>
            </Box>
            <TextField
              autoFocus
              label="Deal Description"
              multiline
              rows={4}
              variant="outlined"
              color="primary"
              size="small"
              name="dealDescription"
              value={dealDescription}
              onChange={(e) => setDealDescription(e.target.value)}
              fullWidth
            />
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 2,
              }}
            >
              <FormControl size="small" sx={{ flex: 2 }}>
                <InputLabel id="dealSizeUnit">Deal Stage</InputLabel>
                <Select
                  size="small"
                  label="Deal Stage"
                  labelId={'dealStage'}
                  id={'dealStage'}
                  name={'dealStage'}
                  MenuProps={{ disableScrollLock: true }}
                  onChange={(e) => {
                    setDealStage(e.target.value);
                  }}
                  value={dealStage}
                >
                  <MenuItem value={'qualification'}>
                    <Typography variant="subtitle2">Qualification</Typography>
                  </MenuItem>
                  <MenuItem value={'need_analysis'}>
                    <Typography variant="subtitle2">Need Analysis</Typography>
                  </MenuItem>
                  <MenuItem value={'value_proposition'}>
                    <Typography variant="subtitle2">
                      Value Proposition
                    </Typography>
                  </MenuItem>
                  <MenuItem value={'identify_decision_makers'}>
                    <Typography variant="subtitle2">
                      Identify Decision Makers
                    </Typography>
                  </MenuItem>
                  <MenuItem value={'proposal_price_quote'}>
                    <Typography variant="subtitle2">
                      Proposal/Price Quote
                    </Typography>
                  </MenuItem>
                  <MenuItem value={'negotiation_review'}>
                    <Typography variant="subtitle2">
                      Negotiation/Review
                    </Typography>
                  </MenuItem>
                  <MenuItem value={'closed_won'}>
                    <Typography variant="subtitle2">Closed Won</Typography>
                  </MenuItem>
                  <MenuItem value={'closed_lost'}>
                    <Typography variant="subtitle2">Closed Lost</Typography>
                  </MenuItem>
                  <MenuItem value={'closed_lost_to_competition'}>
                    <Typography variant="subtitle2">
                      Closed Lost to Competition
                    </Typography>
                  </MenuItem>
                </Select>
              </FormControl>
              <TextField
                autoFocus
                label="Deal Amount"
                variant="outlined"
                color="primary"
                size="small"
                name="dealSize"
                type="number"
                value={dealSize}
                onChange={(e) => setDealSize(e.target.value)}
                fullWidth
                sx={{ flex: 1 }}
              />
              <FormControl size="small" sx={{ flex: 1 }}>
                <InputLabel id="dealSizeUnit">Deal Amount Unit</InputLabel>
                <Select
                  size="small"
                  label="Deal Amount Unit"
                  labelId={'dealSizeUnit'}
                  id={'dealSizeUnit'}
                  name={'dealSizeUnit'}
                  MenuProps={{ disableScrollLock: true }}
                  onChange={(e) => {
                    setDealSizeUnit(e.target.value);
                  }}
                  value={dealSizeUnit}
                >
                  <MenuItem value={'usd'}>
                    <Typography variant="subtitle2">USD</Typography>
                  </MenuItem>
                  <MenuItem value={'inr'}>
                    <Typography variant="subtitle2">INR</Typography>
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
            <AutoCompleteMUI
              id="accountName"
              value={accountName}
              onChange={(event, newValue) => {
                setAccountName(newValue);
              }}
              options={accounts?.map((ac) => ac?.name)}
              onTextChange={(e) => setAccountName(e.target.value)}
              onKeyDown={() => {
                return false;
              }}
              placeholder="Account Name"
              minWidth={140}
            />
            <FormControl size="small" sx={{ width: '100%' }}>
              <InputLabel id="dealContact">Deal Contact</InputLabel>
              <Select
                size="small"
                label="Deal Contact"
                labelId={'dealContact'}
                id={'dealContact'}
                name={'dealContact'}
                MenuProps={{ disableScrollLock: true }}
                onChange={(e) => {
                  setDealContact(e.target.value);
                }}
                value={dealContact}
              >
                {members?.map((member) => (
                  <MenuItem value={member?.email} key={member?.email}>
                    <Typography variant="subtitle2">
                      {member?.fullName} -- {member?.email}
                    </Typography>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {createNewDealDialog && typeof createNewDealDialog === 'object' ? (
              <FormControlLabel
                sx={{ marginTop: -1 }}
                control={
                  <Checkbox
                    size="small"
                    checked={dealClosed}
                    onChange={(e) => {
                      setDealClosed(e.target.checked);
                    }}
                    name="dealClosed"
                  />
                }
                label={
                  <Typography variant="body2" color={'text.secondary'}>
                    Deal Closed
                  </Typography>
                }
              />
            ) : null}
            {dealClosed ? (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Deal Close Date"
                  format="YYYY-MM-DD"
                  value={dayjs(dealCloseDate)}
                  onChange={(newValue) => setDealCloseDate(newValue)}
                  sx={{ flex: 1 }}
                  slotProps={{ textField: { size: 'small' } }}
                />
              </LocalizationProvider>
            ) : null}
            <Box display={'flex'} justifyContent={'flex-end'}>
              <Button
                size="small"
                variant="contained"
                disabled={!dealName?.trim() || creatingDeal}
                onClick={() => {
                  if (
                    createNewDealDialog &&
                    typeof createNewDealDialog === 'object'
                  ) {
                    setCreatingDeal(true);
                    dispatch(
                      updateDeal(
                        createNewDealDialog?.id,
                        {
                          name: dealName,
                          description: dealDescription,
                          accountId: accounts?.find(
                            (ac) => ac?.name === accountName,
                          )?.id,
                          contact: {
                            email: members?.find(
                              (member) => member?.email === dealContact,
                            )?.email,
                            name: members?.find(
                              (member) => member?.email === dealContact,
                            )?.fullName,
                          },
                          amount: {
                            amount: Number(dealSize),
                            currency: dealSizeUnit,
                          },
                          goLiveOn: goLiveOn,
                          stage: dealStage,
                          closeOn: dealClosed ? dealCloseDate : 0,
                        },
                        (data, error) => {
                          if (error) {
                            setCreatingDeal(false);
                            dispatch(
                              showAlert({
                                message:
                                  'Oops, Something went wront! Please try again.',
                                showCross: true,
                                title: null,
                                type: 'error',
                                autoHideDuration: 2000,
                                vertical: 'top',
                                horizontal: 'center',
                              }),
                            );
                          } else {
                            dispatch(
                              getDeals(
                                {
                                  archived:
                                    currentTab === 'archived' ? true : false,
                                  size: 10,
                                },
                                (cs) => {
                                  setCreatingDeal(false);
                                  setCreateNewDealDialog(false);
                                },
                              ),
                            );
                          }
                        },
                      ),
                    );
                  } else {
                    setCreatingDeal(true);
                    dispatch(
                      createNewDeal(
                        {
                          name: dealName,
                          description: dealDescription,
                          accountId: accounts?.find(
                            (ac) => ac?.name === accountName,
                          )?.id,
                          contact: {
                            email: members?.find(
                              (member) => member?.email === dealContact,
                            )?.email,
                            name: members?.find(
                              (member) => member?.email === dealContact,
                            )?.fullName,
                          },
                          amount: {
                            amount: Number(dealSize),
                            currency: dealSizeUnit,
                          },
                          goLiveOn: goLiveOn,
                          stage: dealStage,
                          closeOn: dealClosed ? dealCloseDate : 0,
                        },
                        (data, error) => {
                          if (error) {
                            setCreatingDeal(false);
                            dispatch(
                              showAlert({
                                message:
                                  'Oops, Something went wront! Please try again.',
                                showCross: true,
                                title: null,
                                type: 'error',
                                autoHideDuration: 2000,
                                vertical: 'top',
                                horizontal: 'center',
                              }),
                            );
                          } else {
                            history.push(`/teams/${teamId}/deals`);
                            dispatch(
                              getDeals(
                                {
                                  archived:
                                    currentTab === 'archived' ? true : false,
                                  size: 10,
                                },
                                (cs) => {
                                  setCreatingDeal(false);
                                  setCreateNewDealDialog(false);
                                },
                              ),
                            );
                          }
                        },
                      ),
                    );
                  }
                }}
              >
                {createNewDealDialog && typeof createNewDealDialog === 'object'
                  ? 'Update Deal'
                  : 'Create new deal'}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

VideoPreview.propTypes = {
  selectedAudio: PropTypes.object,
  onDurationChange: PropTypes.func,
};

Deals.propTypes = {
  uId: PropTypes.string,
  member: PropTypes.object,
  parentComponent: PropTypes.string,
};

export default Deals;
