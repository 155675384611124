import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { alpha, useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import {
  Avatar,
  Divider,
  Menu,
  MenuItem,
  Typography,
  Modal,
  IconButton,
  Chip,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Circle, ExitToApp, Home, Close, Bolt } from '@mui/icons-material';
import firebaseLogout from 'redux/actions/Auth/firebaseLogout';
import firebaseCheckSession from 'redux/actions/Auth/firebaseCheckSession';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { detect } from 'detect-browser';
import trackEvent from 'redux/actions/Common/trackEvent';
import logError from 'redux/actions/Common/logError';
import { NavItem } from './components';
import updateNotificationPreferences from 'redux/actions/Common/updateNotificationPreferences.js';

// import { NavItem } from './components';

const Topbar = ({
  onSidebarOpen,
  //  pages,
  colorInvert = false,
}) => {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const { isLoggedIn } = useSelector((state) => state.firebaseSession);
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const [anchorEl, setAnchorEl] = useState(null);
  const { userDetails } = useSelector((state) => state.userDetails);

  const { domainDetails } = useSelector((state) => state.whitelabelDetails);

  const { pathname } = useLocation();
  const browser = detect();

  const handleTrackEvent = (e, type) => {
    try {
      dispatch(
        trackEvent({
          context: 'click_event',
          eventType: type,
          userId: isLoggedIn ? userInfo?.id : null,
          time: new Date().getTime(),
          os: browser?.os,
          sessionId: isLoggedIn
            ? userInfo?.id
            : sessionStorage.getItem('trackingSessionId'),
          customData: {
            page_path: pathname,
            browser: browser?.name,
            browser_version: browser?.version,
          },
        }),
      );
    } catch (e) {
      console.log(e);
      dispatch(
        logError({
          message: e?.message,
          data: {
            formatted: {
              errorText: e?.message,
              severity: 'error',
            },
            raw: e,
          },
        }),
      );
    }
  };

  const handleProfileMenuClick = (event) => {
    if (anchorEl) setAnchorEl(null);
    else setAnchorEl(event.target);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogoutClick = () => {
    if (localStorage.getItem('notificationToken')) {
      dispatch(
        updateNotificationPreferences(
          {
            pushnotification: false,
          },
          () => {
            localStorage.removeItem('notificationToken');
            dispatch(
              firebaseLogout(() => {
                dispatch(firebaseCheckSession());
                history.go();
              }),
            );
          },
        ),
      );
    } else {
      dispatch(
        firebaseLogout(() => {
          dispatch(firebaseCheckSession());
          history.go();
        }),
      );
    }
  };

  return (
    <Box
      display={'flex'}
      justifyContent={'space-between'}
      alignItems={'center'}
      width={1}
    >
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <Box
          display={'flex'}
          component="a"
          href={
            domainDetails?.organizationWebsite
              ? `https://${domainDetails?.organizationWebsite}`
              : 'https://qualification.ai'
          }
          title={domainDetails?.appTitle || 'Qualification AI'}
          height={{ xs: 40, md: 50 }}
        >
          <Box
            component={'img'}
            src={
              theme.palette.mode === 'dark'
                ? '/dark_theme_logo.svg'
                : domainDetails?.textInLogo
                ? domainDetails?.logo || '/light_theme_logo.svg'
                : domainDetails?.logoWithoutText || '/light_theme_logo.svg'
            }
            height={1}
            width={1}
          />
        </Box>
      </Box>
      <Box sx={{ display: { xs: 'none', md: 'flex' } }} alignItems={'center'}>
        {isLoggedIn && userInfo ? (
          <Box>
            <Button
              data-trackid={'open_profile_menu'}
              aria-controls="profile-menu"
              aria-haspopup="true"
              onClick={(e) => {
                handleTrackEvent(e, 'open_profile_menu');
                handleProfileMenuClick(e);
              }}
            >
              <Avatar
                alt={userInfo?.firstName?.toUpperCase()}
                src={userDetails?.profilePicture?.url}
              >
                {userInfo?.firstName?.[0]?.toUpperCase()}
              </Avatar>
            </Button>
            <Menu
              id="profile-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              disableScrollLock={true}
              transformOrigin={{ horizontal: 'left', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
              style={{
                marginTop: '15px',
              }}
            >
              <MenuItem
                data-trackid={'profile_menu_home_option'}
                style={{}}
                onClick={(e) => {
                  handleTrackEvent(e, 'profile_menu_home_option');
                  history.push('/');
                }}
              >
                <Home style={{ marginRight: '10px' }} />
                Home
              </MenuItem>
              <MenuItem
                data-trackid={'profile_menu_logout_option'}
                style={{}}
                onClick={(e) => {
                  handleTrackEvent(e, 'profile_menu_logout_option');
                  handleLogoutClick();
                }}
              >
                <ExitToApp style={{ marginRight: '10px' }} />
                Logout
              </MenuItem>
              <MenuItem
                style={{
                  color: 'rgba(0, 0, 0, 0.54)',
                  pointerEvents: 'none',
                }}
              >
                {userInfo?.email}
              </MenuItem>
            </Menu>
          </Box>
        ) : (
          <>
            {/* <Box marginLeft={2}>
              <Button
                sx={{
                  borderBottom:
                    location.pathname === '/contests'
                      ? `2px solid ${theme.palette.primary.main}`
                      : 'none',
                }}
                data-trackid={'move_to_contests_page'}
                size={'small'}
                variant="text"
                fullWidth
                component="a"
                href={location.pathname === '/contests' ? null : '/contests'}
                onClick={(e) => {
                  handleTrackEvent(e, 'move_to_contests_page');
                }}
              >
                <Typography>Contests</Typography>
              </Button>
            </Box> */}
            {/* <Box marginLeft={2} marginRight={1} position={'relative'}>
              <NavItem
                title={'Practice'}
                id={'practice-options'}
                items={[
                  { title: 'Practice Interview', href: '/practice/interview' },
                  { title: 'Practice Pitch', href: '/practice/pitch' },
                  { title: 'Practice Script', href: '/practice/script' },
                  { title: 'Practice Contests', href: '/practice/contests' },
                ]}
                colorInvert={colorInvert}
                href={'/practice'}
              />
              <Chip
                // variant="outlined"
                size="small"
                label={
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Bolt sx={{ fontSize: 12 }}></Bolt>
                    <Typography variant="caption" sx={{ fontSize: 10 }}>
                      New
                    </Typography>
                  </Box>
                }
                color="primary"
                sx={{
                  position: 'absolute',
                  right: -15,
                  top: -20,
                  padding: '1px',
                }}
              ></Chip>
            </Box> */}
            {/* <Box marginLeft={1}>
              <Button
                sx={{
                  borderBottom:
                    location.pathname === '/jobs'
                      ? `2px solid ${theme.palette.primary.main}`
                      : 'none',
                }}
                data-trackid={'move_to_jobs_page'}
                size={'small'}
                variant="text"
                fullWidth
                component="a"
                href={location.pathname === '/jobs' ? null : '/jobs'}
                onClick={(e) => {
                  handleTrackEvent(e, 'move_to_jobs_page');
                }}
              >
                <Typography>Jobs</Typography>
              </Button>
            </Box> */}
            {/* <Box marginLeft={2}>
              <Button
                sx={{
                  borderBottom:
                    location.pathname === '/pitch-practice'
                      ? `2px solid ${theme.palette.primary.main}`
                      : 'none',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                  paddingRight: 3,
                }}
                data-trackid={'move_to_pitch_practice'}
                size={'small'}
                variant="text"
                fullWidth
                component="a"
                href={
                  location.pathname === '/pitch-practice'
                    ? null
                    : '/pitch-practice'
                }
                onClick={(e) => {
                  handleTrackEvent(e, 'move_to_pitch_practice');
                }}
              >
                <Typography>Pitch Practice</Typography>
                <Chip
                  size="small"
                  label={
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Bolt sx={{ fontSize: 12 }}></Bolt>
                      <Typography variant="caption" sx={{ fontSize: 10 }}>
                        New
                      </Typography>
                    </Box>
                  }
                  color="primary"
                  sx={{
                    position: 'absolute',
                    right: 0,
                    top: -15,
                    padding: '1px',
                  }}
                ></Chip>
              </Button>
            </Box> */}
            {/* <Box marginLeft={2}>
                  <Button
                    data-trackid={'CTA_for_employers'}
                    size={'small'}
                    variant="outlined"
                    fullWidth
                    component="a"
                    href="/auth/signup?bookdemo=true"
                    onClick={(e) => {
                      handleTrackEvent(e, 'CTA_for_employers');
                    }}
                  >
                    For Employers
                  </Button>
                </Box> */}
            <Box marginLeft={2}>
              <Button
                data-trackid={'CTA_sign_in'}
                size={'small'}
                variant="outlined"
                fullWidth
                component="a"
                href="/auth/signin"
                onClick={(e) => {
                  handleTrackEvent(e, 'CTA_sign_in');
                }}
              >
                Sign In
              </Button>
            </Box>
            <Box marginLeft={2}>
              <Button
                data-trackid={'CTA_sign_up'}
                size={'small'}
                variant="contained"
                fullWidth
                component="a"
                href="/auth/signup"
                onClick={(e) => {
                  handleTrackEvent(e, 'CTA_sign_up');
                }}
              >
                Sign Up
              </Button>
            </Box>
          </>
        )}
      </Box>
      <Box sx={{ display: { xs: 'block', md: 'none' } }} alignItems={'center'}>
        <Button
          data-trackid={'toggle_sidebar'}
          onClick={(e) => {
            handleTrackEvent(e, 'toggle_sidebar');
            onSidebarOpen();
          }}
          aria-label="Menu"
          variant={'outlined'}
          sx={{
            borderRadius: 2,
            minWidth: 'auto',
            padding: 1,
            borderColor: alpha(theme.palette.divider, 0.2),
          }}
        >
          <MenuIcon />
        </Button>
      </Box>
    </Box>
  );
};

Topbar.propTypes = {
  onSidebarOpen: PropTypes.func,
  colorInvert: PropTypes.bool,
};

export default Topbar;
