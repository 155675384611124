import { auth } from '../../../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import axios from 'utils/axios';
import { v4 as uuidv4 } from 'uuid';

const deleteDeal = (dealId, callback) => {
  return async (dispatch) => {
    dispatch({ type: 'DELETING_DEAL_ID' });
    try {
      let unsubscribe = onAuthStateChanged(auth, async (user) => {
        const token = await user?.getIdToken();
        const reqId = uuidv4();
        try {
          const response = await axios.delete(`/us/qai/deals/${dealId}`, {
            params: {
              'request-id': reqId,
            },
            headers: {
              Authorization: `Bearer ${token}`,
              // 'current-role': 'candidate',
            },
          });
          if (response.status === 200) {
            await dispatch({
              type: 'DELETING_DEAL_ID_DONE',
            });
            if (typeof callback === 'function') {
              callback();
            }
          } else {
            dispatch({
              type: 'DELETING_DEAL_ID_FAILED',
              payload: `Failed to delete deal`,
            });
            if (typeof callback === 'function') {
              callback(true);
            }
          }
        } catch (e) {
          dispatch({
            type: 'DELETING_DEAL_ID_FAILED',
            payload: `Failed to delete deal`,
          });
          if (typeof callback === 'function') {
            callback(true);
          }
        }
        unsubscribe();
      });
    } catch (e) {
      dispatch({
        type: 'DELETING_DEAL_ID_FAILED',
        payload: `Failed to delete deal`,
      });
      if (typeof callback === 'function') {
        callback(true);
      }
    }
  };
};

export default deleteDeal;
