import {
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
  useTheme,
  Modal,
  Divider,
  Link,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tabs,
  Tab,
  Chip,
  useMediaQuery,
  DialogActions,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Tooltip,
  InputAdornment,
  FormLabel,
  FormControlLabel,
  Checkbox,
  FormGroup,
  RadioGroup,
  Radio,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  Drawer,
} from '@mui/material';
import React, { memo, useEffect, useRef, useState } from 'react';
import Container from 'components/Container';
import { useDispatch, useSelector } from 'react-redux';
import {
  Add,
  ArrowBack,
  Article,
  Check,
  ChevronRight,
  Close,
  CloudUpload,
  FindReplace,
  Info,
  Lock,
  Loop,
  Mic,
  Send,
  StopCircle,
  Subject,
  Timeline,
  Troubleshoot,
} from '@mui/icons-material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useHistory } from 'react-router-dom';
//actions
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { detect } from 'detect-browser';
import moment from 'moment';
import RichTextEditor from 'components/RichTextEditor/RichTextEditor';
import createSalesAnalysisThread from 'redux/actions/Candidate/createSalesAnalysisThread';
import showAlert from 'redux/actions/Common/showAlert';
import checkSalesCallTranscriptStatus from 'redux/actions/Common/checkSalesCallTranscriptStatus';
import getSalesCallReviews from 'redux/actions/Common/getDeals';
import analyzeSalesCall from 'redux/actions/Common/analyzeSalesCall';

import { PropTypes } from 'prop-types';
import { Bar, Line, Pie } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip as TooltipChart,
  Legend,
  ArcElement,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import LineGraph from 'components/LineGraph';
import parse from 'html-react-parser';
import getUserDetails from 'redux/actions/Common/getUserDetails';
import trackEvent from 'redux/actions/Common/trackEvent';
import logError from 'redux/actions/Common/logError';
import checkSalesCallScoreStatus from 'redux/actions/Common/checkSalesCallScoreStatus';
import actionCodeBaseUrl from 'config/actionCodeBaseUrl';
import getPerformanceData from 'redux/actions/Common/getPerformanceData';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import getPerformanceDataByUserId from 'redux/actions/Common/getPerformanceDataByUserId';

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  TooltipChart,
  Legend,
  ChartDataLabels,
);

const PerformanceDashboard = ({ uId, member }) => {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const params = new URLSearchParams(window.location.search);
  const { isLoggedIn } = useSelector((state) => state.firebaseSession);
  const userInfo = JSON.parse(localStorage.getItem('userInfo') || '{}');
  const organization = JSON.parse(localStorage.getItem('organization') || '{}');
  const { dashboardSidebarExpanded } = useSelector((state) => state.uiStates);
  const { uploadingFor, uploadPercentage } = useSelector(
    (state) => state.createSalesAnalysisThreadState,
  );
  const {
    analysisReviews,
    fetching,
    fetchingPagination,
    lastSeen,
    emptyList,
  } = useSelector((state) => state.salesAnalysisReviews);
  const { userDetails } = useSelector((state) => state.userDetails);
  const [loading, setLoading] = useState(false);
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const isXs = useMediaQuery(theme.breakpoints.only('xs'), {
    defaultMatches: true,
  });
  const isSm = useMediaQuery(theme.breakpoints.only('sm'), {
    defaultMatches: true,
  });
  const { orgConfigs } = useSelector((state) => state.orgConfigs);

  const [currentTab, setCurrentTab] = useState('create');

  const [steps, setSteps] = useState([
    {
      title: 'Upload Recording',
      icon: ({ active }) => (
        <CloudUpload
          sx={{
            color: active ? theme.palette.primary.main : '#677788',
          }}
        />
      ),
      completed: false,
      active: true,
    },
    {
      title: 'Analysis Options',
      icon: ({ active }) => (
        <Subject
          sx={{
            color: active ? theme.palette.primary.main : '#677788',
          }}
        />
      ),
      completed: false,
      active: false,
    },
    {
      title: 'Analyze the call',
      icon: ({ active }) => (
        <Troubleshoot
          sx={{
            color: active ? theme.palette.primary.main : '#677788',
          }}
        />
      ),
      completed: false,
      active: false,
    },
  ]);

  const browser = detect();

  const [numberOfDays, setNumberOfDays] = useState(90);

  const [practiceCallScoreLabels, setPracticeCallScoreLabels] = useState([]);
  const [practiceCallScoreValues, setPracticeCallScoreValues] = useState([]);

  const [salesCallScoreLabels, setSalesCallScoreLabels] = useState([]);
  const [salesCallScoreValues, setSalesCallScoreValues] = useState([]);

  const [practiceCallFillerLabels, setPracticeCallFillerLabels] = useState([]);
  const [practiceCallFillerValues, setPracticeCallFillerValues] = useState([]);

  const [salesCallFillerLabels, setSalesCallFillerLabels] = useState([]);
  const [salesCallFillerValues, setSalesCallFillerValues] = useState([]);

  const [
    practiceCallRepetitionLabels,
    setPracticeCallRepetitionLabels,
  ] = useState([]);
  const [
    practiceCallRepetitionValues,
    setPracticeCallRepetitionValues,
  ] = useState([]);

  const [salesCallRepetitionLabels, setSalesCallRepetitionLabels] = useState(
    [],
  );
  const [salesCallRepetitionValues, setSalesCallRepetitionValues] = useState(
    [],
  );

  const [practiceCallFillers, setPracticeCallFillers] = useState({});
  const [selectedPracticeCallFiller, setSelectedPracticeCallFiller] = useState(
    [],
  );

  const [
    practiceCallFillersByDateLabels,
    setPracticeCallFillersByDateLabels,
  ] = useState([]);
  const [
    practiceCallFillersByDateValues,
    setPracticeCallFillersByDateValues,
  ] = useState([]);

  const [salesCallFillers, setSalesCallFillers] = useState({});
  const [selectedSalesCallFiller, setSelectedSalesCallFiller] = useState([]);

  const [
    salesCallFillersByDateLabels,
    setSalesCallFillersByDateLabels,
  ] = useState([]);
  const [
    salesCallFillersByDateValues,
    setSalesCallFillersByDateValues,
  ] = useState([]);

  const [practiceCallRepetitions, setPracticeCallRepetitions] = useState({});
  const [
    selectedPracticeCallRepetition,
    setSelectedPracticeCallRepetition,
  ] = useState([]);

  const [
    practiceCallRepetitionsByDateLabels,
    setPracticeCallRepetitionsByDateLabels,
  ] = useState([]);
  const [
    practiceCallRepetitionsByDateValues,
    setPracticeCallRepetitionsByDateValues,
  ] = useState([]);

  const [salesCallRepetitions, setSalesCallRepetitions] = useState({});
  const [
    selectedSalesCallRepetition,
    setSelectedSalesCallRepetition,
  ] = useState([]);

  const [
    salesCallRepetitionsByDateLabels,
    setSalesCallRepetitionsByDateLabels,
  ] = useState([]);
  const [
    salesCallRepetitionsByDateValues,
    setSalesCallRepetitionsByDateValues,
  ] = useState([]);

  const [practiceCallPaceGraph, setPracticeCallPaceGraph] = useState([]);
  const [salesCallPaceGraph, setSalesCallPaceGraph] = useState([]);
  const [
    practiceCallPerformanceData,
    setPracticeCallPerformanceData,
  ] = useState(null);
  const [salesCallPerformanceData, setSalesCallPerformanceData] = useState(
    null,
  );

  const [salesCallSpeakerProportion, setSalesCallSpeakerProportion] = useState(
    [],
  );
  const [
    practiceCallSpeakerProportion,
    setPracticeCallSpeakerProportion,
  ] = useState([]);

  const [practiceCallEmptyData, setPracticeCallEmptyData] = useState(false);
  const [salesCallEmptyData, setSalesCallEmptyData] = useState(false);

  const practicePitchDeliveryChartRef = useRef(null);
  const salesPitchDeliveryChartRef = useRef(null);

  const [
    practicePitchDeliveryDatasetVisibility,
    setPracticePitchDeliveryDatasetVisibility,
  ] = useState({
    Confidence: true,
    Enthusiasm: true,
    Fluency: true,
    Pace: true,
    Clarity: true,
  });

  const [
    salesPitchDeliveryDatasetVisibility,
    setSalesPitchDeliveryDatasetVisibility,
  ] = useState({
    Confidence: true,
    Enthusiasm: true,
    Fluency: true,
    Pace: true,
    Clarity: true,
  });

  const handleTrackEvent = (e, type) => {
    try {
      dispatch(
        trackEvent({
          context: 'click_event',
          eventType: type,
          userId: isLoggedIn ? userInfo?.id : null,
          time: new Date().getTime(),
          os: browser?.os,
          sessionId: isLoggedIn
            ? userInfo?.id
            : sessionStorage.getItem('trackingSessionId'),
          customData: {
            page_path: pathname,
            browser: browser?.name,
            browser_version: browser?.version,
          },
        }),
      );
    } catch (e) {
      console.log(e);
      dispatch(
        logError({
          message: e?.message,
          data: {
            formatted: {
              errorText: e?.message,
              severity: 'error',
            },
            raw: e,
          },
        }),
      );
    }
  };

  useEffect(() => {
    if (uId) {
      dispatch(
        getPerformanceDataByUserId(
          uId,
          numberOfDays,
          'practice_call',
          (data) => {
            console.log('practice call performance', data);
            setPracticeCallPerformanceData(data);
            setPracticeCallScoreLabels(
              data?.oneNumRecs?.label?.map((date) =>
                moment(
                  new Date(
                    Number(date?.split('/')?.[2]),
                    Number(date?.split('/')?.[1]) - 1,
                    Number(date?.split('/')?.[0]),
                  ).getTime(),
                ).format('DD MMM'),
              ),
            );
            setPracticeCallScoreValues({
              confidence: data?.oneNumRecs?.confidence,
              enthu: data?.oneNumRecs?.enthu,
              fluency: data?.oneNumRecs?.fluency,
              pace: data?.oneNumRecs?.pace,
              calls: data?.oneNumRecs?.calls,
              clarity: data?.oneNumRecs?.clarity,
            });
            setPracticeCallFillerLabels(
              data?.filler?.totalFillersByDate?.map((fillerByDate) =>
                moment(
                  new Date(
                    Number(fillerByDate[0]?.split('/')?.[2]),
                    Number(fillerByDate[0]?.split('/')?.[1]) - 1,
                    Number(fillerByDate[0]?.split('/')?.[0]),
                  ).getTime(),
                ).format('DD MMM'),
              ),
            );
            setPracticeCallFillerValues(
              data?.filler?.totalFillersByDate?.map(
                (fillerByDate) => fillerByDate[1],
              ),
            );
            setPracticeCallRepetitionLabels(
              data?.repetition?.totalRepetitionsByDate?.map(
                (repetitionByDate) =>
                  moment(
                    new Date(
                      Number(repetitionByDate[0]?.split('/')?.[2]),
                      Number(repetitionByDate[0]?.split('/')?.[1]) - 1,
                      Number(repetitionByDate[0]?.split('/')?.[0]),
                    ).getTime(),
                  ).format('DD MMM'),
              ),
            );
            setPracticeCallRepetitionValues(
              data?.repetition?.totalRepetitionsByDate?.map(
                (repetitionByDate) => repetitionByDate[1],
              ),
            );
            setPracticeCallFillers(data?.filler?.fillersByDate);
            setSelectedPracticeCallFiller([
              Object.keys(data?.filler?.fillersByDate || {})?.[0],
            ]);
            setPracticeCallRepetitions(data?.repetition?.repetitionsByDate);
            setSelectedPracticeCallRepetition([
              Object.keys(data?.repetition?.repetitionsByDate || {})?.[0],
            ]);
            setPracticeCallPaceGraph(data?.paceGraph);
            setPracticeCallSpeakerProportion([
              Math.round(
                ((100 -
                  (data?.totalSpeakerTimeInRangePercent +
                    data?.totalSilenceTimeInRangePercent)) /
                  (data?.totalSpeakerTimeInRangePercent +
                    (100 -
                      (data?.totalSpeakerTimeInRangePercent +
                        data?.totalSilenceTimeInRangePercent)))) *
                  100,
              ),
              Math.round(
                (data?.totalSpeakerTimeInRangePercent /
                  (data?.totalSpeakerTimeInRangePercent +
                    (100 -
                      (data?.totalSpeakerTimeInRangePercent +
                        data?.totalSilenceTimeInRangePercent)))) *
                  100,
              ),
            ]);
          },
        ),
      );
      dispatch(
        getPerformanceDataByUserId(
          uId,
          numberOfDays,
          'sales_review',
          (data) => {
            console.log('sales call analysis performance', data);
            setSalesCallPerformanceData(data);
            setSalesCallScoreLabels(
              data?.oneNumRecs?.label?.map((date) =>
                moment(
                  new Date(
                    Number(date?.split('/')?.[2]),
                    Number(date?.split('/')?.[1]) - 1,
                    Number(date?.split('/')?.[0]),
                  ).getTime(),
                ).format('DD MMM'),
              ),
            );
            setSalesCallScoreValues({
              confidence: data?.oneNumRecs?.confidence,
              enthu: data?.oneNumRecs?.enthu,
              fluency: data?.oneNumRecs?.fluency,
              pace: data?.oneNumRecs?.pace,
              calls: data?.oneNumRecs?.calls,
              clarity: data?.oneNumRecs?.clarity,
            });
            setSalesCallFillerLabels(
              data?.filler?.totalFillersByDate?.map((fillerByDate) =>
                moment(
                  new Date(
                    Number(fillerByDate[0]?.split('/')?.[2]),
                    Number(fillerByDate[0]?.split('/')?.[1]) - 1,
                    Number(fillerByDate[0]?.split('/')?.[0]),
                  ).getTime(),
                ).format('DD MMM'),
              ),
            );
            setSalesCallFillerValues(
              data?.filler?.totalFillersByDate?.map(
                (fillerByDate) => fillerByDate[1],
              ),
            );
            setSalesCallRepetitionLabels(
              data?.repetition?.totalRepetitionsByDate?.map(
                (repetitionByDate) =>
                  moment(
                    new Date(
                      Number(repetitionByDate[0]?.split('/')?.[2]),
                      Number(repetitionByDate[0]?.split('/')?.[1]) - 1,
                      Number(repetitionByDate[0]?.split('/')?.[0]),
                    ).getTime(),
                  ).format('DD MMM'),
              ),
            );
            setSalesCallRepetitionValues(
              data?.repetition?.totalRepetitionsByDate?.map(
                (repetitionByDate) => repetitionByDate[1],
              ),
            );
            setSalesCallFillers(data?.filler?.fillersByDate);
            setSelectedSalesCallFiller([
              Object.keys(data?.filler?.fillersByDate || {})?.[0],
            ]);
            setSalesCallRepetitions(data?.repetition?.repetitionsByDate);
            setSelectedSalesCallRepetition([
              Object.keys(data?.repetition?.repetitionsByDate || {})?.[0],
            ]);
            setSalesCallPaceGraph(data?.paceGraph);
            setSalesCallSpeakerProportion([
              Math.round(
                ((100 -
                  (data?.totalSpeakerTimeInRangePercent +
                    data?.totalSilenceTimeInRangePercent)) /
                  (data?.totalSpeakerTimeInRangePercent +
                    (100 -
                      (data?.totalSpeakerTimeInRangePercent +
                        data?.totalSilenceTimeInRangePercent)))) *
                  100,
              ),
              Math.round(
                (data?.totalSpeakerTimeInRangePercent /
                  (data?.totalSpeakerTimeInRangePercent +
                    (100 -
                      (data?.totalSpeakerTimeInRangePercent +
                        data?.totalSilenceTimeInRangePercent)))) *
                  100,
              ),
            ]);
          },
        ),
      );
    } else {
      dispatch(
        getPerformanceData(numberOfDays, 'practice_call', (data) => {
          console.log('practice call performance', data);
          setPracticeCallPerformanceData(data);
          setPracticeCallScoreLabels(
            data?.oneNumRecs?.label?.map((date) =>
              moment(
                new Date(
                  Number(date?.split('/')?.[2]),
                  Number(date?.split('/')?.[1]) - 1,
                  Number(date?.split('/')?.[0]),
                ).getTime(),
              ).format('DD MMM'),
            ),
          );
          setPracticeCallScoreValues({
            confidence: data?.oneNumRecs?.confidence,
            enthu: data?.oneNumRecs?.enthu,
            fluency: data?.oneNumRecs?.fluency,
            pace: data?.oneNumRecs?.pace,
            calls: data?.oneNumRecs?.calls,
            clarity: data?.oneNumRecs?.clarity,
          });
          setPracticeCallFillerLabels(
            data?.filler?.totalFillersByDate?.map((fillerByDate) =>
              moment(
                new Date(
                  Number(fillerByDate[0]?.split('/')?.[2]),
                  Number(fillerByDate[0]?.split('/')?.[1]) - 1,
                  Number(fillerByDate[0]?.split('/')?.[0]),
                ).getTime(),
              ).format('DD MMM'),
            ),
          );
          setPracticeCallFillerValues(
            data?.filler?.totalFillersByDate?.map(
              (fillerByDate) => fillerByDate[1],
            ),
          );
          setPracticeCallRepetitionLabels(
            data?.repetition?.totalRepetitionsByDate?.map((repetitionByDate) =>
              moment(
                new Date(
                  Number(repetitionByDate[0]?.split('/')?.[2]),
                  Number(repetitionByDate[0]?.split('/')?.[1]) - 1,
                  Number(repetitionByDate[0]?.split('/')?.[0]),
                ).getTime(),
              ).format('DD MMM'),
            ),
          );
          setPracticeCallRepetitionValues(
            data?.repetition?.totalRepetitionsByDate?.map(
              (repetitionByDate) => repetitionByDate[1],
            ),
          );
          setPracticeCallFillers(data?.filler?.fillersByDate);
          setSelectedPracticeCallFiller([
            Object.keys(data?.filler?.fillersByDate || {})?.[0],
          ]);
          setPracticeCallRepetitions(data?.repetition?.repetitionsByDate);
          setSelectedPracticeCallRepetition([
            Object.keys(data?.repetition?.repetitionsByDate || {})?.[0],
          ]);
          setPracticeCallPaceGraph(data?.paceGraph);
          setPracticeCallSpeakerProportion([
            Math.round(
              ((100 -
                (data?.totalSpeakerTimeInRangePercent +
                  data?.totalSilenceTimeInRangePercent)) /
                (data?.totalSpeakerTimeInRangePercent +
                  (100 -
                    (data?.totalSpeakerTimeInRangePercent +
                      data?.totalSilenceTimeInRangePercent)))) *
                100,
            ),
            Math.round(
              (data?.totalSpeakerTimeInRangePercent /
                (data?.totalSpeakerTimeInRangePercent +
                  (100 -
                    (data?.totalSpeakerTimeInRangePercent +
                      data?.totalSilenceTimeInRangePercent)))) *
                100,
            ),
          ]);
        }),
      );
      dispatch(
        getPerformanceData(numberOfDays, 'sales_review', (data) => {
          console.log('sales call analysis performance', data);
          setSalesCallPerformanceData(data);
          setSalesCallScoreLabels(
            data?.oneNumRecs?.label?.map((date) =>
              moment(
                new Date(
                  Number(date?.split('/')?.[2]),
                  Number(date?.split('/')?.[1]) - 1,
                  Number(date?.split('/')?.[0]),
                ).getTime(),
              ).format('DD MMM'),
            ),
          );
          setSalesCallScoreValues({
            confidence: data?.oneNumRecs?.confidence,
            enthu: data?.oneNumRecs?.enthu,
            fluency: data?.oneNumRecs?.fluency,
            pace: data?.oneNumRecs?.pace,
            calls: data?.oneNumRecs?.calls,
            clarity: data?.oneNumRecs?.clarity,
          });
          setSalesCallFillerLabels(
            data?.filler?.totalFillersByDate?.map((fillerByDate) =>
              moment(
                new Date(
                  Number(fillerByDate[0]?.split('/')?.[2]),
                  Number(fillerByDate[0]?.split('/')?.[1]) - 1,
                  Number(fillerByDate[0]?.split('/')?.[0]),
                ).getTime(),
              ).format('DD MMM'),
            ),
          );
          setSalesCallFillerValues(
            data?.filler?.totalFillersByDate?.map(
              (fillerByDate) => fillerByDate[1],
            ),
          );
          setSalesCallRepetitionLabels(
            data?.repetition?.totalRepetitionsByDate?.map((repetitionByDate) =>
              moment(
                new Date(
                  Number(repetitionByDate[0]?.split('/')?.[2]),
                  Number(repetitionByDate[0]?.split('/')?.[1]) - 1,
                  Number(repetitionByDate[0]?.split('/')?.[0]),
                ).getTime(),
              ).format('DD MMM'),
            ),
          );
          setSalesCallRepetitionValues(
            data?.repetition?.totalRepetitionsByDate?.map(
              (repetitionByDate) => repetitionByDate[1],
            ),
          );
          setSalesCallFillers(data?.filler?.fillersByDate);
          setSelectedSalesCallFiller([
            Object.keys(data?.filler?.fillersByDate || {})?.[0],
          ]);
          setSalesCallRepetitions(data?.repetition?.repetitionsByDate);
          setSelectedSalesCallRepetition([
            Object.keys(data?.repetition?.repetitionsByDate || {})?.[0],
          ]);
          setSalesCallPaceGraph(data?.paceGraph);
          setSalesCallSpeakerProportion([
            Math.round(
              ((100 -
                (data?.totalSpeakerTimeInRangePercent +
                  data?.totalSilenceTimeInRangePercent)) /
                (data?.totalSpeakerTimeInRangePercent +
                  (100 -
                    (data?.totalSpeakerTimeInRangePercent +
                      data?.totalSilenceTimeInRangePercent)))) *
                100,
            ),
            Math.round(
              (data?.totalSpeakerTimeInRangePercent /
                (data?.totalSpeakerTimeInRangePercent +
                  (100 -
                    (data?.totalSpeakerTimeInRangePercent +
                      data?.totalSilenceTimeInRangePercent)))) *
                100,
            ),
          ]);
        }),
      );
    }
  }, [numberOfDays]);

  useEffect(() => {
    if (selectedPracticeCallFiller && selectedPracticeCallFiller.length > 0) {
      const uniqueDates = [
        ...new Set([
          ...Object.values(practiceCallFillers || {})
            ?.map((pcfv) => pcfv?.map((fv) => fv[0]))
            .flat(Infinity),
        ]),
      ];
      setPracticeCallFillersByDateLabels(
        [
          ...new Set([
            ...Object.values(practiceCallFillers || {})
              ?.map((pcfv) => pcfv?.map((fv) => fv[0]))
              .flat(Infinity),
          ]),
        ]
          ?.map((fillerByDate) =>
            new Date(
              Number(fillerByDate?.split('/')?.[2]),
              Number(fillerByDate?.split('/')?.[1]) - 1,
              Number(fillerByDate?.split('/')?.[0]),
            ).getTime(),
          )
          ?.sort()
          ?.map((timeStamp) => moment(timeStamp).format('DD MMM')),
      );
      setPracticeCallFillersByDateValues(
        selectedPracticeCallFiller?.map((filler, index) => {
          return {
            cubicInterpolationMode: 'monotone',
            label: filler,
            data: uniqueDates?.map(
              (date) =>
                practiceCallFillers?.[filler]?.find(
                  (ar) => ar[0] === date,
                )?.[1] || 0,
            ),
            borderColor: theme.palette.primary.main,
            backgroundColor: theme.palette.primary.main,
          };
        }),
      );
    }
  }, [selectedPracticeCallFiller]);

  useEffect(() => {
    if (selectedSalesCallFiller && selectedSalesCallFiller.length > 0) {
      const uniqueDates = [
        ...new Set([
          ...Object.values(salesCallFillers || {})
            ?.map((pcfv) => pcfv?.map((fv) => fv[0]))
            .flat(Infinity),
        ]),
      ];
      setSalesCallFillersByDateLabels(
        [
          ...new Set([
            ...Object.values(salesCallFillers || {})
              ?.map((pcfv) => pcfv?.map((fv) => fv[0]))
              .flat(Infinity),
          ]),
        ]
          ?.map((fillerByDate) =>
            new Date(
              Number(fillerByDate?.split('/')?.[2]),
              Number(fillerByDate?.split('/')?.[1]) - 1,
              Number(fillerByDate?.split('/')?.[0]),
            ).getTime(),
          )
          ?.sort()
          ?.map((timeStamp) => moment(timeStamp).format('DD MMM')),
      );
      setSalesCallFillersByDateValues(
        selectedSalesCallFiller?.map((filler, index) => {
          return {
            cubicInterpolationMode: 'monotone',
            label: filler,
            data: uniqueDates?.map(
              (date) =>
                salesCallFillers?.[filler]?.find((ar) => ar[0] === date)?.[1] ||
                0,
            ),
            borderColor: theme.palette.primary.main,
            backgroundColor: theme.palette.primary.main,
          };
        }),
      );
    }
  }, [selectedSalesCallFiller]);

  useEffect(() => {
    if (
      selectedPracticeCallRepetition &&
      selectedPracticeCallRepetition.length > 0
    ) {
      const uniqueDates = [
        ...new Set([
          ...Object.values(practiceCallRepetitions || {})
            ?.map((pcfv) => pcfv?.map((fv) => fv[0]))
            .flat(Infinity),
        ]),
      ];
      setPracticeCallRepetitionsByDateLabels(
        [
          ...new Set([
            ...Object.values(practiceCallRepetitions || {})
              ?.map((pcfv) => pcfv?.map((fv) => fv[0]))
              .flat(Infinity),
          ]),
        ]
          ?.map((fillerByDate) =>
            new Date(
              Number(fillerByDate?.split('/')?.[2]),
              Number(fillerByDate?.split('/')?.[1]) - 1,
              Number(fillerByDate?.split('/')?.[0]),
            ).getTime(),
          )
          ?.sort()
          ?.map((timeStamp) => moment(timeStamp).format('DD MMM')),
      );
      setPracticeCallRepetitionsByDateValues(
        selectedPracticeCallRepetition?.map((filler, index) => {
          return {
            cubicInterpolationMode: 'monotone',
            label: filler,
            data: uniqueDates?.map(
              (date) =>
                practiceCallRepetitions?.[filler]?.find(
                  (ar) => ar[0] === date,
                )?.[1] || 0,
            ),
            borderColor: theme.palette.primary.main,
            backgroundColor: theme.palette.primary.main,
          };
        }),
      );
    }
  }, [selectedPracticeCallRepetition]);

  useEffect(() => {
    if (selectedSalesCallRepetition && selectedSalesCallRepetition.length > 0) {
      const uniqueDates = [
        ...new Set([
          ...Object.values(salesCallRepetitions || {})
            ?.map((pcfv) => pcfv?.map((fv) => fv[0]))
            .flat(Infinity),
        ]),
      ];
      setSalesCallRepetitionsByDateLabels(
        [
          ...new Set([
            ...Object.values(salesCallRepetitions || {})
              ?.map((pcfv) => pcfv?.map((fv) => fv[0]))
              .flat(Infinity),
          ]),
        ]
          ?.map((fillerByDate) =>
            new Date(
              Number(fillerByDate?.split('/')?.[2]),
              Number(fillerByDate?.split('/')?.[1]) - 1,
              Number(fillerByDate?.split('/')?.[0]),
            ).getTime(),
          )
          ?.sort()
          ?.map((timeStamp) => moment(timeStamp).format('DD MMM')),
      );
      setSalesCallRepetitionsByDateValues(
        selectedSalesCallRepetition?.map((filler, index) => {
          return {
            cubicInterpolationMode: 'monotone',
            label: filler,
            data: uniqueDates?.map(
              (date) =>
                salesCallRepetitions?.[filler]?.find(
                  (ar) => ar[0] === date,
                )?.[1] || 0,
            ),
            borderColor: theme.palette.primary.main,
            backgroundColor: theme.palette.primary.main,
          };
        }),
      );
    }
  }, [selectedSalesCallRepetition]);

  useEffect(() => {
    if (practiceCallPerformanceData?.totalAnalyzed) {
      setPracticeCallEmptyData(false);
    } else {
      setPracticeCallEmptyData(true);
    }
  }, [practiceCallPerformanceData]);

  useEffect(() => {
    if (salesCallPerformanceData?.totalAnalyzed) {
      setSalesCallEmptyData(false);
    } else {
      setSalesCallEmptyData(true);
    }
  }, [salesCallPerformanceData]);

  useEffect(() => {
    if (practicePitchDeliveryChartRef?.current) {
      practicePitchDeliveryChartRef?.current?.setDatasetVisibility(
        0,
        practicePitchDeliveryDatasetVisibility?.Confidence,
      );
      practicePitchDeliveryChartRef?.current?.setDatasetVisibility(
        1,
        practicePitchDeliveryDatasetVisibility?.Enthusiasm,
      );
      practicePitchDeliveryChartRef?.current?.setDatasetVisibility(
        2,
        practicePitchDeliveryDatasetVisibility?.Fluency,
      );
      practicePitchDeliveryChartRef?.current?.setDatasetVisibility(
        3,
        practicePitchDeliveryDatasetVisibility?.Pace,
      );
      practicePitchDeliveryChartRef?.current?.setDatasetVisibility(
        4,
        practicePitchDeliveryDatasetVisibility?.Clarity,
      );
      practicePitchDeliveryChartRef?.current?.update();
    }
  }, [practicePitchDeliveryDatasetVisibility, practicePitchDeliveryChartRef]);

  useEffect(() => {
    if (salesPitchDeliveryChartRef?.current) {
      salesPitchDeliveryChartRef?.current?.setDatasetVisibility(
        0,
        salesPitchDeliveryDatasetVisibility?.Confidence,
      );
      salesPitchDeliveryChartRef?.current?.setDatasetVisibility(
        1,
        salesPitchDeliveryDatasetVisibility?.Enthusiasm,
      );
      salesPitchDeliveryChartRef?.current?.setDatasetVisibility(
        2,
        salesPitchDeliveryDatasetVisibility?.Fluency,
      );
      salesPitchDeliveryChartRef?.current?.setDatasetVisibility(
        3,
        salesPitchDeliveryDatasetVisibility?.Pace,
      );
      salesPitchDeliveryChartRef?.current?.setDatasetVisibility(
        4,
        salesPitchDeliveryDatasetVisibility?.Clarity,
      );
      salesPitchDeliveryChartRef?.current?.update();
    }
  }, [salesPitchDeliveryDatasetVisibility, salesPitchDeliveryChartRef]);

  // console.log(selectedAudio);
  // console.log(saleReviewId);
  // console.log(viewType);
  // console.log(salesReview);
  // console.log(new Date(startDate));
  // console.log(new Date(endDate));
  // console.log(Object.values(practiceCallFillers || {}));
  // console.log(practiceCallFillers);
  // console.log(salesCallFillers);
  // console.log(practiceCallTopFillers);
  // console.log(practiceCallFillersByDateLabels);
  // console.log(practiceCallFillersByDateValues);
  // console.log(practiceCallSpeakerProportion);
  // console.log(practiceCallFillersByDateValues);

  return (
    <Box
      sx={{
        width: {
          xs: '100%',
          sm: isLoggedIn ? 'calc(100% - 80px)' : 1,
          md:
            isLoggedIn && !uId
              ? dashboardSidebarExpanded
                ? 'calc(100% - 240px)'
                : 'calc(100% - 80px)'
              : 1,
        },
        marginLeft: {
          xs: '0px',
          sm: isLoggedIn ? '80px' : 0,
          md:
            isLoggedIn && !uId
              ? dashboardSidebarExpanded
                ? '240px'
                : '80px'
              : 0,
        },
        height: 1,
        minHeight: isLoggedIn ? 'calc(100vh - 60px)' : 'calc(100vh - 146px)',
      }}
    >
      <Box
        display={'flex'}
        flexDirection={{ xs: 'column', sm: 'column', md: 'row' }}
        height={1}
      >
        <Box
          flex={{ xs: 'auto', sm: 'auto', md: 1 }}
          display={'flex'}
          flexDirection={{ xs: 'row', sm: 'row', md: 'column' }}
          alignItems={{
            xs: 'start',
            sm: 'start',
            md: fetching ? 'center' : 'start',
          }}
          justifyContent={{
            xs: 'start',
            sm: 'start',
            md: fetching ? 'center' : 'flex-start',
          }}
          height={1}
          gap={2}
          sx={{
            borderRight: '1px solid #f1f1f1',
            minHeight: 'calc(100vh - 60px)',
            padding: 2,
            paddingTop: 2,
          }}
        >
          <Box
            display={'flex'}
            alignItems={'center'}
            gap={1}
            width={member ? 0.5 : 1}
            justifyContent={'space-between'}
          >
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={numberOfDays}
                onChange={(e) => {
                  // handleTrackEvent(e, `selected_bot_change`);
                  setNumberOfDays(e.target.value);
                }}
              >
                <FormControlLabel
                  value={7}
                  control={<Radio />}
                  label={'1 Week'}
                />
                <FormControlLabel
                  value={30}
                  control={<Radio />}
                  label={'1 Month'}
                />
                <FormControlLabel
                  value={90}
                  control={<Radio />}
                  label={'3 Months'}
                />
              </RadioGroup>
            </FormControl>
            {member ? (
              <Box
                display={'flex'}
                flexDirection={'column'}
                // gap={1}
              >
                <Typography fontWeight={'bold'} color={'text.secondary'}>
                  {member?.email}
                </Typography>
                <Typography variant="caption">
                  {member?.fullName || ''}
                </Typography>
              </Box>
            ) : null}
          </Box>
          <Box display={'flex'} gap={4} width={1}>
            {member ? null : (
              <Box
                flex={1}
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                sx={{
                  background: '#d3d3d3',
                  p: 1,
                }}
              >
                <Typography>
                  {orgConfigs?.navBarTitles?.['Private Roleplays']?.title ||
                    'Private Roleplays'}
                </Typography>
              </Box>
            )}
            <Box
              flex={1}
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
              sx={{
                background: '#d3d3d3',
                p: 1,
              }}
            >
              <Typography>
                {orgConfigs?.navBarTitles?.['Sales Call Analysis']?.title ||
                  'Sales Call Analysis'}
              </Typography>
            </Box>
            {member ? (
              <Box
                flex={1}
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
              ></Box>
            ) : null}
          </Box>
          <Box display={'flex'} gap={4} width={1}>
            {member ? null : (
              <Box flex={1} display={'flex'} flexDirection={'column'} gap={2}>
                {practiceCallEmptyData ? (
                  <Card
                    sx={{
                      width: 1,
                      p: 2,
                      minHeight: 350,
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 1,
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Typography color={'text.secondary'} textAlign={'center'}>
                      No data found for the selected time range.
                    </Typography>
                    <Typography
                      color={'text.secondary'}
                      textAlign={'center'}
                      variant={'subtitle2'}
                    >
                      Select a bigger time range containing some analyzed calls
                      or do a new call followed by analysis to get performance
                      data.
                    </Typography>
                  </Card>
                ) : (
                  <>
                    <Card sx={{ width: 1, p: 2 }}>
                      <Box
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'center'}
                        gap={6}
                      >
                        <Box
                          sx={{
                            flex: 1,
                            position: 'relative',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            maxWidth: 280,
                          }}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              textAlign: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <Typography
                              variant="caption"
                              color={'text.secondary'}
                              // textAlign={'center'}
                            >
                              Call Participation
                            </Typography>
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                              }}
                            >
                              <Box
                                display={'flex'}
                                alignItems={'center'}
                                gap={1}
                              >
                                <Box
                                  sx={{
                                    width: 12,
                                    height: 12,
                                    background: '#79155B',
                                  }}
                                ></Box>
                                <Typography
                                  variant="caption"
                                  color={'text.secondary'}
                                >
                                  You
                                </Typography>
                              </Box>
                              <Box
                                display={'flex'}
                                alignItems={'center'}
                                gap={1}
                              >
                                <Box
                                  sx={{
                                    width: 12,
                                    height: 12,
                                    background: '#468B97',
                                  }}
                                ></Box>
                                <Typography
                                  variant="caption"
                                  color={'text.secondary'}
                                >
                                  Others
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                          <Pie
                            options={{
                              responsive: true,
                              layout: {
                                padding: 5,
                              },
                              plugins: {
                                legend: {
                                  display: false,
                                },
                                tooltip: {
                                  enabled: true,
                                  callbacks: {
                                    // title: (xDatapoint) => {return formatXValue(xDatapoint.raw)},
                                    label: (yDatapoint) => {
                                      // console.log(yDatapoint);
                                      return yDatapoint?.label;
                                    },
                                  },
                                },
                                title: {
                                  display: false,
                                  text: 'Call Participation',
                                },
                                datalabels: {
                                  color: 'white',
                                  font: {
                                    weight: 'bold',
                                    size: 16,
                                  },
                                  formatter: function(value, context) {
                                    return `${value}%`;
                                  },
                                },
                              },
                              elements: {
                                arc: {
                                  borderRadius: 5,
                                  borderSkipped: false,
                                },
                              },
                              scales: {
                                y: {
                                  display: false,
                                },
                                x: {
                                  display: false,
                                },
                              },
                            }}
                            data={{
                              labels: ['Others', 'You'],
                              datasets: [
                                {
                                  animation: false,
                                  label: '',
                                  data: practiceCallSpeakerProportion,
                                  backgroundColor: ['#468B97', '#79155B'],
                                },
                              ],
                            }}
                          />
                        </Box>
                        <Box
                          flex={1}
                          display={'flex'}
                          flexDirection={'column'}
                          gap={2}
                        >
                          <Typography
                            variant="body1"
                            color={'text.secondary'}
                            fontWeight={'bold'}
                          >
                            Total Roleplays Done:{' '}
                            <span
                              style={{ fontWeight: 'normal', fontSize: 14 }}
                            >
                              {practiceCallPerformanceData?.totalRecords}
                            </span>
                          </Typography>
                          <Typography
                            variant="body1"
                            color={'text.secondary'}
                            fontWeight={'bold'}
                          >
                            Total Roleplays Analyzed:{' '}
                            <span
                              style={{ fontWeight: 'normal', fontSize: 14 }}
                            >
                              {practiceCallPerformanceData?.totalAnalyzed}
                            </span>
                          </Typography>
                          <Typography
                            variant="body1"
                            color={'text.secondary'}
                            fontWeight={'bold'}
                          >
                            Total Roleplays Duration:{' '}
                            <span
                              style={{ fontWeight: 'normal', fontSize: 14 }}
                            >
                              {practiceCallPerformanceData?.totalDurationInRage}{' '}
                              minutes
                            </span>
                          </Typography>
                          <Typography
                            variant="body1"
                            color={'text.secondary'}
                            fontWeight={'bold'}
                          >
                            Your Speaking Duration:{' '}
                            <span
                              style={{ fontWeight: 'normal', fontSize: 14 }}
                            >
                              {
                                practiceCallPerformanceData?.totalSpeakerTimeInRange
                              }{' '}
                              minutes
                            </span>
                          </Typography>
                          <Typography
                            variant="body1"
                            color={'text.secondary'}
                            fontWeight={'bold'}
                          >
                            Number of Fillers used:{' '}
                            <span
                              style={{ fontWeight: 'normal', fontSize: 14 }}
                            >
                              {
                                practiceCallPerformanceData?.filler
                                  ?.totalFillersInRange
                              }
                            </span>
                          </Typography>
                          <Typography
                            variant="body1"
                            color={'text.secondary'}
                            fontWeight={'bold'}
                          >
                            Number of Repetitions used:{' '}
                            <span
                              style={{ fontWeight: 'normal', fontSize: 14 }}
                            >
                              {
                                practiceCallPerformanceData?.repetition
                                  ?.totalRepInRange
                              }
                            </span>
                          </Typography>
                        </Box>
                      </Box>
                    </Card>
                    <Card sx={{ width: 1, p: 2 }}>
                      <Typography
                        fontSize={12}
                        fontWeight={'bold'}
                        color={'text.secondary'}
                        textAlign={'center'}
                      >
                        Pitch Delivery
                      </Typography>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 1,
                          justifyContent: 'center',
                          padding: 1,
                        }}
                      >
                        {[
                          {
                            label: 'Confidence',
                            borderColor: '#652549',
                            backgroundColor: '#652549',
                          },
                          {
                            label: 'Enthusiasm',
                            borderColor: '#1d9cd3',
                            backgroundColor: '#1d9cd3',
                          },
                          {
                            label: 'Fluency',
                            borderColor: '#041944',
                            backgroundColor: '#041944',
                          },
                          {
                            label: 'Pace',
                            borderColor: '#4672c8',
                            backgroundColor: '#4672c8',
                          },
                          {
                            label: 'Clarity',
                            borderColor: '#41a854',
                            backgroundColor: '#41a854',
                          },
                        ].map((dataset, index) => (
                          <Box
                            key={dataset?.label}
                            display={'flex'}
                            alignItems={'center'}
                            gap={1}
                            style={{ cursor: 'pointer' }}
                            onClick={(e) => {
                              setPracticePitchDeliveryDatasetVisibility(
                                (pre) => {
                                  return index === 0
                                    ? {
                                        ...pre,
                                        Confidence: !pre?.Confidence,
                                      }
                                    : index === 1
                                    ? {
                                        ...pre,
                                        Enthusiasm: !pre?.Enthusiasm,
                                      }
                                    : index === 2
                                    ? {
                                        ...pre,
                                        Fluency: !pre?.Fluency,
                                      }
                                    : index === 3
                                    ? {
                                        ...pre,
                                        Pace: !pre?.Pace,
                                      }
                                    : index === 4
                                    ? {
                                        ...pre,
                                        Clarity: !pre?.Clarity,
                                      }
                                    : pre;
                                },
                              );
                            }}
                          >
                            <Box
                              sx={{
                                width: 14,
                                height: 14,
                                background: practicePitchDeliveryDatasetVisibility?.[
                                  index === 0
                                    ? 'Confidence'
                                    : index === 1
                                    ? 'Enthusiasm'
                                    : index === 2
                                    ? 'Fluency'
                                    : index === 3
                                    ? 'Pace'
                                    : index === 4
                                    ? 'Clarity'
                                    : ''
                                ]
                                  ? dataset?.backgroundColor
                                  : '#fff',
                                border: `2px solid ${dataset?.backgroundColor}`,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                            >
                              {practicePitchDeliveryDatasetVisibility?.[
                                index === 0
                                  ? 'Confidence'
                                  : index === 1
                                  ? 'Enthusiasm'
                                  : index === 2
                                  ? 'Fluency'
                                  : index === 3
                                  ? 'Pace'
                                  : index === 4
                                  ? 'Clarity'
                                  : ''
                              ] ? (
                                <Check sx={{ color: '#fff', fontSize: 14 }} />
                              ) : null}
                            </Box>
                            <Typography
                              variant="subtitle1"
                              color={'text.secondary'}
                              sx={
                                {
                                  // textDecoration: practicePitchDeliveryDatasetVisibility?.[
                                  //   index === 0
                                  //     ? 'Confidence'
                                  //     : index === 1
                                  //     ? 'Enthusiasm'
                                  //     : index === 2
                                  //     ? 'Fluency'
                                  //     : index === 3
                                  //     ? 'Pace'
                                  //     : ''
                                  // ]
                                  //   ? 'none'
                                  //   : 'line-through',
                                }
                              }
                            >
                              {dataset?.label}
                            </Typography>
                          </Box>
                        ))}
                      </Box>
                      <Line
                        ref={practicePitchDeliveryChartRef}
                        style={{
                          maxHeight: 'auto',
                          width: '100%',
                        }}
                        options={{
                          responsive: true,
                          layout: {
                            padding: 5,
                          },
                          plugins: {
                            legend: {
                              display: false,
                            },
                            tooltip: {
                              enabled: true,
                              callbacks: {
                                // title: (xDatapoint) => {return formatXValue(xDatapoint.raw)},
                                label: (yDatapoint) => {
                                  // console.log(yDatapoint);
                                  return `${yDatapoint?.dataset?.label}: ${yDatapoint?.formattedValue}`;
                                },
                                afterLabel: (yDatapoint) => {
                                  // console.log(yDatapoint);
                                  return `Calls: ${
                                    practiceCallScoreValues?.calls?.[
                                      yDatapoint?.dataIndex
                                    ]
                                  }`;
                                },
                              },
                            },
                            title: {
                              text: 'Pace',
                            },
                            datalabels: {
                              font: {
                                weight: 'bold',
                                size: 14,
                              },
                              formatter: function(value, context) {
                                return ``;
                              },
                            },
                          },
                          scales: {
                            x: {
                              display: true,
                              ticks: {
                                display: true,
                                // stepSize: Math.floor(on?.length / 5),
                                callback: function(value) {
                                  // console.log(this.getLabelForValue(value));
                                  return `${
                                    this.getLabelForValue(value)?.split(':')[0]
                                  }`;
                                },
                              },
                              grid: {
                                display: false,
                              },
                              title: {
                                display: false,
                                text: 'Date',
                              },
                            },
                            y: {
                              display: true,
                              ticks: {
                                display: true,
                                precision: false,
                              },
                              grid: {
                                display: false,
                              },
                              title: {
                                display: true,
                                text: 'Score',
                              },
                            },
                          },
                        }}
                        data={{
                          labels: practiceCallScoreLabels?.map(
                            (label, index) =>
                              `${label}: ${practiceCallScoreValues?.calls?.[index]}`,
                          ),
                          datasets: [
                            {
                              cubicInterpolationMode: 'monotone',
                              label: 'Confidence',
                              data: practiceCallScoreValues?.confidence,
                              borderColor: '#652549',
                              backgroundColor: '#652549',
                              fill: false,
                            },
                            {
                              cubicInterpolationMode: 'monotone',
                              label: 'Enthusiasm',
                              data: practiceCallScoreValues?.enthu,
                              borderColor: '#1d9cd3',
                              backgroundColor: '#1d9cd3',
                            },
                            {
                              cubicInterpolationMode: 'monotone',
                              label: 'Fluency',
                              data: practiceCallScoreValues?.fluency,
                              borderColor: '#041944',
                              backgroundColor: '#041944',
                            },
                            {
                              cubicInterpolationMode: 'monotone',
                              label: 'Pace',
                              data: practiceCallScoreValues?.pace,
                              borderColor: '#4672c8',
                              backgroundColor: '#4672c8',
                            },
                            {
                              cubicInterpolationMode: 'monotone',
                              label: 'Clarity',
                              data: practiceCallScoreValues?.clarity,
                              borderColor: '#41a854',
                              backgroundColor: '#41a854',
                            },
                          ],
                        }}
                      />
                    </Card>
                    <Card sx={{ width: 1, p: 2 }}>
                      <Bar
                        style={{
                          width: '100%',
                        }}
                        options={{
                          responsive: true,
                          layout: {
                            padding: 10,
                          },
                          plugins: {
                            legend: {
                              display: false,
                              // position: 'top',
                            },
                            tooltip: {
                              enabled: true,
                            },

                            title: {
                              display: true,
                              text: 'Top 10 Fillers',
                            },
                            datalabels: {
                              color: 'white',
                              font: {
                                weight: 'bold',
                                size: 14,
                              },
                              // rotation: -90,
                              formatter: function(value, context) {
                                return `${value}`;
                              },
                            },
                          },
                          elements: {
                            bar: {
                              borderRadius: 6,
                              borderSkipped: false,
                            },
                          },
                          scales: {
                            y: {
                              beginAtZero: true,
                              // max: 100,
                            },
                          },
                        }}
                        data={{
                          labels: Object.entries(
                            practiceCallPerformanceData?.filler
                              ?.top10FillersCount || {},
                          )
                            ?.sort((a, b) => b[1] - a[1])
                            ?.map((entry) => entry[0]),
                          datasets: [
                            {
                              label: 'Top 10 Fillers',
                              data: Object.entries(
                                practiceCallPerformanceData?.filler
                                  ?.top10FillersCount || {},
                              )
                                ?.sort((a, b) => b[1] - a[1])
                                ?.map((entry) => entry[1]),
                              backgroundColor: theme.palette.primary.main,
                              barThickness: 20,
                              pointStyle: 'circle',
                              fill: true,
                            },
                          ],
                        }}
                      />
                    </Card>
                    <Card sx={{ width: 1, p: 2 }}>
                      <Bar
                        style={{
                          width: '100%',
                        }}
                        options={{
                          responsive: true,
                          layout: {
                            padding: 10,
                          },
                          plugins: {
                            legend: {
                              display: false,
                              // position: 'top',
                            },
                            tooltip: {
                              enabled: true,
                            },

                            title: {
                              display: true,
                              text: 'Top 10 Repetitions',
                            },
                            datalabels: {
                              color: 'white',
                              font: {
                                weight: 'bold',
                                size: 14,
                              },
                              // rotation: -90,
                              formatter: function(value, context) {
                                return `${value}`;
                              },
                            },
                          },
                          elements: {
                            bar: {
                              borderRadius: 6,
                              borderSkipped: false,
                            },
                          },
                          scales: {
                            y: {
                              beginAtZero: true,
                              // max: 100,
                            },
                          },
                        }}
                        data={{
                          labels: Object.entries(
                            practiceCallPerformanceData?.repetition
                              ?.top10RepsCount || {},
                          )
                            ?.sort((a, b) => b[1] - a[1])
                            ?.map((entry) => entry[0]),
                          datasets: [
                            {
                              label: 'Top 10 Repetitions',
                              data: Object.entries(
                                practiceCallPerformanceData?.repetition
                                  ?.top10RepsCount || {},
                              )
                                ?.sort((a, b) => b[1] - a[1])
                                ?.map((entry) => entry[1]),
                              backgroundColor: theme.palette.primary.main,
                              barThickness: 20,
                              pointStyle: 'circle',
                              fill: true,
                            },
                          ],
                        }}
                      />
                    </Card>
                    <Card sx={{ width: 1, p: 2 }}>
                      <Line
                        style={{
                          maxHeight: 'auto',
                          width: '100%',
                        }}
                        options={{
                          responsive: true,
                          layout: {
                            padding: 5,
                          },
                          plugins: {
                            legend: {
                              display: true,
                            },
                            tooltip: {
                              enabled: true,
                            },
                            title: {
                              text: 'Pace',
                            },
                            datalabels: {
                              font: {
                                weight: 'bold',
                                size: 14,
                              },
                              formatter: function(value, context) {
                                return ``;
                              },
                            },
                          },
                          elements: {
                            bar: {
                              borderRadius: 12,
                              borderSkipped: false,
                            },
                          },
                          scales: {
                            x: {
                              display: true,
                              ticks: {
                                display: true,
                                // stepSize: Math.floor(on?.length / 5),
                              },
                              grid: {
                                display: false,
                              },
                              title: {
                                display: false,
                                text: 'Date',
                              },
                            },
                            y: {
                              display: true,
                              ticks: {
                                display: true,
                                precision: false,
                              },
                              grid: {
                                display: false,
                              },
                              title: {
                                display: true,
                                text: 'Fillers (per minute)',
                              },
                            },
                          },
                        }}
                        data={{
                          labels: practiceCallFillerLabels,
                          datasets: [
                            {
                              cubicInterpolationMode: 'monotone',
                              label: 'Filler Rate',
                              data: practiceCallFillerValues,
                              borderColor: theme.palette.primary.main,
                              backgroundColor: theme.palette.primary.main,
                            },
                            {
                              cubicInterpolationMode: 'monotone',
                              label: 'Repetition Rate',
                              data: practiceCallRepetitionValues,
                              borderColor: '#041944',
                              backgroundColor: '#041944',
                            },
                          ],
                        }}
                      />
                    </Card>
                    <Card sx={{ width: 1, p: 2 }}>
                      <Typography
                        fontSize={12}
                        fontWeight={'bold'}
                        color={'text.secondary'}
                        textAlign={'center'}
                        mb={2}
                      >
                        Top 10 Filler Rate
                      </Typography>
                      <Box
                        display={'flex'}
                        alignItems={'center'}
                        gap={1}
                        sx={{
                          paddingBottom: 1,
                          width: 'calc(50vw - 200px)',
                          overflowX: 'scroll',
                          '&::-webkit-scrollbar': {
                            height: '5px',
                          },
                          '&::-webkit-scrollbar-track': {
                            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                          },
                          '&::-webkit-scrollbar-thumb': {
                            backgroundColor: '#d3d3d3',
                            borderRadius: 2,
                            // outline: '1px solid slategrey',
                          },
                        }}
                      >
                        {Object.keys(practiceCallFillers || {})?.map(
                          (filler, index) => (
                            <Chip
                              key={`${filler}${index}`}
                              label={filler}
                              size="small"
                              color={
                                selectedPracticeCallFiller?.includes(filler)
                                  ? 'primary'
                                  : 'default'
                              }
                              sx={{
                                cursor: 'pointer',
                              }}
                              onClick={() =>
                                setSelectedPracticeCallFiller((prev) =>
                                  prev.includes(filler)
                                    ? prev.filter((fl) => fl !== filler)
                                    : [...prev, filler],
                                )
                              }
                            ></Chip>
                          ),
                        )}
                      </Box>
                      {selectedPracticeCallFiller &&
                      selectedPracticeCallFiller?.length > 0 ? (
                        <Line
                          style={{
                            maxHeight: 'auto',
                            width: '100%',
                          }}
                          options={{
                            responsive: true,
                            layout: {
                              padding: 5,
                            },
                            plugins: {
                              legend: {
                                display: false,
                              },
                              tooltip: {
                                enabled: true,
                              },
                              title: {
                                text: 'Pace',
                              },
                              datalabels: {
                                font: {
                                  weight: 'bold',
                                  size: 14,
                                },
                                formatter: function(value, context) {
                                  return ``;
                                },
                              },
                            },
                            elements: {
                              bar: {
                                borderRadius: 12,
                                borderSkipped: false,
                              },
                            },
                            scales: {
                              x: {
                                display: true,
                                ticks: {
                                  display: true,
                                  // stepSize: Math.floor(on?.length / 5),
                                },
                                grid: {
                                  display: false,
                                },
                                title: {
                                  display: false,
                                  text: 'Date',
                                },
                              },
                              y: {
                                display: true,
                                ticks: {
                                  display: true,
                                  precision: false,
                                },
                                grid: {
                                  display: false,
                                },
                                title: {
                                  display: true,
                                  text: 'Fillers (per minute)',
                                },
                              },
                            },
                          }}
                          data={{
                            labels: practiceCallFillersByDateLabels,
                            datasets: practiceCallFillersByDateValues,
                          }}
                        />
                      ) : null}
                    </Card>
                    <Card sx={{ width: 1, p: 2 }}>
                      <Typography
                        fontSize={12}
                        fontWeight={'bold'}
                        color={'text.secondary'}
                        textAlign={'center'}
                        mb={2}
                      >
                        Top 10 Repetition Rate
                      </Typography>
                      <Box
                        display={'flex'}
                        alignItems={'center'}
                        gap={1}
                        sx={{
                          paddingBottom: 1,
                          width: 'calc(50vw - 200px)',
                          overflowX: 'scroll',
                          '&::-webkit-scrollbar': {
                            height: '5px',
                          },
                          '&::-webkit-scrollbar-track': {
                            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                          },
                          '&::-webkit-scrollbar-thumb': {
                            backgroundColor: '#d3d3d3',
                            borderRadius: 2,
                            // outline: '1px solid slategrey',
                          },
                        }}
                      >
                        {Object.keys(practiceCallRepetitions || {})?.map(
                          (repetition, index) => (
                            <Chip
                              key={`${repetition}${index}`}
                              label={repetition}
                              size="small"
                              color={
                                selectedPracticeCallRepetition?.includes(
                                  repetition,
                                )
                                  ? 'primary'
                                  : 'default'
                              }
                              sx={{
                                cursor: 'pointer',
                              }}
                              onClick={() =>
                                setSelectedPracticeCallRepetition((prev) =>
                                  prev.includes(repetition)
                                    ? prev.filter((fl) => fl !== repetition)
                                    : [...prev, repetition],
                                )
                              }
                            ></Chip>
                          ),
                        )}
                      </Box>
                      {selectedPracticeCallRepetition &&
                      selectedPracticeCallRepetition?.length > 0 ? (
                        <Line
                          style={{
                            maxHeight: 'auto',
                            width: '100%',
                          }}
                          options={{
                            responsive: true,
                            layout: {
                              padding: 5,
                            },
                            plugins: {
                              legend: {
                                display: false,
                              },
                              tooltip: {
                                enabled: true,
                              },
                              title: {
                                text: 'Pace',
                              },
                              datalabels: {
                                font: {
                                  weight: 'bold',
                                  size: 14,
                                },
                                formatter: function(value, context) {
                                  return ``;
                                },
                              },
                            },
                            elements: {
                              bar: {
                                borderRadius: 12,
                                borderSkipped: false,
                              },
                            },
                            scales: {
                              x: {
                                display: true,
                                ticks: {
                                  display: true,
                                  // stepSize: Math.floor(on?.length / 5),
                                },
                                grid: {
                                  display: false,
                                },
                                title: {
                                  display: false,
                                  text: 'Date',
                                },
                              },
                              y: {
                                display: true,
                                ticks: {
                                  display: true,
                                  precision: false,
                                },
                                grid: {
                                  display: false,
                                },
                                title: {
                                  display: true,
                                  text: 'Repetitions (per minute)',
                                },
                              },
                            },
                          }}
                          data={{
                            labels: practiceCallRepetitionsByDateLabels,
                            datasets: practiceCallRepetitionsByDateValues,
                          }}
                        />
                      ) : null}
                    </Card>
                    {/* <Card sx={{ width: 1, p: 2 }}>
                <Typography
                  fontSize={12}
                  fontWeight={'bold'}
                  color={'text.secondary'}
                  textAlign={'center'}
                >
                  Pace Graph
                </Typography>
                <LineGraph
                  height={230}
                  labels={practiceCallPaceGraph?.labels}
                  data={practiceCallPaceGraph?.data}
                />
              </Card> */}
                  </>
                )}
              </Box>
            )}
            <Box flex={1} display={'flex'} flexDirection={'column'} gap={2}>
              {salesCallEmptyData ? (
                <Card
                  sx={{
                    width: 1,
                    p: 2,
                    minHeight: 350,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Typography color={'text.secondary'} textAlign={'center'}>
                    No data found for the selected time range.
                  </Typography>
                  <Typography
                    color={'text.secondary'}
                    textAlign={'center'}
                    variant={'subtitle2'}
                  >
                    Select a bigger time range containing some calls analyzed or
                    do upload few calls followed by analysis to get performance
                    data.
                  </Typography>
                </Card>
              ) : (
                <>
                  <Card sx={{ width: 1, p: 2 }}>
                    <Box
                      display={'flex'}
                      alignItems={'center'}
                      justifyContent={'center'}
                      gap={6}
                    >
                      <Box
                        sx={{
                          flex: 1,
                          position: 'relative',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          flexDirection: 'column',
                          maxWidth: 280,
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            textAlign: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <Typography
                            variant="caption"
                            color={'text.secondary'}
                            // textAlign={'center'}
                          >
                            Call Participation
                          </Typography>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 1,
                            }}
                          >
                            <Box display={'flex'} alignItems={'center'} gap={1}>
                              <Box
                                sx={{
                                  width: 12,
                                  height: 12,
                                  background: '#79155B',
                                }}
                              ></Box>
                              <Typography
                                variant="caption"
                                color={'text.secondary'}
                              >
                                You
                              </Typography>
                            </Box>
                            <Box display={'flex'} alignItems={'center'} gap={1}>
                              <Box
                                sx={{
                                  width: 12,
                                  height: 12,
                                  background: '#468B97',
                                }}
                              ></Box>
                              <Typography
                                variant="caption"
                                color={'text.secondary'}
                              >
                                Others
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                        <Pie
                          options={{
                            responsive: true,
                            layout: {
                              padding: 5,
                            },
                            plugins: {
                              legend: {
                                display: false,
                              },
                              tooltip: {
                                enabled: true,
                                callbacks: {
                                  // title: (xDatapoint) => {return formatXValue(xDatapoint.raw)},
                                  label: (yDatapoint) => {
                                    // console.log(yDatapoint);
                                    return yDatapoint?.label;
                                  },
                                },
                              },
                              title: {
                                display: false,
                                text: 'Call Participation',
                              },
                              datalabels: {
                                color: 'white',
                                font: {
                                  weight: 'bold',
                                  size: 16,
                                },
                                formatter: function(value, context) {
                                  return `${value}%`;
                                },
                              },
                            },
                            elements: {
                              arc: {
                                borderRadius: 5,
                                borderSkipped: false,
                              },
                            },
                            scales: {
                              y: {
                                display: false,
                              },
                              x: {
                                display: false,
                              },
                            },
                          }}
                          data={{
                            labels: ['Others', 'You'],
                            datasets: [
                              {
                                animation: false,
                                label: '',
                                data: salesCallSpeakerProportion,
                                backgroundColor: ['#468B97', '#79155B'],
                              },
                            ],
                          }}
                        />
                      </Box>
                      <Box
                        flex={1}
                        display={'flex'}
                        flexDirection={'column'}
                        gap={2}
                      >
                        <Typography
                          variant="body1"
                          color={'text.secondary'}
                          fontWeight={'bold'}
                        >
                          Recordings Uploaded:{' '}
                          <span style={{ fontWeight: 'normal', fontSize: 14 }}>
                            {salesCallPerformanceData?.totalRecords}
                          </span>
                        </Typography>
                        <Typography
                          variant="body1"
                          color={'text.secondary'}
                          fontWeight={'bold'}
                        >
                          Recordings Analyzed:{' '}
                          <span style={{ fontWeight: 'normal', fontSize: 14 }}>
                            {salesCallPerformanceData?.totalAnalyzed}
                          </span>
                        </Typography>
                        <Typography
                          variant="body1"
                          color={'text.secondary'}
                          fontWeight={'bold'}
                        >
                          Total Recorded Calls Duration:{' '}
                          <span style={{ fontWeight: 'normal', fontSize: 14 }}>
                            {salesCallPerformanceData?.totalDurationInRage}{' '}
                            minutes
                          </span>
                        </Typography>
                        <Typography
                          variant="body1"
                          color={'text.secondary'}
                          fontWeight={'bold'}
                        >
                          Duration for which you spoke:{' '}
                          <span style={{ fontWeight: 'normal', fontSize: 14 }}>
                            {salesCallPerformanceData?.totalSpeakerTimeInRange}{' '}
                            minutes
                          </span>
                        </Typography>
                        <Typography
                          variant="body1"
                          color={'text.secondary'}
                          fontWeight={'bold'}
                        >
                          Number of Fillers used:{' '}
                          <span style={{ fontWeight: 'normal', fontSize: 14 }}>
                            {
                              salesCallPerformanceData?.filler
                                ?.totalFillersInRange
                            }
                          </span>
                        </Typography>
                        <Typography
                          variant="body1"
                          color={'text.secondary'}
                          fontWeight={'bold'}
                        >
                          Number of Repetitions used:{' '}
                          <span style={{ fontWeight: 'normal', fontSize: 14 }}>
                            {
                              salesCallPerformanceData?.repetition
                                ?.totalRepInRange
                            }
                          </span>
                        </Typography>
                      </Box>
                    </Box>
                  </Card>
                  <Card sx={{ width: 1, p: 2 }}>
                    <Typography
                      fontSize={12}
                      fontWeight={'bold'}
                      color={'text.secondary'}
                      textAlign={'center'}
                    >
                      Pitch Delivery
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        justifyContent: 'center',
                        padding: 1,
                      }}
                    >
                      {[
                        {
                          label: 'Confidence',
                          borderColor: '#652549',
                          backgroundColor: '#652549',
                        },
                        {
                          label: 'Enthusiasm',
                          borderColor: '#1d9cd3',
                          backgroundColor: '#1d9cd3',
                        },
                        {
                          label: 'Fluency',
                          borderColor: '#041944',
                          backgroundColor: '#041944',
                        },
                        {
                          label: 'Pace',
                          borderColor: '#4672c8',
                          backgroundColor: '#4672c8',
                        },
                        {
                          label: 'Clarity',
                          borderColor: '#41a854',
                          backgroundColor: '#41a854',
                        },
                      ].map((dataset, index) => (
                        <Box
                          key={dataset?.label}
                          display={'flex'}
                          alignItems={'center'}
                          gap={1}
                          style={{ cursor: 'pointer' }}
                          onClick={(e) => {
                            setSalesPitchDeliveryDatasetVisibility((pre) => {
                              return index === 0
                                ? {
                                    ...pre,
                                    Confidence: !pre?.Confidence,
                                  }
                                : index === 1
                                ? {
                                    ...pre,
                                    Enthusiasm: !pre?.Enthusiasm,
                                  }
                                : index === 2
                                ? {
                                    ...pre,
                                    Fluency: !pre?.Fluency,
                                  }
                                : index === 3
                                ? {
                                    ...pre,
                                    Pace: !pre?.Pace,
                                  }
                                : index === 4
                                ? {
                                    ...pre,
                                    Clarity: !pre?.Clarity,
                                  }
                                : pre;
                            });
                          }}
                        >
                          <Box
                            sx={{
                              width: 14,
                              height: 14,
                              background: salesPitchDeliveryDatasetVisibility?.[
                                index === 0
                                  ? 'Confidence'
                                  : index === 1
                                  ? 'Enthusiasm'
                                  : index === 2
                                  ? 'Fluency'
                                  : index === 3
                                  ? 'Pace'
                                  : index === 4
                                  ? 'Clarity'
                                  : ''
                              ]
                                ? dataset?.backgroundColor
                                : '#fff',
                              border: `2px solid ${dataset?.backgroundColor}`,
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            {salesPitchDeliveryDatasetVisibility?.[
                              index === 0
                                ? 'Confidence'
                                : index === 1
                                ? 'Enthusiasm'
                                : index === 2
                                ? 'Fluency'
                                : index === 3
                                ? 'Pace'
                                : index === 4
                                ? 'Clarity'
                                : ''
                            ] ? (
                              <Check sx={{ color: '#fff', fontSize: 14 }} />
                            ) : null}
                          </Box>
                          <Typography
                            variant="subtitle1"
                            color={'text.secondary'}
                            sx={
                              {
                                // textDecoration: salesPitchDeliveryDatasetVisibility?.[
                                //   index === 0
                                //     ? 'Confidence'
                                //     : index === 1
                                //     ? 'Enthusiasm'
                                //     : index === 2
                                //     ? 'Fluency'
                                //     : index === 3
                                //     ? 'Pace'
                                //     : ''
                                // ]
                                //   ? 'none'
                                //   : 'line-through',
                              }
                            }
                          >
                            {dataset?.label}
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                    <Line
                      ref={salesPitchDeliveryChartRef}
                      style={{
                        maxHeight: 'auto',
                        width: '100%',
                      }}
                      options={{
                        responsive: true,
                        layout: {
                          padding: 5,
                        },
                        plugins: {
                          legend: {
                            display: false,
                          },
                          tooltip: {
                            enabled: true,
                            callbacks: {
                              // title: (xDatapoint) => {return formatXValue(xDatapoint.raw)},
                              label: (yDatapoint) => {
                                // console.log(yDatapoint);
                                return `${yDatapoint?.dataset?.label}: ${yDatapoint?.formattedValue}`;
                              },
                              afterLabel: (yDatapoint) => {
                                // console.log(yDatapoint);
                                return `Calls: ${
                                  salesCallScoreValues?.calls?.[
                                    yDatapoint?.dataIndex
                                  ]
                                }`;
                              },
                            },
                          },
                          title: {
                            text: 'Pace',
                          },
                          datalabels: {
                            font: {
                              weight: 'bold',
                              size: 14,
                            },
                            formatter: function(value, context) {
                              return ``;
                            },
                          },
                        },
                        elements: {
                          bar: {
                            borderRadius: 12,
                            borderSkipped: false,
                          },
                        },
                        scales: {
                          x: {
                            display: true,
                            ticks: {
                              display: true,
                              // stepSize: Math.floor(on?.length / 5),
                              callback: function(value) {
                                // console.log(this.getLabelForValue(value));
                                return `${
                                  this.getLabelForValue(value)?.split(':')[0]
                                }`;
                              },
                            },
                            grid: {
                              display: false,
                            },
                            title: {
                              display: false,
                              text: 'Date',
                            },
                          },
                          y: {
                            display: true,
                            ticks: {
                              display: true,
                              precision: false,
                            },
                            grid: {
                              display: false,
                            },
                            title: {
                              display: true,
                              text: 'Score',
                            },
                          },
                        },
                      }}
                      data={{
                        labels: salesCallScoreLabels?.map(
                          (label, index) =>
                            `${label}: ${salesCallScoreValues?.calls?.[index]}`,
                        ),
                        datasets: [
                          {
                            cubicInterpolationMode: 'monotone',
                            label: 'Confidence',
                            data: salesCallScoreValues?.confidence,
                            borderColor: '#652549',
                            backgroundColor: '#652549',
                          },
                          {
                            cubicInterpolationMode: 'monotone',
                            label: 'Enthusiasm',
                            data: salesCallScoreValues?.enthu,
                            borderColor: '#1d9cd3',
                            backgroundColor: '#1d9cd3',
                          },
                          {
                            cubicInterpolationMode: 'monotone',
                            label: 'Fluency',
                            data: salesCallScoreValues?.fluency,
                            borderColor: '#041944',
                            backgroundColor: '#041944',
                          },
                          {
                            cubicInterpolationMode: 'monotone',
                            label: 'Pace',
                            data: salesCallScoreValues?.pace,
                            borderColor: '#4672c8',
                            backgroundColor: '#4672c8',
                          },
                          {
                            cubicInterpolationMode: 'monotone',
                            label: 'Clarity',
                            data: salesCallScoreValues?.clarity,
                            borderColor: '#41a854',
                            backgroundColor: '#41a854',
                          },
                        ],
                      }}
                    />
                  </Card>
                  <Card sx={{ width: 1, p: 2 }}>
                    <Bar
                      style={{
                        width: '100%',
                      }}
                      options={{
                        responsive: true,
                        layout: {
                          padding: 10,
                        },
                        plugins: {
                          legend: {
                            display: false,
                            // position: 'top',
                          },
                          tooltip: {
                            enabled: true,
                          },

                          title: {
                            display: true,
                            text: 'Top 10 Fillers',
                          },
                          datalabels: {
                            color: 'white',
                            font: {
                              weight: 'bold',
                              size: 14,
                            },
                            // rotation: -90,
                            formatter: function(value, context) {
                              return `${value}`;
                            },
                          },
                        },
                        elements: {
                          bar: {
                            borderRadius: 6,
                            borderSkipped: false,
                          },
                        },
                        scales: {
                          y: {
                            beginAtZero: true,
                            // max: 100,
                          },
                        },
                      }}
                      data={{
                        labels: Object.entries(
                          salesCallPerformanceData?.filler?.top10FillersCount ||
                            {},
                        )
                          ?.sort((a, b) => b[1] - a[1])
                          ?.map((entry) => entry[0]),
                        datasets: [
                          {
                            label: 'Top 10 Fillers',
                            data: Object.entries(
                              salesCallPerformanceData?.filler
                                ?.top10FillersCount || {},
                            )
                              ?.sort((a, b) => b[1] - a[1])
                              ?.map((entry) => entry[1]),
                            backgroundColor: theme.palette.primary.main,
                            barThickness: 20,
                            pointStyle: 'circle',
                            fill: true,
                          },
                        ],
                      }}
                    />
                  </Card>
                  <Card sx={{ width: 1, p: 2 }}>
                    <Bar
                      style={{
                        width: '100%',
                      }}
                      options={{
                        responsive: true,
                        layout: {
                          padding: 10,
                        },
                        plugins: {
                          legend: {
                            display: false,
                            // position: 'top',
                          },
                          tooltip: {
                            enabled: true,
                          },

                          title: {
                            display: true,
                            text: 'Top 10 Repetitions',
                          },
                          datalabels: {
                            color: 'white',
                            font: {
                              weight: 'bold',
                              size: 14,
                            },
                            // rotation: -90,
                            formatter: function(value, context) {
                              return `${value}`;
                            },
                          },
                        },
                        elements: {
                          bar: {
                            borderRadius: 6,
                            borderSkipped: false,
                          },
                        },
                        scales: {
                          y: {
                            beginAtZero: true,
                            // max: 100,
                          },
                        },
                      }}
                      data={{
                        labels: Object.entries(
                          salesCallPerformanceData?.repetition
                            ?.top10RepsCount || {},
                        )
                          ?.sort((a, b) => b[1] - a[1])
                          ?.map((entry) => entry[0]),
                        datasets: [
                          {
                            label: 'Top 10 Repetitions',
                            data: Object.entries(
                              salesCallPerformanceData?.repetition
                                ?.top10RepsCount || {},
                            )
                              ?.sort((a, b) => b[1] - a[1])
                              ?.map((entry) => entry[1]),
                            backgroundColor: theme.palette.primary.main,
                            barThickness: 20,
                            pointStyle: 'circle',
                            fill: true,
                          },
                        ],
                      }}
                    />
                  </Card>
                  <Card sx={{ width: 1, p: 2 }}>
                    <Line
                      style={{
                        maxHeight: 'auto',
                        width: '100%',
                      }}
                      options={{
                        responsive: true,
                        layout: {
                          padding: 5,
                        },
                        plugins: {
                          legend: {
                            display: true,
                          },
                          tooltip: {
                            enabled: true,
                          },
                          title: {
                            text: 'Pace',
                          },
                          datalabels: {
                            font: {
                              weight: 'bold',
                              size: 14,
                            },
                            formatter: function(value, context) {
                              return ``;
                            },
                          },
                        },
                        elements: {
                          bar: {
                            borderRadius: 12,
                            borderSkipped: false,
                          },
                        },
                        scales: {
                          x: {
                            display: true,
                            ticks: {
                              display: true,
                              // stepSize: Math.floor(on?.length / 5),
                            },
                            grid: {
                              display: false,
                            },
                            title: {
                              display: false,
                              text: 'Date',
                            },
                          },
                          y: {
                            display: true,
                            ticks: {
                              display: true,
                              precision: false,
                            },
                            grid: {
                              display: false,
                            },
                            title: {
                              display: true,
                              text: 'Fillers (per minute)',
                            },
                          },
                        },
                      }}
                      data={{
                        labels: salesCallFillerLabels,
                        datasets: [
                          {
                            cubicInterpolationMode: 'monotone',
                            label: 'Filler Rate',
                            data: salesCallFillerValues,
                            borderColor: theme.palette.primary.main,
                            backgroundColor: theme.palette.primary.main,
                          },
                          {
                            cubicInterpolationMode: 'monotone',
                            label: 'Repetition Rate',
                            data: salesCallRepetitionValues,
                            borderColor: '#041944',
                            backgroundColor: '#041944',
                          },
                        ],
                      }}
                    />
                  </Card>
                  <Card sx={{ width: 1, p: 2 }}>
                    <Typography
                      fontSize={12}
                      fontWeight={'bold'}
                      color={'text.secondary'}
                      textAlign={'center'}
                      mb={2}
                    >
                      Top 10 Filler Rate
                    </Typography>
                    <Box
                      display={'flex'}
                      alignItems={'center'}
                      gap={1}
                      sx={{
                        paddingBottom: 1,
                        width: 'calc(50vw - 200px)',
                        overflowX: 'scroll',
                        '&::-webkit-scrollbar': {
                          height: '5px',
                          // width: '200px',
                        },
                        '&::-webkit-scrollbar-track': {
                          boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                          webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                        },
                        '&::-webkit-scrollbar-thumb': {
                          backgroundColor: '#d3d3d3',
                          borderRadius: 2,
                          // outline: '1px solid slategrey',
                        },
                      }}
                    >
                      {Object.keys(salesCallFillers || {})?.map(
                        (filler, index) => (
                          <Chip
                            key={`${filler}${index}`}
                            label={filler}
                            size="small"
                            color={
                              selectedSalesCallFiller?.includes(filler)
                                ? 'primary'
                                : 'default'
                            }
                            sx={{
                              cursor: 'pointer',
                            }}
                            onClick={() =>
                              setSelectedSalesCallFiller((prev) =>
                                prev.includes(filler)
                                  ? prev.filter((fl) => fl !== filler)
                                  : [...prev, filler],
                              )
                            }
                          ></Chip>
                        ),
                      )}
                    </Box>
                    {selectedSalesCallFiller &&
                    selectedSalesCallFiller?.length > 0 ? (
                      <Line
                        style={{
                          maxHeight: 'auto',
                          width: '100%',
                        }}
                        options={{
                          responsive: true,
                          layout: {
                            padding: 5,
                          },
                          plugins: {
                            legend: {
                              display: false,
                            },
                            tooltip: {
                              enabled: true,
                            },
                            title: {
                              text: 'Pace',
                            },
                            datalabels: {
                              font: {
                                weight: 'bold',
                                size: 14,
                              },
                              formatter: function(value, context) {
                                return ``;
                              },
                            },
                          },
                          elements: {
                            bar: {
                              borderRadius: 12,
                              borderSkipped: false,
                            },
                          },
                          scales: {
                            x: {
                              display: true,
                              ticks: {
                                display: true,
                                // stepSize: Math.floor(on?.length / 5),
                              },
                              grid: {
                                display: false,
                              },
                              title: {
                                display: false,
                                text: 'Date',
                              },
                            },
                            y: {
                              display: true,
                              ticks: {
                                display: true,
                                precision: false,
                              },
                              grid: {
                                display: false,
                              },
                              title: {
                                display: true,
                                text: 'Fillers (per minute)',
                              },
                            },
                          },
                        }}
                        data={{
                          labels: salesCallFillersByDateLabels,
                          datasets: salesCallFillersByDateValues,
                        }}
                      />
                    ) : null}
                  </Card>
                  <Card sx={{ width: 1, p: 2 }}>
                    <Typography
                      fontSize={12}
                      fontWeight={'bold'}
                      color={'text.secondary'}
                      textAlign={'center'}
                      mb={2}
                    >
                      Top 10 Repetition Rate
                    </Typography>
                    <Box
                      display={'flex'}
                      alignItems={'center'}
                      gap={1}
                      sx={{
                        paddingBottom: 1,
                        width: 'calc(50vw - 200px)',
                        overflowX: 'scroll',
                        '&::-webkit-scrollbar': {
                          height: '5px',
                        },
                        '&::-webkit-scrollbar-track': {
                          boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                          webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                        },
                        '&::-webkit-scrollbar-thumb': {
                          backgroundColor: '#d3d3d3',
                          borderRadius: 2,
                          // outline: '1px solid slategrey',
                        },
                      }}
                    >
                      {Object.keys(salesCallRepetitions || {})?.map(
                        (repetition, index) => (
                          <Chip
                            key={`${repetition}${index}`}
                            label={repetition}
                            size="small"
                            color={
                              selectedSalesCallRepetition?.includes(repetition)
                                ? 'primary'
                                : 'default'
                            }
                            sx={{
                              cursor: 'pointer',
                            }}
                            onClick={() =>
                              setSelectedSalesCallRepetition((prev) =>
                                prev.includes(repetition)
                                  ? prev.filter((fl) => fl !== repetition)
                                  : [...prev, repetition],
                              )
                            }
                          ></Chip>
                        ),
                      )}
                    </Box>
                    {selectedSalesCallRepetition &&
                    selectedSalesCallRepetition?.length > 0 ? (
                      <Line
                        style={{
                          maxHeight: 'auto',
                          width: '100%',
                        }}
                        options={{
                          responsive: true,
                          layout: {
                            padding: 5,
                          },
                          plugins: {
                            legend: {
                              display: false,
                            },
                            tooltip: {
                              enabled: true,
                            },
                            title: {
                              text: 'Pace',
                            },
                            datalabels: {
                              font: {
                                weight: 'bold',
                                size: 14,
                              },
                              formatter: function(value, context) {
                                return ``;
                              },
                            },
                          },
                          elements: {
                            bar: {
                              borderRadius: 12,
                              borderSkipped: false,
                            },
                          },
                          scales: {
                            x: {
                              display: true,
                              ticks: {
                                display: true,
                                // stepSize: Math.floor(on?.length / 5),
                              },
                              grid: {
                                display: false,
                              },
                              title: {
                                display: false,
                                text: 'Date',
                              },
                            },
                            y: {
                              display: true,
                              ticks: {
                                display: true,
                                precision: false,
                              },
                              grid: {
                                display: false,
                              },
                              title: {
                                display: true,
                                text: 'Repetitions (per minute)',
                              },
                            },
                          },
                        }}
                        data={{
                          labels: salesCallRepetitionsByDateLabels,
                          datasets: salesCallRepetitionsByDateValues,
                        }}
                      />
                    ) : null}
                  </Card>
                  {/* <Card sx={{ width: 1, p: 2 }}>
                <Typography
                  fontSize={12}
                  fontWeight={'bold'}
                  color={'text.secondary'}
                  textAlign={'center'}
                >
                  Pace Graph
                </Typography>
                <LineGraph
                  height={230}
                  labels={salesCallPaceGraph?.labels}
                  data={salesCallPaceGraph?.data}
                />
              </Card> */}
                </>
              )}
            </Box>
            {member ? (
              <Box
                flex={1}
                display={'flex'}
                flexDirection={'column'}
                gap={2}
              ></Box>
            ) : null}
          </Box>

          <Box display={'flex'} width={1}>
            {/* <Bar
              options={{
                responsive: true,
                // layout: {
                //   padding: 20,
                // },
                plugins: {
                  // legend: {
                  //   display: false,
                  //   // position: 'top',
                  // },
                  // tooltip: {
                  //   enabled: true,
                  // },
                  title: {
                    display: false,
                    text: 'Top Filler words(per day per minute)',
                  },
                  // datalabels: {
                  //   color: 'white',
                  //   font: {
                  //     weight: 'bold',
                  //     size: 20,
                  //   },
                  //   rotation: -90,
                  //   formatter: function(value, context) {
                  //     return `${value}`;
                  //   },
                  // },
                },
                elements: {
                  bar: {
                    borderRadius: 12,
                    borderSkipped: false,
                  },
                },
              }}
              data={{
                labels: Object.keys(practiceCallTopFillers || {}),
                datasets: [
                  {
                    label: 'uh',
                    data: ['', 75, 45, 65, 95, 65, 73, 63]?.filter(
                      (v) => v !== 0,
                    ),
                    backgroundColor: theme.palette.primary.main,
                    barThickness: 10,
                    pointStyle: 'circle',
                    fill: true,
                    // minBarLength: 1,
                  },
                ],
              }}
            /> */}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

PerformanceDashboard.propTypes = {
  uId: PropTypes.string,
  member: PropTypes.object,
};

export default PerformanceDashboard;
