import {
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
  useTheme,
  Modal,
  Divider,
  Paper,
  Avatar,
  Tabs,
  Tab,
  useMediaQuery,
  TextField,
  InputAdornment,
  Alert,
  InputLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
  FormControl,
  FormGroup,
  Checkbox,
  Select,
  MenuItem,
  Link,
  Tooltip,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Drawer,
  FormLabel,
  Chip,
  Switch,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Popover,
  Menu,
} from '@mui/material';
import { PublicFluid } from 'layouts';
import React, { useEffect, useRef, useState } from 'react';
import Container from 'components/Container';
import { useDispatch, useSelector } from 'react-redux';
import {
  Add,
  Article,
  Check,
  ChevronRight,
  Close,
  Delete,
  DeleteOutline,
  Edit,
  HelpOutline,
  MoreVert,
  Remove,
  SupportAgent,
  Visibility,
  VisibilityOff,
} from '@mui/icons-material';
import fileDownload from 'js-file-download';
import axios from 'utils/axios';
import showAlert from 'redux/actions/Common/showAlert';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { detect } from 'detect-browser';
import trackEvent from 'redux/actions/Common/trackEvent';
import logError from 'redux/actions/Common/logError';
import uploadResume from 'redux/actions/Candidate/uploadResume';
import updateProfile from 'redux/actions/Candidate/updateProfile';
import deleteResume from 'redux/actions/Candidate/deleteResume';
import getUserDetails from 'redux/actions/Common/getUserDetails';
import { arraysEqual, capitalizeText } from 'utils';
import getPlaybookHistory from 'redux/actions/Common/getPlaybookHistory';
import RichTextEditor from 'components/RichTextEditor';
import updatePlaybook from 'redux/actions/Common/updatePlaybook';
import getBots from 'redux/actions/Common/getBots';
import requestBot from 'redux/actions/Common/requestBot';
import updateGlobalOrganizationConfig from 'redux/actions/Common/updateGlobalOrganizationConfig';
import getBotRequests from 'redux/actions/Common/getBotRequests';
import updateUserDetails from 'redux/actions/Common/updateUserDetails';
import getDefaultPrompt from 'redux/actions/Common/getDefaultPrompt';
import { useHistory } from 'react-router-dom';
import getOrganizationConfigs from 'redux/actions/Common/getOrganizationConfigs';
import integrateHubspot from 'redux/actions/Common/integrateHubspot';
import getIntegrationKey from 'redux/actions/Common/getIntegrationKey';
import AutoCompleteMUI from 'components/@2024/AutoCompleteMUI';
import getGoogleOAuthUrl from 'redux/actions/Common/getGoogleOAuthUrl';
import updatePlaybookHistory from 'redux/actions/Common/updatePlaybookHistory';
import PlaybookGeneration from '../PlaybookGeneration';
import updatePresetQuestions from 'redux/actions/Common/updatePresetQuestions';
import SkillTagInput from 'components/@2024/SkillTagInput';
import getPrivacyTos from 'redux/actions/Common/getPrivacyTos';

const validationSchema = yup.object({
  currentPassword: yup
    .string()
    .trim()
    .required('Please enter your current password'),
  newPassword: yup
    .string()
    .trim()
    .required('Please enter new password')
    .min(5, 'New password should be atleast 5 character long'),
});

const validationSchemaPlaybook = yup.object().nullable();

const PrivacyPolicy = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = useLocation();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const isXs = useMediaQuery(theme.breakpoints.only('xs'), {
    defaultMatches: false,
  });
  const params = new URLSearchParams(window.location.search);
  const { isLoggedIn } = useSelector((state) => state.firebaseSession);
  const { userDetails } = useSelector((state) => state.userDetails);
  const { orgConfigs } = useSelector((state) => state.orgConfigs);
  const { dashboardSidebarExpanded } = useSelector((state) => state.uiStates);
  const userInfo = JSON.parse(localStorage.getItem('userInfo') || '{}');

  const [fetching, setFetching] = useState(false);

  const [content, setContent] = useState('');

  useEffect(() => {
    console.log(window.location);
    setFetching(true);
    dispatch(
      getPrivacyTos(
        window.location.pathname === '/privacy' ? 'privacy_policy' : 'tos',
        (data) => {
          setContent(data);
          setFetching(false);
        },
      ),
    );
  }, [window.location.href]);

  return (
    <Box
      sx={{
        width: {
          xs: '100%',
          sm: isLoggedIn ? 'calc(100% - 80px)' : 1,
          md: isLoggedIn
            ? dashboardSidebarExpanded
              ? 'calc(100% - 240px)'
              : 'calc(100% - 80px)'
            : 1,
        },
        marginLeft: {
          xs: '0px',
          sm: isLoggedIn ? '80px' : 0,
          md: isLoggedIn ? (dashboardSidebarExpanded ? '240px' : '80px') : 0,
        },
        height: 1,
        minHeight: isLoggedIn ? 'calc(100vh - 60px)' : 'calc(100vh - 110px)',
      }}
    >
      <Container
        paddingX={
          isLoggedIn
            ? dashboardSidebarExpanded
              ? 2
              : { lg: 16, md: 8, sm: 4, xs: 1 }
            : 8
        }
        paddingY={{ lg: 2, md: 2, sm: 2, xs: 3 }}
        maxWidth={{ xs: 1, sm: 1, md: isLoggedIn ? 1 : 1236 }}
      >
        <Box>
          {fetching ? (
            <Box
              display={'flex'}
              flexDirection={'column'}
              gap={2}
              justifyContent={'center'}
              alignItems={'center'}
              minHeight={300}
            >
              <CircularProgress size={24} />
              <Typography>
                Fetching{' '}
                {window.location.pathname === '/privacy'
                  ? 'Privacy Policy'
                  : 'Terms of Service'}
              </Typography>
            </Box>
          ) : content ? (
            <>
              {window.location.host !== 'app.qualification.ai' &&
              window.location.host !== 'app.sellingbees.com' &&
              window.location.host !== 'localhost:3000' ? (
                <Box
                  sx={{
                    // padding: '4px 8px',
                    ml: 2,
                  }}
                >
                  <Typography variant="h6" color={'text.primary'}>
                    Powered by{' '}
                    <span style={{ fontWeight: 'bold' }}>Qualification AI</span>
                  </Typography>
                </Box>
              ) : null}
              <RichTextEditor value={content} readOnly onChange={() => {}} />
            </>
          ) : null}
        </Box>
      </Container>
    </Box>
  );
};

export default PrivacyPolicy;
