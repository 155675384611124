import {
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
  useTheme,
  Modal,
  Divider,
  Link,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tabs,
  Tab,
  Chip,
  useMediaQuery,
  DialogActions,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Tooltip,
  InputAdornment,
  FormLabel,
  FormControlLabel,
  Checkbox,
  FormGroup,
  RadioGroup,
  Radio,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  Drawer,
  Alert,
  Autocomplete,
  Badge,
  Popper,
  Fab,
  Menu,
  ListSubheader,
  Stepper,
  Step,
  StepButton,
  Switch,
  Popover,
  Avatar,
} from '@mui/material';
import React, { memo, useEffect, useRef, useState } from 'react';
import Container from 'components/Container';
import { useDispatch, useSelector } from 'react-redux';
import {
  Add,
  ArrowBack,
  ArrowDropDown,
  ArrowForward,
  ArrowLeft,
  ArrowRight,
  ArrowRightAlt,
  Article,
  Assistant,
  AudioFile,
  Audiotrack,
  AutoAwesome,
  Bookmarks,
  Cached,
  CancelPresentation,
  Chat,
  Check,
  CheckBoxOutlineBlank,
  ChevronRight,
  Close,
  CloudUpload,
  Delete,
  DisabledByDefault,
  ErrorOutline,
  Explore,
  FindReplace,
  Flag,
  HelpOutline,
  Info,
  KeyboardArrowDown,
  KeyboardArrowRight,
  KeyboardArrowUp,
  KeyboardDoubleArrowRight,
  Label,
  LocationOn,
  Lock,
  Loop,
  Mic,
  MoreVert,
  PlayArrow,
  PushPin,
  Redo,
  Refresh,
  Search,
  Send,
  Settings,
  StopCircle,
  Subject,
  TaskAlt,
  TextSnippet,
  Timeline,
  Troubleshoot,
  Visibility,
  Warning,
  WarningAmber,
} from '@mui/icons-material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useHistory } from 'react-router-dom';
//actions
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { detect } from 'detect-browser';
import moment from 'moment';
import RichTextEditor from 'components/RichTextEditor/RichTextEditor';
import createSalesAnalysisThread from 'redux/actions/Candidate/createSalesAnalysisThread';
import showAlert from 'redux/actions/Common/showAlert';
import checkSalesCallTranscriptStatus from 'redux/actions/Common/checkSalesCallTranscriptStatus';
import getSalesCallReviews from 'redux/actions/Common/getDeals';
import analyzeSalesCall from 'redux/actions/Common/analyzeSalesCall';

import { PropTypes } from 'prop-types';
import { Bar, Pie } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip as TooltipChart,
  Legend,
  ArcElement,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import LineGraph from 'components/LineGraph';
import parse from 'html-react-parser';
import getUserDetails from 'redux/actions/Common/getUserDetails';
import trackEvent from 'redux/actions/Common/trackEvent';
import logError from 'redux/actions/Common/logError';
import { capitalizeText, formatSeconds, getFileType } from 'utils';
import getAccounts from 'redux/actions/Compass/getAccounts';
import { useFormik, withFormik } from 'formik';
import * as yup from 'yup';
import createAccount from 'redux/actions/Compass/createAccount';
import updateAccountDetails from 'redux/actions/Compass/updateAccountDetails';
import deleteAccount from 'redux/actions/Compass/deleteAccount';
import FormField from 'components/Compass/FormField';
import getAllTeamMembers from 'redux/actions/Common/getAllTeamMembers';
import getCompanyProfileById from 'redux/actions/Compass/getCompanyProfileById';
import updateCompanyProfile from 'redux/actions/Compass/updateCompanyProfile';
import getCompanyProspects from 'redux/actions/Compass/getCompanyProspects';
import assignAccountToTeamMember from 'redux/actions/Compass/assignAccountToTeamMember';
import updateCompanyProspectDetails from 'redux/actions/Compass/updateCompanyProspectDetails';
import ProspectMembers from 'components/Compass/ProspectMembers';
import DealActivities from 'components/Compass/DealActivities';
import getAllDealActivities from 'redux/actions/Compass/getAllDealActivities';
import OrgChart from 'components/Compass/OrgChart';
import getQuestionFramework from 'redux/actions/Compass/getQuestionFramework';
import updateQuestionAnswers from 'redux/actions/Compass/updateQuestionAnswers';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import updateOption from 'redux/actions/Compass/updateOption';
import getSectionParents from 'redux/actions/Compass/getSectionParents';
import getSectionsByLevel from 'redux/actions/Compass/getSectionsByLevel';
import markSectionNonRelevant from 'redux/actions/Compass/markSectionNonRelevant';
import getTeams from 'redux/actions/Common/getTeams';
import deleteTeam from 'redux/actions/Common/deleteTeam';
import createTeam from 'redux/actions/Common/createTeam';
import updateTeam from 'redux/actions/Common/updateTeam';
import TeamSettings from '../TeamSettings';
import updateGlobalOrganizationConfig from 'redux/actions/Common/updateGlobalOrganizationConfig';
import getOrganizationConfigs from 'redux/actions/Common/getOrganizationConfigs';

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  TooltipChart,
  Legend,
  ChartDataLabels,
);

const validationSchema = yup.object({
  name: yup
    .string()
    .trim()
    .required('Please enter team name')
    .min(3, 'team name should have at least 3 characters'),
  description: yup
    .string()
    .trim()
    .nullable(),
});

const VideoPreview = memo(
  function VideoPreview({ video, onDurationChange }) {
    return (
      <Box
        display={'flex'}
        flexDirection={'column'}
        sx={{ alignItems: 'center', justifyContent: 'flex-start' }}
      >
        {video ? (
          <Box width={'90%'} display={'flex'} flexDirection={'column'} gap={1}>
            <video
              style={{ width: '100%' }}
              preload={false}
              src={
                typeof video === 'string'
                  ? video
                  : window.URL.createObjectURL(video)
              }
              controls
              controlsList="nodownload"
              disablePictureInPicture
              onDurationChange={onDurationChange}
            />
          </Box>
        ) : null}
      </Box>
    );
  },
  (pre, post) => {
    return pre?.video === post?.video;
  },
);

const Teams = ({ uId, parentComponent }) => {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const params = new URLSearchParams(window.location.search);
  const { isLoggedIn } = useSelector((state) => state.firebaseSession);
  const userInfo = JSON.parse(localStorage.getItem('userInfo') || '{}');
  const organization = JSON.parse(localStorage.getItem('organization') || '{}');
  const {
    dashboardSidebarExpanded,
    questionFrameworkSteps,
    activeQuestionFrameworkStep,
    pinnedTeams,
    navExpandedStatus,
  } = useSelector((state) => state.uiStates);
  const { uploadingFor, uploadPercentage } = useSelector(
    (state) => state.createSalesAnalysisThreadState,
  );
  const chatsScrollview = useRef(null);
  const { orgConfigs } = useSelector((state) => state.orgConfigs);
  const {
    teams,
    fetching,
    fetchingPagination,
    lastSeen,
    emptyList,
  } = useSelector((state) => state.teamsGroup);
  const showControls =
    localStorage.getItem('showControls') === 'true' ? true : false;

  const { userDetails } = useSelector((state) => state.userDetails);
  const { members } = useSelector((state) => state.allTeamMembers);
  const { prospects } = useSelector((state) => state.companyProspects);
  const { membersByOrgId } = useSelector((state) => state.membersByOrgId);
  const allTeamMembers = useSelector((state) => state.allTeamMembers);
  const dealActivities = useSelector((state) => state.dealActivities);
  const {
    showCompetitionDetailsOnTopbar,
    currentContextData,
    showBackButtonOnTopbar,
    currentNavigationLevel,
    resetQuestionFrameworkStep,
  } = useSelector((state) => state.uiStates);
  const [loading, setLoading] = useState(false);
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const isXs = useMediaQuery(theme.breakpoints.only('xs'), {
    defaultMatches: true,
  });
  const isSm = useMediaQuery(theme.breakpoints.only('sm'), {
    defaultMatches: true,
  });

  const browser = detect();

  const [showCreateTeam, setShowCreateTeam] = useState(null);
  const [creatingTeam, setCreatingTeam] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState(null);

  const [currentTeamIndex, setCurrentTeamIndex] = useState(null);

  const [viewType, setViewType] = useState('manage');
  const [currentTeam, setCurrentTeam] = useState(null);
  const [currentTeamTab, setCurrentTeamTab] = useState('');
  const [defaultTeamsTab, setDefaultTeamsTab] = useState('');

  const initialValues = {
    name: '',
    description: '',
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setCurrentTeamIndex(null);
  };

  const onSubmit = (values) => {
    setCreatingTeam(true);
    // console.log(values);
    if (showCreateTeam === 'new') {
      dispatch(
        createTeam(
          {
            name: values.name,
            description: values.description,
          },
          (data, error) => {
            formik.resetForm();
            if (error) {
              setCreatingTeam(false);
              dispatch(
                showAlert({
                  message: 'Failed to create team. Please try again later!',
                  showCross: true,
                  title: null,
                  type: 'error',
                  autoHideDuration: 2000,
                  vertical: 'top',
                  horizontal: 'center',
                }),
              );
            } else {
              dispatch(
                getTeams(10, null, (companies) => {
                  setCreatingTeam(false);
                  setShowCreateTeam(null);
                  setCurrentTeamTab('manageusers');
                  setDefaultTeamsTab('invite');
                  history.push(
                    `/teams/${data?.id}/team-settings?manageusers=true&teaminvite=true`,
                  );
                  dispatch({
                    type: 'UPDATE_UI_STATE',
                    payload: {
                      key: 'navExpandedStatus',
                      value: {
                        ...navExpandedStatus,
                        [data?.id]: true,
                      },
                    },
                  });
                }),
              );
              dispatch(
                showAlert({
                  message: 'Team created successfully',
                  showCross: true,
                  title: null,
                  type: 'success',
                  autoHideDuration: 2000,
                  vertical: 'top',
                  horizontal: 'center',
                }),
              );
            }
          },
        ),
      );
    } else {
      dispatch(
        updateTeam(
          showCreateTeam?.id,
          {
            name: values.name,
            description: values.description,
            defaultTeam: showCreateTeam?.defaultTeam,
          },
          (teamData) => {
            setCreatingTeam(false);
            setShowCreateTeam(null);
            formik.resetForm();
            dispatch({
              type: 'UPDATE_TEAM_DETAILS',
              payload: {
                id: showCreateTeam?.id,
                data: {
                  ...teamData,
                  name: values.name,
                  description: values.description,
                },
              },
            });
            dispatch(
              showAlert({
                message: 'Team details updated successfully',
                showCross: true,
                title: null,
                type: 'success',
                autoHideDuration: 2000,
                vertical: 'top',
                horizontal: 'center',
              }),
            );
          },
          () => {
            setCreatingTeam(false);
            dispatch(
              showAlert({
                message:
                  'Failed to update team details. Please try again later!',
                showCross: true,
                title: null,
                type: 'error',
                autoHideDuration: 2000,
                vertical: 'top',
                horizontal: 'center',
              }),
            );
          },
        ),
      );
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  const handleTrackEvent = (e, type) => {
    try {
      dispatch(
        trackEvent({
          context: 'click_event',
          eventType: type,
          userId: isLoggedIn ? userInfo?.id : null,
          time: new Date().getTime(),
          os: browser?.os,
          sessionId: isLoggedIn
            ? userInfo?.id
            : sessionStorage.getItem('trackingSessionId'),
          customData: {
            page_path: pathname,
            browser: browser?.name,
            browser_version: browser?.version,
          },
        }),
      );
    } catch (e) {
      console.log(e);
      dispatch(
        logError({
          message: e?.message,
          data: {
            formatted: {
              errorText: e?.message,
              severity: 'error',
            },
            raw: e,
          },
        }),
      );
    }
  };

  useEffect(() => {
    if (!showBackButtonOnTopbar) {
      setViewType('list');
      setCurrentTeam(null);
    }
  }, [showBackButtonOnTopbar]);

  useEffect(() => {
    if (params && params.get('dashboard') === 'true') {
      setViewType('dashboard');
    } else {
      setViewType('list');
    }
  }, [window.location.href]);

  useEffect(() => {
    console.log('currentContextData', currentContextData);
    console.log('currentContextData', currentTeamTab);
    if (viewType === 'manage' && currentTeam) {
      dispatch({
        type: 'UPDATE_UI_STATE',
        payload: {
          key: 'showBackButtonOnTopbar',
          value: true,
        },
      });
      dispatch({
        type: 'UPDATE_UI_STATE',
        payload: {
          key: 'currentContextData',
          value: {
            ...currentContextData,
            currentTeam: currentTeam?.name,
            currentTeamTab:
              currentTeamTab ||
              currentContextData?.currentTeamTab ||
              'manageusers',
          },
        },
      });
    } else {
      setDefaultTeamsTab('');
      dispatch({
        type: 'UPDATE_UI_STATE',
        payload: {
          key: 'showBackButtonOnTopbar',
          value: false,
        },
      });
      dispatch({
        type: 'UPDATE_UI_STATE',
        payload: {
          key: 'currentContextData',
          value: null,
        },
      });
    }
  }, [viewType, currentTeam, currentTeamTab]);

  // useEffect(() => {
  //   if (currentContextData) {
  //     setViewType('manage');
  //   } else {
  //     setCurrentTeam(null);
  //     setViewType('list');
  //   }
  // }, [currentContextData]);

  useEffect(() => {
    if (showCreateTeam && showCreateTeam !== 'new') {
      formik.setValues({
        name: showCreateTeam?.name,
        description: showCreateTeam?.description,
      });
    }
  }, [showCreateTeam]);

  useEffect(() => {
    if (currentTeam && viewType === 'manage') {
      console.log('test');
    } else {
      console.log('test');
    }
  }, [currentTeam, viewType]);

  useEffect(() => {
    dispatch(getTeams(10, null, (data) => {}));
    return () => {
      dispatch({
        type: 'UPDATE_UI_STATE',
        payload: {
          key: 'showBackButtonOnTopbar',
          value: false,
        },
      });
      dispatch({
        type: 'UPDATE_UI_STATE',
        payload: {
          key: 'currentContextData',
          value: null,
        },
      });
    };
  }, []);

  // console.log(selectedAnswers);
  // console.log(listOfGroups);
  // console.log(listOfPages);
  // console.log(expandedSections);
  // console.log(currentContextData);
  // console.log('currentTeamTab', currentTeamTab);
  // console.log(pinnedTeam);
  // console.log(teams);

  return (
    <Box
      sx={{
        width: {
          xs: '100%',
          sm: isLoggedIn ? 'calc(100% - 80px)' : 1,
          md:
            isLoggedIn && !uId
              ? dashboardSidebarExpanded
                ? 'calc(100% - 240px)'
                : 'calc(100% - 80px)'
              : 1,
        },
        marginLeft: {
          xs: '0px',
          sm: isLoggedIn ? '80px' : 0,
          md:
            isLoggedIn && !uId
              ? dashboardSidebarExpanded
                ? '240px'
                : '80px'
              : 0,
        },
        height: 1,
        minHeight:
          isLoggedIn && !uId ? 'calc(100vh - 60px)' : 'calc(100vh - 146px)',
      }}
    >
      <Box
        display={'flex'}
        flexDirection={{ xs: 'column', sm: 'column', md: 'row' }}
        height={1}
      >
        <Box
          flex={{ xs: 'auto', sm: 'auto', md: 1 }}
          display={'flex'}
          flexDirection={{ xs: 'row', sm: 'row', md: 'column' }}
          alignItems={{
            xs: 'start',
            sm: 'start',
            md: 'start',
          }}
          justifyContent={{
            xs: 'start',
            sm: 'start',
            md: 'flex-start',
          }}
          height={1}
          gap={2}
          sx={{
            borderRight: '1px solid #f1f1f1',
            minHeight: isXs ? 'auto' : 'calc(100vh - 60px)',
            padding: viewType === 'list' ? 2 : 0,
            paddingTop: viewType === 'list' ? 2 : 0,
          }}
        >
          {viewType === 'list' ? (
            <Box display={'flex'} flexDirection={'column'} gap={1} width={1}>
              <Box display={'flex'} alignItems={'center'} gap={2} mb={2}>
                <Typography>All Teams</Typography>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<Add />}
                  size="small"
                  onClick={() => setShowCreateTeam('new')}
                >
                  Create Team
                </Button>
                {/* ) : null} */}
              </Box>
              {fetching ? (
                <Box
                  width={1}
                  height={1}
                  display={'flex'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  flexDirection={'column'}
                >
                  <CircularProgress size={20} />
                  <Typography variant="body1" color={'text.primary'} mt={4}>
                    Loading ...
                  </Typography>
                </Box>
              ) : teams && teams?.length > 0 ? (
                <Grid container spacing={2}>
                  {teams?.map((team, index) => (
                    <Grid item xs={12} key={team?.id} sm={6} md={4} lg={4}>
                      <Card
                        id={team?.defaultTeam ? 'defaultTeamCard' : ''}
                        sx={{
                          padding: 2,
                          position: 'relative',
                          paddingBottom: 10,
                          display: 'flex',
                          flexDirection: 'column',
                          gap: 1,
                          alignItems: 'start',
                          cursor: 'pointer',
                          minHeight: '180px',
                        }}
                        onClick={(e) => {
                          history.push(
                            `/teams/${team?.id}/team-settings?scoresboard=true`,
                          );
                        }}
                      >
                        <Typography
                          variant="body1"
                          fontWeight={'bold'}
                          color={'text.secondary'}
                        >
                          {team?.name}
                        </Typography>
                        {team?.description ? (
                          <Tooltip
                            arrow
                            placement="bottom"
                            title={team?.description}
                          >
                            <Typography
                              variant="body1"
                              color={'text.secondary'}
                              sx={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                display: '-webkit-box',
                                WebkitLineClamp: '2',
                                WebkitBoxOrient: 'vertical',
                              }}
                            >
                              {team?.description}
                            </Typography>
                          </Tooltip>
                        ) : null}
                        {/* <Box
                          sx={{
                            position: 'absolute',
                            bottom: 18,
                            left: 18,
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1,
                          }}
                        >
                          <Button
                            variant="outlined"
                            size="small"
                            color="primary"
                            startIcon={<Settings sx={{ fontSize: 16 }} />}
                            sx={{
                              padding: '4px 8px',
                            }}
                            onClick={() => {
                              history.push(
                                `/teams/${team?.id}/sales-call-analysis`,
                              );
                              if (
                                !pinnedTeams?.find((t) => t?.id === team?.id)
                              ) {
                                dispatch({
                                  type: 'UPDATE_UI_STATE',
                                  payload: {
                                    key: 'currentTeamToManage',
                                    value: team,
                                  },
                                });
                              }
                            }}
                          >
                            Manage
                          </Button>
                        </Box> */}
                        <Typography
                          variant="caption"
                          color={'text.secondary'}
                          sx={{
                            position: 'absolute',
                            bottom: 18,
                            left: 18,
                          }}
                        >
                          Created On{' '}
                          {moment(team?.createOn).format('DD MMM YYYY')}
                        </Typography>
                        {/* {team?.id !== team?.organizationId ? ( */}
                        <Box
                          sx={{
                            position: 'absolute',
                            top: 5,
                            right: 5,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: 1,
                          }}
                        >
                          {team?.defaultTeam ? (
                            <Chip
                              size="small"
                              label={'default'}
                              color="primary"
                              // variant="outlined"
                            />
                          ) : null}
                          {/* <Tooltip
                            arrow
                            placement="top"
                            title={
                              team?.defaultTeam
                                ? 'You cannot unpin default team. If you still want to unpin this team, make different team as default team.'
                                : 'Pin Team as Quick Access'
                            }
                          >
                            <IconButton
                              size="small"
                              onClick={(e) => {
                                if (
                                  pinnedTeams?.find((t) => t?.id === team?.id)
                                ) {
                                  if (!team?.defaultTeam) {
                                    dispatch({
                                      type: 'UPDATE_UI_STATE',
                                      payload: {
                                        key: 'pinnedTeams',
                                        value: pinnedTeams?.filter(
                                          (t) => t?.id !== team?.id,
                                        ),
                                      },
                                    });
                                    dispatch(
                                      updateGlobalOrganizationConfig(
                                        {
                                          pinnedTeamIds: orgConfigs?.pinnedTeamIds?.filter(
                                            (id) => id !== team?.id,
                                          ),
                                        },
                                        () => {
                                          dispatch(
                                            getOrganizationConfigs(() => {}),
                                          );
                                        },
                                      ),
                                    );
                                  }
                                } else {
                                  dispatch({
                                    type: 'UPDATE_UI_STATE',
                                    payload: {
                                      key: 'pinnedTeams',
                                      value: [...pinnedTeams, team],
                                    },
                                  });
                                  dispatch(
                                    updateGlobalOrganizationConfig(
                                      {
                                        pinnedTeamIds: [
                                          ...(orgConfigs?.pinnedTeamIds || []),
                                          team?.id,
                                        ],
                                      },
                                      () => {
                                        dispatch(
                                          getOrganizationConfigs(() => {}),
                                        );
                                      },
                                    ),
                                  );
                                }
                              }}
                            >
                              <PushPin
                                sx={{
                                  fontSize: 20,
                                  color: team?.defaultTeam
                                    ? '#d3d3d3'
                                    : pinnedTeams?.find(
                                        (t) => t?.id === team?.id,
                                      )
                                    ? theme.palette.primary.main
                                    : 'default',
                                }}
                              />
                            </IconButton>
                          </Tooltip> */}
                          <IconButton
                            size="small"
                            onClick={(e) => {
                              e.stopPropagation();
                              setAnchorEl(e.target);
                              setCurrentTeamIndex(index);
                            }}
                          >
                            <MoreVert />
                          </IconButton>
                        </Box>
                        {/* ) : null} */}
                      </Card>
                      <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl) && currentTeamIndex === index}
                        onClose={handleMenuClose}
                        sx={{
                          '& .MuiMenu-paper': {
                            boxShadow: '0 2px 2px 2px rgba(140, 152, 164, 0.2)',
                          },
                        }}
                      >
                        <Tooltip
                          arrow
                          placement="top"
                          title={
                            team?.defaultTeam
                              ? 'This is already a default team'
                              : ''
                          }
                        >
                          <MenuItem
                            // disabled
                            onClick={() => {
                              if (
                                !pinnedTeams?.find((t) => t?.id === team?.id)
                              ) {
                                dispatch({
                                  type: 'UPDATE_UI_STATE',
                                  payload: {
                                    key: 'pinnedTeams',
                                    value: [...pinnedTeams, team],
                                  },
                                });
                                dispatch(
                                  updateGlobalOrganizationConfig(
                                    {
                                      pinnedTeamIds: [
                                        ...(orgConfigs?.pinnedTeamIds || []),
                                        team?.id,
                                      ],
                                    },
                                    () => {
                                      dispatch(
                                        getOrganizationConfigs(() => {}),
                                      );
                                    },
                                  ),
                                );
                              }
                              dispatch(
                                updateTeam(
                                  team?.id,
                                  {
                                    defaultTeam: !team?.defaultTeam,
                                  },
                                  (teamData) => {
                                    handleMenuClose();
                                    formik.resetForm();
                                    dispatch(getTeams(10, null, (data) => {}));
                                    dispatch(
                                      showAlert({
                                        message: 'Team is set to default now.',
                                        showCross: true,
                                        title: null,
                                        type: 'success',
                                        autoHideDuration: 2000,
                                        vertical: 'top',
                                        horizontal: 'center',
                                      }),
                                    );
                                  },
                                  () => {
                                    dispatch(
                                      showAlert({
                                        message:
                                          'Failed to make the team default. Please try again later!',
                                        showCross: true,
                                        title: null,
                                        type: 'error',
                                        autoHideDuration: 2000,
                                        vertical: 'top',
                                        horizontal: 'center',
                                      }),
                                    );
                                  },
                                ),
                              );
                            }}
                            sx={{
                              color: team?.defaultTeam
                                ? 'text.disabled'
                                : 'text.primary',
                            }}
                          >
                            Make as Default
                          </MenuItem>
                        </Tooltip>
                        <MenuItem
                          // disabled
                          onClick={() => {
                            setShowCreateTeam(team);
                            handleMenuClose();
                          }}
                        >
                          Update Team
                        </MenuItem>
                        <Tooltip
                          arrow
                          placement="top"
                          title={
                            team?.defaultTeam
                              ? 'You cannot delete the default team. Please make a different team as default team if you want to delete this team.'
                              : ''
                          }
                        >
                          <MenuItem
                            onClick={() => {
                              if (!team?.defaultTeam) {
                                setShowConfirmDeleteDialog(team?.id);
                                setAnchorEl(null);
                              }
                            }}
                            sx={{
                              color: team?.defaultTeam
                                ? 'text.disabled'
                                : 'text.primary',
                            }}
                          >
                            Delete Team
                          </MenuItem>
                        </Tooltip>
                      </Menu>
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <Box
                  width={1}
                  height={1}
                  display={'flex'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  flexDirection={'column'}
                  gap={2}
                >
                  <Typography variant="body1" color={'text.secondary'} mt={4}>
                    No teams to show here yet.
                  </Typography>
                </Box>
              )}
            </Box>
          ) : viewType === 'view' ? (
            <Box display={'flex'} flexDirection={'column'} width={1}>
              <Box
                sx={{
                  height: '100%',
                  borderRadius: 2,
                  // paddingBottom: 2,
                  minHeight: 'calc(100vh - 80px)',
                  maxHeight: 'calc(100vh - 80px)',
                  overflowY: 'scroll',
                  '&::-webkit-scrollbar': {
                    width: '5px',
                  },
                  '&::-webkit-scrollbar-track': {
                    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: theme.palette.primary.main,
                    borderRadius: 2,
                    // outline: '1px solid slategrey',
                  },
                }}
              >
                <Box
                  sx={{
                    padding: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    paddingTop: 1,
                  }}
                ></Box>
              </Box>
            </Box>
          ) : (
            <Box></Box>
          )}
        </Box>
      </Box>
      <Modal
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        open={Boolean(showCreateTeam)}
        onClose={(e, reason) => {
          if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
            return;
          }
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box
          sx={{
            backgroundColor: theme.palette.background.paper,
            // boxShadow: theme.shadows[5],
            outline: 'none',
            maxHeight: '90vh',
            maxWidth: '40vw',
            minWidth: '40vw',
            overflowY: 'auto',
            borderRadius: 1,
            // padding: 2,
            position: 'relative',
          }}
        >
          <IconButton
            sx={{
              position: 'absolute',
              top: 10,
              right: 10,
              padding: '4px',
              background: theme.palette.background.default,
              ':hover': {
                backgroundColor: '#d3d3d3',
              },
            }}
            onClick={(e) => {
              setShowCreateTeam(null);
            }}
          >
            <Close sx={{ fontSize: 20 }} />
          </IconButton>
          <Box
            p={2}
            sx={{
              borderBottom: '1px solid #d3d3d3',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Typography color={'text.secondary'}>
              {showCreateTeam === 'new' ? 'Create Team' : 'Update Team Details'}
            </Typography>
          </Box>
          <Box
            sx={{
              width: '100%',
              maxHeight: 'calc(90vh - 58px)',
              overflowY: 'scroll',
              '&::-webkit-scrollbar': {
                width: '5px',
              },
              '&::-webkit-scrollbar-track': {
                boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: theme.palette.primary.main,
                borderRadius: 2,
              },
              margin: '0 auto',
              overflowX: 'hidden',
              paddingTop: 2,
            }}
          >
            <form
              noValidate
              autoComplete="off"
              onSubmit={formik.handleSubmit}
              style={{ width: '100%' }}
            >
              <Box display={'flex'} flexDirection={'column'} gap={2} p={2}>
                <TextField
                  autoFocus
                  label="Team Name*"
                  placeholder="Enter Team Name*"
                  variant="outlined"
                  color="primary"
                  size="small"
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  sx={{ flex: 4 }}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />
                <TextField
                  // autoFocus
                  label="Team Description"
                  placeholder="Enter Team Description"
                  variant="outlined"
                  color="primary"
                  size="small"
                  name="description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  multiline
                  rows={4}
                  sx={{ flex: 4 }}
                  error={
                    formik.touched.description &&
                    Boolean(formik.errors.description)
                  }
                  helperText={
                    formik.touched.description && formik.errors.description
                  }
                />
                <Box
                  display={'flex'}
                  gap={2}
                  sx={{ flex: 1, alignSelf: 'flex-end' }}
                >
                  <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    type="submit"
                    disabled={creatingTeam}
                    startIcon={
                      creatingTeam ? (
                        <CircularProgress size={20} />
                      ) : showCreateTeam === 'new' ? (
                        <Add />
                      ) : null
                    }
                  >
                    {showCreateTeam === 'new' ? 'Create Team' : 'Update'}
                  </Button>
                </Box>
              </Box>
            </form>
          </Box>
        </Box>
      </Modal>
      <Dialog
        open={Boolean(showConfirmDeleteDialog)}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          zIndex: 10002,
          '& .MuiPaperRootMuiDialogPaper': {
            maxWidth: '70vw',
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">Confirm</DialogTitle>
        <DialogContent>
          <Box display={'flex'} alignItems={'center'} gap={2}>
            <DialogContentText id="alert-dialog-description" flex={1}>
              Are you sure you want to delete this team?
            </DialogContentText>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            // data-trackid={'cta_permission_denied_dialog_close'}
            onClick={(e) => {
              setShowConfirmDeleteDialog(null);
              // if (testMode) setShowTestDeviceDialog(null);
            }}
            color="primary"
            // autoFocus
          >
            Cancel
          </Button>
          <Button
            size="small"
            variant="contained"
            // data-trackid={'cta_permission_denied_dialog_close'}
            onClick={(e) => {
              dispatch(
                deleteTeam(showConfirmDeleteDialog, (data) => {
                  setShowConfirmDeleteDialog(false);
                  dispatch({
                    type: 'DELETE_TEAM',
                    payload: showConfirmDeleteDialog,
                  });
                  dispatch(
                    showAlert({
                      message: 'Team deleted successfully.',
                      showCross: true,
                      title: null,
                      type: 'success',
                      autoHideDuration: 2000,
                      vertical: 'top',
                      horizontal: 'center',
                    }),
                  );
                }),
              );
            }}
            color="primary"
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

Teams.propTypes = {
  uId: PropTypes.string,
  member: PropTypes.object,
  parentComponent: PropTypes.string,
};

VideoPreview.propTypes = {
  video: PropTypes.any,
  onDurationChange: PropTypes.func,
};

export default Teams;
