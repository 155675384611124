import { auth } from '../../../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import axios from 'utils/axios';
import { v4 as uuidv4 } from 'uuid';

const crmHubspotSync = (callback, errCallback) => {
  return async (dispatch) => {
    dispatch({ type: 'SYNCING_HUBSPOT_CRM_DEALS' });
    try {
      let unsubscribe = onAuthStateChanged(auth, async (user) => {
        const token = await user?.getIdToken();
        const userInfo = localStorage.getItem('userInfo');
        const userId = JSON.parse(userInfo)?.id;
        const reqId = uuidv4();
        try {
          const response = await axios.post(`/us/qai/deals/crm-sync`, null, {
            params: {
              'request-id': reqId,
            },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          if (response.status === 200) {
            await dispatch({
              type: 'SYNCING_HUBSPOT_CRM_DEALS_DONE',
            });
            if (typeof callback === 'function') {
              callback(response?.data);
            }
          } else {
            dispatch({
              type: 'SYNCING_HUBSPOT_CRM_DEALS_FAILED',
              payload: `Failed to sync crm deals`,
            });
            if (typeof errCallback === 'function') {
              errCallback();
            }
          }
        } catch (e) {
          dispatch({
            type: 'SYNCING_HUBSPOT_CRM_DEALS_FAILED',
            payload: `Failed to sync crm deals`,
          });
          if (typeof errCallback === 'function') {
            errCallback();
          }
        }
        unsubscribe();
      });
    } catch (e) {
      console.log(e);
      dispatch({
        type: 'SYNCING_HUBSPOT_CRM_DEALS_FAILED',
        payload: `Failed to sync crm deals`,
      });
      if (typeof errCallback === 'function') {
        errCallback();
      }
    }
  };
};

export default crmHubspotSync;
